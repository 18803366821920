import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import download from "../../../assets/Icon/Download.svg";
import sheetIcon from "../../../assets/Icon/Icons_Fund-fact-sheet.svg";
import ArrowUp from '../../../assets/Icon_Arrow_up.svg';
import DownloadFile from "../../../SharedComponents/DownloadFile";
import { brochureLinkOrso, brochureLinkOrsoPD_EN, brochureLinkOrsoPD_ZH, brochureLinkOrsoZH, brochureLinkSIMP_EN, brochureLinkSIMP_ZH, brochureLinkSMAP_EN, brochureLinkSMAP_ZH, HKAIIB, S800Acronym, schemeBrochureLinkConst, schemeBrochureLinkZhConst, SMP } from "../../../translations/common/commonConstants";
import { FormatUtils } from '../../../utils';
import "./Downloads.css";

const Downloads = (props) => {
    const { t, i18n } = useTranslation("common");
    const language = i18n.language;
    const history = useHistory();
    const selectedQuickPolicyInfo = props?.quickPolicyInfo[props.selectedPolicyIndex];
    const productTypeNmbr = selectedQuickPolicyInfo?.product?.productTypeNmbr;

    let brochureLink, brochureLinkZH;
    if (props?.isOrsoAccount) {
        if (productTypeNmbr == 125) {
            brochureLink = brochureLinkOrsoPD_EN;
            brochureLinkZH = brochureLinkOrsoPD_ZH;

        } else {
            brochureLink = brochureLinkOrso;
            brochureLinkZH = brochureLinkOrsoZH;
        }
    } else if (props.scheme == SMP) {
        if (productTypeNmbr == 129) {
            brochureLink = brochureLinkSIMP_EN;
            brochureLinkZH = brochureLinkSIMP_ZH;
        } else {
            brochureLink = brochureLinkSMAP_EN;
            brochureLinkZH = brochureLinkSMAP_ZH;
        }
    } else {
        brochureLink = schemeBrochureLinkConst;
        brochureLinkZH = schemeBrochureLinkZhConst;
    }

    return (
        <>
            <div className="container" id={"fund_details_downloads"}>
                <div className="mt-4">
                    <div className="row" id={"transaction-downloads"}>
                        <label className="downloads col-10 col-md-11 col-sm-11 col-xl-11 col-lg-11">{t('downloads.title')}</label>
                        <a href="#" data-toggle="collapse" id={"transaction-downloads"} data-target={"#transactionPannel-downloads"} aria-expanded="true" aria-controls={"transactionPannel-fundDetails"} className="col-1">
                            <img src={ArrowUp}></img>
                        </a>
                    </div>
                    <div className="collapse show row downloads-mg-bottom" data-parent={"#transaction-downloads"} id={"transactionPannel-downloads"}>
                        {(brochureLink && (props?.scheme?.includes(S800Acronym) && !props.isOrsoAccount) || props.scheme == SMP || (props.isOrsoAccount && productTypeNmbr == 105 || productTypeNmbr == 125)) &&
                            <div className="col-12">
                                <DownloadFile text={t(props?.isOrsoAccount ? "downloads.brochureOrso" : "downloads.brochure")} link={language === "en" ? brochureLink : brochureLinkZH} />
                            </div>
                        }
                        {props?.scheme?.includes(HKAIIB) &&
                            <div className="col-12">
                                <DownloadFile text={t("downloads.fundFactSheetLabel")} link={"https://rsc.principal.com.hk/AIIB/download/FFS_Principal_AIIB.PDF"} />
                            </div>
                        }
                        {((props?.scheme?.includes(S800Acronym) && !props?.isOrsoAccount) || (props?.isOrsoAccount) || props.scheme == SMP) && props.factSheetsLink &&
                            <div className="col-12">
                                <a
                                    onClick={() => history.push("/fundFactSheet")}
                                    rel="noreferrer"
                                    target="_blank"
                                    className="downloadLink"
                                >
                                    <div className="row bottom-border-line py-4 mx-auto">
                                        <div className="col-1 p-0 my-auto">
                                            <img src={sheetIcon} className="sheetImg" />
                                        </div>
                                        <div className="col-10 inner-label my-auto"> {language === "en" ? FormatUtils.formatFundName(props.fund.legalNm) : props.fund.othLegalNm}</div>
                                        <div className="col-1 p-0 my-auto">
                                            <img src={download} />
                                        </div>
                                    </div>
                                </a>
                            </div>
                        }
                        <p className="this-site-makes-exte">{t("downloads.downloadDisclaimer")}</p>
                    </div>
                </div>
            </div>
        </>
    );
};

Downloads.propTypes = {
    props: PropTypes.any,
    fund: PropTypes.object,
    productTypeNmbr: PropTypes.string,
    factSheetsLink: PropTypes.array,
    scheme: PropTypes.string,
    quickPolicyInfo: PropTypes.array,
    selectedPolicyIndex: PropTypes.number,
    isOrsoAccount: PropTypes.bool
};
const mapStateToProps = (state) => {
    return {
        productTypeNmbr: state.empLogin.productTypeNmbr,
        scheme: state.empLogin.scheme,
        selectedPolicyIndex: state.twoAccounts.selectedPolicyIndex,
        quickPolicyInfo: state.twoAccounts.quickPolicyInfo,
        isOrsoAccount: state.twoAccounts.isOrsoAccount
    };
};
export default connect(mapStateToProps)(Downloads);