import React from "react";
import { useTranslation } from "react-i18next";
import appStore from "../assets/App-Store-1.svg";
import facebook from "../assets/Footer/Footer_Facebook-1.svg";
import playStore from "../assets/Footer/Google_Play_Store_badge_EN.svg";
import principalApp from "../assets/Principal_App_icon-1.svg";
import linkedin from "../assets/Social_Linkedin-1.svg";
import youtube from "../assets/Social_Youtube-1.svg";
import {
  contactUsLink, contactUsZhLink, picsLink, picsZhLink, privStatLink, privStatZhLink,
  resourcesLink, resourcesZhLink, termsOfUseLink, termsOfUseZhLink
} from "../translations/common/commonConstants";

const Footer = () => {
  const { t, i18n } = useTranslation("common");
  const language = i18n.language;

  return (
    <>
      <div className="bg-dark footer-office-details">

        <div className="container">
          <div className="row">
            <div className="col-md-7 order-2 order-lg-1 order-md-1 col-sm-12 col-xs-12 col-lg-7 col-xl-7">
              <div className="d-block d-sm-none">
                <hr className="hr-line" />
              </div>
              <div className="address">
                <h4 className="header"> {t("footer.ofcAddr")}</h4>
                <h4 className="info">
                  <p> {t("footer.ofcAdrLine1")}</p>
                  <p> {t("footer.ofcAdrLine2")}</p>
                  <p> {t("footer.ofcAdrLine3")}</p>
                </h4>
              </div>
            </div>
            <div className="col-md-5 order-1 order-lg-2 order-md-2 col-sm-12 col-xs-12 col-lg-5 col-xl-5">
              <div className="contact">
                <h4 className="header"> {t("footer.usefulLinks")}</h4>
                <h4 className="info">
                  <p><a href={language === "en" ? contactUsLink : contactUsZhLink} target="_blank" rel="noopener noreferrer">{t("footer.constactUs")}</a></p>
                  <p><a href={language === "en" ? resourcesLink : resourcesZhLink} target="_blank" rel="noopener noreferrer">{t("footer.genFAQ")}</a></p>
                </h4>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-7 col-sm-12 col-xs-12 col-lg-7 col-xl-7">
              <div className="time">
                <p>{t("footer.ofcHours")}<span> {t("footer.ofcTime")}</span></p>
                <p> {t("footer.lunchHours")}<span> {t("footer.lunchTime")}</span></p>
                <p> {t("footer.closedOn")}</p>
              </div>
              <div className="d-block d-sm-none"><hr className="hr-line" /></div>
            </div>

            <div className="col-md-5 col-sm-12 col-xs-12 col-lg-5 col-xl-5 my-auto">
              <div className="follow-us ">
                <h4>{t("footer.followUS")}</h4>
                <div className="follow-icons">
                  <a target="_blank" rel="noreferrer" title="Linkedin"
                    href="https://hk.linkedin.com/organization-guest/company/principalhongkong"><img src={linkedin} />
                  </a>
                  <a target="_blank" rel="noreferrer" title="Facebook" href="https://www.facebook.com/PrincipalHongKong">
                    <img src={facebook} />
                  </a>
                  <a target="_blank" rel="noreferrer" title="Youtube" href="https://www.youtube.com/channel/UClTlDRehURiz_naxqnSuE4g">
                    <img src={youtube} />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="row copyright">
            <div className="col-lg-7 col-xl-7 col-sm-12 col-xs-12 col-md-7 order-2 order-lg-1 order-md-1 footer-copyright-text">
              <p>{t("footer.copyRight1")}{new Date().getFullYear()}{t("footer.copyRight2")}</p>
              <p>
                <a href={language === "en" ? termsOfUseLink : termsOfUseZhLink} target="_blank" rel="noopener noreferrer">{t("footer.termsOfUse")}</a>
                <a href={language === "en" ? privStatLink : privStatZhLink} target="_blank" rel="noopener noreferrer">{t("footer.privStmt")}</a>
                <a href={language === "en" ? picsLink : picsZhLink} target="_blank" rel="noopener noreferrer">{t("footer.pics")}</a>
              </p>
            </div>
            <div className="col-lg-5 col-xl-5 col-md-5 col-sm-12 col-xs-12 order-1 order-lg-2 order-md-2 mx-auto footer-download-app">
              <h4 className="heading">{t("footer.download")}</h4>
              <div className="row">
                <div className="col align-self-start mb-3">
                  <div className="logo-principal"><img src={principalApp} /></div>
                </div>
                <div className="col align-self-end d-flex justify-content-md-end mb-3 me-5">
                  <div className="app-download-icon">
                    <a title="Apple" href="https://apps.apple.com/hk/app/principal-mobile-mpf-centre/id730843326" target="_blank" rel="noopener noreferrer">
                      <img src={appStore} />
                    </a>
                  </div>
                  <div className="app-download-icon">
                    <a title="Google Play store" href="https://play.google.com/store/apps/details?id=principal.PTC.PrincipalHK" target="_blank" rel="noopener noreferrer">
                      <img src={playStore} />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </>
  );
};

export default Footer;
