import { SAVE_AUTHENTICATE_MFA_DATA, SAVE_CHANNEL_DATA, SAVE_MFA_ACTIVATION_RESPONSE, SAVE_SELECTED_CHANNEL, SAVE_SEND_OTP_RESPONSE } from "../actions/ActionConstants";

const initialState = {
    channelSelected: '',
    channelData: '',
    authMFAData: [],
    sendOtpResponse: {},
    activationResponse: {}
}

export default function twoFactorAuthenticationReducer(state = initialState, action) {
    switch (action.type) {
        case SAVE_SELECTED_CHANNEL:
            return { ...state, channelSelected: action.data };
        case SAVE_CHANNEL_DATA:
            return { ...state, channelData: action.data };
        case SAVE_AUTHENTICATE_MFA_DATA:
            return { ...state, authMFAData: action.data };
        case SAVE_SEND_OTP_RESPONSE:
            return { ...state, sendOtpResponse: action.data };
        case SAVE_MFA_ACTIVATION_RESPONSE:
            return { ...state, activationResponse: action.data };
        default:
            return state;
    }
}