import i18next from "i18next";
import React from "react";
import ReactDOM from "react-dom";
import { I18nextProvider } from "react-i18next";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from 'react-router-dom';
import { PersistGate } from "redux-persist/integration/react";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { persistor, store } from "./store";
import en from "./translations/en/translation.json";
import zh from "./translations/zh/translation.json";
import langUtils from "./utils/LangUtils";

var lang = langUtils.getFirstBrowserLanguage()
if(lang === "zh"){
  window.GLOBAL_LANG = "zh";
}else{
  window.GLOBAL_LANG = "en";
}
i18next.init({
  interpolation: { escapeValue: false }, // React already does escaping
  lng: lang === "zh" ? "zh" : "en", // language to use
  resources: {
    en: {
      common: en, // 'common' is our custom namespace
    },
    zh: {
      common: zh,
    },
  },
});

ReactDOM.render(
  <>
    <React.StrictMode>
      <I18nextProvider i18n={i18next}>
        <Router>
          <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
              <App />
            </PersistGate>
          </Provider>
        </Router>
      </I18nextProvider>
    </React.StrictMode>
  </>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
