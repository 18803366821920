
import { SAVE_ENGLISH_DROP_DOWN_INFO, SAVE_CHINESE_DROP_DOWN_INFO, SAVE_COUNTRY_DROP_DOWN, SAVE_FILTERED_ENGLISH_DROP_DOWN_INFO, SAVE_FILTERED_CHINESE_DROP_DOWN_INFO } from "../actions/ActionConstants";

const initialState = {
    engCountryDropDown: [],
    chineseCountryDropDown: [],
    countryDropDown: [],
    filteredEngCountryDropDown: [],
    filteredChineseCountryDropDown: []
}

export default function commonDropDownReducer(state = initialState, action) {
    switch (action.type) {
        case SAVE_ENGLISH_DROP_DOWN_INFO:
            return { ...state, engCountryDropDown: action.data };
        case SAVE_CHINESE_DROP_DOWN_INFO:
            return { ...state, chineseCountryDropDown: action.data };
        case SAVE_COUNTRY_DROP_DOWN:
            return { ...state, countryDropDown: action.data };
        case SAVE_FILTERED_ENGLISH_DROP_DOWN_INFO:
            return { ...state, filteredEngCountryDropDown: action.data}
        case SAVE_FILTERED_CHINESE_DROP_DOWN_INFO:
            return { ...state, filteredChineseCountryDropDown: action.data}
        default:
            return state;
    }
}