import {
    SAVE_AUTHENTICATE_MFA_DATA,
    SAVE_AUTHENTICATION_FLAG, SAVE_CHANNEL_DATA, SAVE_MFA_ACTIVATION_RESPONSE,
    SAVE_SELECTED_CHANNEL, SAVE_SEND_OTP_RESPONSE
} from "../actions/ActionConstants";

export function saveSelectedChannel(data) {
    return { type: SAVE_SELECTED_CHANNEL, data }
}
export function saveChannelData(data) {
    return { type: SAVE_CHANNEL_DATA, data }
}
export function saveAuthenticateMFAData(data) {
    return { type: SAVE_AUTHENTICATE_MFA_DATA, data }
}
export function saveSendOtpResponse(data) {
    return { type: SAVE_SEND_OTP_RESPONSE, data }
}
export function saveActivationResponseInfo(data) {
    return { type: SAVE_MFA_ACTIVATION_RESPONSE, data }
}
export function saveAuthenticationFlag(data) {
    return { type: SAVE_AUTHENTICATION_FLAG, data }
}