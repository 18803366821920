import PropTypes from 'prop-types';
import React from "react";
import { useTranslation } from "react-i18next";
import { connect } from 'react-redux';
import empPlan from "../../../assets/Employees.svg";
import ArrowRight from "../../../assets/Icon_Arrow_right.svg";
import "../../../css/EmpLogin.css";

const ErAgentsOtherInd = () => {
  const { t } = useTranslation("common");

  const onClickLinkOpen = () => {
    document.getElementById('mpf-other-login').click();
  }

  return (
    <React.Fragment>
      <div className="login-card mpf-other-card text-center" onClick={onClickLinkOpen} id="er-agent">
        <img src={empPlan} className="empPlan" />
        <div className="row">
          <div className="col-12">
            <a href={t("links.otherMemberType")} className="login-type-cards-title" name="goto">
              {t("titles.ErAgents")}
              <img src={ArrowRight} alt="" className="gotoArrow" />
            </a>
            <p className="lt-title-transparent">
              {t("titles.mpfEmployee").substring(t("titles.ErAgents").length + 3)}
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-12 lt-subtext">
            {t("ErAgents.ErAgentsText")}
          </div>
        </div>
        <a id="mpf-other-login" href={t("links.otherMemberType")}></a>
      </div>
    </React.Fragment>
  );
};

ErAgentsOtherInd.propTypes = {
  props: PropTypes.any,
  dispatch: PropTypes.func
}

const ErAgentsOtherIndComponent = connect()(ErAgentsOtherInd);
export default ErAgentsOtherIndComponent;