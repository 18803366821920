import React from "react";

const LoadingSection = () => {
  return (
    <div className="col-12 d-flex justify-content-center mx-auto">
      <div data-testid="dash-board-spinner" className="spinner-border spinner m-5" role="status">
        <span className="sr-only"></span>
      </div>
    </div>
  );
};

export default LoadingSection;
