import { SAVE_MERGER_ATTRIBUTES, SET_IS_MERGER, SAVE_NB_EXPENCE, RESET_MERGER_INFO } from "../actions/ActionConstants";


const initialState = {
    isMerger: false,
    mergerAttributes: [],
    npExpence: ''
}

export default function MergerInfoReducer(state = initialState, action) {

    switch (action.type) {
        case SET_IS_MERGER:
            return { ...state, isMerger: action.data };
        case SAVE_MERGER_ATTRIBUTES:
            return { ...state, mergerAttributes: action.data };
        case SAVE_NB_EXPENCE:
            return { ...state, npExpence: action.data };
        case RESET_MERGER_INFO:
            return { ...state, isMerger: false, mergerAttributes: [], npExpence: '' };

        default:
            return state;
    }

}