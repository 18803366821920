
const getFundClass = (fundInfoData, invTypeNmbr, invTypeNm) => {
  let fundClass = ''
  invTypeNmbr = invTypeNmbr ?? -1
  invTypeNm = invTypeNm ?? ''
  fundInfoData = Array.isArray(fundInfoData) ? fundInfoData : []

  const regEx1 = /-[\w]{1}$/
  const regEx2 = /^2_/

  const duplicateFund = fundInfoData.find((fundInfo) => fundInfo?.invTypeNmbr !== invTypeNmbr && fundInfo?.invTypeNm?.replace(regEx2, '') === invTypeNm)
  
  if (regEx2.test(invTypeNm)) {
    fundClass = "I";
  } else if (duplicateFund) {
    fundClass = "D";
  } else if (regEx1.test(invTypeNm)) {
    fundClass = invTypeNm.substring(invTypeNm.length - 1);
  } else {
    fundClass = "N";
  }
  return fundClass;
}


export default getFundClass;