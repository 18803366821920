import { RESET_PORTFOLIO_DETAILS, SAVE_CONTRIBUTION_DETAILS, SAVE_INTRAGRP_TRANSFER, SET_UPDATE_DONE } from './ActionConstants.js';

export function saveInterGrpData(data) {
    return { type: SAVE_INTRAGRP_TRANSFER, data }
}

export function saveContributionDetails(data) {
    return { type: SAVE_CONTRIBUTION_DETAILS, data }
}

export function setUpdateDone(data) {
    return { type: SET_UPDATE_DONE, data }
}

export function resetportfoilioDetails() {
    return { type: RESET_PORTFOLIO_DETAILS }
}