import {
  SAVE_CLIENT_INFO, SAVE_CONTACT_INFO_DETAILS, SAVE_CONTACT_INFO_UPDATE_FLAG, SAVE_CONTACT_UPDATE_JSON,
  SAVE_CONTACT_VERIFY_EDIT_FLAG, SAVE_CYCLE_DATE, SAVE_EMAIL_INFO_DETAILS, SAVE_EMAIL_INFO_UPDATE_FLAG, SAVE_EMAIL_UPDATE_JSON,
  SAVE_EMAIL_VERIFY_EDIT_FLAG, SAVE_MOBILE_INFO_DETAILS, SAVE_MOBILE_INFO_UPDATE_FLAG, SAVE_MOBILE_UPDATE_JSON, SAVE_MOBILE_VERIFY_EDIT_FLAG
  ,SAVE_EMAIL_UPDATE_OKTA_JSON, SAVE_MOBILE_UPDATE_OKTA_JSON, //Michael Lam
  SAVE_MAX_AGE,
  SAVE_MULTIPLE_CLIENT_INFO
} from "../actions/ActionConstants.js";

const initialState = {
  clientInfo: "",
  cycleDate: "",
  contactInfoDetails: {},
  contactInfoUpdateFlag: false,
  contactInfoUpdateJson: {},
  contactVerifyEditFlag: false,
  emailInfoDetails: {},
  emailInfoUpdateFlag: false,
  emailInfoUpdateJson: {},
  emailVerifyEditFlag: false,
  mobileInfoDetails: {},
  mobileInfoUpdateFlag: false,
  mobileInfoUpdateJson: {},
  mobileVerifyEditFlag: false,
  emailInfoUpdateOktaJson: {}, //Michael Lam
  mobileInfoUpdateOktaJson: {}, //Michael Lam
  maxAge : 0
};

export default function landingReducer(state = initialState, action) {
  switch (action.type) {
    case SAVE_CLIENT_INFO:
      return { ...state, clientInfo: action.data };

    case SAVE_MULTIPLE_CLIENT_INFO:
      return { ...state, multipleClientInfo: action.data };

    case SAVE_MAX_AGE:
        return { ...state, maxAge: action.data };

    case SAVE_CONTACT_INFO_DETAILS:
      return { ...state, contactInfoDetails: action.data };
    case SAVE_CONTACT_INFO_UPDATE_FLAG:
      return { ...state, contactInfoUpdateFlag: action.data };
    case SAVE_CONTACT_UPDATE_JSON:
      return { ...state, contactInfoUpdateJson: action.data };
    case SAVE_CONTACT_VERIFY_EDIT_FLAG:
      return { ...state, contactVerifyEditFlag: action.data };

    case SAVE_EMAIL_INFO_DETAILS:
      return { ...state, emailInfoDetails: action.data };
    case SAVE_EMAIL_INFO_UPDATE_FLAG:
      return { ...state, emailInfoUpdateFlag: action.data };
    case SAVE_EMAIL_UPDATE_JSON:
      return { ...state, emailInfoUpdateJson: action.data };
    case SAVE_EMAIL_VERIFY_EDIT_FLAG:
      return { ...state, emailVerifyEditFlag: action.data };

    case SAVE_MOBILE_INFO_DETAILS:
      return { ...state, mobileInfoDetails: action.data };
    case SAVE_MOBILE_INFO_UPDATE_FLAG:
      return { ...state, mobileInfoUpdateFlag: action.data };
    case SAVE_MOBILE_UPDATE_JSON:
      return { ...state, mobileInfoUpdateJson: action.data };
    case SAVE_MOBILE_VERIFY_EDIT_FLAG:
      return { ...state, mobileVerifyEditFlag: action.data };
    case SAVE_CYCLE_DATE:
      return { ...state, cycleDate: action.data };
    case SAVE_EMAIL_UPDATE_OKTA_JSON: //Michael Lam
      return { ...state, emailInfoUpdateOktaJson: action.data };   //Michael Lam    
    case SAVE_MOBILE_UPDATE_OKTA_JSON: //Michael Lam
      return { ...state, mobileInfoUpdateOktaJson: action.data };   //Michael Lam    
    default:
      return state;
  }
}
