import { SAVE_CODE_CHALLENGE, SAVE_CODE_VERIFIER, SAVE_NONCE, SAVE_STATE } from "../actions/ActionConstants";

const initialState = {
    codeChallenge: "",
    codeVerifier: "",
    nonce: "",
    state: ""
}

export default function ssoReducer(state = initialState, action) {
    switch (action.type) {
        case SAVE_CODE_CHALLENGE:
            return { ...state, codeChallenge: action.data };
        case SAVE_CODE_VERIFIER:
            return { ...state, codeVerifier: action.data };
        case SAVE_NONCE:
            return { ...state, nonce: action.data };
        case SAVE_STATE:
            return { ...state, state: action.data }
        default:
            return state;
    }
}