import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import ArrowBlueDown from "../../assets/Arrow-Blue-down.svg";
import ArrowBlueUp from "../../assets/Arrow-Blue-up.svg";
import SortIcon from "../../assets/Icon_Sort.svg";
import image_pig_nomoney from "../../assets/image_pig_nomoney.png";
import "../../css/FeesAndCharges.css";
import RiskSpectrum from "../../SharedComponents/RiskReturnSpectrum";
import { HKAIIB } from "../../translations/common/commonConstants";
import { FormatUtils, getRiskIndicator, SortUtils } from "../../utils";
import "./DBFundsList.css";


const renderFundRow = (props, t, i18n, name, holdingPc, amount, filteredFundDetails) => {
  const history = useHistory();

  const onClickViewAccountInfo = () => {
    history.push('/accountBalanceByFund');
  }
  const risk = getRiskIndicator(filteredFundDetails.invRskNm ?? '', props.scheme)
  return (
    <>
      <div className="d-block d-sm-block d-md-none">
        <div className="row">
          <div className="col-12 db-fundsList-item db-fundsList-block d-flex">
            <div className={`fac-risk-indicator mt-md-1 ${risk}`}></div>
            <span className="db-fundList-item-fund-name" data-testid="Acc-Bal-fund-desk-link" onClick={() => { onClickViewAccountInfo() }}> {i18n.language === 'en' ? name : filteredFundDetails.othLegalNm}</span>
          </div>
        </div>

        <div>
          <span className="db-fundList-colName-holdingPc">
            {`${holdingPc} %`}
          </span>
          <span className="db-fundList-colName-value">
            {`  ${FormatUtils.formatToDecimal(amount)}`}
          </span>
          <span className="db-fundList-colName-buck">
            {props.scheme == HKAIIB ? t("currencies.usd") : t("fundSwitchOut.hkd")}
          </span>
        </div>
      </div>

      <div className="d-none d-lg-block d-md-block">
        <div className="row">
          <div className="col-lg-8 col-md-7 db-fundsList-item db-fundsList-block d-flex">
            <div className={`fac-risk-indicator mt-md-1 ${risk}`}></div>
            <span className="db-fundList-item-fund-name pointer-cursor" data-testid="Acc-Bal-fund-mob-link" onClick={() => { onClickViewAccountInfo() }}>{i18n.language === 'en' ? name : filteredFundDetails.othLegalNm}</span>
          </div>
          <div className="col-lg-2 col-md-2  db-fundsList-item db-fundsList-block">
            <span className="db-fundList-item-fund-holdingPc">{`${holdingPc} %`}</span>
          </div>
          <div className="col-lg-2 col-md-3  db-fundsList-block">
            <span className="db-fundsList-item db-fundList-item-fund-currencyBuck">{props.scheme == HKAIIB ? t("currencies.usd") : t("fundSwitchOut.hkd")}</span>
            <span className="db-fundsList-amount">{` ${FormatUtils.formatToDecimal(amount)}`}</span>
          </div>
        </div>
      </div>
    </>
  );
};

const sortByCurrentValue = (data, setdata, setCount, count) => {
  setCount(count + 1);
  if (count % 2 == 0) {
    setdata([...data.sort(SortUtils.dynamicsort("currentValue", "asc"))]);
  }
  else {
    setdata([...data.sort(SortUtils.dynamicsort("currentValue", "desc"))]);
  }
};
const sortByHoldingPc = (data, setdata, setCount, count) => {
  setCount(count + 1);
  if (count % 2 == 0) {
    setdata([...data.sort(SortUtils.dynamicsort("value", "asc"))]);
  }
  else {
    setdata([...data.sort(SortUtils.dynamicsort("value", "desc"))]);
  }
};
const renderColNames = (t, data, setdata, setCount, count) => {
  return (
    <>
      <div className="d-none d-lg-block d-md-block">
        <div className="row">
          <div className="col-lg-8 col-md-7 db-fundList-colName">
            {t("dashboard.FundName")}
          </div>
          <div className="col-lg-2 col-md-2 db-fundList-colName pointer-cursor" data-testid="sort-holding-pc-desktop" onClick={() => sortByHoldingPc(data, setdata, setCount, count)}>
            {t("dashboard.HoldingPc")}
            <img
              className="db-fundList-sort-icon"
              src={SortIcon}
            />
          </div>
          <div className="col-lg-2 col-md-3 db-fundList-colName pointer-cursor" data-testid="sort-current-value-desktop" onClick={() => sortByCurrentValue(data, setdata, setCount, count)}>
            {t("dashboard.CurrentValue")}
            <img
              className="db-fundList-sort-icon"
              src={SortIcon}
            />
          </div>
        </div>
      </div>

      <div className="d-block d-sm-block d-md-none">
        <div className="row">
          <div className="col-sm-12 db-fundList-colName-fundName">
            {t("dashboard.FundName")}
          </div>
        </div>

        <div>
          <span className="db-fundList-colName-holdingPc pointer-cursor" data-testid="sort-holding-pc-mobile" onClick={() => sortByHoldingPc(data, setdata, setCount, count)}>
            {t("dashboard.HoldingPc")}
            <img
              src={SortIcon}
            />
          </span>
          <span className="db-fundList-colName-value pointer-cursor" data-testid="sort-current-value-mobile" onClick={() => sortByCurrentValue(data, setdata, setCount, count)}>
            {t("dashboard.CurrentValue")}
            <img
              src={SortIcon}
            />
          </span>
        </div>
      </div>
    </>
  );
};

const renderExpandedCard = (
  props,
  t,
  i18n,
  data,
  totalAmt,
  setdata,
  setCount,
  count,
  cardExpanded,
  setcardExpanded,
  fundInfoGlobalService
) => {
  var filteredFundDetails;
  data = data.filter(d => d.currentValue > 0);
  return (
    <div className="row">
      <div
        onClick={() => setcardExpanded(false)}
        className="col-12 db-fund-list-expanded-card"
      >
        {renderFundListTitle(t, cardExpanded)}
      </div>
      <span className="col-12 db-fundsList-comment">
        {t("dashboard.FundsComment")}
      </span>
      <div />
      <div className="container">
        <div className="db-fundsList-colNames-block">
          {renderColNames(t, data, setdata, setCount, count)}
        </div>
      </div>
      <div className="line1"></div>
      {totalAmt > 0 && data.map((item) => {
        filteredFundDetails = fundInfoGlobalService.find(fundInfoGlobalServiceObj => (fundInfoGlobalServiceObj.legalNm === item.title.toUpperCase() && fundInfoGlobalServiceObj.isActiveFund === true));
        return (
          <>
            {filteredFundDetails &&
              <React.Fragment>
                <div className="container">
                  <div className="db-fundsList-colItems-block">
                    {renderFundRow(
                      props,
                      t,
                      i18n,
                      item.title,
                      FormatUtils.formatToDecimal(item.value),
                      item.currentValue,
                      filteredFundDetails
                    )}
                  </div>
                </div>
                <div className=" line1"></div>
              </React.Fragment>
            }
          </>
        );
      })
      }
      {totalAmt > 0 && data.length > 0 ?
        <div className="container">
          <span className="db-fundList-riskspectrum db-fundList-riskspectrum-title">
            {t("fundSwitchOut.returnriskspectrum")}
          </span>
          <div className="db-fundList-riskspectrum-graphic">
            <RiskSpectrum />
          </div>
        </div>
        :
        <div className="container">
          <div className="row db-fundList-riskspectrum-graphic">
            <img className="noMoneyImageSmall" src={image_pig_nomoney}></img>
            <div className="noAssetfundList">
              {t("dashboard.noAssetsText")}
            </div>
          </div>
        </div>
      }
    </div>
  );
};

const renderCollapsedCard = (t, cardExpanded, setcardExpanded) => {
  return (
    <div
      className={"db-fund-list-collapsed-card"}
      onClick={() => setcardExpanded(true)}
    >
      {renderFundListTitle(t, cardExpanded)}
    </div>
  );
};

const renderFundListTitle = (t, cardExpanded) => {
  const section = [];

  section.push(
    <span className="db-fundsList-title">{t("dashboard.Funds")}</span>
  );

  section.push(
    <img
      className="d-block d-sm-block d-md-none db-fund-list-collapsed-card-icon"
      src={cardExpanded ? ArrowBlueUp : ArrowBlueDown}
    ></img>
  );

  return section;
};

const DBFundsList = (props) => {
  const { t, i18n } = useTranslation("common");
  let [data, setdata] = useState(props.data);
  const [cardExpanded, setcardExpanded] = useState(true);
  const fundInfoGlobalService = props.fundInfoData;
  const [count, setCount] = useState(0);

  useEffect(() => {
    let initialData = SortUtils.sortDashboardFunds(props?.data)
    initialData.forEach((fund, index) => {
      fund["colorcode"] = index + 1;
    });
    setdata([...initialData]);
  }, []);

    return !cardExpanded ? (
      <div className="container" data-testid="db-funds-list">
        <div className={"d-block d-sm-block d-md-none"}>
          {renderCollapsedCard(t, cardExpanded, setcardExpanded)}
        </div>
        <div className="d-none d-lg-block d-md-block">
          {renderExpandedCard(props, t, i18n, data, props.totalAmt, setdata, setCount, count, cardExpanded, setcardExpanded, fundInfoGlobalService)}
        </div>
      </div>
    ) : (
      <div className="container" data-testid="db-funds-list">
        {renderExpandedCard(props, t, i18n, data, props.totalAmt, setdata, setCount, count, cardExpanded, setcardExpanded, fundInfoGlobalService)}
      </div>
    );
};

DBFundsList.propTypes = {
  props: PropTypes.any,
  data: PropTypes.array,
  totalAmt: PropTypes.number,
  scheme: PropTypes.string,
  fundInfoData: PropTypes.arrayOf(PropTypes.object)
};

const mapStateToProps = (state) => {
  return {
    fundInfoData: state.fundSwitch.fundInfoData,
    scheme: state.empLogin.scheme
  }
}

export default connect(mapStateToProps)(DBFundsList);