import moment from "moment";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from 'react-toastify';
import { saveCurrentLoadedPageOnUI } from "../../actions/LandingAction";
import changesApi from "../../API/Changes/ChangesApi";
import Toast from "../../Components/Toast";
import "../../css/ChangesDetails.css";
import SpinnerButton from "../../SharedComponents/SpinnerButton";
import FormatUtils from "../../utils/FormatUtils";
import { AccountUtils } from "../../utils";
import Logger from "../../SharedComponents/Logger";
import { CAHNGES_DETAILS, DIS, DIS800, DIS_SIMP, DIS_SMAP, HKAIIB, invTypeNumCash, LSS, LSSAIIB, percent } from "../../translations/common/commonConstants";

const onBackBtnClick = (props, history) => { props.setCurrentPage('changes'); history.push({ resetPage: true }); };

const renderSwitchOutcard = (t, switchOutData, lang) => {
    return (
        <div className="pds-card details-card contribution-crd-mg">
            {switchOutData.map((data, index) => {

                return (<>
                    <div className="switch-details-card-title details-card-body-title">
                        {data?.invTypeNmbr === invTypeNumCash ? t("changesFundDetails.cash") : lang === 'en' ? FormatUtils.formatFundName(data?.legalNm) : data?.othLegalNm}
                    </div>
                    <table className="table table-borderless switch-details-table">
                        <tbody>
                            <tr>
                                <td>{t("confirmation.switchOutPercentage")}</td>
                                <td className="text-right">{data.rqstRt && FormatUtils.convertNumToPercentWithRounding(data.rqstRt)}</td>
                            </tr>
                        </tbody>
                    </table>
                    {(switchOutData.length !== index + 1) && <hr className="details-card-hr" />}
                </>
                );
            })}
        </div>
    );
};

const renderSwitchInCard = (t, switchInData, lang) => {
    return (
        <div className="pds-card details-card contribution-crd-mg">
            {switchInData.map((data, index) => {

                return (<>
                    <div className="switch-details-card-title details-card-body-title">
                        {data?.invTypeNmbr === invTypeNumCash ? t("changesFundDetails.cash") : lang === 'en' ? FormatUtils.formatFundName(data?.legalNm) : data?.othLegalNm}
                    </div>
                    <table className="table table-borderless switch-details-table">
                        <tbody>
                            <tr>
                                <td>{t("confirmation.switchInPercentage")}</td>
                                <td className="text-right">{data.rqstRt && FormatUtils.convertNumToPercentWithRounding(data.rqstRt)}</td>
                            </tr>
                        </tbody>
                    </table>
                    {(switchInData.length !== index + 1) && <hr className="details-card-hr" />}
                </>
                );
            })}
        </div>
    );
}

const renderFutureInvestmentCard = (t, switchInData) => {
    return (
        <div className="pds-card details-card contribution-crd-mg">
            {switchInData.map((data, index) => {
                return (<>
                    <div className="switch-details-card-title details-card-body-title">
                        {data.name}
                    </div>
                    <table className="table table-borderless switch-details-table">
                        <tbody>
                            <tr>
                                <td>{t("changesDetails.allocationPercentage")}</td>
                                <td className="text-right">{Math.round((Number(data.allocation) + Number.EPSILON) * 100) / 100}{percent}</td>
                            </tr>
                        </tbody>
                    </table>
                    {(switchInData.length !== index + 1) && <hr className="details-card-hr" />}
                </>
                );
            })}
        </div>
    );
}

const ChangesDetails = (props) => {
    props.dispatch(saveCurrentLoadedPageOnUI('CHANGES_1'))
    const history = useHistory();
    const { t, i18n } = useTranslation("common");
    const fundDetails = props.changeFundDetails;
    const [status, setStatus] = useState('');
    const [cancelTransactionFlag, setCancelTransactionFlag] = useState(false);
    const selectedQuickPolicyInfo = props?.quickPolicyInfo[props.selectedPolicyIndex]
    useEffect(() => {
        switch (fundDetails.statusType) {
            case "Pending":
                setStatus("pending")
                break;
            case "Cancelled":
                setStatus("cancelled")
                break;
            case "Completed":
                setStatus("completed")
                break;
            case "Being Processed":
                setStatus("being-processed")
                break;

            default:
                break;
        }
    }, [])
    const sendNotification = (type) => {
        toast(<Toast type={type} />, props.toastOptions)
    }

    const cancelFundRequest = () => {
        const headers = {
            'pisys_auth_token': props.pisysToken,
            'username': props.clientNmbr
        }

        const inputJson = {
            clntRltnShpNmbr: (selectedQuickPolicyInfo?.clntRltnshpNmbr),
            contractNmbr: (selectedQuickPolicyInfo?.contract?.contractNmbr),
            processorID: (props.changeFundDetails.processorId),
            activityIds: (props.changeFundDetails.activityTableNmbr),
        }
        setCancelTransactionFlag(true);
        changesApi.cancelFundTransaction(inputJson, headers)
            .then(async (response) => {
                if (response?.status === 200) {
                    await AccountUtils.compareWebCycleDateWithExistingWebCycleDate(props, response?.webCycleDate);
                    setCancelTransactionFlag(false);
                    if (document.getElementById('closeCancelationBtn')) {
                        document.getElementById('closeCancelationBtn').click()
                    }
                    props.setCurrentPage('changes');
                    history.push({ resetPage: true });
                } else {
                    if (document.getElementById('closeCancelationBtn')) {
                        document.getElementById('closeCancelationBtn').click()
                    }
                    setCancelTransactionFlag(false);
                    sendNotification("technical-error");
                }
            }).catch(() => {
                if (document.getElementById('closeCancelationBtn')) {
                    document.getElementById('closeCancelationBtn').click()
                }
                setCancelTransactionFlag(false);
                sendNotification("technical-error");
            });
    }
    const rejectFundCancelationRequest = () => {
        props.setCurrentPage('changesDetails');
    }

    const clubDISFunds = (fundFilterList) => {
        let disArr = []
        fundFilterList.forEach((item, index) => {
            if (item.legalNm.includes(DIS)) {
                disArr.push(index)
            }
        })
        let amount = 0
        disArr.forEach((i) => {
            amount = amount + fundFilterList[i].rqstRt
        })
        for (let i = 1; i < disArr.length; i++) {
            fundFilterList.splice(disArr[i], 1);
        }
        if (disArr.length) {
            const disFund = props.fundInfoData.find((fund) => (fund.invTypeNm.includes(DIS800) || fund.invTypeNm.includes(DIS_SIMP) || fund.invTypeNm.includes(DIS_SMAP)))
            fundFilterList[disArr[0]].rqstRt = amount == 2 ? amount / 2 : amount;
            if (disFund) {
                fundFilterList[disArr[0]].invRskNm = disFund.invRskNm
                fundFilterList[disArr[0]].invTypeNmbr = disFund.invTypeNmbr
                fundFilterList[disArr[0]].legalNm = disFund.legalNm
                fundFilterList[disArr[0]].othLegalNm = disFund.othLegalNm
            }
        }
    }

    const clubLSSFunds = (fundFilterList) => {
        let lssArr = []
        fundFilterList.forEach((item, index) => {
            if (item.legalNm.includes(LSS)) {
                lssArr.push(index)
            }
        })
        let rqstRt = 0
        lssArr.forEach((i) => {
            rqstRt = rqstRt + fundFilterList[i].rqstRt
        })
        for (let i = 1; i < lssArr.length; i++) {
            fundFilterList.splice(lssArr[i], 1);
        }
        if (lssArr.length) {
            const lssFund = props.fundInfoData.find((fund) => fund.invTypeNm.includes(LSSAIIB))
            fundFilterList[lssArr[0]].rqstRt = rqstRt;
            if (lssFund) {
                fundFilterList[lssArr[0]].invRskNm = lssFund.invRskNm
                fundFilterList[lssArr[0]].invTypeNmbr = lssFund.invTypeNmbr
                fundFilterList[lssArr[0]].legalNm = lssFund.legalNm
                fundFilterList[lssArr[0]].othLegalNm = lssFund.othLegalNm
            }
        }
    }


    const blockButton = (status === 'pending') ? '' : 'd-none';

    const renderCancelChanges = () => {
        return (
            <div className={`col-12 col-md-6 col-lg-4 btn-top-mg order-1 order-md-0 ${blockButton}`}>
                <button
                    className="cancel-btn mx-auto" name="cancel" data-testid="changes-cancel-modal-btn" data-toggle="modal" data-target="#cancel-msg-modal"
                    // eslint-disable-next-line no-undef
                    disabled={!(Number(process.env.REACT_APP_WRITE_ACCESS)) || ((props?.changeFundDetails?.fundType) ? (t(props.changeFundDetails.fundType) !== t('fundSwitch.cardTitle1')) : false)}>
                    {t("changesDetails.cancelChanges")}
                </button>
                <div className="modal fade modal-background" id="cancel-msg-modal" data-bs-backdrop="static" data-keyboard="false" data-backdrop="static" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                    <div className="modal-dialog ">
                        <div className="modal-content modal-pop">
                            <div className="modal-body details-modal-body">
                                <div className="details-modal-text">{t("changesDetails.cancelMessage")}</div>
                                <div className="modal-btn-mg">
                                    <button
                                        className="back-btn cancel-yes-btn mx-auto"
                                        name="yes"
                                        type="button"
                                        // eslint-disable-next-line no-undef
                                        disabled={!(Number(process.env.REACT_APP_WRITE_ACCESS)) || cancelTransactionFlag}
                                        data-testid="changes-cancel-accept-btn"
                                        onClick={cancelFundRequest}
                                    >
                                        <SpinnerButton serviceCallFlag={cancelTransactionFlag} label={t("changesDetails.yes")}></SpinnerButton>
                                    </button>
                                </div>
                                <button id="closeCancelationBtn" className="d-none" data-dismiss="modal"></button>
                                <div>
                                    <button className="cancel-btn cancel-no-btn mx-auto" name="no" type="button" data-testid="changes-cancel-reject-btn" data-dismiss="modal" onClick={rejectFundCancelationRequest}>
                                        {t("changesDetails.no")}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <>
            <Logger logImpression data={{ page: CAHNGES_DETAILS }}>
                <div className="details-content">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <h1 className="details-title">{t("changesDetails.pageTitle")}</h1>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 ">
                                <div className="pds-card details-card contribution-crd-mg">
                                    <div className="details-card-body ">
                                        <p className="details-card-body-title m-0">{t(fundDetails.fundType)}</p>
                                        <p className={`status-text ${status} m-0`}>{
                                            fundDetails.statusType === 'Pending' ? t('changesFundDetails.pending') : fundDetails.statusType === 'Cancelled' ? t('changesFundDetails.cancelled') : fundDetails.statusType === 'Completed' ? t('changesFundDetails.completed') : fundDetails.statusType === 'Failed' ? t('changesFundDetails.failed') :
                                                fundDetails.statusType === 'Being Processed' && (t('changesFundDetails.beingProcessed'))
                                        }</p>
                                    </div>
                                    <table className="table table-borderless fund-details-table">
                                        <tbody>
                                            {(fundDetails.activityTableNmbr) && (
                                                (fundDetails.fundType === "fundSwitch.cardTitle2" || (fundDetails.fundType === "fundSwitch.cardTitle1" && fundDetails?.pmtMethodTypeNm)) &&
                                                (<tr>
                                                    <td>{t("changesDetails.transactionNo")}</td>
                                                    <td className="text-right">{fundDetails.activityTableNmbr}</td>
                                                </tr>)
                                            )}
                                            {(fundDetails.fundType === "fundSwitch.cardTitle1") && (
                                                <>
                                                    <tr>
                                                        <td>{t("changesDetails.instructionDate")}</td>
                                                        <td className="text-right">{moment(fundDetails.activityInputDate, "DD/MM/YYYY").format(i18n.language === 'en' ? 'D MMM YYYY' : 'YYYY年M月D日')}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>{t("changesDetails.instructionChannel")}</td>
                                                        <td className="text-right">{fundDetails.fundType === "fundSwitch.cardTitle1" ? fundDetails?.pmtMethodTypeNm : 'Internet'}</td>
                                                    </tr>
                                                </>
                                            )}
                                            <tr>
                                                <td>{t("changesFundDetails.transactionDate")}</td>
                                                <td className="text-right">{moment(fundDetails.transHistoryEffectiveDt, "DD/MM/YYYY").format(i18n.language === 'en' ? 'D MMM YYYY' : 'YYYY年M月D日')}</td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        {fundDetails.fundType === "fundSwitch.cardTitle1" &&
                            <>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="details-label dtls-ttl-mg">{t("changesDetails.investment")} </div>
                                    </div>
                                </div>

                                {Array.isArray(fundDetails?.transactions) && fundDetails?.transactions.length > 0 && (
                                    fundDetails?.transactions?.map((transactionDetails) => {
                                        let switchInData = transactionDetails.switchInData;
                                        let switchOutData = transactionDetails?.switchOutData;
                                        if (props?.scheme?.includes(HKAIIB)) {
                                            clubLSSFunds(switchInData);
                                            clubLSSFunds(switchOutData);
                                        } else {
                                            clubDISFunds(switchInData);
                                            clubDISFunds(switchOutData);
                                        }

                                        return (
                                            <>
                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="cds-contri-nm">{t(transactionDetails.contributionType)} </div>
                                                    </div>
                                                </div>
                                                <hr className="cds-contri-hr" />
                                                <div className="row">
                                                    <div className="col-12 col-md-6">
                                                        <div className="details-card-body-title details-mg-btm"> {t("confirmation.switchOut")} </div>
                                                        {renderSwitchOutcard(t, switchOutData, i18n.language)}
                                                    </div>

                                                    <div className="col-12 col-md-6 last-card-mg">
                                                        <div className="details-card-body-title details-mg-btm"> {t("confirmation.switchIn")} </div>
                                                        {renderSwitchInCard(t, switchInData, i18n.language)}
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    })
                                )}

                            </>
                        }

                        {fundDetails.fundType === "fundSwitch.cardTitle2" &&
                            <>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="details-label dtls-ttl-mg">{t("changesDetails.investment")} </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="cds-contri-nm">{t(fundDetails.contributionType)} </div>
                                    </div>
                                </div>
                                <hr className="cds-contri-hr" />
                                <div className="row">
                                    <div className="col-12 col-md-6 last-card-mg">
                                        {renderFutureInvestmentCard(t, fundDetails.switchInData)}
                                    </div>
                                </div>
                            </>
                        }
                        <div className="row d-flex justify-content-between">
                            <div className="col-12 col-md-6 col-lg-4 order-0 order-md-1">
                                <button className="back-btn mx-auto" name="back" onClick={() => onBackBtnClick(props, history)}>{t("changesDetails.backToChanges")}
                                </button>
                            </div>
                            {renderCancelChanges()}
                        </div>
                    </div>
                </div>
            </Logger>
        </>
    );
}
ChangesDetails.propTypes = {
    changeFundDetails: PropTypes.object,
    pisysToken: PropTypes.string,
    loginId: PropTypes.string,
    setCurrentPage: PropTypes.func,
    toastOptions: PropTypes.object,
    fundInfoData: PropTypes.arrayOf(PropTypes.object),
    selectedPolicyIndex: PropTypes.number,
    quickPolicyInfo: PropTypes.array,
    dispatch: PropTypes.func,
    scheme: PropTypes.string,
    clientNmbr: PropTypes.string,
    webCycleDate: PropTypes.string,
}
const mapStateToProps = (state) => {
    return {
        pisysToken: state.token.pisysToken,
        loginId: state.empLogin.loginId,
        toastOptions: state.landing.toastOptions,
        changeFundDetails: state.changeFundDetails.changeFundDetails,
        fundInfoData: state.fundSwitch.fundInfoData,
        selectedPolicyIndex: state.twoAccounts.selectedPolicyIndex,
        quickPolicyInfo: state.twoAccounts.quickPolicyInfo,
        scheme: state.empLogin.scheme,
        clientNmbr: state.empLogin.clientNmbr,
        webCycleDate: state.empLogin.webCycleDate,
    }
}
export default connect(mapStateToProps)(ChangesDetails);