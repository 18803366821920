import dateFormat from 'dateformat';
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { AccountUtils } from "../../src/utils";
import '../css/WelcomeBlock.css';
import { accountTypeEe, accountTypPa, accountTypSep, accountTypSvc, accountTypTvc, ChineseAM, ChinesePM, HKAIIB } from "../translations/common/commonConstants";
var capitalize = require('capitalize')

const WelcomeBlock = (props) => {
  const { t, i18n } = useTranslation("common");
  const lastLoginDt = props?.lastLoginDate && moment(props.lastLoginDate, "DD MM YYYY hh:mm A").format(i18n.language === 'en' ? 'D MMM YYYY hh:mm A' : 'YYYY年M月D日')?.toUpperCase() + `${i18n.language === 'zh' ? (props?.lastLoginDate?.includes("PM") ? ChinesePM : ChineseAM) + moment(props.lastLoginDate, "DD MM YYYY hh:mm A").format('hh:mm') : ""}`;
  let accountTypeText = ''
  let capitalFlag = false;
  let accountTyp = props.accountType.toUpperCase().trim();
  if (accountTyp === accountTypPa) {
    accountTypeText = 'selectAccount.personalAcc';
  } else if (accountTyp === accountTypSep) {
    accountTypeText = 'selectAccount.selfEmp';
  } else if (accountTyp === accountTypTvc) {
    accountTypeText = 'selectAccount.tvc';
  } else if (accountTyp === accountTypSvc) {
    accountTypeText = 'selectAccount.svc';
  } else if (accountTyp === accountTypeEe) {
    if (props.scheme === HKAIIB) {
      capitalFlag = true;
      accountTypeText = props?.policyDetailsList?.filter((x) => x?.policyVO?.clntRltnshpNmbr?.toString() === props?.clntRltnshpNmbr)?.[0]?.policyVO?.policyHistoryList?.[0]?.planName;
    } else {
      capitalFlag = true;
      accountTypeText = props?.chineseOrgNames?.[props?.selectedPolicyIndex] ?? props?.selectedQuickPolicyInfo?.client?.organizationNm;
    }
  } else {
    capitalFlag = true;
    accountTypeText = accountTyp;
  }
  return (
    <>
      <div data-testid='welcome-block' className="col-lg-8 col-xs-12 col-md-6">
        <h1 className="welcome-block-title">{t("dashboard.welcome")}{capitalize.words(props.username)}</h1>
      </div>
      <div className="col-lg-4 col-xs-12 col-md-6">
        <label className="welcome-block-contractNo">{AccountUtils.getContractNumber(props, props.selectedQuickPolicyInfo)}</label>
        <label className="welcome-block-account">{capitalFlag ? capitalize.words(t(accountTypeText)) : t(accountTypeText)}</label>
        {props?.lastLoginDate && <label className="welcome-block-login"> {t("dashboard.lastLogin")} {lastLoginDt !== 'INVALID DATE' ? lastLoginDt : dateFormat(props.lastLoginDate, i18n.language === 'en' ? 'd mmm yyyy HH:MM TT' : 'yyyy年m月d日')?.toUpperCase() + `${i18n.language === 'zh' ? (props?.lastLoginDate?.includes("PM") ? ChinesePM : ChineseAM) + moment(props.lastLoginDate, "DD MM YYYY hh:mm A").format('hh:mm') : ""}`}</label>}
      </div>

    </>
  );
}

WelcomeBlock.propTypes = {
  props: PropTypes.any,
  username: PropTypes.string,
  lastLogin: PropTypes.string,
  scheme: PropTypes.string,
  clientNmbr: PropTypes.string,
  lastLoginDate: PropTypes.string,
  contractNmbr: PropTypes.string,
  accountType: PropTypes.string,
  chineseOrgNames: PropTypes.array,
  selectedPolicyIndex: PropTypes.number,
  policyDetailsList: PropTypes.object,
  clntRltnshpNmbr: PropTypes.string,
  selectedQuickPolicyInfo: PropTypes.object
};

const mapStateToProps = (state) => {
  return {
    clientNmbr: state.empLogin.clientNmbr,
    scheme: state.empLogin.scheme,
    lastLoginDate: state.empLogin.lastLoginDate,
    contractNmbr: state.empLogin.contractNmbr,
    clntRltnshpNmbr: state.empLogin.clntRltnshpNmbr,
    chineseOrgNames: state.empLogin.chineseOrgNames,
    policyDetailsList: state.twoAccounts.allPolicyList,
    selectedPolicyIndex: state.twoAccounts.selectedPolicyIndex,
    selectedQuickPolicyInfo: state.twoAccounts.selectedQuickPolicyInfo
  };
};

export default connect(mapStateToProps)(WelcomeBlock);