import { aiibBrackets, disBrackets, hk, lssBrackets, productTypeMPF, us, USDots } from "../translations/common/commonConstants";

const formatCurrency = (value) => {
  if (value) { value = value.toFixed(2) }
  else {
    value = 0;
  }
  return (value === 0) ? ("0.00") : (parseFloat(value).toLocaleString(undefined, { minimumFractionDigits: 2 }));
};

const formatCurrencyDecimal = (value, decimalPlace, i18n) => {
  if (value) { value = value.toFixed(decimalPlace) }
  else {
    value = 0;
  }
  return parseFloat(value).toLocaleString(i18n.language, { minimumFractionDigits: decimalPlace , maximumFractionDigits: decimalPlace });
};

const formatToDecimal = (value) => {
  if (value && value !== null) {
    value = value.toLocaleString(undefined,
      { minimumFractionDigits: 2, maximumFractionDigits: 2 })
  }
  else {
    value = 0;
  }
  return (value === 0) ? ("0.00") : (value);
};
const formatToNumber = (value, decimalPlace = 0) => {
  if (value) { 
    value = value.toFixed(decimalPlace) 
  } else {
    value = 0;
  }
  return parseFloat(value).toLocaleString(undefined, { minimumFractionDigits: decimalPlace , maximumFractionDigits: decimalPlace });
};
const formatToDecimalFix = (value, maxDecimalDegit) => {
  if (value && value !== null) {
    value = value.toString();
    let decimalIndex = value.lastIndexOf('.');
    if (decimalIndex > 0) {
      value = value.substring(0, (decimalIndex + 1) + maxDecimalDegit)
      value = value.padEnd((decimalIndex + maxDecimalDegit) + 1, 0);
    } else {
      value = (value + ".").padEnd((value.length + maxDecimalDegit) + 1, 0)
    }
  }
  else {
    value = 0;
  }
  return (value === 0) ? ("0.".padEnd(maxDecimalDegit + 2, 0)) : (value);
};
const formatFundName = (str) => {
  let wordArr = str?.split(" ");
  let finalArr = [];

  for (let i = 0; i < wordArr?.length; i++) {
    let strconv = "";
    if (wordArr[i] === disBrackets || wordArr[i] === hk || wordArr[i] === productTypeMPF || wordArr[i] === us || wordArr[i] === USDots || wordArr[i] === lssBrackets || wordArr[i] === aiibBrackets) {
      strconv = wordArr[i];
    } else {
      for (let j = 0; j < wordArr[i].length; j++) {
        let letter = wordArr[i][j];
        if (j == 0) {
          strconv = strconv + letter.toUpperCase();
        } else {
          strconv = strconv + letter.toLowerCase();
        }
      }
    }
    finalArr.push(strconv);
  }
  return finalArr.join(" ");
};

const convertNumToPercentwithPrecisionTwo = (number) => {
  return number.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 2, maximumFractionDigits: 2 })
}

const convertNumToPercentWithRounding = (number) => {
  return number.toLocaleString(undefined, { style: 'percent', maximumFractionDigits: 2 })
}

var capitalize = require('capitalize')
const getClientName = (clientInfo) => {
  const englishFirstName = clientInfo?.firstName ? clientInfo?.firstName : "";
  const englishMiddleName = clientInfo?.secondaryMiddleName ? clientInfo?.secondaryMiddleName : "";
  const englishLastName = clientInfo?.primaryLastName ? clientInfo?.primaryLastName : "";
  const chineseFirstName = clientInfo?.primaryMiddleName ? clientInfo?.primaryMiddleName : "";
  const chineseLastName = clientInfo?.secondaryLastName ? clientInfo?.secondaryLastName : "";
  const prefName = clientInfo?.preferredNm ? clientInfo?.preferredNm : "";
  var namingConditionsSatisfied;
  if (englishLastName?.includes("(") && (chineseLastName || chineseFirstName)) {
    namingConditionsSatisfied = true
  } else {
    namingConditionsSatisfied = false
  }
  return (namingConditionsSatisfied ? chineseLastName + " " + chineseFirstName : capitalize.words(englishLastName + " " + englishFirstName + " " + englishMiddleName + " " + prefName));
}

const cycleDateConverter = (cycleDate) => {
  let newdate = ""
  if (typeof cycleDate === "string" && cycleDate.includes("/")) {
    newdate = cycleDate;
  } else if (cycleDate?.webCycleDate) {
    let date = cycleDate.webCycleDate.substring(0, 10).split("-")
    newdate = date[2] + "/" + date[1] + "/" + date[0];
  } else {
    let date = cycleDate.substring(0, 10).split("-")
    newdate = date[2] + "/" + date[1] + "/" + date[0];
  }
  return newdate;
}

const roundNumber = (number, decimalPlaces = 0) => {
  let roundednumber = number
  if (!isNaN(roundednumber)) {
    roundednumber = Number(Math.round(number + "e" + decimalPlaces) + `e-${decimalPlaces}`)
    if(isNaN(roundednumber)){
      roundednumber = Number(number.toFixed(decimalPlaces))
    }
  }
  return roundednumber;
}

const formatQbBalance = (number, decimalPlaces = 0, locale = 'zh-HK') => {
  if (number) { 
    number = number.toFixed(decimalPlaces) 
  } else {
    number = 0;
  }
  return parseFloat(number).toLocaleString(locale, { minimumFractionDigits: decimalPlaces, maximumFractionDigits: decimalPlaces });
}

const getLastDigit = (number) => {
  return number % 10;
}

export default { getLastDigit, formatCurrency, formatCurrencyDecimal, formatToDecimal, formatToNumber, formatFundName, getClientName, convertNumToPercentwithPrecisionTwo, convertNumToPercentWithRounding, cycleDateConverter, roundNumber, formatToDecimalFix, formatQbBalance };
