import {SAVE_CONTRIBUTION_RECORD_TYPE,SAVE_SELECTED_CONTRIBUTION_RECORD_INDEX,SAVE_CONTRIBUTION_RECORDS_LIST , SAVE_CONTRIBUTION_TO_DATE,SAVE_CONTRIBUTION_FROM_DATE} from "../actions/ActionConstants";

const initialState = {
    selectedContributionRecordIndex: 1,
    contributionRecords: [],
    toDate:'',
    fromDate:'',
    type:"contribution.toi.0"
}

export default function ContributionReducer(state = initialState, action) {
    switch (action.type) {
        case SAVE_SELECTED_CONTRIBUTION_RECORD_INDEX:
            return {...state,selectedContributionRecordIndex:action.data}
        case SAVE_CONTRIBUTION_RECORDS_LIST:
            return {...state,contributionRecords: action.data}
        case  SAVE_CONTRIBUTION_TO_DATE:
            return {...state,toDate: action.data}
        case  SAVE_CONTRIBUTION_FROM_DATE:
            return {...state,fromDate: action.data}
        case SAVE_CONTRIBUTION_RECORD_TYPE:
            return {...state,type: action.data}   
        default:
            return state;
    }
}