import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { sendLog } from '../utils';
const Context = React.createContext('');
export const LogContext = Context;

class Logger extends Component {
    constructor(props) {
       super(props);

       this.hasImpressionAlreadyBeenLogged = false;
   }
   componentDidMount() {
       const data = {
            ...this.combinedProps?.data,
            loginId:'',
            policyId: '',
            contractId: '',
            scheme: this.props.scheme,
            pisysToken:this.props.pisysToken,
            location: window.location.href
        }
        if (!["S800 2FA login Page", "AIIB 2FA login Page", "S800 non-2FA login Page", "AIIB non-2FA login Page", "Landing Page"].includes(data?.page) && data?.AppName !== 'RSC App') {
            data.loginId = this.props.loginId
            if (!["Select Channel OTP Page", "Select Channel non-OTP Page", "Authenticate OTP non-OTP Page", "Authenticate OTP Page", "Select Account Page"].includes(data?.page)) {
                data.policyId = this.props.clntRltnshpNmbr
                data.contractId = this.props.contractNmbr
            }
        }
       sendLog(data);
       this.hasImpressionAlreadyBeenLogged = true;
   }

   componentDidUpdate() {
       if (this.props.logImpression && !this.hasImpressionAlreadyBeenLogged ) {
           const data = {
               ...this.combinedProps?.data,
                loginId:'',
                policyId: '',
                contractId: '',
                scheme: this.props.scheme,
                pisysToken:this.props.pisysToken,
                location: window.location.href
            }
            if (!["S800 2FA login Page", "AIIB 2FA login Page", "S800 non-2FA login Page", "AIIB non-2FA login Page", "Landing Page"].includes(data?.page)) {
                data.loginId = this.props.loginId
                if (!["Select Channel OTP Page", "Select Channel non-OTP Page", "Authenticate OTP non-OTP Page", "Authenticate OTP Page", "Select Account Page"].includes(data?.page)) {
                    data.policyId = this.props.clntRltnshpNmbr
                    data.contractId = this.props.contractNmbr
                }
            }
           sendLog(data);
           this.hasImpressionAlreadyBeenLogged = true;
        }
   }

   render() {
       const { children, ...directProps } = this.props;

       return (
           <LogContext.Consumer>
               {(consumedProps) => {
                   const combinedProps = { ...consumedProps, ...directProps };
                   this.combinedProps = combinedProps;
                   return <LogContext.Provider value={combinedProps}>{children}</LogContext.Provider>;
               }}
           </LogContext.Consumer>
       );
   }
}

Logger.propTypes = {
    children: PropTypes.any,
    logImpression: PropTypes.bool,
    scheme: PropTypes.string,
    loginId: PropTypes.string,
    contractNmbr: PropTypes.string,
    clntRltnshpNmbr: PropTypes.string,
    pisysToken: PropTypes.string
}

const mapStateToProps = (state) => {
  return {
    pisysToken: state.token.pisysToken,
    loginId: state.empLogin.loginId,
    scheme: state.empLogin.scheme,
    contractNmbr: state.empLogin.contractNmbr,
    clntRltnshpNmbr: state.empLogin.clntRltnshpNmbr,
  }
};

export default connect(mapStateToProps)(Logger);