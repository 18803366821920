import PropTypes from 'prop-types';
import React from "react";
import { useTranslation } from "react-i18next";
import { connect } from 'react-redux';
import { useHistory } from "react-router";
import { saveSchemeType } from "../../../actions/EmployeeLoginAction";
import empPlan from "../../../assets/Employers-2.svg";
import ArrowRight from "../../../assets/Icon_Arrow_right.svg";
import "../../../css/EmpLogin.css";
import { SMP } from "../../../translations/common/commonConstants";

const SmartSimple = (props) => {
  const { t } = useTranslation("common");
  const history = useHistory();

  const routeChange = (e) => {
    e.preventDefault();
    props.dispatch(saveSchemeType(SMP));
    history.push("/cobrand/employeelogin");
  }

  return (
    <React.Fragment>
      <div className="login-card simp-smart-card text-center" onClick={routeChange} id="mpf-other">
        <img src={empPlan} className="empPlan" />
        <div className="row">
          <div className="col-12">
            <a href="#" className="login-type-cards-title" name="goto" onClick={routeChange} data-testid="smartSimple">
              {t("titles.simpleSmart")}
              <img src={ArrowRight} alt="" className="gotoArrow" />
            </a>
            <p className="lt-title-transparent">
              {t("titles.mpfEmployee").substring(t("titles.simpleSmart").length + 3)}
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-12 lt-subtext">
            {t("simpleSmart.simpleSmartText")}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

SmartSimple.propTypes = {
  props: PropTypes.any,
  dispatch: PropTypes.func
}

const SmartSimpleComponent = connect()(SmartSimple);
export default SmartSimpleComponent;