import { instance } from '../AxiosInterceptor';

export default {
    getSwitchInDetails: (productTypeNmbr, requestHeaders) =>
        instance({
            'method': 'GET',
            'url': '/investments/switchindetails',
            headers: requestHeaders,
            'params': {
                'productTypeNmbr': productTypeNmbr,
            },
        }),
    getFundPerformanceDetails: (productTypeNmbr, requestHeaders) =>
        instance({
            'method': 'GET',
            'url': '/investments/fundperformance',
            headers: requestHeaders,
            'params': {
                'productTypeNmbr': productTypeNmbr,
            }
        }),
    getFundInfo: (contractNmbr, requestHeaders) =>
        instance({
            'method': 'GET',
            'url': '/config/funds',
            headers: requestHeaders,
            'params': {
                'contractNmbr': contractNmbr
            }
        })
}