import enUS from "date-fns/locale/en-US";
import zhTW from "date-fns/locale/zh-TW";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import { useTranslation } from "react-i18next";
import { connect } from 'react-redux';
import { Slide, toast } from 'react-toastify';
import { changePagination, changeStatusType, resetChangesList, saveChangesList, saveFilteredChangesList, setCurrentPageNo, setFundFromDate, setFundStatus, setFundToDate } from "../../actions/ChangesAction";
import { saveCurrentLoadedPageOnUI } from "../../actions/LandingAction";
import changesApi from '../../API/Changes/ChangesApi';
import Toast from '../../Components/Toast';
import '../../css/Changes.css';
import Logger from "../../SharedComponents/Logger";
import { CAHNGES, custSvcNo, custSvcNoSmap, HKAIIB, SMP } from "../../translations/common/commonConstants";
import { AccountUtils, ChangesUtils } from "../../utils";
import SortUtils from "../../utils/SortUtils";
import ChangeFundDetailsPagination from "./ChangeFundDetailsPagination";

registerLocale("en", enUS);
registerLocale("zh", zhTW);

const toastOptions = {
    position: 'top-center',
    autoClose: 4000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    transition: Slide,
    rtl: false,
    closeButton: false,
}
const sendNotification = (type) => {
    toast(<Toast type={type} />, toastOptions)
}
let changesList = [];
const ChangesFundDetails = (props) => {
    props.dispatch(saveCurrentLoadedPageOnUI('CHANGES_0'))
    const { t, i18n } = useTranslation("common");
    const cutOffDateTime = new Date(new Date().setHours(props?.cutOffHours, 0, 0, 0))
    var date = new Date();
    date.setDate(1);
    const [state] = useState({
        fundChangesList: [],
        fundStatus: (props?.changesPageEditFlag && props?.fundStatus) ? props.fundStatus : 'All'
    })
    const [toDate, setToDate] = useState(props.changesPageEditFlag ? new Date(props.fundToDate).setHours(0, 0, 0, 0) : new Date().setHours(0, 0, 0, 0));
    const [fromDate, setFromDate] = useState(props.changesPageEditFlag ? new Date(props.fundFromDate) : new Date(new Date(new Date().setMonth(new Date().getMonth() - 6)).getFullYear(), new Date(new Date().setMonth(new Date().getMonth() - 6)).getMonth(), 1));
    props.dispatch(setFundFromDate(fromDate));
    props.dispatch(setFundToDate(toDate));
    let hotline;
    switch (props.scheme) {
        case SMP:
            hotline = custSvcNoSmap;
            break;
        case HKAIIB:
            hotline = custSvcNo;
            break;
        default:
            hotline = t("changesFundDetails.noteText4");
            break;
    }
    const statusType = t("changesFundDetails.statusType", { returnObjects: true });
    useEffect(() => {
        props.dispatch(resetChangesList());
        state.fundChangesList = [];
        getChangesData();
    }, [])

    useEffect(() => {
        return () => {
            props.dispatch(saveCurrentLoadedPageOnUI(''))
        }
    }, [])


    const getTransactionStatus = (transactionDate) => {
        const cutOffHours = props.cutOffHours;
        const cycleDt = props.cycleDate
        const cycleDate = moment(cycleDt, 'DD/MM/YYYY').toDate();

        const hkDate = new Date().toLocaleString('en-US', { timeZone: 'Asia/Hong_Kong' });

        const hkDateTime = moment(hkDate, 'M/D/YYYY, h:mm:ss A').toDate();
        const cutOffDate = new Date(hkDateTime);
        cutOffDate.setHours(cutOffHours, 0, 0, 0)

        let status = 'Being Processed'
        if (cutOffHours === 0) {
            if (transactionDate > cycleDate) {
                status = 'Pending'
            }
        } else {
            if ((transactionDate.getTime() === cycleDate.getTime() && hkDateTime.getTime() < cutOffDate.getTime()) || transactionDate > cycleDate) {
                status = 'Pending'
            }

        }

        return status;
    }

    const getChangesData = async () => {
        const headers = {
            'pisys_auth_token': props.pisysToken,
            'username': props.clientNmbr,
            'Access-Control-Allow-Origin': '*'
        }
        let clntRltnshpNmbr = props.clntRltnshpNmbr;
        await changesApi.getChangesList(clntRltnshpNmbr, headers)
            .then(async (response) => {
                let decodedResponse = response.data
                await AccountUtils.compareWebCycleDateWithExistingWebCycleDate(props, decodedResponse?.webCycleDate);
                if (decodedResponse) {
                    changesList = ChangesUtils.getProcessChangesResponse(decodedResponse);
                    changesList.forEach((x) => {
                        let status = x.activityStatusname;
                        if (status == "PENDING FOR NIGHTLY BATCH" || status == "PENDING TO BE PROCESSED" || status == "PENDING APPROVAL" ||
                            status == "PENDING FOR CHECKING" || status == "WAITING FOR INVESTMENT PRICE" || status == "WAITING FOR NIGHTLY BATCH" ||
                            status == "IN PROGRESS" || status == "READY TO BE PROCESSED" || status == "MOVED TO BE PROCESSED") {
                            x["statusType"] = getTransactionStatus(moment(x.transHistoryEffectiveDate, 'DD/M/YYYY').toDate())
                            x["validFund"] = true
                        } else {
                            x["validFund"] = false
                        }
                    })

                    if (changesList) {
                        changesList = changesList.filter((data) => (data.validFund === true));
                        changesList.sort(SortUtils.sortOnDate("transHistoryEffectiveDate", "desc", "DD/M/YYYY"));
                    } else {
                        changesList = [];
                    }
                    state.fundChangesList = changesList;
                    props.dispatch(saveChangesList(changesList))
                }
            })
            .catch(() => {
                sendNotification("technical-error");
            });
        if (state.fundChangesList && state.fundChangesList.length > 0) {
            getFundDetails(false);
        }
    }

    const getFundDetails = (btnClicked) => {
        let statusWiseFund = [];
        if (btnClicked) {
            props.dispatch(setCurrentPageNo(0));
        } else {
            props.dispatch(setCurrentPageNo(props.pageNo));
        }

        if (fromDate && toDate) {
            var from = new Date(fromDate);
            var toDateValue = moment(toDate).endOf('month')
            var to = new Date(toDateValue);
            if (state.fundStatus !== "All") {
                statusWiseFund = state.fundChangesList.filter((x) => x.statusType === state.fundStatus && ((from <= (moment(x.activityInputdate, 'DD/M/YYYY').toDate())) && (to >= (moment(x.activityInputdate, 'DD/M/YYYY').toDate()))));
                if (statusWiseFund !== undefined) {
                    props.dispatch(saveFilteredChangesList(statusWiseFund));
                } else {
                    props.dispatch(saveFilteredChangesList([]));
                }
            }
            else {
                statusWiseFund = state.fundChangesList.filter((x) => ((from <= (moment(x.activityInputdate, 'DD/M/YYYY').toDate())) && (to >= (moment(x.activityInputdate, 'DD/M/YYYY').toDate()))));
                if (statusWiseFund !== undefined) {
                    props.dispatch(saveFilteredChangesList(statusWiseFund));
                } else {
                    props.dispatch(saveFilteredChangesList([]));
                }
            }
        } else {
            if (state.fundStatus === "All") {
                props.dispatch(saveFilteredChangesList(state.fundChangesList));
            }
            if (state.fundStatus !== "All") {
                statusWiseFund = state.fundChangesList.filter((x) => x.statusType === state.fundStatus);
                if (statusWiseFund !== undefined) {
                    props.dispatch(saveFilteredChangesList(statusWiseFund));
                } else {
                    props.dispatch(saveFilteredChangesList([]));
                }
            }
        }
        let numberOfPage = props.statusWiseList ? props.statusWiseList.length / 10 : 0;

        if (!isNaN(numberOfPage) && numberOfPage.toString().includes('.')) {
            let intNumber = numberOfPage.toString().substring(0, numberOfPage.toString().indexOf('.'))
            let paginationArray = range(0, Number(intNumber) + 1);
            let totalPost = (Number(intNumber) + 1)
            props.dispatch(changePagination(totalPost, paginationArray))
        } else {
            let paginationArray = range(0, numberOfPage);
            props.dispatch(changePagination(numberOfPage, paginationArray))

        }
    }

    const range = (start, end) => {
        return Array(end - start + 1).fill().map((_, idx) => start + idx)
    }

    const getLastDateOfMonth = (date) => {
        return date.setDate(new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate());
    }

    const handleFromDateChange = (date) => {
        if (date === null || date === "") {
            setFromDate();
        } else {
            setFromDate(date);
            props.dispatch(setFundFromDate(date))
        }
    }
    const handleStatus = (e) => {
        if (e.target.value === 'All') {
            state.fundStatus = "All";
            props.dispatch(changeStatusType(state.fundStatus))
            props.dispatch(setFundStatus(state.fundStatus))
        } else {
            state.fundStatus = e.target.value;
            props.dispatch(changeStatusType(state.fundStatus))
            props.dispatch(setFundStatus(state.fundStatus))
        }
    }
    const handleToDateChange = (date) => {
        if (date === null || date === "") {
            setToDate();
        } else {
            setToDate(getLastDateOfMonth(date));
            props.dispatch(setFundToDate(date))
        }
    }
    return (
        <React.Fragment>
            <Logger logImpression data={{ page: CAHNGES }}>
                <div>
                    <div id="change" className="container">
                        <div className="row">
                            <div className="col-12 col-xs-12">
                                <label className="lbl-ch-title">{t('changesFundDetails.title')}</label>
                                <div className="row">
                                    <div className="col-12 col-xl-3 col-md-3 col-lg-3 col-sm-12">
                                        <label className="lbl-ch-statusType">{t('changesFundDetails.statustitle')}</label>
                                        <select id="selectPlaceholder" aria-label="Select placeholder" name="selectStatus" data-testid="changes-status-select" defaultValue={state.fundStatus}
                                            onChange={handleStatus} className="rsc-select select-arrow ch-select ch-fullwidht ch-cursor-pointer">
                                            {statusType.map((status, i) => (
                                                <option key={i} value={status.value} > {status.label}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="col-6 col-xl-3 col-md-3 col-lg-3 col-sm-6">
                                        <label className="lbl-ch-statusType">{t('transactionHistory.from')}</label>
                                        <div>
                                            <DatePicker
                                                selected={fromDate}
                                                className={"ch-date-input"}
                                                onChange={handleFromDateChange}
                                                dateFormat="M/yyyy"
                                                locale={i18n.language === 'en' ? 'en' : 'zh'}
                                                showMonthYearPicker
                                                popperModifiers={{
                                                    preventOverflow: {
                                                        enabled: true,
                                                    },
                                                }}
                                                maxDate={toDate}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-6 col-xl-3 col-md-3 col-lg-3 col-sm-6">
                                        <label className="lbl-ch-statusType">{t('transactionHistory.to')}</label>
                                        <div>
                                            <DatePicker
                                                selected={toDate}
                                                className={"ch-date-input"}
                                                onChange={handleToDateChange}
                                                dateFormat="M/yyyy"
                                                locale={i18n.language === 'en' ? 'en' : 'zh'}
                                                showMonthYearPicker
                                                popperModifiers={{
                                                    preventOverflow: {
                                                        enabled: true,
                                                    },
                                                }}
                                                minDate={fromDate}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 col-xl-3 col-md-3 col-lg-3 col-sm-12">
                                        <button
                                            name="update"
                                            data-testid="changes-generate-btn"
                                            type="submit"
                                            onClick={() => getFundDetails(true)}
                                            className="forgot-btn button button--primary js-form-submit form-submit btn btn-primary ch-submitbtn ch-submitbtn-text"
                                        >
                                            {t('changesFundDetails.generateRecordsBtn')}
                                        </button>
                                    </div>

                                </div>
                                {(props.changesListLoading) ?
                                    (
                                        <div className="col-12 d-flex justify-content-center mx-auto">
                                            <div className="spinner-border spinner m-5" role="status">
                                                <span className="sr-only"></span>
                                            </div>
                                        </div>
                                    ) :
                                    (props.statusWiseList && props.statusWiseList.length > 0 && !props.changesListLoading ?
                                        (<ChangeFundDetailsPagination setCurrentPage={props.setCurrentPage} data={props.statusWiseList}
                                        >
                                        </ChangeFundDetailsPagination>
                                        )
                                        : (<p>{t("changesFundDetails.activitiesNotFound")}</p>))
                                }
                            </div></div>
                    </div>
                    <hr className="ch-line"></hr>
                    <div className="container ch-note-div">
                        <div className="row">
                            <div className="col-12">
                                <label className="ch-note-lbl">{t("changesFundDetails.notesTitle")}</label>
                                <p className="ch-note-text">{t("changesFundDetails.noteText1")}</p>
                                <p className="ch-note-text">{`${t("changesFundDetails.noteText2-1")}${cutOffDateTime.toLocaleTimeString(i18n.language === 'en' ? 'en-US' : 'zh-HK', { hour: "numeric", minute: '2-digit' })}${t("changesFundDetails.noteText2-2")}`}</p>
                                <p className="ch-note-text">{t("changesFundDetails.noteText3")}<span className="ch-note-text ch-note-number">{hotline}</span></p>
                            </div>
                        </div>
                    </div>
                </div>
            </Logger>
        </React.Fragment>
    );
}
ChangesFundDetails.propTypes = {
    props: PropTypes.any,
    setCurrentPage: PropTypes.func,
    dispatch: PropTypes.func,
    pisysToken: PropTypes.string,
    loginId: PropTypes.string,
    changesList: PropTypes.array,
    changesListLoading: PropTypes.bool,
    statusType: PropTypes.string,
    totalActivities: PropTypes.number,
    paginationNo: PropTypes.any,
    statusWiseList: PropTypes.any,
    totalPosts: PropTypes.number,
    clntRltnshpNmbr: PropTypes.string,
    cycleDate: PropTypes.string,
    pageNo: PropTypes.number,
    fundStatus: PropTypes.string,
    fundFromDate: PropTypes.any,
    fundToDate: PropTypes.any,
    changesPageEditFlag: PropTypes.bool,
    cutOffHours: PropTypes.number,
    clientNmbr: PropTypes.string,
    scheme: PropTypes.string,
    webCycleDate: PropTypes.string
};
const mapStateToProps = (state) => {
    return {
        pisysToken: state.token.pisysToken,
        loginId: state.empLogin.loginId,
        changesListLoading: state.changeFundDetails.changesListLoading,
        changesList: state.changeFundDetails.changesList,
        statusType: state.changeFundDetails.statusType,
        totalActivities: state.changeFundDetails.totalActivities,
        paginationNo: state.changeFundDetails.paginationNo,
        fundStatus: state.changeFundDetails.fundStatus,
        fundFromDate: state.changeFundDetails.fundFromDate,
        fundToDate: state.changeFundDetails.fundToDate,
        changesPageEditFlag: state.changeFundDetails.changesPageEditFlag,
        statusWiseList: state.changeFundDetails.statusWiseList,
        totalPosts: state.changeFundDetails.totalPosts,
        clntRltnshpNmbr: state.empLogin.clntRltnshpNmbr,
        cycleDate: state.landing.cycleDate,
        pageNo: state.changeFundDetails.pageNo,
        cutOffHours: state.empLogin.cutOffHours,
        clientNmbr: state.empLogin.clientNmbr,
        webCycleDate: state.empLogin.webCycleDate,
        scheme: state.empLogin.scheme
    };
}
const ChangesFundDetailsComponent = connect(mapStateToProps)(ChangesFundDetails);
export default ChangesFundDetailsComponent;