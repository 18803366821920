import { checkIfCBFund } from ".";
import { accountTypeEe, accountTypPa, accountTypSep, s200, s200zh, s600, s600zh, s800, s800zh } from "../translations/common/commonConstants";
import { normalPercentageAccuracyCheck } from "./accuracyCheckutil";
import FormatUtils from './FormatUtils';

const clubAmoutForAllMoneyTypes = (objArray) => {
    let tempObj = {};
    objArray.forEach((obj) => {
        if (!(obj.invTypeLegalName in tempObj)) {
            if (!checkIfCBFund(Number(obj.invTypeNmbr))) {
                tempObj[obj.invTypeLegalName] = obj.investmentAmt;
            }
        } else {
            tempObj[obj.invTypeLegalName] += obj.investmentAmt;
        }
    });

    let finalArr = [];
    for (const key in tempObj) {
        finalArr.push({ name: key, value: tempObj[key] });
    }
    return finalArr;
}

const getTotalInvestmentAmount = (policyList) => {
    let total = 0;
    policyList.policyAccountValueVOList.map((obj) => {
        total = total + obj.investmentAmt;
    });
    return FormatUtils.roundNumber(total, 2);
}

const getDonutChartData = (policyList) => {
    const policyAccountValueList = policyList.policyAccountValueVOList.filter((fund) => fund.investmentAmt > 0)
    const clubbedPolicyAccountValueList = clubAmoutForAllMoneyTypes(policyAccountValueList)
    const totalReducer = (total, fund) => total + Number(fund?.value ?? 0);
    let total = clubbedPolicyAccountValueList?.reduce(totalReducer, 0) ?? 0
    total = FormatUtils.roundNumber(total,2)
    let donutChartData = clubbedPolicyAccountValueList.map((obj) => ({
        title: FormatUtils.formatFundName(obj?.name ?? ''),
        value: FormatUtils.roundNumber((obj.value * 100 / total), 2) || 0,
        currentValue: obj.value,
    }));

    donutChartData = normalPercentageAccuracyCheck(donutChartData, 'value')
    return donutChartData;
}

const checkCBFund = (policyList) => {
    let isCBFund = false;
    policyList.forEach((obj) => {
        obj.policyAccountValueVOList.forEach((o) => {
            if (checkIfCBFund(Number(o.invTypeNmbr))) {
                isCBFund = true;
                return;
            }
        })
    });
    return isCBFund;
}

const isSEP = (policyList, selectedPolicyIndex, selectedQuickPolicyInfo) => {
    var isSEPFlag = false;
    if (policyList[selectedPolicyIndex]?.policyVO?.policyHistoryList.filter((policy) => policy.memberClassNmbr === 303).length > 0 &&
        selectedQuickPolicyInfo?.orgTypeNm === "SELF-EMPLOYED") {
        isSEPFlag = true;
    }
    return (isSEPFlag);
}


const getAccountsApplicableForABF = (selectedQuickPolicyInfo) => {
    if (selectedQuickPolicyInfo.accountType === accountTypPa || selectedQuickPolicyInfo.accountType === accountTypSep || selectedQuickPolicyInfo.accountType === accountTypeEe) {
        return true;
    } else {
        return false;
    }
}

const getFundDistributionData = (sortedData) => {
    let othersPrctg = 0;
    let fundList = [];
    let fundCount = sortedData.length;
    if (fundCount > 6) {
        for (let k = 0; k < 5; k++) {
            fundList.push(sortedData[k]);
        }
        for (let j = 5; j < fundCount; j++) {
            othersPrctg = othersPrctg + sortedData[j].value;
        }
        fundList.push({
            color: "#4D4E53",
            colorcode: "",
            currentValue: "",
            title: "Others",
            value: othersPrctg
        });
    } else {
        fundList = sortedData;
    }
    return (fundList);
}

const getSchemeName = (attrNm, language) => {
    let mergerArr = [];
    switch (attrNm) {
        case "SP":
            language === 'en' ? mergerArr.push("Eagle Star MPF Scheme-Security Planner") : mergerArr.push('鷹星強積金計劃-穩健人生');
            language === 'en' ? mergerArr.push(s200) : mergerArr.push(s200zh);
            break;
        case "SB":
            language === 'en' ? mergerArr.push(s200) : mergerArr.push(s200zh);
            language === 'en' ? mergerArr.push(s600) : mergerArr.push(s600zh);
            break;
        case "KO":
            language === 'en' ? mergerArr.push("Principal MPF Scheme Series B300") : mergerArr.push("信安強積金計劃系列 B300");
            language === 'en' ? mergerArr.push(s800) : mergerArr.push(s800zh);
            break;
        case "DH":
            language === 'en' ? mergerArr.push("Principal MPF Scheme Series 500") : mergerArr.push("信安強積金計劃500系列");
            language === 'en' ? mergerArr.push(s600) : mergerArr.push(s600zh);
            break;
        case "ZD":
            language === 'en' ? mergerArr.push("Zurich-Chinese Bank MPF Scheme-PremierDeluxe") : mergerArr.push("蘇黎世華人銀行強積金-顯赫之選");
            language === 'en' ? mergerArr.push(s800) : mergerArr.push(s800zh);
            break;
        case "ZP":
            language === 'en' ? mergerArr.push("Zurich-Chinese Bank MPF Scheme-Premier") : mergerArr.push("蘇黎世華人銀行強積金-優越之選");
            language === 'en' ? mergerArr.push(s200) : mergerArr.push(s200zh);
            break;
        case "AP":
            language === 'en' ? mergerArr.push("Eagle Star MPF Scheme-Advance Planner") : mergerArr.push("鷹星強積金計劃-金輝人生");
            language === 'en' ? mergerArr.push(s600) : mergerArr.push(s600zh);
            break;
        case "SC":
            language === 'en' ? mergerArr.push(s600) : mergerArr.push(s600zh);
            language === 'en' ? mergerArr.push(s800) : mergerArr.push(s800zh);
            break;
        default:
            break;
    }
    return mergerArr;
}

export default { getTotalInvestmentAmount, getDonutChartData, isSEP, getFundDistributionData, getSchemeName, getAccountsApplicableForABF, checkCBFund };
