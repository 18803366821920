import moment from "moment";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { saveFundClassData, saveFundfactSheet, saveFundHistory } from "../../actions/FundPerformanceAction";
import fundInfoApi from "../../API/FundInfo/fundInfoApi";
import '../../css/PGEFClassAccUnits.css';
import Logger from "../../SharedComponents/Logger";
import NavigationMod from '../../SharedComponents/NavigationMod';
import { FUND_INFO_DETAILS, SMP } from "../../translations/common/commonConstants";
import { AccountUtils } from "../../utils";
import FundDetails from './FundDetails';
import Downloads from "./FundDetails/Downloads";
import FundPerformance from "./FundDetails/FundPerformance";
import FundReturns from "./FundDetails/FundReturns";
import FundDetailsTitle from './FundDetailsTitle';
import FundOverview from './FundOverview';

const PGEFClassAccUnits = (props) => {
  const [selectedTabIndex, setselectedTabIndex] = useState(0);
  const [loading, setloading] = useState(true);
  let [factSheetsLink, setFundFactSheetsLink] = useState([]);
  const cobrandGUA = props.scheme === SMP && props.fundPricePerformance.invTypeNmbr == 805;

  useEffect(async () => {
    setloading(true);

    const requestHeaders = {
      'pisys_auth_token': props.pisysToken,
      'username': props.clientNmbr,
      'Access-Control-Allow-Origin': '*'
    };

    const date = new Date();
    let startDate = moment(date).format("MM/DD/YYYY");
    date.setFullYear(date.getFullYear() - 1);
    // eslint-disable-next-line no-undef
    const invPriceEffectiveDate = process.env.REACT_APP_INV_PRICE_EFFECTIVE_DATE;
    const invPriceDate = moment((invPriceEffectiveDate), "YYYY-MM-DD");
    let endDate = invPriceDate.isValid() ? invPriceDate.format("MM/DD/YYYY") : moment(date).format("MM/DD/YYYY");
    const productTypeNmbr = props?.productTypeNmbr;
    const invTypeNm = props?.fundPricePerformance?.invTypeNm?.includes("2_") ? props?.fundPricePerformance?.invTypeNm?.substring(2) : props?.fundPricePerformance?.invTypeNm;
    const invTypeNmbr = props?.fundPricePerformance?.invTypeNmbr;

    fundInfoApi.getFundInfoDetails((String(productTypeNmbr)), (String(invTypeNm)), (String(startDate)), (String(endDate)), (String(invTypeNmbr)), requestHeaders).then(async (response) => {
      let decodedResponse = response.data;
      await AccountUtils.compareWebCycleDateWithExistingWebCycleDate(props, decodedResponse?.webCycleDate);
      const factsheetURLs = decodedResponse?.factsheetURLs ?? [];
      const fundPriceHistory = decodedResponse?.fundPriceHistoryResponseVO ?? {};
      const invTypeDetails = decodedResponse?.invTypeDetails ?? [];
      props.dispatch(saveFundHistory(fundPriceHistory));

      let arr = [];
      factsheetURLs.forEach((item) => {
        if (item?.invTypeNmbr === invTypeNmbr) {
          arr.push(item);
        }
      });

      setFundFactSheetsLink(arr);
      factSheetsLink = arr;
      props.dispatch(saveFundfactSheet(arr));
      props.dispatch(saveFundClassData(invTypeDetails));
      setloading(false);
    }).catch(() => {
      props.dispatch(saveFundHistory({}));
      setFundFactSheetsLink([]);
      props.dispatch(saveFundfactSheet([]));
      props.dispatch(saveFundClassData([]));
      setloading(false);
    });
  }, []);

  if (loading) {
    return (
      <div className="d-flex justify-content-center" data-testid="pgef_class_acc_units">
        <div className="spinner-border spinner m-5" role="status">
          <span className="sr-only"></span>
        </div>
      </div>
    );
  } else {
    return (
      <>
        <Logger logImpression data={{ page: FUND_INFO_DETAILS }}>
          <div data-testid="pgef_class_acc_units">
            <FundDetailsTitle fund={props.fundPricePerformance}></FundDetailsTitle>
          </div>
          <NavigationMod activeIndex={selectedTabIndex} setselectedTabIndex={setselectedTabIndex} fundfactSheetLink={props?.factSheetLink ?? ''} scheme={props?.scheme ?? ''} cobrandGUA={cobrandGUA} />
          <div className="container">
            <FundOverview activeIndex={selectedTabIndex} fund={props.fundPricePerformance}></FundOverview>
          </div>
          <div className="container">
            <FundDetails activeIndex={selectedTabIndex} fund={props.fundPricePerformance}></FundDetails>
          </div>
          {!cobrandGUA && <FundPerformance activeIndex={selectedTabIndex} fund={props.fundPricePerformance}></FundPerformance>}
          {props.scheme != SMP && <FundReturns activeIndex={selectedTabIndex} fund={props.fundPricePerformance}></FundReturns>}
          <Downloads activeIndex={selectedTabIndex} fund={props.fundPricePerformance} factSheetsLink={factSheetsLink}></Downloads>
          <p className="mb-5"></p>
        </Logger>
      </>
    );
  }
};

PGEFClassAccUnits.propTypes = {
  props: PropTypes.any,
  fundPricePerformance: PropTypes.object,
  pisysToken: PropTypes.string,
  dispatch: PropTypes.func,
  factSheetLink: PropTypes.array,
  clientNmbr: PropTypes.string,
  productTypeNmbr: PropTypes.string,
  scheme: PropTypes.string,
  webCycleDate: PropTypes.string
};
const mapStateToProps = (state) => {
  return {
    fundPricePerformance: state.fundPerformance.fundPricePerformance,
    webCycleDate: state.empLogin.webCycleDate,
    pisysToken: state.token.pisysToken,
    factSheetLink: state.fundPerformance.factSheetLink,
    clientNmbr: state.empLogin.clientNmbr,
    productTypeNmbr: state.empLogin.productTypeNmbr,
    scheme: state.empLogin.scheme
  };
};
export default connect(mapStateToProps)(PGEFClassAccUnits);
