import { SAVE_REPORT_DATA } from "../actions/ActionConstants";

const initialState = {
    reportData: {}
}

export default function reportReducer(state = initialState, action) {
    switch (action.type) {
        case SAVE_REPORT_DATA:
            return { ...state, reportData: action.data };
        default:
            return state;
    }
}