import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts/highstock";
import exporting from "highcharts/modules/exporting.js";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import download from "../../../assets/download_1.svg";
import ArrowUp from '../../../assets/Icon_Arrow_up.svg';
import print from "../../../assets/printing.svg";
import '../../../css/fund.css';
import { SMP } from "../../../translations/common/commonConstants";
import "./FundPerformanceChart.css";
import "./FundReturns.css";
require("highcharts/modules/export-data")(Highcharts);

const FundPerformance = (props) => {
	const { t, i18n } = useTranslation("common");
	const inputRef = React.useRef(null);
	const fundName = (i18n.language === "en") ? props?.fund?.legalNm : props?.fund?.othLegalNm;
	const productTypeNum = props?.quickPolicyInfo[props.selectedPolicyIndex]?.product?.productTypeNmbr;
	let graphData = [];

	Highcharts.setOptions({
		lang: {
			rangeSelectorZoom: t("fundInfo.period"),
			rangeSelectorFrom: t("fundInfo.from"),
			rangeSelectorTo: t("fundInfo.to"),
			weekdays: t("fundInfo.chartweekdays", { returnObjects: true }),
			shortMonths: t("fundInfo.chartshortmonths", { returnObjects: true }),
		},
		time: {
			timezoneOffset: -(8 * 60),
		}
	});

	exporting(Highcharts);

	let sortedFundPriceHistory = props?.fundHistory?.fundPriceHistoryDetails;
	sortedFundPriceHistory?.sort((a, b) => a.invPriceEffectiveDate - b.invPriceEffectiveDate);
	sortedFundPriceHistory?.map(obj => {
		graphData.push([obj.invPriceEffectiveDate, obj.unitPriceAmount]);
	});

	const options = {
		title: {
			text: props?.fund ? fundName : ''
		},
		rangeSelector: {
			selected: 4,
			inputSpacing: (i18n.language === "en") ? 10 : 20,
			inputBoxWidth: 120,
			inputDateFormat: '%Y-%m-%d',
			inputEditDateFormat: '%Y-%m-%d',
			inputPosition: {
				align: 'left',
				x: 0,
				y: 0,
			},
			buttonTheme: {
				fill: '#f6f6f6',
				stroke: 'none', 'stroke-width': 0,
				r: 10,
				width: 'auto',
				height: 30,
				padding: 5,
				y: -7,
				style: {
					color: '#464646',
					fontWeight: 'normal',
				},
				states: {
					hover: {
					},
					select: {
						fill: '#E4EFF9',
						style: {
							color: '#464646'
						}
					}
				},
				labelStyle: {
					display: 'none'
				},
			},

			buttons: [
				{
					type: 'ytd',
					text: t("fundInfo.ytd"),
				},
				{
					type: 'month',
					count: 1,
					text: t("fundInfo.chartmt1"),
				},
				{
					type: 'month',
					count: 3,
					text: t("fundInfo.chartmt3"),
				},
				{
					type: 'month',
					count: 6,
					text: t("fundInfo.chartmt6"),
				},
				{
					type: 'year',
					count: 1,
					text: t("fundInfo.chartyt1"),
				},
				{
					type: 'year',
					count: 3,
					text: t("fundInfo.chartyt3"),
				},
				{
					type: 'year',
					count: 5,
					text: t("fundInfo.chartyt5"),
				},
				{
					type: 'year',
					count: 10,
					text: t("fundInfo.chartyt10"),
				},
				{
					type: 'all',
					text: t("fundInfo.chartall"),
				},
			],
			buttonPosition: {
				align: 'right',
				x: 0,
				y: 0,
			},
		},
		xAxis: {
			dateTimeLabelFormats: {
				day: t("fundInfo.chartDayDateFormat"),
				week: t("fundInfo.chartDayDateFormat"),
				month: t("fundInfo.chartMonthDateFormat"),
				year: t("fundInfo.chartYearDateFormat"),
			}
		},
		yAxis: {
			title: {
				text: t("fundInfo.fundPrice"),
				offset: -80,
				align: 'high',
				y: -5,
				rotation: 0,
			}
		},
		navigator: {
			enabled: true,
			height: 10,
			series: {
				id: 'navigator',
			}
		},
		series: [{
			name: t("fundInfo.fundPrice"),
			data: graphData,
			tooltip: {
				valueDecimals: props.scheme == SMP ? 2 : 4
			},
		}],
		exporting: {
			buttons: {
				contextButton: {
					enabled: false,
				},
			},
			chartOptions: {
				rangeSelector: {
					enabled: false,
				},
				navigator: {
					enabled: false,
				},
			}
		},
		responsive: {
			rules: [
				{
					condition: {
						minWidth: 300,
						maxWidth: 565
					},
					chartOptions: {
						rangeSelector: {
							inputSpacing: (i18n.language === "en") ? 5 : 10,
						}
					},
				},
				{
					condition: {
						maxWidth: 300
					},
					chartOptions: {
						rangeSelector: {
							inputSpacing: -2,
						}
					},
				},
			]
		},
		tooltip: {
			xDateFormat: t("fundInfo.chartDateFormat"),
		},
	};

	const downloadFundPrice = () => {
		inputRef.current.chart.downloadXLS();
	};

	const exportChart = () => {
		inputRef.current.chart.exportChart({ type: "image/jpeg" });
	};

	const printChart = () => {
		inputRef.current.chart.print();
	};

	return (
		<>
			<div className="container" id={"fund_performance"}>
				<div className=" mt-4">
					<div className="row" id={"transaction-fundPerformance"}>
						<label className="fund-returns col-10 col-md-11 col-sm-11 col-xl-11 col-lg-11">{t("fundPerformance.title")}{((productTypeNum == 142 || productTypeNum == 143) && graphData?.length > 0) && <label className="fund-returns goto-link">*</label>}</label>
						<a href="#" data-toggle="collapse" id={"transaction-fundPerformance"} data-target={"#transactionPannel-fundPerformance"} aria-expanded="true" aria-controls={"transactionPannel-fundDetails"} className="col-1">
							<img src={ArrowUp}></img>
						</a>
					</div>
					<div className="collapse show" data-parent={"#transaction-fundPerformance"} id={"transactionPannel-fundPerformance"}>
						{graphData.length > 0 &&
							<React.Fragment>
								<HighchartsReact highcharts={Highcharts} constructorType={"stockChart"} options={options} ref={inputRef} />
								<div className="col-12">
									{(productTypeNum == 142 || productTypeNum == 143) && <p className="goto-link">{`${t('fundPerformance.note1')}${fundName}${t('fundPerformance.note2')}${fundName}${t('fundPerformance.note3')}${fundName}${t('fundPerformance.note4')}`}</p>}
									<button onClick={downloadFundPrice} className="fr-download-btn"><img src={download} alt="download" className="download-print-icon" />{t("fundPerformance.downloadFundPrice")}</button>
									<button onClick={exportChart} className="fr-download-btn mt-md-0 mt-2"><img src={download} alt="download" className="download-print-icon" />{t("fundPerformance.exportChart")}</button>
									<button onClick={printChart} className="fr-download-btn mt-md-0 mt-2"><img src={print} alt="download" className="download-print-icon" />{t("fundPerformance.print")}</button>
								</div>
							</React.Fragment>
						}
						{graphData.length === 0 &&
							<div className="col-12">
								<p>{t('fundPerformance.note')}</p>
							</div>
						}
					</div>
				</div>
				<hr className="tab-seprator"></hr>
			</div>
		</>
	);
};

FundPerformance.propTypes = {
	fundHistory: PropTypes.object,
	fundPerformance: PropTypes.array,
	fund: PropTypes.object,
	legalNm: PropTypes.string,
	scheme: PropTypes.string,
	selectedPolicyIndex: PropTypes.number,
	quickPolicyInfo: PropTypes.array,
};

const mapStateToProps = (state) => {
	return {
		fundHistory: state.fundPerformance.fundHistory,
		scheme: state.empLogin.scheme,
		selectedPolicyIndex: state.twoAccounts.selectedPolicyIndex,
		quickPolicyInfo: state.twoAccounts.quickPolicyInfo,
	};
};

export default connect(mapStateToProps)(FundPerformance);