import moment from "moment";
import PropTypes from 'prop-types';
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import "../../../../css/GeneralInfo.css";
import { DDMMYYYY, descendingOrder, effectiveDate, HKAIIB } from "../../../../translations/common/commonConstants";
import { FormatUtils, SortUtils } from "../../../../utils";

GeneralInfo.propTypes = {
  props: PropTypes.any,
  clientInfo: PropTypes.object,
  maxAge: PropTypes.number,
  isOrsoAccount: PropTypes.bool,
  scheme: PropTypes.string
}
let pageRefreshFlag = true

function GeneralInfo(props) {
  const { t, i18n } = useTranslation("common");
  const history = useHistory();

  useEffect(() => {
    if (history && history.action === 'POP' && pageRefreshFlag === true) {
      pageRefreshFlag = false
    }
  }, []);

  const clientCustomCitizenshipData = props?.clientInfo?.clientCustomData?.find(obj => obj.cstmAttrCd === 189);
  const clientCustomCitizenshipDataSorted = clientCustomCitizenshipData?.customDataDetailsList?.sort(SortUtils.sortOnDate(effectiveDate, descendingOrder, DDMMYYYY));
  var clientCitizenship = clientCustomCitizenshipDataSorted?.[0]?.cstmAttrTxt;
  const citizenshipValue = ((props.clientInfo && clientCitizenship) ? clientCitizenship : 'N/A');
  const doB = props.clientInfo.birthDate;
  const dateOfBirth = (doB !== null && doB !== "") && moment(doB, 'DD-MM-YYYY').format(i18n.language === 'en' ? 'D MMM YYYY' : 'YYYY年M月D日');
  const retirementDate = ((props.isOrsoAccount || props.scheme === HKAIIB) && props.maxAge !== 0 ? moment(doB, 'DD-MM-YYYY').add(props.maxAge, 'years') : moment(doB, 'DD-MM-YYYY').add(65, 'years')).format(i18n.language === 'en' ? 'D MMM YYYY' : 'YYYY年M月D日');
  const clientName = FormatUtils.getClientName(props?.clientInfo)

  return (
    <React.Fragment>
      <div className="row">
        {props.clientInfo &&
          <div className="pds-card info-body rsc-card general-section" id="general-section">
            <div className="card-body">
              <div className="d-flex justify-content-between">
                <h3 className="info-title"> {t("generalInfo.general")}</h3>
              </div>

              <form noValidate>
                <div className="row">
                  <div className="col-12">
                    <label className="left-align">
                      {t("common.name")}
                      <label className="inner-label gi-cname">
                        {clientName}
                      </label>
                    </label>
                  </div>

                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12">
                    <label className="left-align">
                      {t("generalInfo.doB")}
                      <label className="inner-label">
                        {dateOfBirth}
                      </label>
                    </label>
                  </div>

                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12">
                    <label className="left-align">{t('generalInfo.citizenShip')}</label>
                    {citizenshipValue === "N/A" ?
                      <label className="inner-label">{citizenshipValue === "N/A" ? t('generalInfo.responseN/A') : citizenshipValue}</label>
                      :
                      <label className="inner-label">{clientCitizenship}</label>
                    }
                  </div>
                </div>

                <div className="row">
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12">
                    <label className="left-align">
                      {t("generalInfo.normalRetiremntDt")}
                      <label className="inner-label">
                        {retirementDate}
                      </label>
                    </label>
                  </div>
                </div>
              </form>
            </div>
          </div>
        }
      </div>
    </React.Fragment>
  );
}
const mapStateToProps = (state) => {
  return {
    clientInfo: state.landing.clientInfo,
    maxAge: state.landing.maxAge,
    isOrsoAccount: state.twoAccounts.isOrsoAccount,
    scheme: state.empLogin.scheme
  };
}
const GeneralInfoComp = connect(mapStateToProps)(GeneralInfo)

export default class GeneralInfoComponent extends React.Component {
  constructor() {
    super();
  }
  render() {
    return <GeneralInfoComp></GeneralInfoComp>
  }
}