import { HKAIIB, schemeS800, SMP } from "../translations/common/commonConstants";

const logoutTo = (scheme, isAdmin) => {
    let path = "/";
    if (isAdmin) {
        switch (scheme) {
            case schemeS800: path = "/employeelogin";
                break;

            case HKAIIB: path = "/aiib/employeelogin";
                break;

            case SMP: path = "/cobrand/employeelogin";
                break;

            default: path = "/";
                break;
        }
    } else {
        path = "/superLogon/signIn"
    }
    return path;
}

export { logoutTo };