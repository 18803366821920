import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import ArrowUp from '../../../assets/Icon_Arrow_up.svg';
import '../../../css/fund.css';
import { DDMMYYYY, D_MMM_YYYY, D_MMM_YYYY_CH, EN, notApplicable } from "../../../translations/common/commonConstants";
import "./FundReturns.css";

const displayCarat = (invName) => {
	if (invName === "A65F-800-(DIS)" || invName === "CAF-800" || invName === "CAF-800-(DIS)" || invName === "A65F-800") {
		return true;
	}
	return false;
};

const FundReturns = (props) => {
	const { t, i18n } = useTranslation("common");
	const fundName = (i18n.language === "en") ? props?.fund?.legalNm : props?.fund?.othLegalNm;
	const productTypeNum = props?.quickPolicyInfo[props.selectedPolicyIndex]?.product?.productTypeNmbr;
	let dNote = displayCarat(props.fund.invTypeNm);
	return (
		<>
			<div className="container" id={"fund_details_returns"}>
				<div className=" mt-4">
					<div className="row" id={"transaction-fundReturns"}>
						<label className="fund-returns col-10 col-md-11 col-sm-11 col-xl-11 col-lg-11">{t("fundReturns.title")}</label>
						<a href="#" data-toggle="collapse" id={"transaction-fundReturns"} data-target={"#transactionPannel-fundReturns"} aria-expanded="true" aria-controls={"transactionPannel-fundDetails"} className="col-1">
							<img src={ArrowUp}></img>
						</a>
					</div>

					<div className="collapse show" data-parent={"#transaction-fundReturns"} id={"transactionPannel-fundReturns"}>
						<p className="cumulative-return col-12">{t("fundReturns.table1Title")} {(productTypeNum == 142 || productTypeNum == 143) && <sup><label className="cumulative-return goto-link mb-5">#</label></sup>}</p>
						<table className="table wrap-data">
							<thead>
								<tr className="tHeaderRow">
									<th></th>
									<td>{t("fundReturns.asOf")}</td>
									<td>{t("fundReturns.ytd")}</td>
									<td>{t("fundReturns.oneYear")}</td>
									<td>{t("fundReturns.threeYears")}</td>
									<td>{t("fundReturns.fiveYears")}</td>
								</tr>
							</thead>
							<tbody>
								<tr>
									<th>{t("fundReturns.fund")}</th>
									<td>{(props?.fund?.performance?.asOfDate ?? notApplicable) === notApplicable ? t("common.responseNA") : moment(props?.fund?.performance?.asOfDate, DDMMYYYY).format(i18n.language === EN ? D_MMM_YYYY : D_MMM_YYYY_CH)}</td>
									<td>{isNaN(parseFloat(props?.fund?.performance?.crYtdTxt)) ? t("common.responseNA") : props?.fund?.performance?.crYtdTxt}{displayCarat(props.fund.invTypeNm) ? '^' : ''}</td>
									<td>{isNaN(parseFloat(props?.fund?.performance?.cr1yTxt)) ? t("common.responseNA") : props?.fund?.performance?.cr1yTxt}{displayCarat(props.fund.invTypeNm) ? '^' : ''}</td>
									<td>{isNaN(parseFloat(props?.fund?.performance?.cr3yTxt)) ? t("common.responseNA") : props?.fund?.performance?.cr3yTxt}{displayCarat(props.fund.invTypeNm) ? '^' : ''}</td>
									<td>{isNaN(parseFloat(props?.fund?.performance?.cr5yTxt)) ? t("common.responseNA") : props?.fund?.performance?.cr5yTxt}{displayCarat(props.fund.invTypeNm) ? '^' : ''}</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				{dNote &&
					<div className="row">
						<div className="col fpap-note" style={{ marginTop: "20px" }}>
							<p>{t("fundSwitchIn.notePara1")}</p>
						</div>
					</div>
				}
				{(productTypeNum == 142 || productTypeNum == 143) && <p className="goto-link">{`${t('fundReturns.note1')}${fundName}${t('fundReturns.note2')}${fundName}${t('fundReturns.note3')}${fundName}${t('fundReturns.note4')}`}</p>}
				<hr className="tab-seprator"></hr>
			</div>
		</>
	);
};
FundReturns.propTypes = {
	props: PropTypes.any,
	fund: PropTypes.array,
	feesCharges: PropTypes.object,
	fundPerformance: PropTypes.array,
	selectedPolicyIndex: PropTypes.number,
	quickPolicyInfo: PropTypes.array,
};

const mapStateToProps = (state) => {
	return {
		feesCharges: state.fundPerformance.feesCharges,
		fundPerformance: state.fundPerformance,
		selectedPolicyIndex: state.twoAccounts.selectedPolicyIndex,
		quickPolicyInfo: state.twoAccounts.quickPolicyInfo,
	};
};
export default connect(mapStateToProps)(FundReturns);
