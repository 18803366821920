import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types"

const OtherCountryAddr = (props) => {
    const { t } = useTranslation("common");
    let [addressLine1, setAddressLine1] = useState(props.nonHKAddr1);
    let [addressLine2, setAddressLine2] = useState(props.nonHKAddr2);
    let [addressLine3, setAddressLine3] = useState(props.nonHKAddr3);
    let flag = false;
    const handleChange = (e) => {
        if (e.target.value !== null || e.target.value !== "") {
            if (e.target.name === "addrLine1") {
                setAddressLine1(e.target.value);
                flag = true;

            } else if (e.target.name === "addrLine2") {
                setAddressLine2(e.target.value);
                flag = true;
            } else if (e.target.name === "addrLine3") {
                setAddressLine3(e.target.value);
                flag = true;
            }
            props.updateBtnState(flag);
        }


    }

    useEffect(() => {
        props.parentCallback(addressLine1, addressLine2, addressLine3)
    }, [addressLine1, addressLine2, addressLine3])
    return (
        <>
            <label className="left-align">
                <font>{t("addressInfo.nonHKAddressLabel")}</font>
            </label>
            <div className="row">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                    <input type="text"
                        name="addrLine1"
                        value={addressLine1}
                        maxLength="45"
                        data-testid="addrLine1Test"
                        className="form-input form-text required form-control addr-formInput"
                        onChange={(e) => handleChange(e)}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                    <input type="text"
                        name="addrLine2"
                        value={addressLine2}
                        maxLength="45"
                        data-testid="addrLine2Test"
                        className="form-input form-text required form-control addr-formInput"
                        onChange={(e) => handleChange(e)} />

                </div>
            </div>

            <div className="row">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                    <input type="text"
                        name="addrLine3"
                        maxLength="45"
                        value={addressLine3}
                        data-testid="addrLine3Test"
                        className="form-input form-text required form-control addr-formInput"
                        onChange={(e) => handleChange(e)} />
                </div>
            </div>
        </>
    )
}

OtherCountryAddr.propTypes = {
    props: PropTypes.any,
    dispatch: PropTypes.func,
    updateBtnState: PropTypes.func,
    parentCallback: PropTypes.func,
    nonHKAddr1: PropTypes.string,
    nonHKAddr2: PropTypes.string,
    nonHKAddr3: PropTypes.string
}

export default OtherCountryAddr;