import PropTypes from 'prop-types';
import React from "react";
import { useTranslation } from "react-i18next";
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import { saveSchemeType } from "../../../actions/EmployeeLoginAction";
import empPlan from "../../../assets/Employers.svg";
import ArrowRight from "../../../assets/Icon_Arrow_right.svg";
import "../../../css/EmpLogin.css";
import { schemeS800 } from "../../../translations/common/commonConstants";

const S800andORSO = (props) => {
  const { t } = useTranslation("common");
  const history = useHistory();

  const routeChange = (e) => {
    e.preventDefault();
    props.dispatch(saveSchemeType(schemeS800));
    history.push("/employeelogin");
  }

  return (
    <React.Fragment>
      <div className="login-card mpf-emp-card text-center" onClick={routeChange} id="mpf-employee">
        <div>
          <img src={empPlan} className="empPlan" />
          <div className="row">
            <div className="col-12">
              <a href="#" className="login-type-cards-title" name="goto" onClick={routeChange} data-testid="s800Orso">
                {t("titles.mpfEmployee")}
                <img src={ArrowRight} alt="" className="gotoArrow" />
              </a>
            </div>
          </div>
          <div className="row">
            <div className="col-12 lt-subtext">
              {t("mpfEmp.mpfText")}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

S800andORSO.propTypes = {
  props: PropTypes.any,
  dispatch: PropTypes.func
}

const S800andORSOComponent = connect()(S800andORSO);
export default S800andORSOComponent;