import { saveAsOfDate, saveCutOffHours, saveWebCycleDate } from "../actions/EmployeeLoginAction";
import accountApi from "../API/Account/accountApi";


const saveLatestPriceDate = (props, productTypeNmbr, headers) => {
    accountApi.getAsOfDate(productTypeNmbr, headers).then((asofDtResponse) => {
        let decodedResponse = asofDtResponse.data
        props.dispatch(saveAsOfDate(decodedResponse.priceDate));
        props.dispatch(saveCutOffHours(decodedResponse?.cutOffHours ?? 16))
        props.dispatch(saveWebCycleDate(decodedResponse?.webCycleDate))
    })
}

export { saveLatestPriceDate };

