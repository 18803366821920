import {
    ADD_FUND_SWITCH_IN_PERCENT, CLEAR_SWITCH_IN_FUNDS_PERCENT, FUND_SWITCH_ESTIMATED_AMOUNT,
    FUND_SWITCH_OUT_PERCENTAGE, REMOVE_FUND_SWITCH_DATA, RESET_FUND_SWITCH_DATA, SAVE_ALL_POLICY_FUND_INFO,
    SAVE_BAL_CHECK_EDIT, SAVE_CONTRIBUTION_TYPE,
    SAVE_EE_INVESTMENT_DETAILS, SAVE_EE_PENDING_FOR_SAME_DAY, SAVE_EE_PENDING_FUNDS, SAVE_ER_INVESTMENT_DETAILS, SAVE_ER_PENDING_FOR_SAME_DAY, SAVE_ER_PENDING_FUNDS, SAVE_FUNDWISE_PERCENTAGE_ALLOCATION_FUTURE,
    SAVE_FUND_INFO_DATA, SAVE_FUND_SWITCH_DATA,
    SAVE_GFC_FUNDS,
    SAVE_GFC_INVESTMENT_FLAG,
    SAVE_INVESTMENT_DETAILS, SAVE_INVESTMENT_DIRECTION, SAVE_INVESTMENT_TRANSFER_DETAILS, SAVE_MAN_INVESTMENT_DETAILS,
    SAVE_ORSO_FUND_SWITCH_DATA, SAVE_PENDING_FUND,
    SAVE_PENDING_FUNDS_LIST, SAVE_SELECTED_ASSET_TYPE, SAVE_SELECTED_FUNDS_FOR_SWITCH_IN,
    SAVE_SELECTED_FUND_FOR_SWITCH_OUT, SAVE_SWITCHIN_CONFIRMED_FUNDS, SAVE_SWITCH_IN_DATA, SAVE_SWITCH_NEXT_FLAG,
    SAVE_SWITCH_OUT_FUNDS, SAVE_TRANSACTION_DETAILS, SAVE_VOL_INVESTMENT_DETAILS, UPDATE_FUND_SWITCH_DATA
} from "../actions/ActionConstants.js";

export function saveSelectedAssetType(data) {
    return { type: SAVE_SELECTED_ASSET_TYPE, data }
}
export function saveContributionType(data) {
    return { type: SAVE_CONTRIBUTION_TYPE, data }
}
export function saveSelectedFundForSwitchOut(data) {
    return { type: SAVE_SELECTED_FUND_FOR_SWITCH_OUT, data }
}
export function saveFundSwitchOutPercentage(data) {
    return { type: FUND_SWITCH_OUT_PERCENTAGE, data }
}
export function saveSwitchinConfirmedFunds(data) {
    return { type: SAVE_SWITCHIN_CONFIRMED_FUNDS, data }
}
export function saveFundSwitchEstimatedAmount(data) {
    return { type: FUND_SWITCH_ESTIMATED_AMOUNT, data }
}
export function saveFundwisePercentageAllocationForFuture(data) {
    return { type: SAVE_FUNDWISE_PERCENTAGE_ALLOCATION_FUTURE, data }
}
export function saveSwitchInData(data) {
    return { type: SAVE_SWITCH_IN_DATA, data }
}
export function saveInvestmentTransferDetails(data) {
    return { type: SAVE_INVESTMENT_TRANSFER_DETAILS, data }
}
export function saveFundInfoData(data) {
    return { type: SAVE_FUND_INFO_DATA, data }
}
export function savePendingFundsList(data) {
    return { type: SAVE_PENDING_FUNDS_LIST, data }
}
export function saveInvestmentDirection(data) {
    return { type: SAVE_INVESTMENT_DIRECTION, data }
}
export function savePendingFund(data) {
    return { type: SAVE_PENDING_FUND, data }
}
export function addSwitchInPercent(data) {
    return { type: ADD_FUND_SWITCH_IN_PERCENT, data }
}
export function clearSwitchInFundsPercent() {
    return { type: CLEAR_SWITCH_IN_FUNDS_PERCENT }
}
export function saveSelectedSwitchInFunds(data) {
    return { type: SAVE_SELECTED_FUNDS_FOR_SWITCH_IN, data }
}
export function saveTransactionDetails(data) {
    return { type: SAVE_TRANSACTION_DETAILS, data }
}

export function saveAllPolicyFundInfo(data) {
    return { type: SAVE_ALL_POLICY_FUND_INFO, data }
}

export function saveFundSwitchData(data) {
    return { type: SAVE_FUND_SWITCH_DATA, data }
}

export function resetFundSwitchData() {
    return { type: RESET_FUND_SWITCH_DATA }
}

export function removeFundSwitchData(data) {
    return { type: REMOVE_FUND_SWITCH_DATA, data }
}

export function updateFundSwitchData(data) {
    return { type: UPDATE_FUND_SWITCH_DATA, data }
}

export function saveSwitchNextFlag(data) {
    return { type: SAVE_SWITCH_NEXT_FLAG, data }
}

export function saveManInvestmentDetails(data) {
    return { type: SAVE_MAN_INVESTMENT_DETAILS, data }
}

export function saveVolInvestmentDetails(data) {
    return { type: SAVE_VOL_INVESTMENT_DETAILS, data }
}
export function saveContributionEditFlag(data) {
    return { type: SAVE_BAL_CHECK_EDIT, data }
}

export function saveEeInvestmentDetails(data) {
    return { type: SAVE_EE_INVESTMENT_DETAILS, data }
}

export function saveErInvestmentDetails(data) {
    return { type: SAVE_ER_INVESTMENT_DETAILS, data }
}

export function saveAllInvestmentDetails(data) {
    return { type: SAVE_INVESTMENT_DETAILS, data }
}

export function saveSwitchOutFunds(data) {
    return { type: SAVE_SWITCH_OUT_FUNDS, data }
}

export function saveOrsoFundSwitchData(data) {
    return { type: SAVE_ORSO_FUND_SWITCH_DATA, data }
}

export function saveErPendingFunds(data) {
    return { type: SAVE_ER_PENDING_FUNDS, data }
}

export function saveEePendingFunds(data) {
    return { type: SAVE_EE_PENDING_FUNDS, data }
}

export function isErPendingForSameDay(data) {
    return { type: SAVE_ER_PENDING_FOR_SAME_DAY, data }
}

export function isEePendingForSameDay(data) {
    return { type: SAVE_EE_PENDING_FOR_SAME_DAY, data }
}

export function saveGFCFunds(data) {
    return { type: SAVE_GFC_FUNDS, data }
}

export function saveGfcInvFlag(data) {
    return { type: SAVE_GFC_INVESTMENT_FLAG, data }
}