import API from "../API/SuperLogon/SuperLogon";

const getCode = async () => {
    const response = await API.superLogon();
    return response;
};

const authentication = async (code, codeVerifier) => {
    const response = await API.superLogonLogin(code, codeVerifier);
    return response;
};

const generateOAuthUrl = (urlString, codeChallenge, nonce, state) => {
    const url = new URL(urlString);
    const params = new URLSearchParams(url.search);

    params.set('code_challenge', codeChallenge);
    params.set('nonce', nonce);
    params.set('state', state);
    const generatedUrl = `${url.origin}${url.pathname}?${params.toString()}`;
    return generatedUrl;
};

export default { getCode, authentication, generateOAuthUrl };