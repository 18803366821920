import React from 'react'
import '../css/CardContainer.css'
import PropTypes from 'prop-types'

const CardContainer = (props)=> {
    return (
        <div className='card-container'>
            {props.children}
        </div>
    )
}

CardContainer.propTypes = {
    props:PropTypes.any,
    children:PropTypes.any,
}

export default CardContainer
