import { instance } from '../AxiosInterceptor';

export default {
    getContractInfo: (contractNmbr, requestHeaders) =>
        instance({
            method: 'GET',
            url: '/contractinfoservice/customattributes',
            headers: requestHeaders,
            params: {
                contractNmbr
            }
        })
}