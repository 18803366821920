import { CHANGES_LIST_LOADING, CHANGE_FUND_DETAILS, CHANGE_STATUS_TYPE, RESET_CHANGES_LIST, SAVE_CHANGES_LIST, SAVE_CHANGES_PAGINATION, SAVE_FILTERED_CHANGES_LIST, SAVE_CURRENT_PAGE_NO, SAVE_CURRENT_POSTS_ON_PAGE,
    SAVE_FUND_STATUS,SAVE_FUND_FROM_DATE,SAVE_FUND_TO_DATE,SAVE_CHANGES_PAGE_EDIT_FLAG } from "../actions/ActionConstants";

const initialState = {
    changeFundDetails: {},
    changesList: [],
    statusWiseList: [],
    changesListLoading: true,
    statusType: "All",
    totalActivities: 0,
    paginationNo: [],
    totalPosts: 0,
    pageNo: 0,
    currentPostsOnPage: [],
    fundStatus: "All",
    fundFromDate :undefined,
    fundToDate : undefined,
    changesPageEditFlag:false,
    transactionDetails:[]
}

export default function ChangesFundDetailsReducer(state = initialState, action) {
    switch (action.type) {
        case CHANGES_LIST_LOADING: {
            return { ...state, changesListLoading: false };
        }
        case CHANGE_FUND_DETAILS:
            return { ...state, changeFundDetails: action.data };
        case SAVE_CHANGES_LIST:
            return {
                ...state, changesList: action.data, changesListLoading: false,
                totalActivities: action.data.length, statusWiseList: action.data
            }
        case CHANGE_STATUS_TYPE:
            return { ...state, statusType: action.status };
        case SAVE_CHANGES_PAGINATION:
            return {
                ...state,
                totalActivities: action.payload.totalActivities,
                totalPosts: action.payload.totalActivities,
                paginationNo: action.payload.paginationNo
            };
        case SAVE_FILTERED_CHANGES_LIST:
            return { ...state, statusWiseList: action.data };
        case RESET_CHANGES_LIST:
            return {
                ...state, changeFundDetails: {},
                changesList: [],
                statusWiseList: [],
                changesListLoading: true,
                statusType: "All",
                totalActivities: 0,
                paginationNo: [],
                totalPosts: 0
            };
        case SAVE_CURRENT_PAGE_NO: return {
            ...state,
            pageNo: action.data
        };
        case SAVE_FUND_STATUS: return {
            ...state,
            fundStatus: action.data
        };
        case SAVE_FUND_FROM_DATE: return {
            ...state,
            fundFromDate: action.data
        };
        case SAVE_FUND_TO_DATE: return {
            ...state,
            fundToDate: action.data
        };
        case SAVE_CHANGES_PAGE_EDIT_FLAG: return {
            ...state,
            changesPageEditFlag: action.data
        };
        case SAVE_CURRENT_POSTS_ON_PAGE: return {
            ...state,
            currentPostsOnPage: action.data
        };
        default:
            return state;
    }
}