import { BadAddressText, mainAddressTypeNo, mpfBillingAddressTypeNo, orsoBillingAddressTypeNo, schemeAIIB, schemeS800, SMP } from "../translations/common/commonConstants";

const formatAddress = (state, props) => {
    if (props.verifyData.isHKCountry) {
        let addLine1 = "";
        let addLine2 = "";
        let addrRoom = "";
        let adrFloor = "";
        let addrBlock = "";
        let add3 = "";
        let add4 = "";
        if (state.languagePreference === "E") {
            if (state.room != "") {
                addrRoom = "Room " + state.room;
                addLine1 = addLine1 + addrRoom;
            }

            if (state.floor != "" && state.floor != null) {
                adrFloor = state.floor + "/F";
                if (addLine1 === "") {
                    addLine1 = addLine1 + adrFloor;
                } else {
                    addLine1 = addLine1 + ", " + adrFloor;
                }
            }

            if (state.block != "" && state.block != null) {
                addrBlock = "Block " + state.block;
                if (addLine1 === "") {
                    addLine1 = addLine1 + addrBlock;
                } else {
                    addLine1 = addLine1 + ", " + addrBlock;
                }
            }
            state.addressLine1 = addLine1;

            if (state.nameOfBuilding != "" && state.nameOfBuilding != null) {
                state.addressLine2 = state.nameOfBuilding;
            }

            if (state.streetNumber != "" && state.streetNumber != null) {
                add3 = state.streetNumber;
            }

            if (state.nameOfStreet != "" && state.nameOfStreet != null) {
                add3 = add3 + " " + state.nameOfStreet;
            }
            state.addressLine3 = add3;

            if (state.city != "" && state.city != null) {
                if (add4 === "") {
                    add4 = add4 + state.city;
                } else {
                    add4 = add4 + "," + state.city;
                }
            }
            if (state.territory != "" && state.territory != null) {
                if (add4 === "") {
                    add4 = add4 + state.territory;
                } else {
                    add4 = add4 + "," + state.territory;
                }
            }
            if (state.postOrZip != "" && state.postOrZip != null) {
                if (add4 === "") {
                    add4 = add4 + state.postOrZip + " ";
                } else {
                    add4 = add4 + "," + state.postOrZip;
                }
            }
            state.addressLine4 = add4;

            if (state.countryCode != "" && state.countryCode != null) {
                state.addressLine5 = state.countryCode;
            }
        }

        if (state.languagePreference === "C") {
            if (state.postOrZip != "" && state.postOrZip != null) {
                addLine1 = addLine1 + state.postOrZip + " ";
            }

            if (state.territory != "" && state.territory != null) {
                addLine1 = addLine1 + state.territory;
            }
            if (state.city != "" && state.city != null) {
                addLine1 = addLine1 + " " + state.city;
            }
            state.addressLine1 = addLine1;

            if (state.nameOfStreet != "" && state.nameOfStreet != null) {
                addLine2 = addLine2 + state.nameOfStreet;
            }
            if (state.streetNumber != "" && state.streetNumber != null) {
                addLine2 = addLine2 + " " + state.streetNumber + " 號";
            }
            state.addressLine2 = addLine2;

            if (state.nameOfBuilding != "" && state.nameOfBuilding != null) {
                add3 = add3 + state.nameOfBuilding;
            }
            if (state.block != "" && state.block != null) {
                add3 = add3 + " " + state.block + " 座";
            }
            state.addressLine3 = add3;

            if (state.floor != "" && state.floor != null) {
                add4 = add4 + state.floor + " 樓 ";
            }

            if (state.room != "" && state.room != null) {
                if (add4 === "") {
                    add4 = add4 + state.room + " 室";
                } else {
                    add4 = add4 + " " + state.room + " 室";
                }
            }
            state.addressLine4 = add4;

            if (state.countryCode != "" && state.countryCode != null) {
                state.addressLine5 = state.countryCode;
            }
        }
    } else {
        state.addressLine1 = props.verifyData.nonHKAddressLine1;
        state.addressLine2 = props.verifyData.nonHKAddressLine2;
        state.addressLine3 = props.verifyData.nonHKAddressLine3;
        if (state.countryCode != "" && state.countryCode != null) {
            if (state.addressLine1 && state.addressLine2 && state.addressLine3) {
                state.addressLine4 = state.countryCode;
            } else if (state.addressLine1 && state.addressLine2 === "" && state.addressLine3 === "") {
                state.addressLine2 = state.countryCode;
            } else if (state.addressLine1 && state.addressLine2 && state.addressLine3 === "") {
                state.addressLine3 = state.countryCode;
            } else if (state.addressLine1 === "" && state.addressLine2 && state.addressLine3 === "") {
                state.addressLine1 = state.addressLine2;
                state.addressLine2 = state.countryCode;
            } else if (state.addressLine1 === "" && state.addressLine2 && state.addressLine3) {
                state.addressLine1 = state.addressLine2;
                state.addressLine2 = state.addressLine3;
                state.addressLine3 = state.countryCode;
            } else if (state.addressLine1 && state.addressLine2 === "" && state.addressLine3) {
                state.addressLine2 = state.addressLine3;
                state.addressLine3 = state.countryCode;
            } else if (state.addressLine1 === "" && state.addressLine2 === "" && state.addressLine3) {
                state.addressLine1 = state.addressLine3;
                state.addressLine2 = state.countryCode;
                state.addressLine3 = "";

            }
        }
    }
};

const getAddressLinesArray = (state, props) => {
    let arr = [];
    let finalArr = [];
    if (!state.isHKCountry) {
        if (state.addressLine1) {
            arr.push({ addrLineSeqNmbr: 100, addrLineTxt: state.addressLine1, delete: false });
        } if (state.addressLine2) {
            arr.push({ addrLineSeqNmbr: 101, addrLineTxt: state.addressLine2, delete: false });
        }
        if (state.addressLine3) {
            if (state.addressLine2 !== "" && state.addressLine1 !== "") {
                arr.push({ addrLineSeqNmbr: 102, addrLineTxt: state.addressLine3, delete: false });
            } else if (state.addressLine1 !== "" && state.addressline2 === "") {
                arr.push({ addrLineSeqNmbr: 101, addrLineTxt: state.addressLine3, delete: false });
            }
        }
        if (state.addressLine4) {
            if (state.addressLine3 !== "" && state.addressLine2 !== "" && state.addressLine1 !== "") {
                arr.push({ addrLineSeqNmbr: 103, addrLineTxt: state.addressLine4, delete: false });
            } else if (state.addressLine2 == "" && state.addressLine1 !== "" && state.addressLine3 === "") {
                arr.push({ addrLineSeqNmbr: 101, addrLineTxt: state.addressLine4, delete: false });
            } else if (state.addressLine1 !== "" && state.addressLine2 !== "" && state.addressLine3 === "") {
                arr.push({ addrLineSeqNmbr: 102, addrLineTxt: state.addressLine4, delete: false });
            }
        }

        finalArr = [...arr];
        if (arr.length < props?.verifyData?.verifyAddress?.addressLineTxt?.length) {
            for (let index = 0; index < props.verifyData.verifyAddress.addressLineTxt.length; index++) {
                let seNumb = props.verifyData.verifyAddress.addressLineTxt[index].addrLineSeqNmbr;
                for (let a = 0; a < arr.length; a++) {
                    if (arr[a].addrLineSeqNmbr !== seNumb) {
                        let finalArrVal = finalArr.find(final => final['addrLineSeqNmbr'] === seNumb);
                        if (userExists(seNumb, props)) {
                            let addrVal = getAddrValue(props.verifyData.verifyAddress.addressLineTxt[index].addrLineSeqNmbr, props);
                            if (!finalArrVal) {
                                finalArr.push({ addrLineSeqNmbr: props.verifyData.verifyAddress.addressLineTxt[index].addrLineSeqNmbr, addrLineTxt: addrVal, delete: true });
                            }
                        }
                    }

                }

            }
        }
    } else {
        let newAddressLines = [];
        let previousAddress = props.verifyData?.verifyAddress?.addressLineTxt;

        state.addressLine1 && newAddressLines.push(state.addressLine1);
        state.addressLine2 && newAddressLines.push(state.addressLine2);
        state.addressLine3 && newAddressLines.push(state.addressLine3);
        state.addressLine4 && newAddressLines.push(state.addressLine4);

        for (let addressLineIndex = 0; addressLineIndex < Math.max((previousAddress?.length ? previousAddress?.length : 0), newAddressLines?.length); addressLineIndex++) {
            if (addressLineIndex < newAddressLines?.length) {
                finalArr.push({ addrLineSeqNmbr: 100 + addressLineIndex, addrLineTxt: newAddressLines[addressLineIndex], delete: false });
            } else {
                finalArr.push({ addrLineSeqNmbr: 100 + addressLineIndex, addrLineTxt: previousAddress[addressLineIndex]?.addrLineTxt, delete: true });
            }
        }
    }

    return finalArr;
};

const getAddrValue = (seqNum, props) => {
    let arr = props.verifyData.verifyAddress.addressLineTxt;
    let addLineTxt;
    arr.forEach(element => {
        if (element.addrLineSeqNmbr === seqNum) {
            addLineTxt = element.addrLineTxt;
        }
    });
    return addLineTxt;
};

function userExists(seqNum, props) {
    return props.verifyData.verifyAddress.addressLineTxt.some(function (el) {
        return el.addrLineSeqNmbr === seqNum;
    });
}

function orsoAddrExists(clientAddresses) {
    return clientAddresses.some(function (el) {
        if ((el.addressTypeNmbr === orsoBillingAddressTypeNo)
            && !(el.addressLocationNm === BadAddressText)) {
            return el;
        }
    });
}

const getAddressType = (address, props) => {
    let clientAddresses = props?.clientInfo?.clientAddresses;
    if (props.scheme === schemeAIIB || props?.isOrsoAccount) {
        if (clientAddresses?.length > 0) {
            let orsoAddFlag = orsoAddrExists(clientAddresses);
            clientAddresses.forEach(element => {
                if (orsoAddFlag && element.addressTypeNmbr === orsoBillingAddressTypeNo) {
                    address = element;
                    address.addressTypeNmbr = element.addressTypeNmbr;
                    return;
                }
                else if (!orsoAddFlag && element.addressTypeNmbr === mainAddressTypeNo && !(element.addressLocationNm === BadAddressText)) {
                    address = element;
                    address.addressTypeNmbr = element.addressTypeNmbr;
                }
            });
        } else {
            if (props.clientInfo.mpfBillingAddress && props?.clientInfo?.mpfBillingAddress?.addressLocationNm !== BadAddressText) {
                address = props.clientInfo.mpfBillingAddress;
                address.addressTypeNmbr = mpfBillingAddressTypeNo;
            } else if (props.clientInfo.mainAddress && props?.clientInfo?.mainAddress?.addressLocationNm !== BadAddressText) {
                address = props.clientInfo.mainAddress;
                address.addressTypeNmbr = mainAddressTypeNo;
            }
        }
    }
    else
        if (props.scheme === schemeS800 || props.scheme == SMP) {
            if (props.clientInfo.mpfBillingAddress && props?.clientInfo?.mpfBillingAddress?.addressLocationNm !== BadAddressText) {
                address = props.clientInfo.mpfBillingAddress;
                address.addressTypeNmbr = mpfBillingAddressTypeNo;
            } else if (props.clientInfo.mainAddress && props?.clientInfo?.mainAddress?.addressLocationNm !== BadAddressText) {
                address = props.clientInfo.mainAddress;
                address.addressTypeNmbr = mainAddressTypeNo;
            }
        }
    return address;
};

export default { formatAddress, getAddressLinesArray, getAddressType };