import { DISInvNm, LSSInvNm } from "../translations/common/commonConstants"

const getFundNameFrmContribution = (fundCode, selectedContributionRecord, i18n) => {
    if (i18n.language === "en") {
        if (fundCode === "TERM HSITF-600") {
            return "Principal Hang Seng Index Tracking Fund"
        } else if (fundCode === "TERM 2_GGF-600") {
            return "Principal Global Growth Fund"
        } else if (fundCode === "TERM CAF-600-(DIS)") {
            return "PRINCIPAL CORE ACCUMULATION FUND (DIS)"
        } else if (fundCode === "TERM A65F-600-(DIS)") {
            return "PRINCIPAL AGE 65 PLUS FUND (DIS)"
        } else {
            return selectedContributionRecord.length ? selectedContributionRecord[0].legalNm : '';
        }
    } else if (i18n.language === "zh") {
        if (fundCode === "TERM HSITF-600") {
            return "信安恒指基金"
        } else if (fundCode === "TERM 2_GGF-600") {
            return "信安環球增長基金"
        } else if (fundCode === "TERM CAF-600-(DIS)") {
            return "信安核心累積基金 (預設投資策略)"
        } else if (fundCode === "TERM A65F-600-(DIS)") {
            return "信安65歲後基金 (預設投資策略)"
        } else {
            return selectedContributionRecord.length ? selectedContributionRecord[0].othLegalNm : '';
        }
    } else { return fundCode }
}

const getFundCode = (invTypeNm) => {
    let fundCode = ''
    if (invTypeNm) {
        if (![DISInvNm, LSSInvNm]?.includes(invTypeNm)) {
            fundCode = invTypeNm?.split('-')?.[0]
            if (fundCode?.includes("TERM")) {
                fundCode = fundCode?.split(' ')?.[1]
            }
        } else {
            fundCode = invTypeNm
        }
    }
    return fundCode
}

export default { getFundNameFrmContribution, getFundCode }