import { instance } from '../AxiosInterceptor';

export default {
    log: (reqBody, header) =>
        instance({
            method: 'POST',
            url: '/record',
            data: reqBody,
            headers: header
        })
}