import { Base64 } from "js-base64";
import AuthTwoFactor from "../API/TwoFactorAuthentication/TwoFactorAuthAPI";
import { COBRAND, SMP } from "../translations/common/commonConstants";
import { constructCID } from "./LoginUtils";

const authenticateMFA = async (userInfo) => {
  const constructedCID = userInfo.loginId !== "" ? constructCID(userInfo.loginId) : "";
  const username = userInfo.scheme == COBRAND ? constructedCID : userInfo.loginId.replace(/^0+/, '');
  const userLoginInfo = {
    userName: username,
    password: Base64.encode(userInfo.password),
    sysId: "1",
    roleCode: "2",
    scheme: userInfo.scheme,
    govtId: userInfo.govtId,
    loginId: userInfo.loginId
  };
  const response = await AuthTwoFactor.authenticateMFA((JSON.stringify(userLoginInfo)));
  return response;
}

const activateMFAuth = async (authMFAInfo, preference, activateMFADetails, phoneNumber, emailAddress, scheme) => {
  const userName = scheme === SMP ? constructCID(authMFAInfo.token.userName[0]) : authMFAInfo.token.userName[0];
  const headers = {
    pisys_auth_token: authMFAInfo.token.accessToken.token,
    username: userName,
    "Access-Control-Allow-Origin": "*",
  };

  let resendOTP = "", userid, smsFactorID, emailFactorID;
  if (activateMFADetails) {
    userid = activateMFADetails.userid ? activateMFADetails.userid : "";
    smsFactorID = activateMFADetails.smsFactorid ? activateMFADetails.smsFactorid : "";
    emailFactorID = activateMFADetails.emailFactorid ? activateMFADetails.emailFactorid : "";
    resendOTP = "TRUE";
  }

  const serviceReq = { 
    "govtId": "", 
    "scheme": scheme === SMP ? COBRAND : scheme,
    "userName": userName,
    "loginId": authMFAInfo.token.userName[0],
    "password": authMFAInfo.password, 
    "pref": preference, 
    "clientEmail": emailAddress, 
    "clientMobile": phoneNumber, 
    "userid": userid, 
    "emailFactorid": emailFactorID, 
    "smsFactorid": smsFactorID, 
    "resendOTP": resendOTP 
  }
  const encodedServiceReq = (JSON.stringify(serviceReq));

  const response = await AuthTwoFactor.activateMFA(encodedServiceReq, headers)
  const decodedResponse = (response.data);
  return decodedResponse;
};

const verifyMFA = async (authMFADetails, activateMFADetails, enteredOTP, scheme) => {
  const userName = scheme === SMP ? constructCID(authMFADetails.userName) : authMFADetails.userName;
  const headers = {
    pisys_auth_token: authMFADetails.token.accessToken.token
  };

  const verifyReqBody = {
    "govtId": authMFADetails.govtId,
    "userName": userName,
    "password": authMFADetails.password,
    "pref": activateMFADetails.pref,
    "clientEmail": activateMFADetails.clientEmail,
    "clientMobile": activateMFADetails.clientMobile,
    "smsFactorid": activateMFADetails.smsFactorid,
    "emailFactorid": activateMFADetails.emailFactorid,
    "userid": activateMFADetails.userid,
    "passCode": enteredOTP
  }

  let encodedReqBody = (JSON.stringify(verifyReqBody));

  const response = await AuthTwoFactor.verifyMFA(headers, encodedReqBody)
  const decodedResponse = ((response.data));
  return decodedResponse;
};

const maskDetails = (detail, type) => {
  if (type === "sms") {
    detail = "*".repeat(detail.length - 4) + detail.slice(-4)
    return detail
  } else if (type === "email") {
    return (detail[0] + detail[1] + "*".repeat(detail.length - 4) + detail.slice(-4))
  }
}

export default { activateMFAuth, authenticateMFA, maskDetails, verifyMFA };