import moment from "moment"
import logApi from "../API/Logger/logApi";
import { CAHNGES_DETAILS, CONFIRMATION_PAGE, CONTRIBUTION_DETAILS, FUND_PROCESSING, SELECT_ASSET_PAGE, SELECT_CONTRIBUTION_PAGE, SELECT_FUTURE_CONTRIBUTIONH, SIMPLIFIED_PAGE, SWITCHOUT_ALLOCATION_PAGE, SWITCH_IN_PAGE, SWITCH_OUT_PAGE, TRNS_ACCPT_EXT_PAGE, FUTURE_ALLOCATION_CONTRIBUTION, FUTURE_TRNS_ACCEPTED, VERIFICATION, FUND_INFO_DETAILS } from "../translations/common/commonConstants";


const sendLog = (data) => {
    let date = new Date();
    const retentionDate = new Date();
    retentionDate.setDate(retentionDate.getDate() + 30)
    const pathName = getPathName(data?.page)
    const reqBody = {
        "TableName": "RSC2_Access_log",
        "Item": {
            "loginId": {
                "S": data?.loginId
            },
            "accessDate": {
                "S": moment.utc(date).format('YYYY-MM-DD')
            },
            "accessTime": {
                "S": moment.utc(date).format('YYYYMMDDHHmmss.SS')
            },
            "clientId": {
                "N": data?.loginId ? data.loginId  : "0"
            },
            "policyId": {
                "N": data?.policyId ? data.policyId : "0" 
            },
            "contractId": {
                "N": data?.contractId ? data.contractId : "0"
            },
            "pageLink": {
                "S": `${data?.location}${pathName}`
            },
            "browserInfo": {
                "S": navigator.userAgent
            },
            "recordRetentionDate": {
                "N": `${Math.floor(retentionDate?.getTime() / 1000)}`
            }
        }
    }

    const header = {
        pisys_auth_token: data?.pisysToken,
        'Access-Control-Allow-Origin': '*'
    }
    
    logApi.log(reqBody, header);
}

const getPathName = (page) => {
    let path = '';
    switch (page) {
        case SELECT_ASSET_PAGE:
            path = '/selectAsset'
            break;
        
        case SELECT_CONTRIBUTION_PAGE:
            path = '/selectContribution'
            break;

        case SWITCH_OUT_PAGE:
            path = '/switchOut'
            break;
        case SWITCHOUT_ALLOCATION_PAGE:
            path = '/switchOutPercentAllocation'
            break;

        case SWITCH_IN_PAGE:
            path = '/switchIn'
            break;

        case CONFIRMATION_PAGE:
            path = '/confirmation'
            break;

        case TRNS_ACCPT_EXT_PAGE:
            path = '/existingTransactionAccepted'
            break;

        case CONTRIBUTION_DETAILS:
        case CAHNGES_DETAILS:
        case FUND_INFO_DETAILS:
            path = '/details'
            break;

        case SIMPLIFIED_PAGE:
            path = '/rateOfReturn'
            break;

        case FUND_PROCESSING:
            path = '/fundProcessing'
            break;

        case SELECT_FUTURE_CONTRIBUTIONH:
            path = '/selectFutureContribution'
            break;

        case FUTURE_ALLOCATION_CONTRIBUTION:
            path = '/futurePercentageAllocation'
            break;

        case FUTURE_TRNS_ACCEPTED:
            path = '/futureTransactionAccepted'
            break;

        case VERIFICATION:
            path = '/verification'
            break;


        default:
            break;
    }

    return path;
}


export default sendLog;