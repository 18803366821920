import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import '../../css/fund.css';
import '../../css/FundInfoTabView.css';
import { HONG_KONG_DOLLAR, US_DOLLAR, SMP } from "../../translations/common/commonConstants";
import { FormatUtils } from "../../utils";

const FundOverview = (props) => {
    const { t, i18n } = useTranslation("common");
    const decimalPlaces = props.scheme == SMP ? 2 : 4;
    const cobrandGUA = props?.scheme === SMP && props?.fund?.invTypeNmbr == 805;
    return (
        <>
            <div id="fund_info_tab_overview" className="row" data-testid="Fund Overview">
                <div className="col-12">
                    <div className="pds-card fund-rectangle">
                        <div className="col-12 fund-rect-inner-pad">
                            <div className="row">
                                <div className="col-12 col-md-6">
                                    <label className="col-12 nav-lbl">{t('fundOverview.nav')}</label>
                                    <div className="col-12 nav-val d-flex align-self-center">
                                        <div className="mr-2">{(props?.fund?.unitPriceAmt && !cobrandGUA) ? FormatUtils.formatToDecimalFix(props.fund.unitPriceAmt, decimalPlaces) : t("common.responseNA")} </div>
                                        {cobrandGUA ? '' : <span className="fund_details_usd my-auto">{props.fund?.currencyTypeNm?.includes(HONG_KONG_DOLLAR) ? t("currencies.hkd") : props.fund?.currencyTypeNm?.includes(US_DOLLAR) ? t("currencies.usd") : ''}</span>}
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 fo-mg-top">
                                    <label className="col-12 fund-vd-lbl">{t('fundOverview.valuationDate')}</label>
                                    <label className="col-12 fund-vd-val">{moment(props?.fund?.asOfDate, 'DD/MM/YYYY').format(i18n.language === 'en' ? 'D MMM YYYY' : 'YYYY年M月D日')}</label>
                                </div>
                                <div className="col-12">
                                    <label className="col-12 over-view-note">{t('fundOverview.nav-note')}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr className="tab-seprator"></hr>
                </div>
            </div>
        </>
    );
};

FundOverview.propTypes = {
    props: PropTypes.any,
    fund: PropTypes.object,
    scheme: PropTypes.string
};

const mapStateToProps = (state) => {
    return {
        scheme: state.empLogin.scheme
    };
};

export default connect(mapStateToProps)(FundOverview);