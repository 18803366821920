import { saveCustomFields, saveCustomFieldsArray } from "../actions/DashboardAction";
import contractInfoApi from "../API/Dashboard/customFieldsApi";

const getCustomFieldsUtils = async (props, productTypeNmbr) => {
    let reqHeaders = {
        'pisys_auth_token': props.pisysToken,
        'userName': props.clientNmbr,
        'Access-Control-Allow-Origin': '*'
    }
    props.dispatch(saveCustomFields([]))
    await contractInfoApi.getCustomFields((String(productTypeNmbr)), reqHeaders)
        .then((response) => {
            if (response.status === 200) {
                let decodedResponse = response.data;
                props.dispatch(saveCustomFieldsArray(decodedResponse))
                decodedResponse = decodedResponse.filter((customeFieldsArray) => ((customeFieldsArray?.customDataDetailsList && customeFieldsArray.cstmAttrNm) && (['SCHEME NAME-CHINESE', 'SCHEME NAME-ENGLISH'].includes(customeFieldsArray.cstmAttrNm))));
                let schemeName = { englishSchemeName: '', chineseSchemeName: '' };
                decodedResponse.forEach(customFields => {
                    customFields.customDataDetailsList.forEach(fields => {
                        if ((customFields?.cstmAttrNm && ['SCHEME NAME-ENGLISH'].includes(customFields.cstmAttrNm) && fields?.cstmAttrTxt)) {
                            schemeName.englishSchemeName = fields.cstmAttrTxt;
                        } else if ((customFields?.cstmAttrNm && ['SCHEME NAME-CHINESE'].includes(customFields.cstmAttrNm) && fields?.cstmAttrTxt)) {
                            schemeName.chineseSchemeName = fields.cstmAttrTxt;
                        }
                    });
                });
                props.dispatch(saveCustomFields(schemeName))
            }
        }).catch(() => { });
}

export { getCustomFieldsUtils };
