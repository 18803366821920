import { AI, AO, CD, EE_MAN_TFR_G, FD, PA2_OVERPAY, PD, PI, productTypeMPF, productTypeORSO, PRTL_DSTRB_OVERPAY, SC9_UNIT_CREDIT, SEP_MAN_TFR_G, transTypAI2, transTypAO2 } from "../../translations/common/commonConstants";
import { contributionTrnsTypes, transferInTrnsTypes, transferOutTrnsTypes, unitCreditTrnsTypes, withdrawalTrnsTypes } from "../../utils/TransactionUtils";

const getTransactionName = (transaction, productDetails) => {
    if ((transaction?.activityTypeNmbr === 25 && transaction?.subTrnsNmbr === 1)
        || (transaction?.activityTypeNmbr === 95 && transaction?.subTrnsNmbr === 1)) {
        transaction.transactionNm = 'transactionHistory.txn1';
        transaction.out = false;
        transaction.transFerOut = true;
        transaction.transFerIn = true;
    } else if (transaction?.activityTypeNmbr === 5 || transaction?.activityTypeNmbr === 75
        || (transaction?.activityTypeNmbr === 25 && [4, 8].includes(transaction?.subTrnsNmbr))) {
        transaction.transactionNm = 'transactionHistory.txn2';
        transaction.out = false;
        transaction.transFerOut = true;
        transaction.transFerIn = true;
    } else if ([259, 291].includes(transaction?.activityTypeNmbr)
        || (transaction?.activityTypeNmbr === 25 && [2, 3, 7, 10].includes(transaction?.subTrnsNmbr))) {
        transaction.transactionNm = 'transactionHistory.txn3';
        transaction.out = false;
        transaction.transFerOut = false;
        transaction.transFerIn = true;
    } else if ((transaction?.activityTypeNmbr === 95 && transaction?.subTrnsNmbr === 2 && transaction?.moneyTypeNm.includes('-G'))
        || (transaction?.activityTypeNmbr === 92 && [2, 3].includes(transaction?.subTrnsNmbr))
        || (transaction?.activityTypeNmbr === 94 && transaction?.subTrnsNmbr === 7)
        || ([258, 290].includes(transaction?.activityTypeNmbr))
    ) {
        transaction.transactionNm = 'transactionHistory.txn4';
        transaction.out = true;
        transaction.transFerOut = true;
        transaction.transFerIn = false;
    } else if (transaction?.activityTypeNmbr === 149) {
        transaction.transactionNm = 'transactionHistory.txn5';
        transaction.out = true;
        transaction.transFerOut = true;
        transaction.transFerIn = true;
    } else if (transaction?.activityTypeNmbr === 148) {
        transaction.transactionNm = 'transactionHistory.txn6';
        transaction.out = false;
        transaction.transFerOut = true;
        transaction.transFerIn = true;
    } else if (transaction?.activityTypeNmbr === 91
        || (transaction?.activityTypeNmbr === 95 && transaction?.subTrnsNmbr === 3)) {
        transaction.transactionNm = 'transactionHistory.txn7';
        transaction.out = true;
        transaction.transFerOut = true;
        transaction.transFerIn = true;
    } else if ((transaction?.activityTypeNmbr === 25 && [6, 9].includes(transaction?.subTrnsNmbr))
        || (transaction?.activityTypeNmbr === 279)) {
        transaction.transactionNm = 'transactionHistory.txn8';
        transaction.out = false;
        transaction.transFerOut = true;
        transaction.transFerIn = true;
    } else if ((transaction?.activityTypeNmbr === 94 && transaction?.subTrnsNmbr !== 7)
        || (transaction?.activityTypeNmbr === 95 && transaction?.subTrnsNmbr === 2 && !transaction?.moneyTypeNm.includes('-G'))
        || (transaction?.activityTypeNmbr === 92 && [1, 4, 5, 6, 7, 8].includes(transaction?.subTrnsNmbr))
        || (transaction?.activityTypeNmbr === 98)
    ) {
        transaction.transactionNm = 'transactionHistory.txn9';
        transaction.out = true;
        transaction.transFerOut = true;
        transaction.transFerIn = true;
    } else if (
        (transaction?.activityTypeNmbr === 92 && transaction?.subTrnsNmbr === 9 && productDetails?.product?.productTypeName.includes(productTypeMPF))
        || (transaction?.activityTypeNmbr === 94 && [8, 9].includes(transaction?.subTrnsNmbr) && productDetails?.product?.productTypeName.includes(productTypeMPF))
        || (transaction?.activityTypeNmbr === 94 && transaction?.subTrnsNmbr === 16 && productDetails?.product?.productTypeName.includes(productTypeORSO))
        || (transaction?.activityTypeNmbr === 92 && transaction?.subTrnsNmbr === 10 && productDetails?.product?.productTypeName.includes(productTypeORSO))
    ) {
        transaction.transactionNm = 'transactionHistory.txn10';
        transaction.out = true;
        transaction.transFerOut = true;
        transaction.transFerIn = true;
    } else {
        transaction.transactionNm = '';
        transaction.out = false;
        transaction.transFerOut = true;
        transaction.transFerIn = true;
    }
};

//To show policy description only for AIIB funds
const getAIIBTransactionName = (transaction) => {
    if ((transaction?.activityTypeNmbr === 25 && transaction?.subTrnsNmbr === 5)) {
        transaction.transactionNm = 'transactionHistoryAIIB.txn101';
        transaction.out = false;
        transaction.transFerOut = true;
        transaction.transFerIn = true;
    }
    else if ((transaction?.activityTypeNmbr === 95 && transaction?.subTrnsNmbr === 2)) {
        transaction.transactionNm = 'transactionHistoryAIIB.txn102';
        transaction.out = true;
        transaction.transFerOut = true;
        transaction.transFerIn = true;
    }
    else if (([75, 25, 5].includes(transaction?.activityTypeNmbr) && transaction?.subTrnsNmbr === 1)) {
        transaction.transactionNm = 'transactionHistoryAIIB.txn103';
        transaction.out = false;
        transaction.transFerOut = true;
        transaction.transFerIn = true;
    }

    else if ((transaction?.activityTypeNmbr === 25 && [7, 2, 3].includes(transaction?.subTrnsNmbr))
        || (transaction?.activityTypeNmbr === 259 && (transaction?.subTrnsNmbr === 2))) {
        transaction.transactionNm = 'transactionHistoryAIIB.txn104';
        transaction.out = false;
        transaction.transFerOut = false;
        transaction.transFerIn = true;
    }
    else if ((transaction?.activityTypeNmbr === 92 && [7, 8].includes(transaction?.subTrnsNmbr))
        || (transaction?.activityTypeNmbr === 258 && (transaction?.subTrnsNmbr === 2))) {
        transaction.transactionNm = 'transactionHistoryAIIB.txn105';
        transaction.out = true;
        transaction.transFerOut = true;
        transaction.transFerIn = false;
    }
    else if ((transaction?.activityTypeNmbr === 149 && (transaction?.pmtMthdTypeNmbr) != 110)) {
        transaction.transactionNm = 'transactionHistoryAIIB.txn106';
        transaction.out = true;
        transaction.transFerOut = true;
        transaction.transFerIn = true;
    }
    else if ((transaction?.activityTypeNmbr === 148 && (transaction?.pmtMthdTypeNmbr) != 110)) {
        transaction.transactionNm = 'transactionHistoryAIIB.txn107';
        transaction.out = false;
        transaction.transFerOut = true;
        transaction.transFerIn = true;
    }
    else if ((transaction?.activityTypeNmbr === 149 && (transaction?.pmtMthdTypeNmbr) == 110)) {
        transaction.transactionNm = 'transactionHistoryAIIB.txn108';
        transaction.out = true;
        transaction.transFerOut = true;
        transaction.transFerIn = true;
    }
    else if ((transaction?.activityTypeNmbr === 148 && (transaction?.pmtMthdTypeNmbr) == 110)) {
        transaction.transactionNm = 'transactionHistoryAIIB.txn109';
        transaction.out = false;
        transaction.transFerOut = true;
        transaction.transFerIn = true;
    }

    else if ((transaction?.activityTypeNmbr === 91 && transaction?.subTrnsNmbr === 1)
        || (transaction?.activityTypeNmbr === 95 && (transaction?.subTrnsNmbr === 3))) {
        transaction.transactionNm = 'transactionHistoryAIIB.txn110';
        transaction.out = true;
        transaction.transFerOut = true;
        transaction.transFerIn = true;
    }
    else if ((transaction?.activityTypeNmbr === 279 && transaction?.subTrnsNmbr === 1)
        || (transaction?.activityTypeNmbr === 25 && [4, 8].includes(transaction?.subTrnsNmbr))) {
        transaction.transactionNm = 'transactionHistoryAIIB.txn111';
        transaction.out = false;
        transaction.transFerOut = true;
        transaction.transFerIn = true;
    }
    else if ((transaction?.activityTypeNmbr === 94 && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 14, 15, 16].includes(transaction?.subTrnsNmbr))
        || (transaction?.activityTypeNmbr === 95 && (transaction?.subTrnsNmbr === 1))
        || (transaction?.activityTypeNmbr === 92 && [1, 2, 3, 4, 5, 6, 10].includes(transaction?.subTrnsNmbr))
        || (transaction?.activityTypeNmbr === 98 && (transaction?.subTrnsNmbr === 1))) {
        transaction.transactionNm = 'transactionHistoryAIIB.txn112';
        transaction.out = true;
        transaction.transFerOut = true;
        transaction.transFerIn = true;
    }
    else {
        transaction.transactionNm = '';
        transaction.out = false;
        transaction.transFerOut = true;
        transaction.transFerIn = true;
    }

};
const removeTransaction = (response, conversionDate, productTypeNumber) => {
    if (response && conversionDate && productTypeNumber) {
        return response.filter(transaction => !((transaction.suppressionFlg.toString() === '1' && [148, 149].includes(transaction.activityTypeNmbr) && transaction.trnsHstEfctvDt === conversionDate && [112, 111].includes(productTypeNumber))));
    } else {
        return response;
    }
};
const removeCBTransaction = (response, cbTransactionName) => {
    if (response) {
        return response.filter(transaction => (transaction?.legalNm?.toUpperCase() !== cbTransactionName));
    } else {
        return response;
    }
};
const removeLegalMergeTransfer = (response) => {
    let result = [];
    response.map((transaction, index) => {
        if ((([258, 259].includes(transaction.activityTypeNmbr)) && (transaction.subTrnsNm.includes(transTypAO2) || transaction.subTrnsNm.includes(transTypAI2)) && transaction.suppressionFlg.toString() === '1')) {
            if (transaction.subTrnsNm.includes(transTypAO2)) {
                if (response.findIndex((item, itemIndex) => (index !== itemIndex && item.trnsHstEfctvDt === transaction.trnsHstEfctvDt && Math.abs(item.grossAmt - transaction.grossAmt) < 0.01 && item.subTrnsNm.includes(transTypAI2))) === -1) {
                    result.push(transaction);
                }
            } else {
                if (response.findIndex((item, itemIndex) => (index !== itemIndex && item.trnsHstEfctvDt === transaction.trnsHstEfctvDt && Math.abs(item.grossAmt - transaction.grossAmt) < 0.01 && item.subTrnsNm.includes(transTypAO2))) === -1) {
                    result.push(transaction);
                }
            }
        } else {
            result.push(transaction);
        }
    });
    return result;
};
const contributionFilter = (subTrnsNmb) => {
    return contributionTrnsTypes.includes(subTrnsNmb);
};

const transferInFilter = (subTrnsNmb) => {
    if (subTrnsNmb.substr(0, 2) === AI || subTrnsNmb.substr(0, 2) === PI) {
        return true;
    }
    return transferInTrnsTypes.includes(subTrnsNmb);
};

const transferOutFilter = (subTrnsNmb, moneyType) => {
    if (subTrnsNmb.substr(0, 2) === AO) {
        return true;
    }
    if (subTrnsNmb === PA2_OVERPAY && (moneyType === EE_MAN_TFR_G || moneyType === SEP_MAN_TFR_G)) return true;
    return transferOutTrnsTypes.includes(subTrnsNmb);
};

const withdrawalFilter = (subTrnsNm, activityTypeNmbr, moneyType) => {
    if (subTrnsNm.substr(0, 2) === PD) {
        return true;
    }
    if (94 === activityTypeNmbr) {
        return true;
    }
    if (98 === activityTypeNmbr) {
        return true;
    }
    if (92 === activityTypeNmbr && !transferOutTrnsTypes.includes(subTrnsNm)) {
        return true;
    }
    if (subTrnsNm.substr(0, 2) === FD) {
        return true;
    }
    if (subTrnsNm.substr(0, 2) === CD && !transferOutTrnsTypes.includes(subTrnsNm)) {
        return true;
    }
    if (subTrnsNm === PA2_OVERPAY && !(moneyType === EE_MAN_TFR_G || moneyType === SEP_MAN_TFR_G)) return true;
    if (subTrnsNm === PRTL_DSTRB_OVERPAY && !(moneyType === EE_MAN_TFR_G || moneyType === SEP_MAN_TFR_G)) return true;
    return withdrawalTrnsTypes.includes(subTrnsNm);
};

const unitCreditFilter = (subTrnsNmb, suppressionFlg) => {
    if (subTrnsNmb === SC9_UNIT_CREDIT && suppressionFlg == 0) return true;
    return unitCreditTrnsTypes.includes(subTrnsNmb);
};

export { contributionFilter, getAIIBTransactionName, getTransactionName, removeCBTransaction, removeLegalMergeTransfer, removeTransaction, transferInFilter, transferOutFilter, unitCreditFilter, withdrawalFilter };

