import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  saveMobileInfoDetails, saveMobileInfoUpdateFlag, saveMobileUpdateJson,
  saveMobileUpdateOktaJson //Michael Lam 20210831
  , saveMobileVerifyEditFlag
} from "../../../../actions/LandingAction";
import { saveSelectedChannel } from "../../../../actions/TwoFactorAuthenticationAction";
import Icon_Mail_Copy from "../../../../assets/Icon/icon_mail copy.svg";
import { COBRAND, DDMMYYYY, descendingOrder, effectiveDate, HKAIIB,schemeAIIB, SMP } from "../../../../translations/common/commonConstants";
import { getValidJrsdNmbr, SortUtils } from "../../../../utils";
import "./MobileInfo.css";
// import { BadAddress, BadAddressZh, InactiveClient, InactiveClientZh, ReturnedMail } from "../../../../translations/common/commonConstants"
MobileInfoComponent.propTypes = {
  props: PropTypes.any,
  countryDropDown: PropTypes.arrayOf(PropTypes.object),
  clientInfo: PropTypes.object,
  mobileInfoDetails: PropTypes.object,
  mobileVerifyEditFlag: PropTypes.bool,
  mobileContactNumber: PropTypes.string,
  smsSubscription: PropTypes.bool,
  smsDisplayLanguage: PropTypes.string,
  cycleDate: PropTypes.string,
  dispatch: PropTypes.func,
  onEdit: PropTypes.func,
  activeEdit: PropTypes.bool,
  scheme: PropTypes.string,
  authMFAData: PropTypes.object
};

let pageRefreshFlag = true

function MobileInfoComponent(props) {
  const { t } = useTranslation("common");
  let [badAddressFlag, setBadAddressFlag] = useState(false);
  const history = useHistory();
  const clientCustomSMSData = props?.clientInfo?.clientCustomData.find(obj => obj.cstmAttrCd === 173);
  const clientCustomSMSDataSorted = clientCustomSMSData?.customDataDetailsList?.sort(SortUtils.sortOnDate(effectiveDate, descendingOrder, DDMMYYYY));
  var clientSMSUser = clientCustomSMSDataSorted?.[0].cstmAttrTxt;
  const clientCustomDisplayLangData = props?.clientInfo?.clientCustomData.find(obj => obj.cstmAttrCd === 174);
  const clientCustomDisplayLangDataSorted = clientCustomDisplayLangData?.customDataDetailsList?.sort(SortUtils.sortOnDate(effectiveDate, descendingOrder, DDMMYYYY));

  var clientSMSDisplayLang = props.scheme === schemeAIIB ? "E" : clientCustomDisplayLangDataSorted?.[0].cstmAttrTxt;

  let loginUsername; 
  if(props.scheme === COBRAND || props.scheme === SMP){
    loginUsername = '8' + String(props?.authMFAData?.userName).padStart(8, "0");
  }else{
    loginUsername = '' + props.clientInfo.clientNmbr;
  }
  
  useEffect(() => {
    if (history && history.action === 'POP' && pageRefreshFlag === true) {
      resetPropsValue();
      pageRefreshFlag = false
    }
    if (props.mobileVerifyEditFlag) {
      setBadAddressFlag(false);
      props.onEdit(true)
    } else {
      checkMBErrorMsgVisibility();
    }
    if (state.mobileNo.length === 11 && state.mobileNo.startsWith('852') && ((state.mobilePltcJrNumber === '100' || state.mobilePltcJrNumber === '101') || (inputCountryCode[0]?.countryNm === "Bad Address"))) {
      var hkMobileNm = state.mobileNo.substring(3);
      state.mobileNo = hkMobileNm;
      setState({
        ...state,
        mobileNo: hkMobileNm,
        prevMobileNoValue: hkMobileNm,
        selectInputCountry: (state.mobilePltcJrNumber === '100' || state.mobilePltcJrNumber === '101') ? state.mobilePltcJrNumber : '100'
      })

    }
    if (state.prevMobileNoValue.length === 11 && state.prevMobileNoValue.startsWith('852') && (state.updatedPhoneCode === '+852' || state.updatedPhoneCode === '+852')) {
      setState({
        ...state,
        prevMobileNoValue: state.prevMobileNoValue.substring(3)
      })
    }
  }, []);
  const checkMBErrorMsgVisibility = () => {
    if (props?.clientInfo?.mobilePltcJrsdNmbr == 166 || props?.clientInfo?.mobilePltcJrsdNmbr == 167
      || !state?.mobileNo
      || ((props?.clientInfo?.mobilePltcJrsdNmbr == 166 || props?.clientInfo?.mobilePltcJrsdNmbr == 167) && !state?.updatedPhoneCode)) {
      setBadAddressFlag(true);
    } else {
      setBadAddressFlag(false);
    }
  }
  const options = props && props.countryDropDown ? props.countryDropDown : "";
  const jrsdNmbr = getValidJrsdNmbr(props?.clientInfo?.mobilePltcJrsdNmbr)
  const inputCountryCode = options
    ? options.filter(
      (x) => x.pltcJrsdNmbr === jrsdNmbr
    )
    : "";
  var updatedMobileDetails;
  if (props.mobileVerifyEditFlag === true) {
    if (Object.keys(props.mobileInfoDetails).length > 0) {
      updatedMobileDetails = props.mobileInfoDetails;
    }

    var doc = document.getElementById("mobileSection");
    if (doc !== undefined && doc !== null) {
      setTimeout(function () {
        document.getElementById("mobileSection").click();
      }, 2);
    }
  }

  const [state, setState] = useState({

    mobileInfoEditFlag: false,
    mobileInfoDisplayFlag: true,
    mobileInfoVerifyPage: false,
    validHKNumber: false,
    nonHkMobileNmLengthError: false,
    mobileNoLength: 8,
    HKMobileNoError: false,
    mobileNo:
      props.mobileVerifyEditFlag && updatedMobileDetails !== undefined
        ? updatedMobileDetails.mobileContactNumber
        : props.clientInfo.mobileContactNumber === undefined ||
          props.clientInfo.mobileContactNumber === null
          ? ""
          : props.clientInfo.mobileContactNumber,
    mobileNoEdited: "",
    prevMobileNoValue:
      props.clientInfo.mobileContactNumber === undefined ||
        props.clientInfo.mobileContactNumber === null
        ? ""
        : props.clientInfo.mobileContactNumber,
    mobileNoEditFlag: props.mobileVerifyEditFlag
      ? updatedMobileDetails.conNoDetailsEditFlag
      : false,
    mobileNoError: false,
    mobileFirstDigitError: false,


    selectInputCountry:
      props.mobileVerifyEditFlag && updatedMobileDetails !== undefined
        ? typeof updatedMobileDetails.mobilePltcJrsdNmbr === "string" ? updatedMobileDetails.mobilePltcJrsdNmbr : updatedMobileDetails.mobilePltcJrsdNmbr.toString()
        : props.clientInfo.mobilePltcJrsdNmbr === undefined ||
          props.clientInfo.mobilePltcJrsdNmbr === null
          ? ""
          : inputCountryCode.length > 0
            ? typeof inputCountryCode[0].pltcJrsdNmbr === 'string' ? inputCountryCode[0].pltcJrsdNmbr : inputCountryCode[0].pltcJrsdNmbr.toString()
            : "",
    prevSelectInputCountry:
      props.clientInfo.mobilePltcJrsdNmbr === undefined ||
        props.clientInfo.mobilePltcJrsdNmbr === null
        ? ""
        : inputCountryCode.length > 0
          ? typeof inputCountryCode[0].pltcJrsdNmbr === 'string' ? inputCountryCode[0].pltcJrsdNmbr : inputCountryCode[0].pltcJrsdNmbr.toString()
          : "",
    selectInputCountryEditFlag: false,
    selectInputCountryError: false,

    eChannelServices: "*",
    prevEChannelValue: "",
    eChannelEditFlag: true,
    eChannelError: false,
    mobilePltcJrNumber: props.mobileVerifyEditFlag && updatedMobileDetails !== undefined
      ? typeof updatedMobileDetails.mobilePltcJrsdNmbr === "string" ? updatedMobileDetails.mobilePltcJrsdNmbr : updatedMobileDetails.mobilePltcJrsdNmbr.toString()
      : jrsdNmbr.toString(),
    prevMobilePltclJrNum: props.mobileVerifyEditFlag && updatedMobileDetails !== undefined
      ? typeof updatedMobileDetails.mobilePltcJrsdNmbr === "string" ? updatedMobileDetails.mobilePltcJrsdNmbr : updatedMobileDetails.mobilePltcJrsdNmbr.toString()
      : jrsdNmbr.toString(),
    selectLangPref:
      props.mobileVerifyEditFlag && updatedMobileDetails !== undefined
        ? updatedMobileDetails.selectLangPref
        : props.clientInfo !== undefined && props.clientInfo !== null
          ? clientSMSDisplayLang !== undefined &&
            clientSMSDisplayLang !== null
            ? clientSMSDisplayLang
            : ""
          : "",
    prevLanguagePreference:
      props.mobileVerifyEditFlag && updatedMobileDetails !== undefined
        ? props.clientInfo !== undefined && props.clientInfo !== null
          ? clientSMSDisplayLang !== undefined &&
            clientSMSDisplayLang !== null
            ? clientSMSDisplayLang === "C"
              ? t("mobile.chinese")
              : t("mobile.english")
            : ""
          : ""
        : "",
    langPreferenceEditFlag: props.mobileVerifyEditFlag
      ? updatedMobileDetails.lanEditFlag
      : false,
    languagePreferenceError: false,

    eChannelStatus: t("mobile.unsubscribed"),
    checked:
      props.mobileVerifyEditFlag && updatedMobileDetails !== undefined
        ? updatedMobileDetails.smsSubscription
        : props.clientInfo !== undefined && props.clientInfo !== null

          ? clientSMSUser !== undefined &&
            clientSMSUser !== null
            ? (clientSMSUser === "Y" || clientSMSUser === "C")
              ? true
              : false
            : false
          : false,
    prevChecked: props.clientInfo !== undefined && props.clientInfo !== null
      ? clientSMSUser !== undefined &&
        clientSMSUser !== null
        ? (clientSMSUser === "Y" || clientSMSUser === "C")
          ? true
          : false
        : false
      : false,
    checkEcomEditFlag: (props.mobileVerifyEditFlag && updatedMobileDetails !== undefined)
      ? updatedMobileDetails.checkEcomDetailsEditFlag
      : false,

    disableUpdate: props.mobileVerifyEditFlag
      ? updatedMobileDetails.conNoDetailsEditFlag
        ? false
        : true
      : true,
    verifyPinFlag: true,
    mobileNumberEditFlag: props.mobileVerifyEditFlag
      ? updatedMobileDetails.conNoDetailsEditFlag
      : false,
    mobileDropDownEditFlag: props.mobileVerifyEditFlag
      ? updatedMobileDetails.conNoDetailsEditFlag
      : false,
    langPrefEditFlag: false,
    echanelServiceEditFlag: false,
    updatedPhoneCode: inputCountryCode.length > 0 ? inputCountryCode[0].phoneCd : "",
    prevPhoneCode: inputCountryCode.length > 0 ? inputCountryCode[0].phoneCd : ""
  });

  const handleMobileInfoEdit = (e) => {
    e.preventDefault();
    if (e.target.name === "editButton") {
      const initialMobileDigit = state.mobileNo.charAt(0);
      state.mobileInfoEditFlag = true;
      state.prevLanguagePreference = state.selectLangPref;
      setState({
        ...state,
        prevMobileNoValue: state.mobileNo,
        prevSelectInputCountry: state.selectInputCountry,
        mobilePltcJrNumber: state.selectInputCountry,
        prevLanguagePreference: state.selectLangPref,
        prevMobilePltclJrNum: state.mobilePltcJrNumber,
        disableUpdate: true,
        mobileNoError: false,
        prevChecked: state.checked,
        verifyPinFlag: true,
        enteredPin: "",
        HKMobileNoError: false,
        mobileNumberEditFlag: false,
        mobileDropDownEditFlag: false,
        langPrefEditFlag: false,
        echanelServiceEditFlag: false,
        mobileInfoEditFlag: true,
        mobileInfoUpdateFlag: false,
      });
      if (
        state.selectInputCountry === "101" || state.selectInputCountry === "100" &&
        (initialMobileDigit === "4" ||
          initialMobileDigit === "5" ||
          initialMobileDigit === "6" ||
          initialMobileDigit === "7" ||
          initialMobileDigit === "8" ||
          initialMobileDigit === "9")
      ) {
        state.validHKNumber = true;
      } else {
        state.validHKNumber = false;
        state.mobileNo = '';
        setState({
          ...state,
          mobileNo: '',
          validHKNumber: ''
        })
      }
      if (badAddressFlag) {
        state.mobileNo = '';
        state.selectInputCountry = '';
        state.mobilePltcJrNumber = '';
        setState({
          ...state,
          mobileNo: '',
          selectInputCountry: '',
          mobilePltcJrNumber: ''
        })
        setBadAddressFlag(false);
      }
      props.onEdit(true);
    } else if (e.target.name === "cancel") {
      state.mobileNo = state.prevMobileNoValue;
      state.selectLangPref = state.prevLanguagePreference;
      state.updatedPhoneCode = state.prevPhoneCode,
        setState({
          ...state,
          mobileInfoEditFlag: false,
          mobileNo: state.prevMobileNoValue,
          selectInputCountry: state.prevSelectInputCountry,
          mobilePltcJrNumber: state.prevMobilePltclJrNum,
          mobileInfoVerifyPage: false,
          disableUpdate: true,
          eChannelEditFlag: false,
          checked: state.prevChecked,
          verifyPinFlag: true,
          enteredPin: "",
          mobileNumberEditFlag: false,
          langPrefEditFlag: false,
          echanelServiceEditFlag: false,
          mobileInfoUpdateFlag: false,
          mobileDropDownEditFlag: false,
          langPreferenceEditFlag: false,
          checkEcomEditFlag: false,
        });
      checkMBErrorMsgVisibility();
      props.onEdit(false);
    }
    resetPropsValue();
  };

  const resetPropsValue = () => {
    props.dispatch(saveMobileVerifyEditFlag(false));
    props.dispatch(saveMobileInfoUpdateFlag(false));
    props.dispatch(saveMobileInfoDetails({}));
    props.dispatch(saveMobileUpdateJson({}));
    props.dispatch(saveMobileUpdateOktaJson({})); //Michael Lam
  };

  const isNumeric = (event) => {
    event = event ? event : window.event;
    var charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      event.preventDefault();
    }
  };

  const isNotEmpty = (event) => {
    if (event.target.value === null || event.target.value === "") {
      setState({
        ...state,
        disableUpdate: true,
      });
    }
  };

  const onlyNumber = (event) => {
    if (event.type == "paste") {
      var clipboardData = event.clipboardData || window.clipboardData;
      var pastedData = clipboardData.getData("Text");
      let re = /^\d*$/;
      if (!re.test(pastedData)) {
        event.preventDefault();
      }
    }
  };
  const handleEChannelChange = (event) => {
    if (event.target.checked == false) {
      setState({
        ...state,
        eChannelStatus: t("mobile.subscribed"),
        checked: false,
        disableUpdate: false,
        echanelServiceEditFlag: true,
        checkEcomEditFlag: true,
        langPreferenceEditFlag: false

      });
    } else {
      setState({
        ...state,
        eChannelStatus: t("mobile.subscribed"),
        checked: true,
        disableUpdate: false,
        echanelServiceEditFlag: true,
        checkEcomEditFlag: true,
        selectLangPref: state.selectLangPref === 'E' ? 'E' : 'C',
        langPreferenceEditFlag: true
      });
    }
  };

  function handleChange(event) {
    if (event.target.name === "mobileNo") {
      if (
        (event.target.defaultValue === null ||
          event.target.value === "" ||
          event.target.value === null) || (state.selectInputCountry === "" ||
            state.selectInputCountry === null || state.mobilePltcJrNumber === "" || state.mobilePltcJrNumber === null)
      ) {
        setState(
          {
            ...state,
            mobileNoError: true,
            mobileNoEditFlag: true,
            mobileNo: event.target.value,
            disableUpdate: true,
            mobileNumberEditFlag: true,
          },
          () => {
            this.enableContinue();
          }
        );
      }
    }
    if (event.target.name === "selectInputCountry") {
      state.mobileDropDownEditFlag = true;
      if (event.target.value !== null && event.target.value !== "") {
        var index = event.nativeEvent.target.selectedIndex;
        if (event.target.value === "100" || event.target.value === "101") {
          state.checked = props.clientInfo
            ? (clientSMSUser === "Y" || clientSMSUser === "C")
              ? true
              : false
            : false;
          (state.eChannelEditFlag = true);
          state.mobileNumberEditFlag = true;
          state.mobilePltcJrNumber = event.target.value;
          let updatedCountryCode = event.nativeEvent.target[index].text.split("-");
          state.selectInputCountry = event.target.value;
          state.updatedPhoneCode = updatedCountryCode[0];
        } else {
          state.checked = false;
          state.eChannelEditFlag = false;
          state.mobileNoError = false;
          let updatedCountryCode = event.nativeEvent.target[index].text.split("-");
          state.selectInputCountry = event.target.value;
          state.mobilePltcJrNumber = event.target.value,
            state.updatedPhoneCode = updatedCountryCode[0];
          state.mobileFirstDigitError = false;
          state.mobileNumberEditFlag = true;
          state.langPrefEditFlag = false;
          state.echanelServiceEditFlag = false;
          state.checkEcomEditFlag = false;
          state.langPreferenceEditFlag = false;
        }
        checkPhoneNumberValidOrNot(event);
        if (state.mobileNoError) {
          setState(
            {
              ...state,
              selectInputCountryError: false,
              selectInputCountryEditFlag: true,
              disableUpdate: true,
            }
          );
        } else {
          setState(
            {
              ...state,
              selectInputCountryError: false,
              selectInputCountryEditFlag: true,
              disableUpdate: false,
            }
          );
          enableContinue();
        }


        if (state.mobileNo === "" || state.mobileNo === null) {

          setState({
            ...state,
            selectInputCountryError: false,
            selectInputCountryEditFlag: true,
            disableUpdate: true,
          });
        }
      } else {
        setState(
          {
            ...state,
            checked: false,
            selectInputCountryError: true,
            selectInputCountryEditFlag: true,
            selectInputCountry: event.target.value,
            disableUpdate: true,
            mobileNo: state.mobileNo,
            mobileFirstDigitError: false,
            mobileNoError: false,
          }
        );
      }
    }
    // if (event.target.name === "mobileNo") {
    //   // num = event.target.value;
    //   if (
    //     (event.target.defaultValue === null ||
    //     event.target.value === "" ||
    //     event.target.value === null) && (state.selectInputCountry === "" ||
    //     state.selectInputCountry === null || state.mobilePltcJrNumber ==="" || state.mobilePltcJrNumber === null)
    //   ) {
    //     state.disableUpdate = true;
    //     setState(
    //       {
    //         ...state,
    //         mobileNoError: true,
    //         mobileNoEditFlag: true,
    //         mobileNo: event.target.value,
    //         disableUpdate: true,
    //         mobileNumberEditFlag: true,
    //       },
    //       () => {
    //         this.enableContinue();
    //       }
    //     );
    //   }
    // }
    if (event.target.name === "selectLangPref") {
      const selectedIndex = event.target.options.selectedIndex;
      const dataKey = event.target.options[selectedIndex].getAttribute('data-key')
      if (event.target.defaultValue !== null && event.target.value !== "") {
        state.selectLangPref = dataKey;
        state.languagePreferenceError = false;
        state.disableUpdate = false;
        state.langPreferenceEditFlag = true;
        state.langPrefEditFlag = true;
        enableContinue();
      } else {
        setState(
          {
            ...state,
            languagePreferenceError: true,
            langPreferenceEditFlag: false,

            selectLangPref: event.target.value,
            disableUpdate: true,
            langPrefEditFlag: true,
          },
          () => {
            this.enableContinue();
          }
        );
      }
      if ((event.target.defaultValue === null ||
        event.target.value === "" ||
        event.target.value === null) || (state.selectInputCountry === "" ||
          state.selectInputCountry === null || state.mobilePltcJrNumber === "" || state.mobilePltcJrNumber === null)) {
        setState(
          {
            ...state,
            disableUpdate: true
          })
      }
    }
  }

  function enableContinue() {
    let mobileNoError = state.mobileNoError;
    let selectInputCountryError = state.selectInputCountryError;
    let languagePreferenceError = state.languagePreferenceError;
    if (mobileNoError || selectInputCountryError || languagePreferenceError) {
      setState({
        ...state,
        disableUpdate: true,
        mobileInfoDisplayFlag: true,
      });
    }
    if (!mobileNoError) {
      setState({
        ...state,
        disableUpdate: false,
        mobileInfoDisplayFlag: true,
      });
    }
  }
  function checkPhoneNumberValidOrNot(event) {
    if (
      state.mobileNo != null &&
      state.mobileNo != undefined &&
      state.mobileNo.length > 0
    ) {
      let updatedMobileNo = state.mobileNo;
      var index = event.nativeEvent.target.selectedIndex;
      let updatedCountryCode = event.nativeEvent.target[index].text.split("-");
      const initialMobileDigit = updatedMobileNo.charAt(0);

      if (updatedCountryCode[0] === "+852") {
        state.mobileNoLength = 8;
      } else {
        state.mobileNoLength = "";
      }

      if (
        updatedCountryCode[0] === "+852" &&
        updatedMobileNo.length !== 8 &&
        updatedMobileNo.length > 0
      ) {
        state.HKMobileNoError = true;
        state.mobileNoError = true;
      } else {
        state.HKMobileNoError = false;
        state.mobileNoError = false;
      }
      if (updatedCountryCode[0] !== "+852") {
        if (updatedMobileNo.length < 7) {
          state.nonHkMobileNmLengthError = true;
        } else {
          state.nonHkMobileNmLengthError = false;
        }
      } else {
        state.nonHkMobileNmLengthError = false;
      }

      if (
        updatedCountryCode[0] === "+852" &&
        initialMobileDigit != 4 &&
        initialMobileDigit != 5 &&
        initialMobileDigit != 6 &&
        initialMobileDigit != 7 &&
        initialMobileDigit != 8 &&
        initialMobileDigit != 9 &&
        updatedMobileNo.length > 0
      ) {
        state.mobileFirstDigitError = true;
        state.checked = false;
      } else {
        state.mobileFirstDigitError = false;
      }

      if (
        updatedCountryCode[0] === "+852" &&
        (initialMobileDigit === "4" ||
          initialMobileDigit === "5" ||
          initialMobileDigit === "6" ||
          initialMobileDigit === "7" ||
          initialMobileDigit === "8" ||
          initialMobileDigit === "9") &&
        updatedMobileNo.length > 0
      ) {
        state.eChannelStatus = t("mobile.subscribed");
        state.eChannelEditFlag = true;
        state.validHKNumber = true;
      } else state.validHKNumber = false;

      let HKMobileNoError = state.HKMobileNoError;
      let mobileFirstDigitError = state.mobileFirstDigitError;
      let selectInputCountryError = state.selectInputCountryError;
      let languagePreferenceError = state.languagePreferenceError;
      if (
        HKMobileNoError ||
        selectInputCountryError ||
        languagePreferenceError ||
        mobileFirstDigitError ||
        state.nonHkMobileNmLengthError
      ) {
        setState({
          ...state,
          disableUpdate: true,
          mobileInfoDisplayFlag: true,
        });
      }
      if (!HKMobileNoError) {
        setState({
          ...state,
          disableUpdate: false,
          mobileInfoDisplayFlag: true,
        });
      }
    } else {
      setState({
        ...state,
        disableUpdate: true,
      });
    }
  }
  function enableCheckbox(e) {
    if (e.target.value !== null && e.target.value !== undefined) {
      state.mobileNumberEditFlag = true;
      state.mobileNo = e.target.value;
    } else {
      state.mobileNo = "";
      state.mobileNumberEditFlag = true;
    }

    let updatedMobileNo = state.mobileNo;
    const initialMobileDigit = updatedMobileNo.charAt(0);

    if (state.updatedPhoneCode === "+852") {
      state.mobileNoLength = 8;
    } else {
      state.mobileNoLength = "";
    }

    if (
      state.updatedPhoneCode === "+852" &&
      e.target.value.length !== 8 &&
      e.target.value.length > 0
    ) {
      state.HKMobileNoError = true;
    } else {
      state.HKMobileNoError = false;
    }
    if (state.updatedPhoneCode !== "+852") {
      if (e.target.value.length < 7) {
        state.nonHkMobileNmLengthError = true;
      } else {
        state.nonHkMobileNmLengthError = false;
      }
    } else {
      state.nonHkMobileNmLengthError = false;
    }
    if (
      state.updatedPhoneCode === "+852" &&
      initialMobileDigit != 4 &&
      initialMobileDigit != 5 &&
      initialMobileDigit != 6 &&
      initialMobileDigit != 7 &&
      initialMobileDigit != 8 &&
      initialMobileDigit != 9 &&
      e.target.value.length > 0
    ) {
      state.mobileFirstDigitError = true;
      state.langPrefEditFlag = false;
      state.echanelServiceEditFlag = false;
      state.checked = false;
    } else {
      state.mobileFirstDigitError = false;
    }

    if (
      state.updatedPhoneCode &&
      (initialMobileDigit === "4" ||
        initialMobileDigit === "5" ||
        initialMobileDigit === "6" ||
        initialMobileDigit === "7" ||
        initialMobileDigit === "8" ||
        initialMobileDigit === "9") &&
      e.target.value.length > 0
    ) {
      state.eChannelStatus = t("mobile.subscribed");
      state.eChannelEditFlag = true;
      state.validHKNumber = true;
    } else state.validHKNumber = false;

    let HKMobileNoError = state.HKMobileNoError;
    let selectInputCountryError = state.selectInputCountryError;
    let languagePreferenceError = state.languagePreferenceError;

    if (HKMobileNoError || selectInputCountryError || languagePreferenceError) {

      setState({
        ...state,
        disableUpdate: true,
        mobileInfoDisplayFlag: true,
      });
    }

    if (state.validHKNumber) {
      if (!HKMobileNoError) {
        setState({
          ...state,
          eChannelEditFlag: true,
          disableUpdate: false,

        });
      }
    }
    if (!HKMobileNoError) {
      setState({
        ...state,
        disableUpdate: false,
        mobileInfoDisplayFlag: true,
      });
    }
    if (state.nonHkMobileNmLengthError) {
      setState({
        ...state,
        disableUpdate: true
      });
    }
  }

  const onSubmit = (e) => {
    e.preventDefault();
    let updatedMobileNo = state.mobileNo;
    var selectedCountry = options
      ? options.filter((x) => x.pltcJrsdNmbr === parseInt(state.mobilePltcJrNumber))
      : "";
    let seqNmrObject = props?.clientInfo?.clientPhones ? props?.clientInfo?.clientPhones?.filter((x) => x?.phoneTxt === props?.clientInfo?.mobileContactNumber) : ""
    let seqNmr = seqNmrObject ? seqNmrObject[0]?.phoneSeqNmbr : '';
    var countryJrsdNm = selectedCountry.length > 0 ? selectedCountry[0].pltcJrsdNmbr : '';
    var countryName = selectedCountry.length > 0 ? selectedCountry[0].countryNm : '';
    var countryCode = selectedCountry.length > 0 ? selectedCountry[0].phoneCd.replace("+", "").replace("-", "") : '';
    const phnSequenceNumber = seqNmr ? seqNmr : 100
    const clientPhoneContactNm = props?.clientInfo?.clientPhones?.find((phone) => phone?.phoneTypeCd === 100 && phone?.phoneSeqNmbr === phnSequenceNumber)
    let updateData = {
      mobileOtherDetailsEditFlag: !(state.checkEcomEditFlag || state.langPreferenceEditFlag)
    };
    if (state.mobileNumberEditFlag || state.selectInputCountryEditFlag) {
      updateData.mobileDetails = {
        clientNmbr: props.clientInfo.clientNmbr,
        clntPhoneInfoList: [
          {
            phoneTypeCd: 100,
            phoneSeqNmbr: phnSequenceNumber,
            phoneTypeNm: "CELLULAR PHONE",
            phoneTxt: countryCode + updatedMobileNo,
            extensionTxt: null,
            phonePltcJrsdNmbr: countryJrsdNm,
            phoneLocationNm: countryName,
            deleteRecord: false,
            contactNm: clientPhoneContactNm?.contactNm ?? ''
          }
        ]
      }
    }
    if (state.checkEcomEditFlag) {
      updateData.mobileOtherDetails = [
        {
          "chgDt": props.cycleDate,
          "clientNmbr": props.clientInfo.clientNmbr,
          "cstmAttrCd": 173,
          "cstmAttrNm": "SMS USER (Y/N/C)",
          "cstmAttrTxt": state.checked ? "Y" : "N",
          "efctvDt": props.cycleDate,
        }
      ]
    }
    const OktaData = { //Michael Lam 20210831
      userName: loginUsername,
      updateMobile: true,
      updateEmail: false,
      clientMobile: '+' + countryCode + updatedMobileNo,
      clientEmail: "",
      pltcJrsdNmbr: ""

    }
    if (state.checked && (state.langPreferenceEditFlag || state.checkEcomEditFlag)) {
      if (!state.checkEcomEditFlag) {
        updateData.mobileOtherDetails = [];
      }
      if (state.checked) {
        updateData.mobileOtherDetails.push(
          {
            "chgDt": props.cycleDate,
            "clientNmbr": props.clientInfo.clientNmbr,
            "cstmAttrCd": 174,
            "cstmAttrNm": "SMS DISPLAY LANGUAGE (E/C)",
            "cstmAttrTxt": state.selectLangPref,
            "efctvDt": props.cycleDate,
          }
        )
      }
    }
    const enable2FA = (state.selectInputCountry === '100' || state.selectInputCountry === '101') && (state.prevSelectInputCountry !== state.selectInputCountry || state.prevMobileNoValue !== state.mobileNo) && !state.mobileFirstDigitError
    var editFiedlJsonValue = {};
    editFiedlJsonValue.mobileContactNumber = updatedMobileNo;
    editFiedlJsonValue.smsSubscription = state.checked;
    editFiedlJsonValue.mobilePltcJrsdNmbr = state.selectInputCountry;
    editFiedlJsonValue.selectLangPref = state.selectLangPref;
    editFiedlJsonValue.phoneCd = selectedCountry[0]?.phoneCd;

    editFiedlJsonValue.twoFACheck = enable2FA;
    editFiedlJsonValue.twoFactorAuthEditFlage = enable2FA;

    if (state.mobileNumberEditFlag || state.selectInputCountryEditFlag) {

      if (state.checkEcomEditFlag && state.langPreferenceEditFlag) {
        editFiedlJsonValue.conNoDetailsEditFlag = true;
        editFiedlJsonValue.checkEcomDetailsEditFlag = true;
        editFiedlJsonValue.lanEditFlag = true;
      } else if (state.langPreferenceEditFlag) {
        editFiedlJsonValue.conNoDetailsEditFlag = true;
        editFiedlJsonValue.checkEcomDetailsEditFlag = false;
        editFiedlJsonValue.lanEditFlag = true;
      } else if (state.checkEcomEditFlag) {
        editFiedlJsonValue.conNoDetailsEditFlag = true;
        editFiedlJsonValue.checkEcomDetailsEditFlag = true;
        editFiedlJsonValue.lanEditFlag = false;
      } else {
        editFiedlJsonValue.conNoDetailsEditFlag = true;
        editFiedlJsonValue.checkEcomDetailsEditFlag = false;
        editFiedlJsonValue.lanEditFlag = false;
      }
    } else if (state.checkEcomEditFlag && state.langPreferenceEditFlag) {
      editFiedlJsonValue.conNoDetailsEditFlag = false;
      editFiedlJsonValue.checkEcomDetailsEditFlag = true;
      editFiedlJsonValue.lanEditFlag = true;
    } else if (!state.checkEcomEditFlag && state.langPreferenceEditFlag) {
      editFiedlJsonValue.conNoDetailsEditFlag = false;
      editFiedlJsonValue.checkEcomDetailsEditFlag = false;
      editFiedlJsonValue.lanEditFlag = true;
    } else if (state.checkEcomEditFlag && !state.langPreferenceEditFlag) {
      editFiedlJsonValue.conNoDetailsEditFlag = false;
      editFiedlJsonValue.checkEcomDetailsEditFlag = true;
      editFiedlJsonValue.lanEditFlag = false;
    } else {
      editFiedlJsonValue.conNoDetailsEditFlag = false;
      editFiedlJsonValue.checkEcomDetailsEditFlag = false;
      editFiedlJsonValue.lanEditFlag = false;

    }

    props.dispatch(saveMobileInfoDetails(editFiedlJsonValue));
    props.dispatch(saveMobileInfoUpdateFlag(true));
    props.dispatch(saveMobileUpdateJson(updateData));
    props.dispatch(saveMobileUpdateOktaJson(OktaData)); //Michael Lam 20210831
    // if (enable2FA) {
    //   history.push("/authenticateOTP");
    //   props.dispatch(saveSelectedChannel("MOBILE-INFO"));
    // } else {
    //   history.push("/verify");
    // }
    props.dispatch(saveSelectedChannel("MOBILE-INFO")); //Michael Lam
    history.push("/verify"); //Michael Lam
  };
  const isHkNmValid = () => {
    if ((state.selectInputCountry === '100' || state.selectInputCountry === "101") && (((state.selectInputCountry === '100' || state.selectInputCountry === "101") && (state.mobileNo.startsWith('0') || state.mobileNo.startsWith('1') || state.mobileNo.startsWith('2') || state.mobileNo.startsWith('3'))) || ((state.selectInputCountry === '100' || state.selectInputCountry === "101") && (state.mobileNo.startsWith('999') || state.mobileNo.startsWith('1') || state.mobileNo.startsWith('2') || state.mobileNo.startsWith('3'))))) {
      return true;
    } else {
      return false;
    }
  }

  const getFilteredCountryList = (countryDropDown) => {
    let countryList = countryDropDown;
    countryList = countryList.filter((item) => {
      if (item.pltcJrsdNmbr === 167 || item.pltcJrsdNmbr === 169 || item.pltcJrsdNmbr === 175)
        return false;
      return true;
    });
    countryList.sort((item, item1) => {
      if (item1.countryNm > item.countryNm)
        return -1;
      return 1;
    });
    return countryList;
  }

  return (
    <React.Fragment>
      <div className="row">
        <div className="pds-card info-body rsc-card" id="mobile-section">
          <div className="card-body">
            <div className="d-flex justify-content-between">
              <h3 className="info-title">{t("mobile.title")}</h3>
              {state.mobileInfoEditFlag === true ||
                state.mobileInfoVerifyPage === true ||
                props.mobileVerifyEditFlag === true ? (
                  <a
                    href="#"
                    className="edit-link "
                    name="cancel"
                    onClick={handleMobileInfoEdit}
                  >
                    {t("common.cancel")}
                  </a>
                ) : (
                  <a
                    href="#"
                    name="editButton"
                    className={props.activeEdit ? "edit-link inactive" : "edit-link"}
                    onClick={(e) => handleMobileInfoEdit(e)}
                  >
                    {t("common.edit")}
                  </a>
                )}
            </div>
            <form data-testid="form">
              <div className="row">
                {props.mobileVerifyEditFlag ||
                  (state.mobileInfoEditFlag && !state.mobileInfoVerifyPage) ? (
                    <>
                      <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12">
                        <div className="left-align mobile-select">
                          <select
                            id="selectPlaceholder"
                            aria-label="Select placeholder"
                            data-testid="countryDropDown"
                            name="selectInputCountry"
                            className="form-input form-text required form-control select-input-bold select-arrow rsc-select"
                            onChange={(e) => handleChange(e)}
                            value={state.mobileDropDownEditFlag ? state.mobilePltcJrNumber : (state.mobilePltcJrNumber === '100' || state.mobilePltcJrNumber === '101') ? state.mobilePltcJrNumber : ''}
                          >
                            <option value="" hidden disabled defaultValue   ></option>
                            {getFilteredCountryList(options).map((opt, i) => (
                              <option key={i} value={opt.pltcJrsdNmbr}>
                                {" "}
                                {opt.phoneCd + "-" + opt.countryNm}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-xs-12">
                        <div className="left-align mobile-select">
                          <input
                            type="number"
                            name="mobileNo"
                            data-testid="mobileInput"
                            value={state.mobileNo}
                            onFocus={isNotEmpty}
                            onKeyPress={isNumeric}
                            onPaste={onlyNumber}
                            className="form-input form-text required form-control"
                            onChange={(e) => {
                              enableCheckbox(e);
                              handleChange(e);

                            }}
                            maxLength={state.mobileNoLength}
                          />
                          {((state.selectInputCountry === '100' || state.selectInputCountry === "101") && state.mobileNo.startsWith('0')) && (
                            <div className="pds-error common-pds-error mobile-error-font" data-testid="startsWithzeroId">{t("otherContact.HkNumberStart0error")}</div>
                          )}
                          {((state.selectInputCountry === '100' || state.selectInputCountry === "101") && state.mobileNo.startsWith('999')) && (
                            <div className="pds-error common-pds-error mobile-error-font" data-testid="mobile999Test">{t("otherContact.HkNumberStart999error")}</div>
                          )}
                          {state.HKMobileNoError && (
                            <div className="pds-error common-pds-error mobile-error-font" data-testid="mobileErrorTest">{t("mobile.mobileError")}</div>
                          )}
                          {state.nonHkMobileNmLengthError && (
                            <div className="pds-error common-pds-error mobile-error-font" >{t("mobile.nonHKmobileError")}</div>
                          )}
                          {state.mobileFirstDigitError && (
                            <div className="pds-error common-pds-error mobile-error-font" data-testid="mobileFrstErrorTest"><p className="contect_info_error">{t("mobile.mobileNoFirstDigitError")}</p></div>
                          )}
                        </div>
                      </div>
                    </>
                  ) : !state.mobileInfoEditFlag && !state.mobileInfoVerifyPage ? (
                    <div className="col-12">
                      {state.selectInputCountry !== undefined &&
                        <label className="inner-label">
                          {badAddressFlag ? (props.scheme != "HKAIIB" && <label className="invalid-Email_lbl"><img className="email-icon" src={Icon_Mail_Copy}></img>{t("mobile.invalidMobileMessage")}</label>) : (state.updatedPhoneCode === "+852" || props?.clientInfo?.mobilePltcJrsdNmbr == 100 || props?.clientInfo?.mobilePltcJrsdNmbr == 101) ?
                            ((props?.clientInfo?.mobilePltcJrsdNmbr == 100 || props?.clientInfo?.mobilePltcJrsdNmbr == 101) ? (state.updatedPhoneCode ? state.updatedPhoneCode : "+852") + " " + (state.mobileNo.match(/.{1,4}/g).join(' ')??
                              '') : t('generalInfo.responseN/A'))
                            : (state.updatedPhoneCode !== undefined ? state.mobileNo : t('generalInfo.responseN/A'))}
                        </label>
                      }
                    </div>
                  ) : (state.mobileInfoVerifyPage && state.mobileNumberEditFlag) && (
                    <div className="col-12">
                      <label className="inner-label">
                        {state.updatedPhoneCode + "" + state.mobileNo}
                      </label>
                    </div>
                  )}
              </div>
              <div className="row">
                {state.mobileInfoVerifyPage === true &&
                  state.echanelServiceEditFlag === true && (
                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4">
                      <div className="left-align ">
                        <label>{t("mobile.echannelServices")}</label>
                        <ul className="list-unstyled inner-label">
                          <li></li>
                          <li>
                            {state.checked
                              ? t("mobile.subscribed")
                              : t("mobile.unsubscribed")}
                          </li>
                        </ul>
                      </div>
                    </div>
                  )}
              </div>
              <div className="row">
                {(state.mobileInfoVerifyPage === true &&
                  state.langPrefEditFlag == true) && (
                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4">
                      <div className="left-align langPreference">
                        <label>{t("mobile.languagePreference")}</label>
                        <ul className="list-unstyled inner-label">
                          <li></li>
                          <li>{state.selectLangPref}</li>
                        </ul>
                      </div>
                    </div>
                  )}
              </div>

              <div className="row">
                {(state.mobileInfoEditFlag || props.mobileVerifyEditFlag) &&
                  (state.selectInputCountry === "100" || state.selectInputCountry === "101") ? (
                    <div>

                      <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                        <div className="pds-checkbox">
                          <input
                            type="checkbox"
                            id="checkbox5"
                            data-testid="checkboxTest"
                            checked={state.checked}
                            disabled={
                              (state.selectInputCountry === '100' || state.selectInputCountry === "101") &&
                                !state.HKMobileNoError &&
                                !((state.selectInputCountry === "100" || state.selectInputCountry === "101") && state.mobileNo.startsWith('0')) &&
                                !((state.selectInputCountry === "100" || state.selectInputCountry === "101") && state.mobileNo.startsWith('999')) &&
                                (state.mobileNo.charAt(0) === "4" ||
                                  state.mobileNo.charAt(0) === "5" ||
                                  state.mobileNo.charAt(0) === "6" ||
                                  state.mobileNo.charAt(0) === "7" ||
                                  state.mobileNo.charAt(0) === "8" ||
                                  state.mobileNo.charAt(0) === "9")
                                ? false
                                : true
                            }
                            onChange={handleEChannelChange}
                          />
                          <label className="checkbox-label" htmlFor="checkbox5">
                            {t("mobile.registerechannelServices")}
                          </label>
                        </div>
                      </div>
                    </div>
                  ) : ((state.selectInputCountry === "100" || state.selectInputCountry === "101")) && (
                    <div className={badAddressFlag ? "disable-Mobile" : ""}>
                    {!(props.scheme.includes(HKAIIB) && badAddressFlag) &&
                      <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                        <div className="pds-checkbox">
                          <input
                            type="checkbox"
                            id="checkbox5"
                            checked={state.checked}
                            disabled={true}
                          />
                          <label className="checkbox-label" htmlFor="checkbox5">
                            {t("mobile.registerechannelServices")}
                          </label>
                        </div>
                      </div>}
                  </div>
                )}
              </div>

              {state.mobileInfoVerifyPage === false &&
                (state.selectInputCountry === "100" || state.selectInputCountry === "101") && (state.selectInputCountry !== "" || state.mobilePltcJrNumber !== "") && /*!badAddressFlag &&*/ (
                  <div className="row">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 justify-label">
                      <ul className={badAddressFlag ? "disable-Mobile" : "list-unstyled"} data-testid="mobileInfoList">
                        <li data-testid="listitem1">{t("mobile.mobileInfoTitle")}</li>
                      </ul>
                    </div>
                  </div>
                )}

              {state.mobileInfoVerifyPage === false || state.selectInputCountry === "" && (
                <div className="row">
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4">
                    <a
                      href="#"
                      className={badAddressFlag ? "disable-Mobile" : "info-link"}
                      data-toggle="modal"
                      data-target="#pds-modal-decoupled"
                      onClick={(e) => e.preventDefault()}
                    >
                      {t("mobile.learnMore")}
                    </a>
                  </div>
                </div>
              )}

              <div className="row">
                {(state.mobileInfoVerifyPage === false &&
                  (state.selectInputCountry === "100" || state.selectInputCountry === "101") && (state.selectInputCountry !== "" || state.mobilePltcJrNumber !== "") /*&& !badAddressFlag*/) && (
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 pl-0">

                      {state.mobileInfoEditFlag === true ||
                        state.mobileInfoVerifyPage === true ||
                        props.mobileVerifyEditFlag ? (
                          <div>
                            <div className="col-12">
                              <label className="left-align langPreference">
                                {t("mobile.languagePreference")}
                              </label>
                              <div className="col-xl-4 col-lg-4 col-md-4 col-12 pl-0">
                                <ul className="list-unstyled inner-label">
                                  <li>
                                    <select
                                      id="selectPlaceholder"
                                      aria-label="Select placeholder"
                                      data-testid="langTest"
                                      disabled={props.scheme === "HKAIIB" ? true : !state.checked}
                                      name="selectLangPref"
                                      className="form-input form-text required form-control rsc-select"
                                      onChange={(e) => handleChange(e)}
                                      value={(state.checked || state.langPreferenceEditFlag || props.scheme === schemeAIIB) ? (state.selectLangPref === 'E' ? t("mobile.english") : t("mobile.chinese")) : ""}
                                    >
                                      <option value="" hidden disabled defaultValue   ></option>
                                      <option value={t("mobile.chinese")} data-key="C">
                                        {t("mobile.chinese")}
                                      </option>
                                      <option value={t("mobile.english")} data-key="E">
                                        {t("mobile.english")}
                                      </option>
                                    </select>
                                  </li>
                                </ul>
                              </div>
                            </div>

                          </div>
                        ) : (
                      (!(props.scheme.includes(HKAIIB) && badAddressFlag) &&
                        <div className={badAddressFlag ? "disable-Mobile col-12" : "col-12"} data-testid="selectLangTest">
                          <label className="left-align langPreference">
                            {t("mobile.languagePreference")}
                          </label>

                          <ul className="list-unstyled inner-label">
                            <li>{state.checked ? clientSMSDisplayLang === 'C' ? t("mobile.chinese") : t("mobile.english") : t("common.responseNA")}</li>
                          </ul>
                        </div>
                      ))}
                    </div>
                  )}
              </div>

              <div className="row">
                {(state.mobileInfoEditFlag || props.mobileVerifyEditFlag) && (
                  <div className="col-md-4 float-left">
                    <button
                      className="pds-button pds-button-tertiary btn-blue"
                      name="update"
                      data-testid="updateBtnTest"
                      disabled={
                        props.mobileVerifyEditFlag ? false : (state.disableUpdate || isHkNmValid())
                      }

                      onClick={(e) => onSubmit(e)}
                    >
                      {t("common.update")}
                    </button>
                  </div>
                )}
              </div>
            </form>
            <div className="modal fade rectangle" id="pds-modal-decoupled" data-bs-backdrop="static" data-keyboard="false" data-backdrop="static" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
              <div className="modal-dialog">
                <div className="modal-content col-md-12 col-lg-12 col-sm-10 col-xl-12 mx-auto successpopup">
                  <div className="modal-body">
                    <div className="col-md-12 col-lg-12 col-sm-12 mx-auto">
                      <div className="forgot-card-body">
                        <p>
                          <span className="pds-typography-bold">
                            {t("mobile.applyechannel")}
                          </span>
                        </p>
                        <div className="row">
                          <div className="ptagpopupcenter col-md-9 col-sm-10 col-12 mx-auto">
                            <button
                              name="login"
                              type="button"
                              onClick={(e) => e.preventDefault()}
                              data-dismiss="modal"
                              className="forgot-btn button button--primary js-form-submit form-submit btn btn-primary btn-successpage"
                            >
                              {t("mobile.ok")}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    clientInfo: state.landing.clientInfo,
    countryDropDown: state.commonDropDown.countryDropDown,
    mobileInfoDetails: state.landing.mobileInfoDetails,
    mobileInfoUpdateFlag: state.landing.mobileInfoUpdateFlag,
    mobileInfoUpdateJson: state.landing.mobileInfoUpdateJson,
    mobileVerifyEditFlag: state.landing.mobileVerifyEditFlag,
    cycleDate: state.landing.cycleDate,
    scheme: state.empLogin.scheme,
    mobileInfoUpdateOktaJson: state.landing.mobileInfoUpdateOktaJson, //Michael Lam 20210831
    authMFAData: state.TFADetails.authMFAData
  };
};
const MobileInfoComp = connect(mapStateToProps)(MobileInfoComponent);

export default class MobileInfo extends React.Component {
  constructor() {
    super();
  }
  render() {
    return <MobileInfoComp onEdit={this.props.onEdit} activeEdit={this.props.activeEdit}></MobileInfoComp>;
  }
}
MobileInfo.propTypes = {
  onEdit: PropTypes.func,
  activeEdit: PropTypes.bool,
}
