import { combineReducers, createStore } from "redux";
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import accountReducer from "./reducers/Account-Reducer";
import authenticationFlagReducer from './reducers/Authentication-Reducer';
import ChangesFundDetailsReducer from "./reducers/ChangesFundDetailsReducer.js";
import commonDropDownReducer from './reducers/Common-Dropdown-Reducer.js';
import empLoginReducer from "./reducers/Emp-Login_reducer.js";
import fundSwitchReducer from './reducers/FundSwitch-Reducer';
import fundSwitchDataReducer from './reducers/FundSwitchReducer';
import fundSwitchInReducer from "./reducers/FundSwitchIn-Reducer.js";
import genInfoReducer from "./reducers/GenInfoReducer.js";
import landingReducer from './reducers/Landing-Reducer.js';
import reportReducer from "./reducers/ReportReducer.js";
import tokenReducer from "./reducers/TokenReducer.js";
import verifyReducer from "./reducers/Verify-Reducer.js";
import transactionReducer from "./reducers/TransactionContribution-Reducer.js"
import contributionReducer from "./reducers/ContributionReducer"
import transactionDetailsReducer from "./reducers/TransactionReducer"
import twoFactorAuthenticationReducer from "./reducers/TwoFactorAuthenticationReducer"
import MergerInfoReducer from "./reducers/MergerInfoReducer";
import dashboardReducer from "./reducers/DashboardReducer"
import fundPerformanceReducer from "./reducers/FundPerformanceReducer"
import portfolioReducer from "./reducers/PortfolioReducer"
import contrctCustomFieldReducer from "./reducers/contrctCustomFieldReducer"
import ssoReducer from "./reducers/SSOReducer";

const rootReducer = combineReducers({
    empLogin: empLoginReducer,
    landing: landingReducer,
    commonDropDown: commonDropDownReducer,
    verify: verifyReducer,
    genInfo: genInfoReducer,
    report: reportReducer,
    token: tokenReducer,
    fundSwitch: fundSwitchReducer,
    fundSwitchData: fundSwitchDataReducer,
    isAuthenticated: authenticationFlagReducer,
    switchIn: fundSwitchInReducer,
    changeFundDetails: ChangesFundDetailsReducer,
    twoAccounts: accountReducer,
    transactionContribution: transactionReducer,
    contributionRecord: contributionReducer,
    transactionDetails: transactionDetailsReducer,
    TFADetails: twoFactorAuthenticationReducer,
    mergerInfo: MergerInfoReducer,
    dashboard: dashboardReducer,
    portfolio: portfolioReducer,
    fundPerformance: fundPerformanceReducer,
    contrctCustomField: contrctCustomFieldReducer,
    ssoData: ssoReducer
})
const persistConfig = {
    key: 'root',
    storage,
}

const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = createStore(persistedReducer);
const persistor = persistStore(store)
export { persistor, store };
// const store = createStore(rootReducer, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__())
// export default store;
