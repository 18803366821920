import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import { saveRedirectPricePerformancePage, saveSelectedFundPrincPerformance, setFundDetailRedirection } from "../../actions/FundPerformanceAction";
import ArrowBlueDown from "../../assets/Arrow-Blue-down.svg";
import ArrowBlueUp from "../../assets/Arrow-Blue-up.svg";
import image_pig_nomoney from "../../assets/image_pig_nomoney.png";
import ColorDot from "../../SharedComponents/ColorDot";
import PieChartPI from "../../SharedComponents/PieChartPI";
import { DashBoardUtils, FormatUtils, getColorByCode, SortUtils } from "../../utils";
import { addFundPerformanceToArray } from "../../utils/FundInfoAPIUtils";
import "./DBFundDistribution.css";


const goToFundPricePerformance = (props, fundInfoList, fund, history) => {

  const fundInfo = fundInfoList.find(fundInfo => (fundInfo.legalNm === fund?.title?.toUpperCase()));

  if (fundInfo) {
    props.dispatch(saveSelectedFundPrincPerformance({ ...fundInfo, unitPriceAmt: fundInfo?.unitPrice }))
    props.dispatch(setFundDetailRedirection(true));
    props.dispatch(saveRedirectPricePerformancePage(false))
    history.push('/fundInfo')
  }
}


const renderFundList = (
  t,
  i18n,
  data,
  fundInfoGlobalService,
  fundInfoList,
  props,
  history
) => {
  let fundListSection = [];
  let fundCountToShow = data.length;
  var filteredFundDetails;
  for (let index = 0; index < fundCountToShow; index++) {
    filteredFundDetails = fundInfoGlobalService.find(fundInfoGlobalServiceObj => (fundInfoGlobalServiceObj.legalNm === data[index].title.toUpperCase() && fundInfoGlobalServiceObj.isActiveFund === true));
    fundListSection.push(
      <div >
        <div className="db-fund-distribution-fund-dot-name pointer-cursor" key={index} onClick={() => goToFundPricePerformance(props, fundInfoList, data?.[index], history)} data-testid="fund-link">
          <span className="db-fund-distribution-fund-dot">
            <ColorDot colorcode={data?.[index]?.title === "Others" ? 6 : index + 1} />
          </span>
          {
            (index < 5) ?
              <span className="db-fund-distribution-fund-name">
                {i18n.language === 'en' ? data[index].title : filteredFundDetails?.othLegalNm} ({FormatUtils.convertNumToPercentwithPrecisionTwo(data[index].value / 100)})
              </span> :
              (data[index]?.title === "Others") ?
                <span className="db-fund-distribution-fund-name">
                  {i18n.language === 'en' ? "Others" : "其他"} ({FormatUtils.convertNumToPercentwithPrecisionTwo(data[index].value / 100)})
              </span> :
                <span className="db-fund-distribution-fund-name">
                  {i18n.language === 'en' ? data[index].title : filteredFundDetails?.othLegalNm} ({FormatUtils.convertNumToPercentwithPrecisionTwo(data[index].value / 100)})
              </span>
          }
        </div>
      </div>
    );
  }
  return fundListSection;
};

const renderCollapsedCard = (t, cardExpanded, setcardExpanded, history) => {
  return (
    <div
      className={"db-fund-distribution-collapsed-card"}
      onClick={() => setcardExpanded(true)}
    >
      {renderFundDistributionTitle(t, cardExpanded, history)}
    </div>
  );
};

const renderFundDistributionTitle = (t, cardExpanded, history) => {
  const section = [];
  section.push(
    <span className="db-fund-distribution-title pointer-cursor" onClick={() => { history.push('/accountBalanceByFund') }}>
      {t("dashboard.onlineDashboard")}
    </span>
  );

  section.push(
    <img
      className="d-block d-sm-block d-md-none db-fund-distribution-collapsed-card-icon"
      src={cardExpanded ? ArrowBlueUp : ArrowBlueDown}
    ></img>
  );

  return section;
};

const renderExpandedCard = (
  t,
  totalAmt,
  data,
  i18n,
  fundDistributionData,
  fundInfoGlobalService,
  cardExpanded,
  setcardExpanded,
  fundInfoList,
  props,
  history
) => {
  let fundList = [];
  fundDistributionData.forEach((fund) => {
    if ("OTHERS" === fund?.title?.toUpperCase()) {
      fundList.push({ ...fund, othLegalNm: "其他" })
      return;
    }
    for (let index = 0; index < fundInfoGlobalService.length; index++) {
      const fundInfoData = fundInfoGlobalService[index];
      if (fundInfoData.legalNm === fund?.title?.toUpperCase()) {
        fundList.push({ ...fund, othLegalNm: fundInfoData?.othLegalNm })
        break;
      }
    }
  });
  return (
    <>
      <div className="row">
        <div
          onClick={() => setcardExpanded(false)}
          className="col-12 db-fund-distribution-expanded-card"
        >
          {renderFundDistributionTitle(t, cardExpanded, history)}
        </div>
      </div>
      {totalAmt > 0 && data.length > 0 ?
        <div className="row db-fund-distribution-graphic-section">
          <div className="col-lg-6 col-md-6 col-sm-12 db-pie">
            <PieChartPI data={fundList} radius={29} width={42} click={() => history.push('/accountBalanceByFund')}/>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <div className="db-fund-distribution-funds-list-section">
              {renderFundList(
                t,
                i18n,
                fundDistributionData,
                fundInfoGlobalService,
                fundInfoList,
                props,
                history
              )}
            </div>
          </div>
        </div>
        :
        <div>
          <div className="row">
            <div className="noMoneyImg">
              <img className="noMoneyImage" src={image_pig_nomoney}></img>
            </div>
          </div>
          <div className="noAsset">
            {t("dashboard.noAssetsText")}
          </div>
        </div>

      }
    </>
  );
};

const DBFundDistribution = (props) => {
  const { t, i18n } = useTranslation("common");
  const fundInfoGlobalService = props?.fundInfoData ?? [];
  const [cardExpanded, setcardExpanded] = useState(true);
  const [serviceCallFlag, setServiceCallFlag] = useState(false);
  const [fundDistributionData, setFundDistributiondata] = useState([])
  const [fundInfoList, setFundInfoList] = useState([])
  const history = useHistory();

  useEffect(() => {
    setcardExpanded(true);
    setServiceCallFlag(true);
    let sortedData = SortUtils.sortDashboardFunds(props?.data)

    sortedData.forEach((fund, index) => {
      let color = getColorByCode(index + 1);
      fund["color"] = color;
    });

    let fundData = DashBoardUtils.getFundDistributionData(sortedData);
    setFundDistributiondata(fundData)

    let fundList = []
    fundData.map((fund) => {
      for (let index = 0; index < fundInfoGlobalService.length; index++) {
        const fundInfoGlobalServiceObj = fundInfoGlobalService[index];
        if (fundInfoGlobalServiceObj.legalNm === fund?.title?.toUpperCase() && fundInfoGlobalServiceObj.isActiveFund === true) {
          fundList.push(fundInfoGlobalServiceObj)
        }
      }
    });
    if (fundList.length > 0) {
      const selectedQuickPolicyInfo = props?.quickPolicyInfo[props.selectedPolicyIndex];
      const productTypeNmbr = selectedQuickPolicyInfo?.product?.productTypeNmbr
      let headers = {
        'pisys_auth_token': props.pisysToken,
        'Access-Control-Allow-Origin': '*'
      }
      let fundPerformanceData = addFundPerformanceToArray(props,fundList, productTypeNmbr, headers, props.cycleDate)
      fundPerformanceData.then((fundPerformance) => {
        setFundInfoList(fundPerformance)
      })


    }

    setServiceCallFlag(false);
  }, [])



  return !serviceCallFlag ?
    (!cardExpanded ? (
      <div className="container" data-testid='db-fund-distribution'>
        <div className={"d-block d-sm-block d-md-none"}>
          {renderCollapsedCard(t, cardExpanded, setcardExpanded, history)}
        </div>
        <div className="d-none d-lg-block d-md-block">
          {renderExpandedCard(
            t,
            props.totalAmt,
            props.data,
            i18n,
            fundDistributionData,
            fundInfoGlobalService,
            cardExpanded,
            setcardExpanded,
            fundInfoList,
            props,
            history
          )}
        </div>
      </div>
    ) : (<div className="container card-container" data-testid='db-fund-distribution'>
      {renderExpandedCard(
        t,
        props.totalAmt,
        props.data,
        i18n,
        fundDistributionData,
        fundInfoGlobalService,
        cardExpanded,
        setcardExpanded,
        fundInfoList,
        props,
        history
      )}
    </div>)
    ) : (
      <div className="d-flex justify-content-center">
        <div className="spinner-border spinner m-5" role="status">
          <span className="sr-only"></span>
        </div>
      </div>
    );
};

DBFundDistribution.propTypes = {
  props: PropTypes.any,
  data: PropTypes.array,
  fundInfoData: PropTypes.arrayOf(PropTypes.object),
  totalAmt: PropTypes.number,
  selectedPolicyIndex: PropTypes.number,
  quickPolicyInfo: PropTypes.array,
  pisysToken: PropTypes.string,
  cycleDate: PropTypes.string,
  loginId: PropTypes.string,
  clientNmbr: PropTypes.string,
  scheme: PropTypes.string,
  webCycleDate: PropTypes.string,
};

const mapStateToProps = (state) => {
  return {
    fundInfoData: state.fundSwitch.fundInfoData,
    selectedPolicyIndex: state.twoAccounts.selectedPolicyIndex,
    quickPolicyInfo: state.twoAccounts.quickPolicyInfo,
    pisysToken: state.token.pisysToken,
    cycleDate: state.landing.cycleDate,
    loginId: state.empLogin.loginId,
    clientNmbr: state.empLogin.clientNmbr,
    webCycleDate: state.empLogin.webCycleDate,
    scheme: state.empLogin.scheme,
  }
}

export default connect(mapStateToProps)(DBFundDistribution);
