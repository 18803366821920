import { asiaHongKong, colon, enUS } from "../translations/common/commonConstants";

const getBreadCrumbDetails = (t, currentPage, history, contributionType) => {
  let propArr = [];
  if (currentPage === "assetTypes") {
    propArr = [
      {
        title: t("titles.dashboard"),
        active: true,
        route: "/",
      },
      {
        title: t("titles.fundSwitch"),
        active: false,
        route: "assetTypes",
      },
    ];
  } else if (
    currentPage === "contributionTypes" ||
    currentPage === "fundsprocessing"
  ) {
    propArr = [
      {
        title: t("titles.dashboard"),
        active: true,
        route: "/",
      },
      {
        title: t("titles.fundSwitch"),
        active: true,
        route: "assetTypes",
      },
      {
        title: t("fundSwitch.cardTitle1"),
        active: false,
        route: "contributionTypes",
      },
    ];
  } else if (currentPage === "switchOut" || currentPage === "smpSwitchOut") {
    propArr = [
      {
        title: t("titles.dashboard"),
        active: true,
        route: "/",
      },
      {
        title: t("titles.fundSwitch"),
        active: true,
        route: "assetTypes",
      },
      {
        title: t("fundSwitch.cardTitle1"),
        active: true,
        route: "contributionTypes",
      },
      {
        title: t("titles.switchOut"),
        active: false,
        route: "switchOut",
      },
    ];
  } else if (currentPage === "switchOutPercentage") {
    propArr = [
      {
        title: t("titles.dashboard"),
        active: true,
        route: "/",
      },
      {
        title: t("titles.fundSwitch"),
        active: true,
        route: "assetTypes",
      },
      {
        title: t("fundSwitch.cardTitle1"),
        active: true,
        route: "contributionTypes",
      },
      {
        title: t("titles.switchOut"),
        active: false,
        route: "switchOutPercentage",
      },
    ];
  } else if (currentPage === "switchIn") {
    propArr = [
      {
        title: t("titles.dashboard"),
        active: true,
        route: "/",
      },
      {
        title: t("titles.fundSwitch"),
        active: true,
        route: "assetTypes",
      },
      {
        title: t("fundSwitch.cardTitle1"),
        active: true,
        route: "contributionTypes",
      },
      {
        title: t("titles.switchOut"),
        active: true,
        route: getSwitchOutRout(contributionType),
      },
      {
        title: t("fundSwitchIn.title"),
        active: false,
        route: "switchIn",
      },
    ];
  } else if (currentPage === "SMPswitchIn") {
    propArr = [
      {
        title: t("titles.dashboard"),
        active: true,
        route: "/",
      },
      {
        title: t("titles.fundSwitch"),
        active: true,
        route: "assetTypes",
      },
      {
        title: t("fundSwitch.cardTitle1"),
        active: true,
        route: "contributionTypes",
      },
      {
        title: t("titles.switchOut"),
        active: true,
        route: getSwitchOutRout(contributionType),
      },
      {
        title: t("fundSwitchIn.title"),
        active: false,
        route: "switchIn",
      },
    ];
  } else if (currentPage === "confirmation") {
    propArr = [
      {
        title: t("titles.dashboard"),
        active: true,
        route: "/",
      },
      {
        title: t("titles.fundSwitch"),
        active: true,
        route: "assetTypes",
      },
      {
        title: t("fundSwitch.cardTitle1"),
        active: true,
        route: "contributionTypes",
      },
      {
        title: t("titles.switchOut"),
        active: true,
        route: getSwitchOutRout(contributionType),
      },
      {
        title: t("titles.switchIn"),
        active: true,
        route: getSwitchInRout(contributionType),
      },
      {
        title: t("titles.confirmation"),
        active: false,
        route: "confirmation",
      },
    ];
  } else if (currentPage === "transaction") {
    propArr = [
      {
        title: t("titles.dashboard"),
        active: true,
        route: "/",
      },
      {
        title: t("titles.fundSwitch"),
        active: true,
        route: "assetTypes",
      },
      {
        title: t("titles.transactionAcc"),
        active: false,
        route: "transaction",
      },
    ];
  } else if (currentPage === "futureContributionTypes") {
    propArr = [
      {
        title: t("titles.dashboard"),
        active: true,
        route: "/",
      },
      {
        title: t("titles.fundSwitch"),
        active: true,
        route: "assetTypes",
      },
      {
        title: t("fundSwitch.cardTitle2"),
        active: false,
        route: "futureContributionTypes",
      },
    ];
  } else if (currentPage === "futureTransaction") {
    propArr = [
      {
        title: t("titles.dashboard"),
        active: true,
        route: "/",
      },
      {
        title: t("titles.fundSwitch"),
        active: true,
        route: "assetTypes",
      },
      {
        title: t("titles.transactionAcc"),
        active: false,
        route: "transaction",
      },
    ];
  } else if (currentPage === "futureAssetTypes") {
    propArr = [
      {
        title: t("titles.dashboard"),
        active: true,
        route: "/",
      },
      {
        title: t("titles.fundSwitch"),
        active: true,
        route: "assetTypes",
      },
      {
        title: t("fundSwitch.cardTitle2"),
        active: true,
        route: "futureContributionTypes",
      },
      {
        title: t("confirmation.allocation"),
        active: false,
        route: "futureAssetTypes",
      },
    ];
  } else if (currentPage === "futureConfirmation") {
    propArr = [
      {
        title: t("titles.dashboard"),
        active: true,
        route: "/",
      },
      {
        title: t("titles.fundSwitch"),
        active: true,
        route: "assetTypes",
      },
      {
        title: t("fundSwitch.cardTitle2"),
        active: true,
        route: "futureContributionTypes",
      },
      {
        title: t("confirmation.allocation"),
        active: true,
        route: "futureAssetTypes",
      },
      {
        title: t("titles.confirmation"),
        active: false,
        route: "futureConfirmation",
      },
    ];
  } else if (currentPage === "/") {
    history.push("/dashboard");
  }
  return propArr;
};

const getSwitchOutRout = (contributionType) => {
  let switchOutRout = "switchOutPercentage";
  if (["fsContribution.type8", "fsContribution.type9", "fsContribution.type10"].includes(contributionType)) {
    switchOutRout = "contributionTypes";
  } else if (["fsContribution.type11"].includes(contributionType)) {
    switchOutRout = "smpSwitchOut";
  }
  return switchOutRout;
};

const getSwitchInRout = (contributionType) => {
  let switchInRout = "switchIn";
  if (["fsContribution.type8", "fsContribution.type11"].includes(contributionType)) {
    switchInRout = "SMPswitchIn";
  } else if (["fsContribution.type9", "fsContribution.type10"].includes(contributionType)) {
    switchInRout = "switchIn";
  }
  return switchInRout;
};

const getEmptyPerformanceResponse = () => {
  var emptyPerf =
  {
    "asOfDate": "N/A",
    "productTypeNmbr": "N/A",
    "rcrdTypeNmbr": "N/A",
    "acronymNm": "N/A",
    "cfInvTypeNmbr": 0,
    "productTypeNm": "N/A",
    "invTypeNm": "N/A",
    "inceptionDate": "",
    "totalOtstdUnit": "N/A",
    "totalNavAmt": "N/A",
    "inceptionYr": "N/A",
    "cr1mTxt": "N/A",
    "cr3mTxt": "N/A",
    "cr6mTxt": "N/A",
    "cr1yTxt": "N/A",
    "cr3yTxt": "N/A",
    "cr5yTxt": "N/A",
    "cr10yTxt": "N/A",
    "crYtdTxt": "N/A",
    "crSiTxt": "N/A",
    "crUdfPrdTxt": "N/A",
    "ar1mTxt": "N/A",
    "ar3mTxt": "N/A",
    "ar6mTxt": "N/A",
    "arPrevCalYrTxt": "N/A",
    "ar1yTxt": "N/A",
    "ar3yTxt": "N/A",
    "ar5yTxt": "N/A",
    "ar10yTxt": "N/A",
    "arYtdTxt": "N/A",
    "arSiTxt": "N/A",
    "arUdfPrdTxt": "N/A",
    "iy1Txt": "N/A",
    "iy2Txt": "N/A",
    "iy3Txt": "N/A",
    "iy4Txt": "N/A",
    "iy5Txt": "N/A",
    "iy6Txt": "N/A",
    "iy7Txt": "N/A",
    "iy8Txt": "N/A",
    "iy9Txt": "N/A",
    "iy10Txt": "N/A",
    "iyNTxt": "N/A",
    "stdDeviationTxt": "N/A",
    "cy1Txt": "N/A",
    "cy2Txt": "N/A",
    "cy3Txt": "N/A",
    "cy4Txt": "N/A",
    "cy5Txt": "N/A",
    "riskClassTxt": "N/A",
    "systemSeqNmbr": "N/A",
    "processorId": "N/A",
    "lastChangeDt": "N/A"
  };
  return emptyPerf;
};

const getAsOfFlagValue = (fund) => {
  return (isNaN(parseFloat(fund?.crYtdTxt)) && isNaN(parseFloat(fund?.cr1yTxt)) && isNaN(parseFloat(fund?.cr3yTxt)) && isNaN(parseFloat(fund?.cr5yTxt)));
};

const disableFund = (invTypeNmbr) => {
  let disableStatus = false;
  const terminationDate = new Date();
  terminationDate.setFullYear(2022, 10, 16);
  const currentDate = new Date();

  let browserSupport = true;
  var hkTime = currentDate.toLocaleTimeString(enUS, { timeZone: asiaHongKong, hour12: false });
  if (hkTime == null || hkTime == undefined || hkTime == "") {
    browserSupport = false;
    hkTime = new Date(currentDate.getTime() + currentDate.getTimezoneOffset() * 60 * 1000 + 480 * 60 * 1000);
  }

  var terminatedPriod = false;
  if (terminationDate.getTime() == currentDate.getTime()) {
    if (browserSupport) {
      let time = hkTime?.split(colon);
      if (time[0] >= 16) {
        terminatedPriod = true;
      }
    } else {
      if (hkTime.getHours().toLocaleString() == 16) {
        terminatedPriod = true;
      }
    }
  }

  if (terminationDate.getTime() < currentDate.getTime()) {
    terminatedPriod = true;
  }

  if (terminatedPriod && invTypeNmbr == 221) {
    disableStatus = true;
  }

  return disableStatus;
};

export { disableFund };
export default { getAsOfFlagValue, getBreadCrumbDetails, getEmptyPerformanceResponse };