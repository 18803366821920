import { SAVE_SCHEME_DATA, SAVE_SCHEME_500_DATA, SAVE_SCHEME_800_DATA, SAVE_SCHEME_600_DATA, SAVE_SCHEME_200_DATA, RESET_FUND_PERFORMANCE, SAVE_SELECTED_FUND_PRICE_PERFORMANCE, SAVE_FEES_CHARGES, SAVE_FUND_HISTORY, SAVE_REDIRECT_FUND_PRICE_PERFORMANCE, SAVE_FUND_FACT_SHEET_LINK, SET_FUND_DETAIL_REDIRECTION, SAVE_FUND_CLASS_DATA, SAVE_LATEST_DATE, SAVE_POLICY_WISE_FEES_CHARGES, SAVE_FUND_INFO_CONTRACT_NMBR, SAVE_FUND_INFO_PRODUCT_NMBR } from "../actions/ActionConstants";

const initialState = {
    is800: [],
    is600: [],
    is200: [],
    is500: [],
    fundPricePerformance: {},
    feesCharges: {},
    fundHistory: {},
    priceAndPerformancePage: false,
    factSheetLink: [],
    redirectFundInfoDetails: false,
    fundClassData: [],
    latestDate: '',
    policyWiseFeesCharges: {},
    contractNmbr: 0,
    productTypeNmbr: 0
}

export default function fundPerformanceReducer(state = initialState, action) {
    switch (action.type) {
        case SAVE_SCHEME_DATA:
            return { ...state, schemeJson: action.data };

        case SAVE_SCHEME_800_DATA:
            return { ...state, is800: action.data };
        case SAVE_REDIRECT_FUND_PRICE_PERFORMANCE:
            return { ...state, priceAndPerformancePage: action.data };
        case SAVE_SCHEME_600_DATA:
            return { ...state, is600: action.data };

        case SAVE_SCHEME_200_DATA:
            return { ...state, is200: action.data };
        case SAVE_SCHEME_500_DATA:
            return { ...state, is500: action.data };

        case RESET_FUND_PERFORMANCE:
            return { ...state, is200: [], is600: [], is800: [], is500: [], factSheetLink: [], redirectFundInfoDetails: false, priceAndPerformancePage: false, fundPricePerformance: {}, contractNmbr: 0, productTypeNmbr: 0 };
        case SAVE_SELECTED_FUND_PRICE_PERFORMANCE:
            return { ...state, fundPricePerformance: action.data }
        case SAVE_FEES_CHARGES:
            return { ...state, feesCharges: action.data }
        case SAVE_POLICY_WISE_FEES_CHARGES:
            return { ...state, policyWiseFeesCharges: action.data }
        case SAVE_FUND_HISTORY:
            return { ...state, fundHistory: action.data }
        case SAVE_FUND_FACT_SHEET_LINK:
            return { ...state, factSheetLink: action.data }
        case SET_FUND_DETAIL_REDIRECTION:
            return { ...state, redirectFundInfoDetails: action.data }
        case SAVE_FUND_CLASS_DATA:
            return { ...state, fundClassData: action.data }
        case SAVE_LATEST_DATE:
            return { ...state, latestDate: action.data }
        case SAVE_FUND_INFO_CONTRACT_NMBR:
            return { ...state, contractNmbr: action.data}
        case SAVE_FUND_INFO_PRODUCT_NMBR:
            return { ...state, productTypeNmbr: action.data}
        default:
            return state;
    }
}