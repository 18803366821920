import { REMOVE_FUND_SWITCH_DATA, RESET_FUND_SWITCH_DATA, SAVE_EE_INVESTMENT_DETAILS, SAVE_EE_PENDING_FOR_SAME_DAY, SAVE_EE_PENDING_FUNDS, SAVE_ER_INVESTMENT_DETAILS, SAVE_ER_PENDING_FOR_SAME_DAY, SAVE_ER_PENDING_FUNDS, SAVE_FUND_SWITCH_DATA, SAVE_GFC_FUNDS, SAVE_INVESTMENT_DETAILS, SAVE_MAN_INVESTMENT_DETAILS, SAVE_ORSO_FUND_SWITCH_DATA, SAVE_SWITCH_NEXT_FLAG, SAVE_SWITCH_OUT_FUNDS, SAVE_VOL_INVESTMENT_DETAILS, UPDATE_FUND_SWITCH_DATA } from "../actions/ActionConstants";

const initialState = {
    fundSwitchData: [],
    switchNextFlag: false,
    manInvestmentDetails: [],
    volInvestmentDetails: [],
    eeInvestmentDetails: [],
    erInvestmentDetails: [],
    investmentDetails: [],
    switchOutfunds: [],
    erPendingFunds: {},
    eePendingFunds: {},
    erPendingForSameDay: false,
    eePendingForSameDay: false,
    GFCFunds: []
}

export default function fundSwitchReducer(state = initialState, action) {
    switch (action.type) {
        case SAVE_FUND_SWITCH_DATA:
            return {
                ...state,
                fundSwitchData: [...state.fundSwitchData, action.data]
            }
        case RESET_FUND_SWITCH_DATA:
            return {
                ...state,
                fundSwitchData: [],
                switchNextFlag: false,
                manInvestmentDetails: [],
                volInvestmentDetails: [],
                eeInvestmentDetails: [],
                erInvestmentDetails: [],
                investmentDetails: [],
                switchOutfunds: [],
                erPendingFunds: {},
                eePendingFunds: {},
                erPendingForSameDay: false,
                eePendingForSameDay: false,
                GFCFunds: []
            };
        case REMOVE_FUND_SWITCH_DATA: {
            const data = [...state?.fundSwitchData]
            const fund = data?.find((fund) => fund?.switchOut?.invTypeNmbr === action?.data?.invTypeNmbr && fund?.contributionType === action?.data?.contributionType)
            const index = data?.indexOf(fund)

            if (index !== -1) {
                data.splice(index, 1)
            }
            return {
                ...state,
                fundSwitchData: [...data]
            }
        }
        case UPDATE_FUND_SWITCH_DATA:
            return {
                ...state,
                fundSwitchData: [...action?.data]
            }
        case SAVE_SWITCH_NEXT_FLAG:
            return { ...state, switchNextFlag: action.data }
        case SAVE_MAN_INVESTMENT_DETAILS:
            return { ...state, manInvestmentDetails: action.data }
        case SAVE_VOL_INVESTMENT_DETAILS:
            return { ...state, volInvestmentDetails: action.data }
        case SAVE_EE_INVESTMENT_DETAILS:
            return { ...state, eeInvestmentDetails: action.data }
        case SAVE_ER_INVESTMENT_DETAILS:
            return { ...state, erInvestmentDetails: action.data }
        case SAVE_INVESTMENT_DETAILS:
            return { ...state, investmentDetails: action.data }
        case SAVE_SWITCH_OUT_FUNDS:
            return { ...state, switchOutfunds: action.data }
        case SAVE_ORSO_FUND_SWITCH_DATA:
            return { ...state, fundSwitchData: [...action.data] }
        case SAVE_ER_PENDING_FUNDS:
            return { ...state, erPendingFunds: action.data }
        case SAVE_EE_PENDING_FUNDS:
            return { ...state, eePendingFunds: action.data }
        case SAVE_ER_PENDING_FOR_SAME_DAY:
            return { ...state, erPendingForSameDay: action.data }
        case SAVE_EE_PENDING_FOR_SAME_DAY:
            return { ...state, eePendingForSameDay: action.data }
        case SAVE_GFC_FUNDS:
            return { ...state, GFCFunds: [...action.data] }
        default:
            return state;
    }
}




