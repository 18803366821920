import {
  SAVE_CLIENT_INFO, SAVE_CONTACT_INFO_DETAILS, SAVE_CONTACT_INFO_UPDATE_FLAG, SAVE_CONTACT_UPDATE_JSON,
  SAVE_CONTACT_VERIFY_EDIT_FLAG, SAVE_CURRENT_LOADED_PAGE_ON_UI, SAVE_CYCLE_DATE, SAVE_EMAIL_INFO_DETAILS,
  SAVE_EMAIL_INFO_UPDATE_FLAG, SAVE_EMAIL_UPDATE_JSON,
  SAVE_EMAIL_UPDATE_OKTA_JSON,
  SAVE_EMAIL_VERIFY_EDIT_FLAG, SAVE_MOBILE_INFO_DETAILS, SAVE_MOBILE_INFO_UPDATE_FLAG, SAVE_MOBILE_UPDATE_JSON,
  SAVE_MOBILE_UPDATE_OKTA_JSON, SAVE_MOBILE_VERIFY_EDIT_FLAG, SAVE_MAX_AGE, SAVE_MULTIPLE_CLIENT_INFO
} from "./ActionConstants.js";

export function saveClientDetails(data) {
  return { type: SAVE_CLIENT_INFO, data };
}

export function saveMultipleClientDetails(data) {
  return { type: SAVE_MULTIPLE_CLIENT_INFO, data };
}

export function saveMaxAge(data) {
  return { type: SAVE_MAX_AGE, data };
}
export function saveContactInfoDetails(data) {
  return { type: SAVE_CONTACT_INFO_DETAILS, data };
}
export function saveContactInfoUpdateFlag(data) {
  return { type: SAVE_CONTACT_INFO_UPDATE_FLAG, data };
}
export function saveContactUpdateJson(data) {
  return { type: SAVE_CONTACT_UPDATE_JSON, data };
}
export function saveContactVerifyEditFlag(data) {
  return { type: SAVE_CONTACT_VERIFY_EDIT_FLAG, data };
}
export function saveEmailInfoDetails(data) {
  return { type: SAVE_EMAIL_INFO_DETAILS, data };
}
export function saveEmailInfoUpdateFlag(data) {
  return { type: SAVE_EMAIL_INFO_UPDATE_FLAG, data };
}
export function saveEmailUpdateJson(data) {
  return { type: SAVE_EMAIL_UPDATE_JSON, data };
}
export function saveEmailVerifyEditFlag(data) {
  return { type: SAVE_EMAIL_VERIFY_EDIT_FLAG, data };
}
export function saveMobileInfoDetails(data) {
  return { type: SAVE_MOBILE_INFO_DETAILS, data };
}
export function saveMobileInfoUpdateFlag(data) {
  return { type: SAVE_MOBILE_INFO_UPDATE_FLAG, data };
}
export function saveMobileUpdateJson(data) {
  return { type: SAVE_MOBILE_UPDATE_JSON, data };
}
export function saveMobileVerifyEditFlag(data) {
  return { type: SAVE_MOBILE_VERIFY_EDIT_FLAG, data };
}
export function saveCycleDate(data) {
  return { type: SAVE_CYCLE_DATE, data }
}
export function saveCurrentLoadedPageOnUI(data) {
  return { type: SAVE_CURRENT_LOADED_PAGE_ON_UI, data }
}
export function saveMobileUpdateOktaJson(data) {
  return { type: SAVE_MOBILE_UPDATE_OKTA_JSON, data };
}
export function saveEmailUpdateOktaJson(data) {
  return { type: SAVE_EMAIL_UPDATE_OKTA_JSON, data };
}