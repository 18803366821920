import { OPEN_VERIFY_PAGE } from "../actions/ActionConstants";

const initialState = {
    verifyData: {
        "fromVerifyFlag": false
    }
}

export default function verifyReducer(state = initialState, action) {
    switch (action.type) {
        case OPEN_VERIFY_PAGE:
            return { ...state, verifyData: action.data };
        default:
            return state;
    }
}