import {
    RESET_FUND_PERFORMANCE, SAVE_FEES_CHARGES, SAVE_FUND_CLASS_DATA, SAVE_FUND_FACT_SHEET_LINK,
    SAVE_FUND_HISTORY,
    SAVE_FUND_INFO_CONTRACT_NMBR, SAVE_FUND_INFO_PRODUCT_NMBR, SAVE_LATEST_DATE, SAVE_POLICY_WISE_FEES_CHARGES, SAVE_REDIRECT_FUND_PRICE_PERFORMANCE, SAVE_SCHEME_200_DATA,
    SAVE_SCHEME_500_DATA, SAVE_SCHEME_600_DATA, SAVE_SCHEME_800_DATA, SAVE_SCHEME_DATA, SAVE_SELECTED_FUND_PRICE_PERFORMANCE,
    SET_FUND_DETAIL_REDIRECTION
} from "../actions/ActionConstants";

export function saveSchemeData(data) {
    return { type: SAVE_SCHEME_DATA, data }
}
export function saveSchemeData800(data) {
    return { type: SAVE_SCHEME_800_DATA, data }
}
export function saveRedirectPricePerformancePage(data) {
    return { type: SAVE_REDIRECT_FUND_PRICE_PERFORMANCE, data }
}
export function saveSchemeData600(data) {
    return { type: SAVE_SCHEME_600_DATA, data }
}
export function saveSchemeData200(data) {
    return { type: SAVE_SCHEME_200_DATA, data }
}
export function resetPerformaceInfo() {
    return { type: RESET_FUND_PERFORMANCE }
}
export function saveSelectedFundPrincPerformance(data) {
    return { type: SAVE_SELECTED_FUND_PRICE_PERFORMANCE, data }
}
export function saveFeesAndCharges(data) {
    return { type: SAVE_FEES_CHARGES, data }
}
export function savePolicyWiseFeesAndCharges(data) {
    return { type: SAVE_POLICY_WISE_FEES_CHARGES, data }
}
export function saveFundHistory(data) {
    return { type: SAVE_FUND_HISTORY, data }
}
export function saveFundfactSheet(data) {
    return { type: SAVE_FUND_FACT_SHEET_LINK, data }
}
export function setFundDetailRedirection(data) {
    return { type: SET_FUND_DETAIL_REDIRECTION, data }
}
export function saveFundClassData(data) {
    return { type: SAVE_FUND_CLASS_DATA, data }
}
export function saveSchemeData500(data) {
    return { type: SAVE_SCHEME_500_DATA, data }
}
export function saveLatestDate(data) {
    return { type: SAVE_LATEST_DATE, data }
}
export function saveFundInfoContractNmbr(data) {
    return { type: SAVE_FUND_INFO_CONTRACT_NMBR, data }
}

export function saveFundInfoProductNmbr(data) {
    return { type: SAVE_FUND_INFO_PRODUCT_NMBR, data }
}