import moment from "moment";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { saveFundInfoContractNmbr, saveFundInfoProductNmbr, saveRedirectPricePerformancePage, saveSchemeData200, saveSchemeData500, saveSchemeData600, saveSchemeData800, saveSelectedFundPrincPerformance } from '../../actions/FundPerformanceAction';
import riskSpectrumSmp from "../../assets/Icon/Graph_SMP-RiskSpectrum.svg";
import riskSpectrum from "../../assets/Icon/Rectangle   Rectangle Copy 6   Rectangle Copy 6 Mask.svg";
import "../../css/FundPricesAndPerformance.css";
import Logger from "../../SharedComponents/Logger";
import LssPopUp from "../../SharedComponents/LssPopUp";
import { asiaHongKong, DD_MM_YYYY_HH_mm_ss, DISfundName, enGB, FUND_INFO, HKAIIB, HONG_KONG_DOLLAR, notApplicable, percent, schemeS800, SMP, US_DOLLAR } from "../../translations/common/commonConstants";
import { FormatUtils, FundPerformanceUtil, fundSwitchUtils, SortUtils } from "../../utils";
import { getFundInfo } from "../../utils/FundInfoAPIUtils";

const displayCarat = (invName) => {
    if (invName === "A65F-800-(DIS)" || invName === "CAF-800" || invName === "CAF-800-(DIS)" || invName === "A65F-800") {
        return true;
    }
    return false;
};

const FundPricesAndPerformance = (props) => {
    const { t, i18n } = useTranslation("common");
    let webCycleDt = props.webCycleDate && moment(props.webCycleDate, "YYYY-MM-DD HH:mm:ss A").toDate();
    var initialToDate = new Date(webCycleDt);
    initialToDate.setHours(0, 0, 0, 0);
    webCycleDt = moment(initialToDate).format("DD/MM/YYYY");
    const riskType = t("feesAndCharges.riskType", { returnObjects: true });
    const riskTypeSmp = t("feesAndCharges.riskTypeSmp", { returnObjects: true });
    const sortOptions = t("fundInfo.sortOptions", { returnObjects: true });
    const [selectedRiskType, setSelectedRiskType] = useState(t("feesAndCharges.riskType.0"));
    const [selectedSort, setSelectedSort] = useState(t("fundInfo.sortOptions.0"));
    const [selectedScheme, setSelectedScheme] = useState(null);
    const [schemes, setSchemes] = useState([]);
    const [serviceCallFlag, setServiceCallFlag] = useState(false);
    const [filteredFundList, setFilteredFundList] = useState([]);
    const [fundList, setFundList] = useState([]);

    const getInvested = (policyList) => {
        var invArr = [];
        policyList.forEach((item) => {
            item.policyAccountValueVOList.forEach((inv) => {
                let tempArr = inv.invTypeNm.split('-');
                if (tempArr.includes('DHB'))
                    invArr.push('DHB');
                else
                    invArr.push(tempArr[tempArr.length - 1]);
            });
        });
        return invArr;
    };

    useEffect(() => {
        let filteredList;
        switch (selectedRiskType) {
            case t("feesAndCharges.riskType.1"):
                filteredList = sortData(fundList.filter((data) => (data.invRskNm === "LOW")), selectedSort);
                break;
            case t("feesAndCharges.riskType.2"):
                filteredList = sortData(fundList.filter((data) => (data.invRskNm === "MODERATE")), selectedSort);
                break;
            case t("feesAndCharges.riskType.3"):
                filteredList = sortData(fundList.filter((data) => (data.invRskNm === "MODERATE TO HIGH")), selectedSort);
                break;
            case t("feesAndCharges.riskType.4"):
                filteredList = sortData(fundList.filter((data) => (data.invRskNm === "HIGH")), selectedSort);
                break;
            case t("feesAndCharges.riskTypeSmp.2"):
                filteredList = sortData(fundList.filter((data) => (data.invRskNm === "Low to Medium")), selectedSort);
                break;
            case t("feesAndCharges.riskTypeSmp.3"):
                filteredList = sortData(fundList.filter((data) => (data.invRskNm === "Medium")), selectedSort);
                break;
            case t("feesAndCharges.riskTypeSmp.4"):
                filteredList = sortData(fundList.filter((data) => (data.invRskNm === "Medium to High")), selectedSort);
                break;
            default:
                filteredList = sortData(fundList, selectedSort);
                break;
        }
        setFilteredFundList(filteredList);
    }, [selectedRiskType, selectedSort, selectedScheme]);

    const changePage = (fund) => {
        props.dispatch(saveSelectedFundPrincPerformance(fund));
        props.setCurrentPage('pGEFClassAccUnits');
        props.dispatch(saveRedirectPricePerformancePage(false));
    };

    const renderFundInfo = (t, fundList, i18n) => {
        const decimalPlaces = props.scheme == SMP ? 2 : 4;
        if (fundList && fundList.length > 0) {
            fundList.forEach((fund) => {
                fund["asOfFlag"] = fundSwitchUtils.getAsOfFlagValue(fund?.performance);
            });
            let lssFund;
            if (props.scheme?.includes(HKAIIB)) {
                lssFund = fundList?.find(fund => fund?.invTypeNm?.includes("(LSS)-AIIB"));
                fundList = fundList?.filter(fund => !fund?.invTypeNm?.includes("(LSS)-AIIB"));
            }
            return (
                <>
                    <div className="row" data-testid="fundinfo">
                        <div className="col d-flex justify-content-center justify-content-md-end fpap-funds-found">
                            {fundList.length} {t("fundInfo.fundsFound")}
                        </div>
                    </div>
                    <hr className="fpap-hr-2" />
                    {props?.scheme?.includes(HKAIIB) && lssFund &&
                        <>
                            <div className="row">
                                <div className="col-12">
                                    <div className="pds-card fpap-card">
                                        <div className="fpap-card-body">
                                            <div className="fpap-card-subttl">
                                                {t("fundInfo.constituentFunds")}
                                            </div>
                                            <div className="d-flex justify-content-between flex-wrap">
                                                <div className="fpap-card-title">{i18n.language === 'en' ? FormatUtils.formatFundName(lssFund?.legalNm) : lssFund?.othLegalNm ?? lssFund?.legalNm}</div>
                                                <div>
                                                    <button type="button" className="fpap-learn-btn" data-toggle="modal" data-target="#lssModal">
                                                        {t("common.learnMore")}
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="fpap-card-note">
                                                The unit price of this fund will not be shown.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr className="fpap-hr-2" />
                            <LssPopUp />
                        </>
                    }
                    <div className="row fpap-title-row fpap-info-mg">
                        <div className={props.scheme != SMP ? "col-12 col-md-6 pl-0" : "col-12"}>
                            <div className="row">
                                <div className="col-8 col-lg-10 fpap-sub-title">
                                    <div>{t("fundInfo.constituentFunds")}{(!(props.isOrsoAccount) && props.scheme == schemeS800) && <sup>^^</sup>} </div>
                                    <div>{t("fundInfo.unitPrice")}<sup>*</sup></div>
                                </div>
                                <div className="col-4 col-lg-2 fpap-risk-ttl fpap-sub-title align-self-end pr-lg-0">{t("fundInfo.riskReturn")}<sup>#</sup></div>
                            </div>
                        </div>
                        {props.scheme != SMP && <div className="col-12 col-md-6 fpap-sub-title align-self-end pd-l-0">{t("fundInfo.performance")}<sup>**</sup></div>}
                    </div>
                    {fundList.map((fund, index) => {
                        const bgColor = (index + 1) % 2 === 0 ? 'fpap-bg-wht' : 'fpap-bg-blue';
                        const fundRisk = FundPerformanceUtil.getFundRisk(props?.scheme, fund.invRskNm, t);
                        const productTypeNmbr = props?.quickPolicyInfo[props?.selectedPolicyIndex]?.product?.productTypeNmbr;
                        const cobrandGUA = props?.scheme === SMP && fund?.invTypeNmbr == 805;
                        let invSymbol = false;
                        if (productTypeNmbr == 129) {
                            invSymbol = (fund?.invTypeNmbr == 808);
                        }
                        if (productTypeNmbr == 130) {
                            invSymbol = (fund?.invTypeNmbr == 802 || fund?.invTypeNmbr == 803);
                        }
                        return (
                            <div className={`row fpap-info fpap-info-mg ${bgColor}`} key={index} >
                                <div className="col-12 px-0">
                                    <div className="row">
                                        <div className="col">
                                            <div className="d-flex">
                                                <div className="fpap-fund-name" onClick={() => { changePage(fund); }} data-testid={index}>
                                                    {i18n.language === 'en' ? FormatUtils.formatFundName(fund?.legalNm) : fund?.othLegalNm ?? fund?.legalNm} {invSymbol ? "##" : cobrandGUA ? "**" : ''}
                                                </div>
                                                {fund.isNew && <div className=""><span className="fpap-new-label">{t("fundInfo.new")}</span></div>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={props.scheme != SMP ? "col-12 col-md-6 pl-0" : "col-12"}>
                                    <div className="row">
                                        <div className="col-8 col-lg-10 fpap-fund-pt d-flex align-self-center">
                                            <div className="mr-2">
                                                <span className="d-flex">
                                                    {((fund.unitPriceAmt && !cobrandGUA) ?
                                                        props.scheme == SMP ? FormatUtils.formatToDecimalFix(fund.unitPriceAmt, 2) : parseFloat(fund.unitPriceAmt).toFixed(decimalPlaces)
                                                        :
                                                        t("common.responseNA")
                                                    )}
                                                    {cobrandGUA ? <p className="fpap-sub-title">^</p> : ''}
                                                </span>
                                            </div>
                                            {!cobrandGUA && <span className="fpap-hkd my-auto">{(fund?.currencyTypeNm?.includes(HONG_KONG_DOLLAR) ? t("currencies.hkd") : fund?.currencyTypeNm?.includes(US_DOLLAR) ? t("currencies.usd") : '')}</span>}
                                        </div>
                                        <div className="col-4 col-lg-2 fpap-risk-ttl align-self-sm-end fpap-spect-n-mg">
                                            <div><img className="fpap-spectrum" src={fundRisk.spectrum} /></div>
                                            <div className={`fpap-risk-name ${fundRisk.color}`}>{fundRisk.risk}</div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col fpap-sub-pts fpap-dt-mg-top">
                                            {t("fundInfo.asOf")} {moment(fund?.asOfDate, 'DD/MM/YYYY').format(i18n.language === 'en' ? 'D MMM YYYY' : 'YYYY年M月D日')}
                                        </div>
                                    </div>
                                </div>
                                {props.scheme != SMP && <div className="col-12 col-md-6  pd-l-0">
                                    <div className="row fpap-mg-top">
                                        <div className="col brdr-right">
                                            <div className="fpap-sub-pts fpap-bold fpap-yearly">{t("fundInfo.ytd")}</div>
                                            <div className={`fpap-pc ${isNaN(parseFloat(fund?.performance?.crYtdTxt)) ? 'fpap-pc-na' : parseFloat(fund?.performance?.crYtdTxt) < 0 ? 'fpap-high' : 'fpap-low'}`}>{isNaN(parseFloat(fund?.performance?.crYtdTxt)) ? t("common.responseNA") : fund?.performance?.crYtdTxt + percent}{displayCarat(fund?.invTypeNm) ? '^' : ''}</div>
                                        </div>

                                        <div className="col brdr-right">
                                            <div className="fpap-sub-pts fpap-bold fpap-yearly">{t("fundInfo.yt1")}</div>
                                            <div className={`fpap-pc ${isNaN(parseFloat(fund?.performance?.cr1yTxt)) ? 'fpap-pc-na' : parseFloat(fund?.performance?.cr1yTxt) < 0 ? 'fpap-high' : 'fpap-low'}`}>{isNaN(parseFloat(fund?.performance?.cr1yTxt)) ? t("common.responseNA") : fund?.performance?.cr1yTxt + percent}{displayCarat(fund?.invTypeNm) ? '^' : ''}</div>
                                        </div>

                                        <div className="col brdr-right">
                                            <div className="fpap-sub-pts fpap-bold fpap-yearly">{t("fundInfo.yt3")}</div>
                                            <div className={`fpap-pc ${isNaN(parseFloat(fund?.performance?.cr3yTxt)) ? 'fpap-pc-na' : parseFloat(fund?.performance?.cr3yTxt) < 0 ? 'fpap-high' : 'fpap-low'}`}>{isNaN(parseFloat(fund?.performance?.cr3yTxt)) ? t("common.responseNA") : fund?.performance?.cr3yTxt + percent}{displayCarat(fund?.invTypeNm) ? '^' : ''}</div>
                                        </div>

                                        <div className="col pr-0">
                                            <div className="fpap-sub-pts fpap-bold fpap-yearly">{t("fundInfo.yt5")}</div>
                                            <div className={`fpap-pc ${isNaN(parseFloat(fund?.performance?.cr5yTxt)) ? 'fpap-pc-na' : parseFloat(fund?.performance?.cr5yTxt) < 0 ? 'fpap-high' : 'fpap-low'}`}>{isNaN(parseFloat(fund?.performance?.cr5yTxt)) ? t("common.responseNA") : fund?.performance?.cr5yTxt + percent}{displayCarat(fund?.invTypeNm) ? '^' : ''}</div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col fpap-sub-pts fpap-dt-mg-top">
                                            {fund?.asOfFlag ? "" : `${t("common.dataAsOf")} ${(fund?.performance?.asOfDate) === notApplicable ? "" : moment(fund?.performance?.asOfDate, 'DD/MM/YYYY').format(i18n.language === 'en' ? 'D MMM YYYY' : 'YYYY年M月D日')}`}
                                        </div>
                                    </div>
                                </div>}
                            </div>
                        );
                    })}
                </>
            );
        } else {
            <div className="fpap-record-not-found">{t("contribution.recordNotFound")}</div>;
        }
    };

    const sortData = (fundList, sortOption) => {
        const fundListSort = [...fundList];
        if (sortOption === t("fundInfo.sortOptions.1")) {
            return fundListSort.sort(SortUtils.dynamicsort("unitPriceAmt", "desc"));
        } else {
            return fundListSort;
        }
    };

    const oldSchemeData = (fundInfoData) => {
        let fundInfo = [];
        if (props.npExpence === '1') {
            fundInfo = fundInfoData.filter((fund) => !fund.invTypeNm.includes('2_') && fund.legalNm != DISfundName);
        } else {
            fundInfo = fundInfoData.filter((fund) => !FundPerformanceUtil.isDuplicateFund(fundInfoData, fund) && fund.legalNm != DISfundName);
        }
        return fundInfo;
    };

    useEffect(() => {
        if (selectedScheme !== null) {
            let schemeDataJson = props.schemeJson;

            let reqHeaders = {
                'pisys_auth_token': props.pisysToken,
                'username': props.clientNmbr,
                'Access-Control-Allow-Origin': '*'
            };
            const selectedQuickPolicyInfo = props?.quickPolicyInfo[props.selectedPolicyIndex];
            const contractNmbr = selectedQuickPolicyInfo?.contract?.contractNmbr ?? 0;
            const productTypeNmbr = FundPerformanceUtil.getSchemeNumber(parseInt(selectedScheme));

            let webCycleDt = props?.webCycleDate && moment(props.webCycleDate, "YYYY-MM-DD HH:mm:ss A").toDate();
            var initialToDate = new Date(webCycleDt);
            if (selectedScheme != 0) {
                initialToDate = moment(FundPerformanceUtil.getOldDate(parseInt(selectedScheme)), "DD/MM/YYYY").toDate();
            }
            initialToDate.setHours(0, 0, 0, 0);
            webCycleDt = moment(initialToDate).format("YYYY-MM-DD");
            let schemename;
            if (selectedScheme == 0) {
                schemename = "is800";
            } else if (selectedScheme == 1) {
                schemename = "is600";
            } else if (selectedScheme == 2) {
                schemename = "is200";
            }
            else if (selectedScheme == 3) {
                schemename = "is500";
            }

            if (props.schemeJson && props.schemeJson?.contractNmbr === contractNmbr && props.schemeJson?.productTypeNmbr === productTypeNmbr && props.schemeJson[schemename] && props.schemeJson[schemename].length > 0) {
                let fundData = props.schemeJson[schemename];
                setServiceCallFlag(false);
                setFundList(fundData);
                setFilteredFundList(fundData);
            } else {
                setServiceCallFlag(true);
                props.dispatch(saveFundInfoContractNmbr(contractNmbr));
                props.dispatch(saveFundInfoProductNmbr(productTypeNmbr));
                let fundInfoRes = getFundInfo(props, contractNmbr, productTypeNmbr, moment(webCycleDt).format("YYYY-MM-DD"), reqHeaders, parseInt(selectedScheme));
                fundInfoRes.then((fundInfo) => {
                    let fundInfoData = fundInfo;
                    if (selectedScheme == 0) {
                        fundInfoData = fundInfoData.filter((fund) => fund?.isActiveFund);
                    } else {
                        fundInfoData = oldSchemeData(fundInfoData);
                    }

                    fundInfoData.sort(SortUtils.dynamicsort("legalNm", "asc"));
                    SortUtils.riskOrdering(fundInfoData, "asc");
                    setFilteredFundList(fundInfoData);
                    setFundList(fundInfoData);
                    setServiceCallFlag(false);
                    if (selectedScheme == 0 && fundInfoData) {
                        schemeDataJson = fundInfoData;
                        props.dispatch(saveSchemeData800(schemeDataJson));
                    } else if (selectedScheme == 1 && fundInfoData) {
                        schemeDataJson = fundInfoData;
                        props.dispatch(saveSchemeData600(schemeDataJson));
                    } else if (selectedScheme == 2 && fundInfoData) {
                        schemeDataJson = fundInfoData;
                        props.dispatch(saveSchemeData200(schemeDataJson));
                    } else if (selectedScheme == 3 && fundInfoData) {
                        schemeDataJson = fundInfoData;
                        props.dispatch(saveSchemeData500(schemeDataJson));
                    }
                }).catch(() => {
                    if (selectedScheme == 0) {
                        props.dispatch(saveSchemeData800({}));
                    } else if (selectedScheme == 1) {
                        props.dispatch(saveSchemeData600({}));
                    } else if (selectedScheme == 2) {
                        props.dispatch(saveSchemeData200({}));
                    } else if (selectedScheme == 3) {
                        props.dispatch(saveSchemeData500({}));
                    }
                });
            }
        }
    }, [selectedScheme]);

    useEffect(() => {
        let schemeDataJson = {};

        let reqHeaders = {
            'pisys_auth_token': props.pisysToken,
            'username': props.clientNmbr,
            'Access-Control-Allow-Origin': '*'
        };
        const selectedQuickPolicyInfo = props?.quickPolicyInfo[props.selectedPolicyIndex];
        const contractNmbr = selectedQuickPolicyInfo?.contract?.contractNmbr;
        const productTypeNmbr = selectedQuickPolicyInfo?.product?.productTypeNmbr;
        if (props.isMerger && schemes.length === 0) {
            let sch = FundPerformanceUtil.getschemeList(props.mergerAttributes, getInvested(props.policyList));
            sch.sort();
            setSchemes(sch);
            setSelectedScheme(0);
        } else {
            const schemename = "is800";
            if (props.schemeJson && props.schemeJson?.contractNmbr === contractNmbr && props.schemeJson?.productTypeNmbr === productTypeNmbr && props.schemeJson[schemename] && props.schemeJson[schemename].length > 0) {
                let fundData = props.schemeJson[schemename];
                setServiceCallFlag(false);
                setFundList(fundData);
                setFilteredFundList(fundData);
            } else {
                setServiceCallFlag(true);
                props.dispatch(saveFundInfoContractNmbr(contractNmbr));
                props.dispatch(saveFundInfoProductNmbr(productTypeNmbr));
                setSelectedScheme(0);
                let webCycleDt = props?.webCycleDate && moment(props.webCycleDate, "YYYY-MM-DD HH:mm:ss A").toDate();
                webCycleDt.setHours(0, 0, 0, 0);

                const fundInfoRes = getFundInfo(props, contractNmbr, productTypeNmbr, moment(webCycleDt).format("YYYY-MM-DD"), reqHeaders, 0);

                fundInfoRes.then((fundInfo) => {
                    const fundInfoData = fundInfo.filter((fund) => fund?.isActiveFund);
                    fundInfoData.sort(SortUtils.dynamicsort("legalNm", "asc"));
                    SortUtils.riskOrdering(fundInfoData, "asc");
                    setFundList(fundInfoData);
                    setFilteredFundList(fundInfoData);
                    setServiceCallFlag(false);
                    schemeDataJson = fundInfoData;
                    props.dispatch(saveSchemeData800(schemeDataJson));
                }).catch(() => {
                    setFundList([]);
                    setFilteredFundList([]);
                    setServiceCallFlag(false);
                });
            }
            setSelectedScheme(null);
        }
    }, []);

    let finalFundList = filteredFundList?.filter((fund) => !((fund?.invTypeNm)?.toUpperCase()?.includes("TERM")));
    const currentDateHk = new Date();
    const hkTimeString = currentDateHk.toLocaleString(enGB, { timeZone: asiaHongKong });
    const hkTime = moment(hkTimeString, DD_MM_YYYY_HH_mm_ss, true);
      // eslint-disable-next-line no-undef
    const startDate = moment(process.env.REACT_APP_GFC_WORDINGS_DISABLE_START_DATE, DD_MM_YYYY_HH_mm_ss, true);
    const isSmartProduct = (props?.quickPolicyInfo[props.selectedPolicyIndex]?.product?.productTypeNmbr === 130);
    return (
        <>
            <Logger logImpression data={{ page: FUND_INFO }}>
                <div className="fpap-content">
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <h1 className="fpap-title">{t("fundInfo.pageTitle")}</h1>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12 col-md-6 fpap-desc">
                                {t("fundInfo.pageDescription")}
                            </div>
                        </div>
                        {props.isMerger &&
                            <div className="row" data-testid='selectsc'>
                                <div className="col-12 col-md-6 fpap-scheme-name">
                                </div>
                            </div>
                        }
                    </div>

                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-md-3">
                                <label className="fpap-label">{t("feesAndCharges.riskLevel")}</label>
                                <select id="selectRiskPlaceholder" aria-label="Select placeholder" data-testid="selectRisk" name="selectRisk" onChange={(e) => { setSelectedRiskType(e.target.value); }} className="rsc-select fpap-select-arrow  fpap-select">

                                    {props?.scheme === 'SMP' ?
                                        riskTypeSmp.map((risk, i) => (
                                            <option key={i} value={risk} data-testid="riskOption">
                                                {risk}
                                            </option>))
                                        : riskType.map((risk, i) => (
                                            <option key={i} value={risk} data-testid="riskOption">
                                                {risk}
                                            </option>
                                        ))}
                                </select>
                            </div>
                            <div className="col-12 col-md-3">
                                <label className="fpap-label">{t("fundInfo.sortBy")}</label>
                                <select id="selectSortPlaceholder" aria-label="Select placeholder" data-testid="selectSort" name="selectSort" onChange={(e) => { setSelectedSort(e.target.value); }} className="rsc-select fpap-select-arrow  fpap-select">
                                    {sortOptions.map((sortBy, i) => (
                                        <option key={i} value={sortBy} data-testid="sortOption">
                                            {sortBy}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        {serviceCallFlag === true ? (
                            <div className="d-flex justify-content-center">
                                <div className="spinner-border spinner m-5" role="status">
                                    <span className="sr-only"></span>
                                </div>
                            </div>
                        ) :
                            Array.isArray(finalFundList) && finalFundList.length > 0 ?
                                renderFundInfo(t, finalFundList, i18n)
                                :
                                <div className="fpap-record-not-found">{t("contribution.recordNotFound")}</div>
                        }
                    </div>
                    {
                        Array.isArray(finalFundList) && finalFundList.length > 0 &&
                        <>
                            <hr className="fpap-hr-3" />
                            <div className="container">
                                {props?.scheme === SMP ?
                                    <>
                                        <div className="row">
                                            <div className="col fpap-note">
                                                <p>{t("fundInfo.noteSmartPara1")}</p>
                                                <p>{isSmartProduct ? t("fundInfo.noteSmartPara2") : t("fundInfo.noteSimplePara6")}</p>
                                                <p>{isSmartProduct && (hkTime.toDate() < startDate.toDate())&& t("fundInfo.noteSmartPara3")}</p>
                                                <p>{isSmartProduct && t("fundInfo.noteSmartPara4")}</p>
                                            </div>
                                        </div>
                                        <div className="row fpap-spectrum-mg">
                                            <div className="col-12 col-md-5">
                                                <img src={riskSpectrumSmp} className="fpap-smp-rectangle-spectrum" />
                                                <div className="row fpap-rectangle-spectrum-text">
                                                    <div className="col">{t("feesAndCharges.riskTypeSmp.1")}</div>
                                                    <div className="col">{t("feesAndCharges.riskTypeSmp.2")}</div>
                                                    <div className="col">{t("feesAndCharges.riskTypeSmp.3")}</div>
                                                    <div className="col">{t("feesAndCharges.riskTypeSmp.4")}</div>
                                                    <div className="col">{t("feesAndCharges.riskTypeSmp.5")}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col fpap-note">
                                                <p># {t("fundInfo.smapImpNote9")}</p>
                                                <p>{t("fundInfo.noteSimplePara1")}</p>
                                                <p>{t("fundInfo.notePara7")}</p>
                                                <p>{t("fundInfo.notePara8")}</p>
                                                <p>{t("fundInfo.notePara9")}</p>
                                            </div>
                                        </div>
                                    </>
                                    :
                                    <>
                                        <div className="row">
                                            <div className="col fpap-note">
                                                <p>{t("fundInfo.notePara1")}</p>
                                                <p>{t("fundInfo.notePara2")}</p>
                                                <p>{t("fundInfo.notePara3")}</p>
                                                {!(props.isOrsoAccount || props.scheme == HKAIIB) && <p>{t("fundInfo.notePara4")}</p>}
                                                <p>{t("fundInfo.notePara5")}</p>
                                            </div>
                                        </div>
                                        <div className="row fpap-spectrum-mg">
                                            <div className="col-12 col-md-5">
                                                <img src={riskSpectrum} className="fpap-rectangle-spectrum" />
                                                <div className="row fpap-rectangle-spectrum-text">
                                                    <div className="col">{t("feesAndCharges.riskType.1")}</div>
                                                    <div className="col">{t("feesAndCharges.riskType.2")}</div>
                                                    <div className="col">{t("feesAndCharges.riskType.3")}</div>
                                                    <div className="col">{t("feesAndCharges.riskType.4")}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col fpap-note">
                                                {!(props.isOrsoAccount || props.scheme == HKAIIB) ?
                                                    <>
                                                        <p>{t("fundInfo.notePara6")}</p>
                                                        <p>{t("fundInfo.notePara7")}</p>
                                                        <p>{t("fundInfo.notePara8")}</p>
                                                        <p className="fpap-last-note-mgb">{t("fundInfo.notePara9")}</p>
                                                    </>
                                                    :
                                                    <>
                                                        <p className="fpap-last-note-mgb">{t("fundInfo.notePara10")}</p>
                                                    </>
                                                }
                                            </div>
                                        </div>
                                    </>
                                }
                            </div>
                        </>
                    }
                </div>
            </Logger>
        </>
    );
};

FundPricesAndPerformance.propTypes = {
    policyList: PropTypes.array,
    isMerger: PropTypes.bool,
    mergerAttributes: PropTypes.array,
    quickPolicyInfo: PropTypes.array,
    pisysToken: PropTypes.string,
    props: PropTypes.any,
    dispatch: PropTypes.func,
    schemeJson: PropTypes.arrayOf(PropTypes.object),
    contractNmbr: PropTypes.string,
    webCycleDate: PropTypes.string,
    selectedPolicyIndex: PropTypes.number,
    setCurrentPage: PropTypes.func,
    cycleDate: PropTypes.string,
    npExpence: PropTypes.string,
    loginId: PropTypes.string,
    scheme: PropTypes.string,
    clientNmbr: PropTypes.string,
    isOrsoAccount: PropTypes.bool
};
const mapStateToProps = (state) => {
    return {
        policyList: state.twoAccounts.allPolicyList,
        isMerger: state.mergerInfo.isMerger,
        mergerAttributes: state.mergerInfo.mergerAttributes,
        quickPolicyInfo: state.twoAccounts.quickPolicyInfo,
        pisysToken: state.token.pisysToken,
        schemeJson: state.fundPerformance,
        contractNmbr: state.empLogin.contractNmbr,
        webCycleDate: state.empLogin.webCycleDate,
        selectedPolicyIndex: state.twoAccounts.selectedPolicyIndex,
        cycleDate: state.landing.cycleDate,
        npExpence: state.mergerInfo.npExpence,
        loginId: state.empLogin.loginId,
        scheme: state.empLogin.scheme,
        clientNmbr: state.empLogin.clientNmbr,
        isOrsoAccount: state.twoAccounts.isOrsoAccount
    };
};
export default connect(mapStateToProps)(FundPricesAndPerformance);