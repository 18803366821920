import { SAVE_GAIN_LOSS_DATA, SAVE_TOTAL_ACCOUNT_BALANCE, SAVE_CUSTOM_FIELDS, SAVE_CUSTOM_FIELDS_ARRAY, SAVE_POPUP_ALERT_STATUS, IS_AGENCY_PLAN } from "../actions/ActionConstants";

const initialState = {
    gainLossData: {},
    customFields: [],
    customFieldsArray: [],
    popUpAlertStatus: false,
    isAgencyPlan: false
};

export default function dashboardReducer(state = initialState, action) {
    switch (action.type) {
        case SAVE_GAIN_LOSS_DATA:
            return { ...state, gainLossData: action.data };
        case SAVE_TOTAL_ACCOUNT_BALANCE:
            return { ...state, totalAmt: action.data };
        case SAVE_CUSTOM_FIELDS:
            return { ...state, customFields: action.data };
        case SAVE_CUSTOM_FIELDS_ARRAY:
            return { ...state, customFieldsArray: action.data };
        case SAVE_POPUP_ALERT_STATUS:
            return { ...state, popUpAlertStatus: action.data };
        case IS_AGENCY_PLAN:
            return { ...state, isAgencyPlan: action.data };
        default:
            return state;
    }
}