import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import "../css/fundSwitchOutStyle.css";
import { SMP } from '../translations/common/commonConstants';

const RiskReturnSpectrum = (props) => {
    const { t } = useTranslation("common");
    const S800Spectrum = <div className="row under_part polygon-progress-top mx-0">
        <div className="col-3 tenth_separator">
            <label className="progressBarLow">{t('fundSwitchOut.Low')}</label>
        </div>
        <div className="col-3 tenth_separator"></div>
        <div className="col-3 tenth_separator"></div>
        <div className="col-3 tenth_separator"><label className="progressBarhigh">{t('fundSwitchOut.High')}</label></div>
    </div>
    const SsSpectrum = <div className="row ss-spectrum polygon-progress-top mx-0">
        <div className="col tenth_separator ">
            <label className="progressBarLow">{t('fundSwitchOut.Low')}</label>
        </div>
        <div className="col tenth_separator"></div>
        <div className="col tenth_separator"></div>
        <div className="col tenth_separator"></div>
        <div className="col tenth_separator">
            <label className="progressBarhigh">{t('fundSwitchOut.High')}</label>
        </div>
    </div>

    let spectrum = props.scheme == SMP ? SsSpectrum : S800Spectrum;

    return spectrum;
}

const mapStateToProps = (state) => {
    return {
        scheme: state.empLogin.scheme
    }
}
export default connect(mapStateToProps)(RiskReturnSpectrum)