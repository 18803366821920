import React from 'react'
import PropTypes from 'prop-types'

const SpinnerButton = (props) => {
    return props.serviceCallFlag ? <span className="spinner-border spinner-border-sm verify-spinnerStyle" role="status" aria-hidden="true"></span> : props.label
}

SpinnerButton.propTypes = {
    label: PropTypes.string,
    serviceCallFlag: PropTypes.bool
}

export default SpinnerButton;