import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import Breadcrumbs from "../../SharedComponents/Breadcrumbs";
import Header from "../../SharedComponents/Header";
import FundPricesAndPerformance from "./FundPricesAndPerformance";
import PGEFClassAccUnits from './PGEFClassAccUnits'
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { saveRedirectPricePerformancePage, setFundDetailRedirection} from '../../actions/FundPerformanceAction';
import { FormatUtils } from '../../utils';

const renderBreadCrumb = (t, currentPage, setcurrentPage, selectEnglishFundName, selectChineseFundName, i18n) => {
  const history = useHistory();
  let propArr = [];
  if (currentPage === 'pricesAndPerformance') {
    propArr = [
      {
        title: t("titles.dashboard"),
        active: true,
        route: '/'
      },
      {
        title: t("titles.fundInfo"),
        active: true,
        route: 'pricesAndPerformance'
      },
      {
        title: t("fundInfo.pageTitle"),
        active: false,
        route: 'pricesAndPerformance'
      }
    ]
  } else if (currentPage === 'pGEFClassAccUnits') {
    propArr = [
      {
        title: t("titles.dashboard"),
        active: true,
        route: '/'
      },
      {
        title: t("titles.fundInfo"),
        active: true,
        route: 'pricesAndPerformance'
      },
      {
        title: t("fundInfo.pageTitle"),
        active: true,
        route: 'pricesAndPerformance'
      },
      {
        title: i18n.language === 'en' ? FormatUtils.formatFundName(selectEnglishFundName) : selectChineseFundName,
        active: false,
        route: 'pGEFClassAccUnits'
      }
    ]
  } else if (currentPage === '/') {
    history.push("/dashboard");
  }


  return (<Breadcrumbs setCurrentPage={(route) => { setcurrentPage(route) }} currentPage={currentPage} breadCumbInfo={propArr}></Breadcrumbs>)
}

const renderPageContent = (currentPage, setcurrentPage) => {
  window.scrollTo(0, 0)
  const history = useHistory();
  const location = useLocation();

  if (currentPage !== 'pricesAndPerformance' && location.resetPage) {
    history.push({ resetPage: false })
    setcurrentPage('pricesAndPerformance')
  } else if (location.resetPage) {
    history.push({ resetPage: false })
  }

  if (currentPage === 'pricesAndPerformance') {
    return (<FundPricesAndPerformance setCurrentPage={setcurrentPage} />)
  } else if (currentPage === 'pGEFClassAccUnits') {
    return (<PGEFClassAccUnits setCurrentPage={setcurrentPage} />)
  }
}

const FundInfo = (props) => {
  const { t, i18n } = useTranslation("common");
  const [currentPage, setcurrentPage] = useState('pricesAndPerformance');
  let [selectEnglishFundName, setSelectEnglishFundName] = useState('');
  let [selectChineseFundName, setSelectChineseFundName] = useState('');
  useEffect(() => {
    if (props?.fundPricePerformance?.invTypeNmbr) {
      let selectedFundInfoData = props.fundInfoData.find(fundInfoGlobalServiceObj => fundInfoGlobalServiceObj.invTypeNmbr === props.fundPricePerformance.invTypeNmbr && fundInfoGlobalServiceObj.isActiveFund === true);
      setSelectEnglishFundName(selectedFundInfoData?.legalNm ? selectedFundInfoData.legalNm: '');
      setSelectChineseFundName(selectedFundInfoData?.othLegalNm ? selectedFundInfoData.othLegalNm: '');
    }
  }, [props.fundPricePerformance])
  useEffect(() => {
    props.dispatch(saveRedirectPricePerformancePage(false));
    setcurrentPage('pricesAndPerformance');
  }, [props.priceAndPerformancePage === true && currentPage !== 'pricesAndPerformance'])

  useEffect(() => {
    if(props.redirectFundInfoDetails === true && currentPage !== 'pGEFClassAccUnits') {
      props.dispatch(setFundDetailRedirection(false));
      props.dispatch(saveRedirectPricePerformancePage(false));
      setcurrentPage('pGEFClassAccUnits');
    }
  }, [props.redirectFundInfoDetails])

  return (
    <>
      <Header />
      {renderBreadCrumb(t, currentPage, setcurrentPage, selectEnglishFundName, selectChineseFundName, i18n)}
      {renderPageContent(currentPage, setcurrentPage)}
    </>
  );
};
FundInfo.propTypes = {
  props: PropTypes.any,
  fundPricePerformance: PropTypes.object,
  dispatch: PropTypes.func,
  fundInfoData: PropTypes.arrayOf(PropTypes.object),
  priceAndPerformancePage: PropTypes.bool,
  redirectFundInfoDetails: PropTypes.bool
};

const mapStateToProps = (state) => {
  return {
    priceAndPerformancePage: state.fundPerformance.priceAndPerformancePage,
    fundPricePerformance: state.fundPerformance.fundPricePerformance,
    fundInfoData: state.fundSwitch.fundInfoData,
    redirectFundInfoDetails: state.fundPerformance.redirectFundInfoDetails
  };
};

export default connect(mapStateToProps)(FundInfo);
