import { saveCustomContractField } from "../actions/contractCustomFeildAction";
import { isAgencyPlan } from "../actions/DashboardAction";
import { saveMergerAttributes, saveNbExpence, setIsMerger } from "../actions/MergerInfoAction";
import contractInfoApi from "../API/ContractInfo/contractInfoApi";
import { chngDate, DDMMYYYY, descendingOrder, effectiveDate } from "../translations/common/commonConstants";
import SortUtils from "./SortUtils";


const getContractInfo = async (props, contractNmbr) => {
    let reqHeaders = {
        'pisys_auth_token': props.pisysToken,
        'userName': props.clientNmbr,
        'Access-Control-Allow-Origin': '*'
    };

    let mergerInfo = [];
    await contractInfoApi.getContractInfo((String(contractNmbr)), reqHeaders)
        .then((response) => {
            if (response.status === 200) {
                let decodedResponse = ((response.data));
                props.dispatch(saveCustomContractField(decodedResponse ?? []));
                const agencyPlanStatus = getAgencyPlanStatus(decodedResponse ?? []);
                props.dispatch(isAgencyPlan(agencyPlanStatus));
                let nbExpance = decodedResponse.filter((data) => data.cstmAttrCd === 115);
                props.dispatch(saveNbExpence(nbExpance?.[0]?.customDataDetailsList?.[0]?.cstmAttrTxt ? nbExpance[0].customDataDetailsList[0].cstmAttrTxt : ''));
                mergerInfo = decodedResponse.filter((data) => data.cstmAttrCd === 218);
                props.dispatch(setIsMerger(mergerInfo?.[0]?.customDataDetailsList?.length > 0 && true));
                props.dispatch(saveMergerAttributes(mergerInfo?.[0]?.customDataDetailsList ?? []));
            }
        });
    return mergerInfo?.[0]?.customDataDetailsList ?? [];
};

const saveContractInfo = (props, contractInfo) => {
    if (Array.isArray(contractInfo) && contractInfo.length > 0) {
        let mergerInfo = [];
        props.dispatch(saveCustomContractField(contractInfo ?? []));
        const agencyPlanStatus = getAgencyPlanStatus(contractInfo ?? []);
        props.dispatch(isAgencyPlan(agencyPlanStatus));
        const nbExpance = contractInfo.filter((data) => data.cstmAttrCd === 115);
        props.dispatch(saveNbExpence(nbExpance?.[0]?.customDataDetailsList?.[0]?.cstmAttrTxt ?? ''));
        mergerInfo = contractInfo.filter((data) => data.cstmAttrCd === 218);
        props.dispatch(setIsMerger(mergerInfo?.[0]?.customDataDetailsList?.length > 0 && true));
        props.dispatch(saveMergerAttributes(mergerInfo?.[0]?.customDataDetailsList ?? []));
    }
};

const getAgencyPlanStatus = (contractCustomFields) => {
    const agencyPlanList = contractCustomFields?.filter((contract) => (contract?.cstmAttrCd == 287));
    let agencyPlan = "", agencyPlanStatus = false;
    if (0 < agencyPlanList?.length) {
        if (0 < agencyPlanList?.[0]?.customDataDetailsList?.length) {
            let sortednSchemeTypeList = agencyPlanList?.[0]?.customDataDetailsList?.sort(SortUtils.sortOnDate(chngDate, descendingOrder, DDMMYYYY));
            sortednSchemeTypeList = agencyPlanList?.[0]?.customDataDetailsList?.sort(SortUtils.sortOnDate(effectiveDate, descendingOrder, DDMMYYYY));
            agencyPlan = sortednSchemeTypeList?.[0]?.cstmAttrTxt;
        }
    }
    if (agencyPlan == "Y") {
        agencyPlanStatus = true;
    }
    return agencyPlanStatus;
};

export { getAgencyPlanStatus, getContractInfo, saveContractInfo };

