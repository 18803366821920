import { SAVE_TRANSACTION_CONTRIBUION_HISTORY } from "../actions/ActionConstants";

const initialState = {
    transactionContributionHistory: [
        {
            fundName: 'Principal Hong Kong Good Fund',
            openingBalanceInfo: {
                date: '1 Feb 2021',
                openingBalance: 'HKD 60,000.00',
                unitPrice: 'HKD 16.0295608',
                noOfUnit: '4,233.235'
            },
            transactionInfo: [{
                date: '1 Feb 2021',
                typeOfTransaction: 'Contribution Invested - acquistion',
                amoutOfHkId: 'HKD 690.00',
                unitPrice: 'HKD 16.0295608',
                noOfUnit: '4,233.235'
            }, {
                date: '1 Feb 2021',
                typeOfTransaction: 'Unit Credit - acquisition',
                amoutOfHkId: 'HKD 690.00',
                unitPrice: 'HKD 16.0295608',
                noOfUnit: '4,233.235'
            }, {
                date: '1 Feb 2021',
                typeOfTransaction: 'Unit Credit - acquisition ',
                amoutOfHkId: 'HKD 690.00',
                unitPrice: 'HKD 16.0295608',
                noOfUnit: '4,233.235'
            },],
            totalNoOfUnits: '3,815.479',
            closingBalanceInfo: {
                date: '31 Mar 2021',
                closingBalance: 'HKD 62,027.00',
            }
        },
        {
            fundName: 'Principal HK Bond Fund',
            openingBalanceInfo: {
                date: '1 Feb 2021',
                openingBalance: 'HKD 60,000.00',
                unitPrice: 'HKD 16.0295608',
                noOfUnit: '4,233.235'
            },
            transactionInfo: [{
                date: '1 Feb 2021',
                typeOfTransaction: 'Contribution Invested - acquistion',
                amoutOfHkId: 'HKD 690.00',
                unitPrice: 'HKD 16.0295608',
                noOfUnit: '4,233.235'
            }, {
                date: '1 Feb 2021',
                typeOfTransaction: 'Unit Credit - acquisition',
                amoutOfHkId: 'HKD 690.00',
                unitPrice: 'HKD 16.0295608',
                noOfUnit: '4,233.235'
            }, {
                date: '1 Feb 2021',
                typeOfTransaction: 'Unit Credit - acquisition ',
                amoutOfHkId: 'HKD 690.00',
                unitPrice: 'HKD 16.0295608',
                noOfUnit: '4,233.235'
            },],
            totalNoOfUnits: '3,815.479',
            closingBalanceInfo: {
                date: '31 Mar 2021',
                closingBalance: 'HKD 62,027.00',
            }
        }
    ]
}

export default function transactionReducer(state = initialState, action) {
    switch (action.type) {
        case SAVE_TRANSACTION_CONTRIBUION_HISTORY:
            return { ...state, transactionContributionHistory: action.data };
        default:
            return state;
    }
}