import { RESET_PORTFOLIO_DETAILS, SAVE_CONTRIBUTION_DETAILS, SAVE_INTRAGRP_TRANSFER, SET_UPDATE_DONE } from "../actions/ActionConstants";

const initialState = {
    interGrpData: {},
    contributionDetails: {},
    updateDone: true
}

export default function portfolioReducer(state = initialState, action) {
    switch (action.type) {
        case SAVE_INTRAGRP_TRANSFER:
            return { ...state, interGrpData: action.data };
        case SAVE_CONTRIBUTION_DETAILS:
            return { ...state, contributionDetails: action.data };
        case SET_UPDATE_DONE:
            return { ...state, updateDone: action.data };
        case RESET_PORTFOLIO_DETAILS:
            return {interGrpData: {}, contributionDetails: {}, updateDone: true};
        default:
            return state;
    }
}