import React from "react";
import download from "../assets/Icon/Download.svg";
import sheetIcon from "../assets/Icon/Icons_Fund-fact-sheet.svg";
import "./DownloadFile.css";

const DownloadFile = (prop) => {
  return (
    <a
      href={prop.link}
      target="_blank"
      rel="noreferrer"
      className="downloadLink"
    >
      <div className="row bottom-border-line py-4 mx-auto">
        <div className="col-1 p-0 my-auto">
          <img src={sheetIcon} className="sheetImg" />
        </div>
        <div className="col-10 inner-label my-auto">{prop.text}</div>
        <div className="col-1 p-0 my-auto">
          <img src={download} />
        </div>
      </div>
    </a>
  );
};

export default DownloadFile;

