import { RESET_MERGER_INFO, SAVE_MERGER_ATTRIBUTES, SAVE_NB_EXPENCE, SET_IS_MERGER } from "./ActionConstants";

export function setIsMerger(data) {
    return { type: SET_IS_MERGER, data }
}
export function saveMergerAttributes(data) {
    return { type: SAVE_MERGER_ATTRIBUTES, data }
}
export function saveNbExpence(data) {
    return { type: SAVE_NB_EXPENCE, data }
}
export function resetMergerData() {
    return { type: RESET_MERGER_INFO }
}