import { instance } from '../AxiosInterceptor';

export default {
    authenticateMFA: (userLoginInfo) =>
        instance({
            'method': 'POST',
            'url': '/auth/authenticateMFA',
            data: userLoginInfo
        }),
    activateMFA: (userActivationInfo, requestheaders) =>
        instance({
            'method': 'POST',
            'url': '/auth/activateMFA',
            headers: requestheaders,
            data: userActivationInfo
        }),
    verifyMFA: (requestHeaders, verifyReqBody) =>
        instance({
            'method': 'POST',
            'url': '/auth/verifyMFA',
            headers: requestHeaders,
            data: verifyReqBody
        }),
    changePwd: (chgPwdBody, requestHeaders) =>
        instance({
            'method': 'POST',
            'url': '/auth/chgPwd',
            headers: requestHeaders,
            data: chgPwdBody
        }),
    updateAsMigrated: (userInfo, requestHeaders) =>
        instance({
            'method': 'POST',
            'url': '/auth/updateMigrated',
            headers: requestHeaders,
            data: userInfo
        }),
}