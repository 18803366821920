import {
    SAVE_CHINESE_DROP_DOWN_INFO, SAVE_COUNTRY_DROP_DOWN, SAVE_ENGLISH_DROP_DOWN_INFO,
    SAVE_FILTERED_CHINESE_DROP_DOWN_INFO, SAVE_FILTERED_ENGLISH_DROP_DOWN_INFO
} from './ActionConstants.js'
export function saveEnglishDropDownInfo(data) {
    return { type: SAVE_ENGLISH_DROP_DOWN_INFO, data }
}
export function saveChineseDropDownInfo(data) {
    return { type: SAVE_CHINESE_DROP_DOWN_INFO, data }
}
export function saveCountryDropDown(data) {
    return { type: SAVE_COUNTRY_DROP_DOWN, data }
}
export function saveFilteredEnglishDropDownInfo(data) {
    return { type: SAVE_FILTERED_ENGLISH_DROP_DOWN_INFO, data }
}
export function saveFilteredChineseDropDownInfo(data) {
    return { type: SAVE_FILTERED_CHINESE_DROP_DOWN_INFO, data }
}