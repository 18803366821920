import { instance } from '../AxiosInterceptor';

export default {
    superLogon: () =>
        instance({
            method: "GET",
            url: "/auth/superLogon/config"
        }),

    superLogonLogin: (authorizationCode, codeVerifier) =>
        instance({
            method: "POST",
            url: "/auth/superLogon/token",
            headers: {
                'Access-Control-Allow-Origin': '*',
            },
            data: {
                authorization_code: authorizationCode,
                code_verifier: codeVerifier,
            },
        }),

    clientSearch: (requestHeaders, loginID) =>
        instance({
            method: "GET",
            url: "/multiclientsearch",
            headers: requestHeaders,
            params: {
                loginID: loginID
            }
        }),
};