import { SAVE_AUTHENTICATION_FLAG } from "../actions/ActionConstants";

const initialState = {
    isAuthenticated: false,
}

export default function authenticationFlagReducer(state = initialState, action) {
    switch (action.type) {
        case SAVE_AUTHENTICATION_FLAG:
            return { ...state, isAuthenticated: action.data };
        default:
            return state;
    }
}