import React from "react";
import { useTranslation } from "react-i18next";
import ButtonTooltip from "../assets/Icon/Button_Tooltip_exclamation.svg";
import "../css/GainAndLossPopUp.css";

const GainAndLossPopUp = () => {
    const { t } = useTranslation("common");
    return (
        <>
            <span data-toggle="modal" data-target="#gainLossModal">
                <img src={ButtonTooltip} className="gl-icon" data-testid="tips-btn" alt="Gain and Loss Info"/>
            </span>

            <div className="modal fade gnl-modal-background" id="gainLossModal" tabIndex="-1" aria-labelledby="gainLossModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable gnl-modal-dlg">
                    <div className="modal-content gnl-modal-content">
                        <div className="modal-body gnl-modal-body">
                            <div className="gnl-modal-title gnl-bold ">{t('gainLossPopUp.title')}</div>
                            <div className="gnl-modal-info mb-4">
                                {t('gainLossPopUp.para1')}
                            </div>
                            <div className="gnl-modal-info mb-4">
                                <p className="mb-0">{t('gainLossPopUp.para2')}</p>
                                <p className="mb-0"><span className="gnl-bold">{t('dashboard.RGLoss')}</span> = {t('gainLossPopUp.accBalance')} - {t('gainLossPopUp.netContribution')}</p>
                            </div>
                            <div className="gnl-modal-info mb-4">
                                <p className="mb-0">{t('gainLossPopUp.para3')}</p>
                                <p className="mb-0"><span className="gnl-bold">{t('gainLossPopUp.gainLossRate')}</span> = ({t('gainLossPopUp.accBalance')} - {t('gainLossPopUp.netContribution')}) ÷ {t('gainLossPopUp.totalContribution')} x 100%</p>
                            </div>
                            <div className="gnl-modal-info">
                                <p className="mb-0">{t('gainLossPopUp.para4')}</p>
                                <p className="mb-0">{t('gainLossPopUp.para5')}</p>
                            </div>
                            <div className="row gnl-btn-mg d-flex justify-content-center">
                                <button type="button" className="gnl-btn col-md-6 col-12" data-dismiss="modal">
                                    {t('gainLossPopUp.iUnderstand')}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default GainAndLossPopUp;