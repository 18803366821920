import React from "react";
import Header from "../../SharedComponents/Header";
import SelectAccount from "./Dashboard/SelectAccount";

const Account = () => {
  return (
    <>
      <Header />
      <SelectAccount></SelectAccount>
    </>
  );
};
export default Account;
