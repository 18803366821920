import React from "react";
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import {  useHistory} from "react-router-dom";
function ImportantNote(props) {
  const { t } = useTranslation("common");
  const history = useHistory();
  
  return (
    <React.Fragment>
      <div className="row">
        <div className="info-body rsc-card" id="contact-section">
          <strong className="other-con-note-title">{t("importantNote.title")}</strong>
          <label className="other-con-note">{t("importantNote.Note")}
            {props.scheme == "HKAIIB" ? <a className="pointer-cursor" onClick={()=>{history.push('/documents')}}>www.principal.com.hk.</a> : <a target='_blank' rel="noreferrer" href={t("importantNote.hklink")} >www.principal.com.hk.</a>}
          </label>
        </div>
      </div>
    </React.Fragment>
  );
}

ImportantNote.propTypes = {
  props: PropTypes.any,
  scheme: PropTypes.string
};

export default ImportantNote;
