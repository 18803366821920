import PropTypes from "prop-types";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import Oval from '../../assets/Oval.png';
import "../../css/FundDetailsTitle.css";
import { CORE_ORSO_ACCOUNT_S800, DD_MM_YYYY_HH_mm_ss, HKAIIB, SMP, asiaHongKong, enGB, schemeS800 } from "../../translations/common/commonConstants";
import { FormatUtils, getFundClass } from "../../utils";
import moment from "moment";

const FundDetailsTitle = (props) => {
  let fund = props.fund;
  const { t, i18n } = useTranslation("common");
  const [cardExpanded, setcardExpanded] = useState(false);
  const selectedQuickPolicyInfo = props?.quickPolicyInfo[props.selectedPolicyIndex];
  const productTypeNum = selectedQuickPolicyInfo?.product?.productTypeNmbr;

  const minimizedCard = () => {
    return (
      <div className="container impnotes_section" data-testid="card_minimised">
        <div className="d-flex justify-content-between align-self-center py-4">
          <div className="impnotes_section_ip_text">
            {t("fundInfo.impNotes")}
          </div>
          <button className="fund_details_read_btn" onClick={() => { setcardExpanded(true); }} data-testid="read_button_min">{t("fundInfo.read")}</button>
        </div>
      </div>
    );
  };
  const currentDateHk = new Date();
  const hkTimeString = currentDateHk.toLocaleString(enGB, { timeZone: asiaHongKong });
  const hkTime = moment(hkTimeString, DD_MM_YYYY_HH_mm_ss, true);
  const orsoLtgfFundEndDate = moment(process.env.REACT_APP_GFC_ORSO_WORDINGS_FUND_END_DATE, DD_MM_YYYY_HH_mm_ss, true);
  // eslint-disable-next-line no-undef
  const gfcDisableStartDate = moment(process.env.REACT_APP_GFC_WORDINGS_DISABLE_START_DATE, DD_MM_YYYY_HH_mm_ss, true);
  const schemeName = props?.quickPolicyInfo[props.selectedPolicyIndex]?.product?.productAcronym;
  const isSmartProduct = (props?.quickPolicyInfo[props.selectedPolicyIndex]?.product?.productTypeNmbr === 130);

  const maximizedCard = () => {
    let impNotes = [];
    if (props?.isOrsoAccount) {
      for (let index = 1; index < 8; index++) {
        if (productTypeNum == 142) {
          if(![2,3].includes(index)){
          impNotes.push(
            <li className="imp_notes_section_points mt-4">
              {t(`fundInfo.chubbImpNote${index}`)}
            </li>
          );
        }
        } else if (productTypeNum == 143) {
          impNotes.push(
            <li className="imp_notes_section_points mt-4">
              {t(`fundInfo.ftlImpNote${index}`)}
            </li>
          );
        } else {
          if ([1, 2].includes(index)) {
            if(!([2].includes(index) && (hkTime.toDate() > orsoLtgfFundEndDate.toDate()))) {
              impNotes.push(
                <li className="imp_notes_section_points mt-4">
                  {t(`fundInfo.OrsoImpNote${index}-1`)}<strong>{t(`fundInfo.OrsoImpNote${index}-2`)}</strong>{t(`fundInfo.OrsoImpNote${index}-3`)}
                </li>
              );
            }
          } else {
            if(!([3].includes(index) && (hkTime.toDate() > orsoLtgfFundEndDate.toDate()))) {
              impNotes.push(
                <li className="imp_notes_section_points mt-4">
                  {t(`fundInfo.OrsoImpNote${index}`)}
                </li>
              );
            }
          }
        }
      }
    } else if (props?.scheme == SMP) {
      if (selectedQuickPolicyInfo.product?.productAcronym?.trim() == "SMAP") {
        for (let index = 1; index <= 8; index++) {
          if (!(isSmartProduct && hkTime.toDate() > gfcDisableStartDate.toDate() && index == 2)) {
            impNotes.push(
              <li className="imp_notes_section_points mt-4">
                {t(`fundInfo.smapImpNote${index}`)}
              </li>
            );
          }
        }
      }
      else {
        for (let index = 1; index < 8; index++) {
          impNotes.push(
            <li className="imp_notes_section_points mt-4">
              {t(`fundInfo.simpImpNote${index}`)}
            </li>
          );
        }
      }

    } else {
      for (let index = 3; index < 15; index++) {
        if ((!(schemeName?.includes(schemeS800) && hkTime.toDate() > gfcDisableStartDate.toDate() && [3, 4, 5, 6].includes(index)))) {
          impNotes.push(
            <li className="imp_notes_section_points mt-4">
              {t(`fundInfo.impnoteline${index}`)}
            </li>
          );
        }
      }
    }
    return (
      <div className="impnotes_section_maximised" data-testid="card_maximised">
        <div className="container">
          <div className="imp_notes_container_maximized">
            <div>
              <span className="impnotes_section_ip_text">{t("fundInfo.impNotes")}</span>
            </div>
          </div>
        </div>
        <hr className="imp_note_hr" />
        <div className="container imp_notes_section_points_container">
          {
            !props?.isOrsoAccount && (
              <>
                <div className="imp_notes_section_points mt-4">
                  {t("fundInfo.impnoteline1")}
                </div>
                <div className="imp_notes_section_points mt-4">
                  {t("fundInfo.impnoteline2")}
                </div>
              </>
            )
          }
          <ol>
            {impNotes}
          </ol>
          {
            (!(props?.isOrsoAccount) && props?.scheme != SMP) && (
              <>
                <div className="imp_notes_section_points mt-4">
                  {t("fundInfo.impnoteline15")}
                </div>
                <div className="imp_notes_section_points mt-4">
                  {t("fundInfo.impnoteline16")}
                </div>
              </>
            )
          }
          <div className="row mt-4">
            <button className="fund_details_read_btn col-5 col-md-3 w-100" onClick={() => { setcardExpanded(false); }} data-testid="cnf_btn">{t("fundInfo.confirm")}</button>
          </div>
        </div>
      </div>
    );
  };

  const fundClass = getFundClass(props?.fundClassData, fund?.invTypeNmbr, fund?.invTypeNm);

  let fundName = FormatUtils.formatFundName(i18n.language === "en" ? fund?.legalNm : fund?.othLegalNm);
  fundName = fundName?.split("-");
  let fundTitle = "";
  fundName?.forEach(name => {
    fundTitle = fundTitle + name;
  });

  return (
    <div className="fund_details_title" data-testid="fund_details_title">
      <div className="container fund_details_title_fund_name">
        {!props?.scheme?.includes(HKAIIB) && !(CORE_ORSO_ACCOUNT_S800.includes(selectedQuickPolicyInfo?.product?.productTypeNmbr)) && !props?.scheme?.includes(SMP) ? `${fundTitle}-${fundClass}` : fundTitle}
        {!props?.scheme?.includes(HKAIIB) && !(props?.isOrsoAccount && productTypeNum !== 105 && productTypeNum != 142 && productTypeNum != 143) && (cardExpanded ? maximizedCard() : minimizedCard())}
      </div>
      <div className='image-wrapper'>
        <img className='oval_image' src={Oval}></img>
      </div>
    </div>
  );
};

FundDetailsTitle.propTypes = {
  props: PropTypes.any,
  fund: PropTypes.object,
  fundClassData: PropTypes.array,
  scheme: PropTypes.string,
  quickPolicyInfo: PropTypes.array,
  selectedPolicyIndex: PropTypes.number,
  selectedQuickPolicyInfo: PropTypes.object,
  isOrsoAccount: PropTypes.bool
};


const mapStateToProps = (state) => {
  return {
    fundClassData: state.fundPerformance.fundClassData,
    scheme: state.empLogin.scheme,
    quickPolicyInfo: state.twoAccounts.quickPolicyInfo,
    selectedPolicyIndex: state.twoAccounts.selectedPolicyIndex,
    isOrsoAccount: state.twoAccounts.isOrsoAccount
  };
};


export default connect(mapStateToProps)(FundDetailsTitle);
