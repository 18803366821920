import PieChart from "highcharts-react-official";
import Highcharts from "highcharts/highstock";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import { FormatUtils } from "../utils";

const PieChartPI = (props) => {
  const { i18n } = useTranslation("common");
  let newData = [];

  const dataConvert = () => {
    let propsData = props.data;

    for (let i = 0; i < propsData.length; i++) {
      let percentVal = parseFloat(FormatUtils.convertNumToPercentwithPrecisionTwo((propsData[i].value) / 100))
      newData.push({ y: percentVal, name: propsData[i].title, color: propsData[i].color, othLegalNm: propsData[i].othLegalNm });
    }
  }
  dataConvert();
  let options = {
    chart: {
      type: "pie",
      title: {
        text: null
      },
    },
    tooltip: {
      formatter: function () {
        if (i18n.language === "en") {
          return `<b>${FormatUtils.formatFundName(this.point.name)}</b><br/><b>${this.point.y}${' %'}</b>`
        }
        else {
          return `<b>${this.point.othLegalNm}</b><br/><b>${this.point.y}${' %'}</b>`
        }

      }
      ,
    },
    series: [
      {
        data: newData
      }
    ],
    exporting: {
      enabled: false
    },
    navigation: {
      buttonOptions: {
        enabled: false
      }
    },
    plotOptions: {
      chart: {

        width: props.radius / 5,
        height: props.width / 2
      },

      pie: {
        size: "50%",
        innerSize: '60%',
        dataLabels: {
          enabled: true,
          padding: 2,
          color: "#464646",
          formatter: function () {
            return FormatUtils.convertNumToPercentwithPrecisionTwo((this.y) / 100);
          },
        }
      }
    },
    title: {
      text: null
    },

  };

  if (props?.click) {
    options.plotOptions['series'] = {
      cursor: 'pointer',
      point: {
        events: {
          click: props?.click
        }
      }
    }
  }
  return (
    <PieChart highcharts={Highcharts} options={options} />
  );
}

PieChartPI.propTypes = {
  props: PropTypes.any,
  data: PropTypes.array,
  radius: PropTypes.number,
  width: PropTypes.number,
  click: PropTypes.func
};

export default PieChartPI;
