import PropTypes from "prop-types";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";
import lang from "../assets/Language-TC.svg";
import aiibLogo from "../assets/Logo/AIIB_full_logo.svg";
import axaLogo from "../assets/Logo/axaLogo.svg";
import principalLogo from "../assets/Logo/Principal-Logo-2.svg";
import "../css/HeaderFooter.css";
import { SMP } from "../translations/common/commonConstants";
import { UiUtils } from "../utils";

const LoginHeader = (props) => {
  const { i18n } = useTranslation("common");
  const location = useLocation();
  var lang1 = i18n.language;
  const [englishFlag, setEnglishFlag] = useState(lang1 === "zh" ? true : false);

  let aiibHeader = false;
  if (location.pathname == "/aiib/employeelogin" || location.pathname == "/aiib/employeelogintemp" || props.scheme == "HKAIIB") {
    aiibHeader = true;
  }

  const handleChangeLanguage = () => {
    setEnglishFlag(!englishFlag);
    englishFlag ? i18n.changeLanguage("en") : i18n.changeLanguage("zh");
    if (englishFlag) {
      window.GLOBAL_LANG = "en";
      UiUtils.callMedalliaUpdate();
    } else {
      window.GLOBAL_LANG = "zh";
      UiUtils.callMedalliaUpdate();
    }
  };

  return (
    <>
      <div className="nav_bg">
        <nav className="navbar navbar-light rsc-navbar login-navbar">
          {aiibHeader ?
            <NavLink to="/aiib/employeelogin" className="navbar-brand">
              <img src={aiibLogo} className="aiib-logo" />
            </NavLink>
            :
            <NavLink to="/" className="navbar-brand">
              <><img src={principalLogo} className="rsc-logo" />{props.scheme == SMP && <img src={axaLogo} className="axa-logo" />}</>
            </NavLink>}
          {!aiibHeader && (englishFlag ? (
            <div className="nav-link" data-testid='englishLangBtn' onClick={() => handleChangeLanguage()}>
              EN
            </div>
          ) : (
            <div className="nav-link" data-testid='ChineseLangBtn' onClick={() => handleChangeLanguage()}>
              <img src={lang} alt="" className="d-inline-block align-top" />
            </div>
          ))}
        </nav>
      </div>
    </>
  );
};

LoginHeader.propTypes = {
  props: PropTypes.any,
  scheme: PropTypes.string
};

const mapStateToProps = (state) => {
  return {
    scheme: state.empLogin.scheme
  };
};

export default connect(mapStateToProps)(LoginHeader);