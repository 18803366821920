import { SAVE_CUSTOM_CONTRACT_FILD } from "../actions/ActionConstants"


const initialState = []

export default function contractCustomFieldReducer(state = initialState, action) {
    switch (action.type) {
        case SAVE_CUSTOM_CONTRACT_FILD:
            return [...action.data]
        default:
            return state
    }
}