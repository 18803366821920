
import { instance } from '../AxiosInterceptor';

export default {
    getGainAndLossDetails: (inputParams, requestHeaders) =>
        instance({
            'method': 'GET',
            'url': '/accounts/gainAndLoss',
            headers: requestHeaders,
            'params': {
                'clientNmbr': inputParams.clientNmbr,
                'clntRltnshpNmbr': inputParams.clntRltnshpNmbr,
                'toDate': inputParams.toDate
            },
        }),

    getSimplifiedTimeListDetails: (inputParams, requestHeaders) =>
        instance({
            'method': 'GET',
            'url': '/accounts/rateOfReturn',
            headers: requestHeaders,
            'params': {
                'clntRltnShpNmbr': inputParams.clntRltnShpNmbr,
                'asOfDt': inputParams.asOfDt,
                'productTypeNmbr': inputParams.productTypeNmbr,
                'productTypeNm': inputParams.productTypeNm
            },
        }),

    getIntraGrpDetails: (inputParams, requestHeaders) =>
        instance({
            'method': 'GET',
            'url': '/investments/intragrptransfer',
            headers: requestHeaders,
            'params': {
                'year': inputParams.year,
                'clntRltnshpNmbr': inputParams.clntRltnshpNmbr,
                'month': inputParams.month,
                'contributionFlg': inputParams.contributionFlg,
                isLatest: inputParams?.isLatest ? 'true' : 'false'
            },
        }),
    getPortfolioDetails: (inputParams, requestHeaders) =>
        instance({
            'method': 'GET',
            'url': '/accounts/portfoliodetails',
            headers: requestHeaders,
            'params': {
                'clntRltnShpNmbr': inputParams.clntRltnShpNmbr,
                'year': inputParams.year,
                'month': inputParams.month,
                'asOfDt': inputParams.asOfDt,
                'productTypeNmbr': inputParams.productTypeNmbr,
                'productTypeNm': inputParams.productTypeNm,
                'clientId': inputParams.clientId,
                'contractNmbr': inputParams.contractNmbr,
                isLatest: inputParams?.isLatest ? true : false
            },
        }),
}