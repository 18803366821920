import moment from "moment";
import fundInfoApi from "../API/FundInfo/fundInfoApi";
import { prototype } from "react-modal";
import AccountUtils from './AccountUtils'

import highRiskSpectrum from "../assets/Icon/Group_7_high.svg";
import lowRiskSpectrum from "../assets/Icon/Group_7_low.svg";
import moderateRiskSpectrum from "../assets/Icon/Group_7_moderate.svg";
import moderateToHighRiskSpectrum from "../assets/Icon/Group_7_moderateToHigh.svg";
import lowRiskSpectrumSmp from "../assets/Icon/Graph_SMP-Low.svg";
import lowToMediumRiskSpectrumSmp from "../assets/Icon/Graph_SMP-LowToMedium.svg";
import mediumRiskSpectrumSmp from "../assets/Icon/Graph_SMP-Medium.svg";
import mediumToHighRiskSpectrumSmp from "../assets/Icon/Graph_SMP-MediumToHigh.svg";
import highRiskSpectrumSmp from "../assets/Icon/Graph_SMP-High.svg";

let oldDateArr = [];
const getschemeList = (mergerAttributes, invArr) => {
    let schemeArray = [];
    mergerAttributes.forEach(element => {
        if (element.cstmAttrTxt === "SC") {
            if (!schemeArray.includes(0)) { schemeArray.push(0); }
            if (!schemeArray.includes(1) && invArr.includes('600')) { schemeArray.push(1); oldDateArr[1] = element.efctvDt; }
            else if (schemeArray.includes(1)) { oldDateArr[1] = element.efctvDt; }
        } else if (element.cstmAttrTxt === "DH") {
            if (!schemeArray.includes(1) && invArr.includes('600')) { schemeArray.push(1); oldDateArr[1] = element.efctvDt; }
            else if (schemeArray.includes(1)) { oldDateArr[1] = element.efctvDt; }
            if (!schemeArray.includes(3) && invArr.includes('DHB')) { schemeArray.push(3); oldDateArr[3] = element.efctvDt; }
        } else if (element.cstmAttrTxt === "KO") {
            if (!schemeArray.includes(0)) { schemeArray.push(0); }
            if (!schemeArray.includes(4) && invArr.includes('KOB')) { schemeArray.push(4); oldDateArr[4] = element.efctvDt; }
        } else if (element.cstmAttrTxt === "200") {
            if (!schemeArray.includes(1) && invArr.includes('600')) { schemeArray.push(1); oldDateArr[1] = element.efctvDt; }
            else if (schemeArray.includes(1)) { oldDateArr[1] = element.efctvDt; }
            if (!schemeArray.includes(2) && invArr.includes('200')) { schemeArray.push(2); oldDateArr[2] = element.efctvDt; }
            else if (schemeArray.includes(2)) { oldDateArr[2] = element.efctvDt; }
        } else if (element.cstmAttrTxt === "SP") {
            if (!schemeArray.includes(2) && invArr.includes('200')) { schemeArray.push(2); oldDateArr[2] = element.efctvDt; }
            else if (schemeArray.includes(2)) { oldDateArr[2] = element.efctvDt; }
            if (!schemeArray.includes(5) && invArr.includes('SP')) { schemeArray.push(5); oldDateArr[5] = element.efctvDt; }
        } else if (element.cstmAttrTxt === "AP") {
            if (!schemeArray.includes(1) && invArr.includes('600')) { schemeArray.push(1); oldDateArr[1] = element.efctvDt; }
            else if (schemeArray.includes(1)) { oldDateArr[1] = element.efctvDt; }
            if (!schemeArray.includes(6) && invArr.includes('AP')) { schemeArray.push(6); oldDateArr[6] = element.efctvDt; }
        } else if (element.cstmAttrTxt === "ZP") {
            if (!schemeArray.includes(2) && invArr.includes('200')) { schemeArray.push(2); oldDateArr[2] = element.efctvDt; }
            else if (schemeArray.includes(2)) { oldDateArr[2] = element.efctvDt; }
            if (!schemeArray.includes(7) && invArr.includes('ZP')) { schemeArray.push(7); oldDateArr[7] = element.efctvDt; }
        } else if (element.cstmAttrTxt === "ZD") {
            if (!schemeArray.includes(0)) { schemeArray.push(0); }
            if (!schemeArray.includes(8) && invArr.includes('ZD')) { schemeArray.push(8); oldDateArr[8] = element.efctvDt; }
        } else if (element.cstmAttrTxt === "SB") {
            if (!schemeArray.includes(1) && invArr.includes('600')) { schemeArray.push(1); oldDateArr[1] = element.efctvDt; }
            else if (schemeArray.includes(1)) { oldDateArr[1] = element.efctvDt; }
            if (!schemeArray.includes(2) && invArr.includes('200')) { schemeArray.push(2); oldDateArr[2] = element.efctvDt; }
            else if (schemeArray.includes(2)) { oldDateArr[2] = element.efctvDt; }
        } else {
            return;
        }
    });
    return schemeArray;
};

const getOldDate = (index) => {
    return oldDateArr[index];
}

const getSchemeFromProductType = (productType) => {
    if (prototype === 112) {
        return 0;
    } else if (productType === 111) {
        return 1;
    }
    else if (productType === 110) {
        return 2;
    }
    else if (productType === 123) {
        return 3;
    }
    else if (productType === 113) {
        return 4;
    }
    else if (productType === 114) {
        return 5;
    }
    else if (productType === 115) {
        return 6;
    }
    else if (productType === 116) {
        return 7;
    }
    else if (productType === 117) {
        return 8;
    }
    else {
        return productType;
    }
}

const getSchemeNumber = (scheme) => {
    if (scheme === 0) {
        return 112;
    } else if (scheme === 1) {
        return 111;
    }
    else if (scheme === 2) {
        return 110;
    }
    else if (scheme === 3) {
        return 123;
    }
    else if (scheme === 4) {
        return 113;
    }
    else if (scheme === 5) {
        return 114;
    }
    else if (scheme === 6) {
        return 115;
    }
    else if (scheme === 7) {
        return 116;
    }
    else if (scheme === 8) {
        return 117;
    }
    else {
        return scheme;
    }
}

const isDuplicateFund = (fundInfoData, fund) => {
    let duplicateFund = false;
    fundInfoData.forEach(element => {
        if (fund.invTypeNmbr !== element.invTypeNmbr && fund.invTypeNm.replace(' ', ' 2_') === element.invTypeNm) {
            duplicateFund = true;
        }
    });
    return duplicateFund;
}

const getFundDetails = async (props, scheme) => {
    const schemeCode = getSchemeNumber(parseInt(scheme));
    let webCycleDt = props.webCycleDate && moment(props.webCycleDate, "YYYY-MM-DD HH:mm:ss A").toDate();
    var initialToDate = new Date(webCycleDt);
    let response;
    if (scheme != 0) {
        initialToDate = moment(oldDateArr[scheme], "DD/MM/YYYY").toDate()
    }
    initialToDate.setHours(0, 0, 0, 0);
    webCycleDt = moment(initialToDate).format("YYYY-MM-DD")
    let headers = {
        'pisys_auth_token': props.pisysToken,
        'Access-Control-Allow-Origin': '*'
    }
    if (scheme != 0)
        response = await fundInfoApi.getFundDetails((String(schemeCode)), (webCycleDt), headers)
    else
        response = await fundInfoApi.getFundDetailsLatest((String(schemeCode)), headers)
    await AccountUtils.compareWebCycleDateWithExistingWebCycleDate(props, response?.data?.webCycleDate);
    return response.data;
};

const getFundHistory = async (props) => {
    const date = new Date();
    let toDate = moment(date).format("DD/MM/YYYY");
    date.setFullYear(date.getFullYear() - 1);
    const inceptionDate = moment(props?.fundPricePerformance?.performance?.inceptionDate, "YYYY-MM-DD")
    let endDate = inceptionDate.isValid() ? inceptionDate.format("DD/MM/YYYY") : moment(date).format("DD/MM/YYYY");
    const headers = {
        'pisys_auth_token': props.pisysToken
    }
    const inputJson = {
        startDate: (endDate),
        endDate: (toDate),
        invTypeNmbr: (props.fundPricePerformance.invTypeNmbr)
    }
    let decodedResponse = await fundInfoApi.getFundHistory(inputJson, headers)
    return decodedResponse.data;
}

const getFundConfigDetails = async (props, contractNmbr, date) => {
    let webCycleDt = props.webCycleDate && moment(props.webCycleDate, "YYYY-MM-DD HH:mm:ss A").toDate();
    var initialToDate = new Date(date);
    initialToDate.setHours(0, 0, 0, 0);
    webCycleDt = moment(initialToDate).format("DD/MM/YYYY")
    let headers = {
        'pisys_auth_token': props.pisysToken,
        'Access-Control-Allow-Origin': '*',
        'username': props.clientNmbr
    }
    const response = await fundInfoApi.getFundConfigDetails((String(contractNmbr)), (webCycleDt), headers)
    return response.data;
};
const getFundFactsheetData = async (props) => {
    let requestHeaders = {
        'pisys_auth_token': props.pisysToken,
        'username': props.clientNmbr,
    }
    const inputJson = {
        productTypeNmbr: (props.productTypeNmbr),
    }
    const response = await fundInfoApi.getFundFactSheet(inputJson, requestHeaders)
    return response?.data;
};

const getFundClassData = async (props) => {
    let requestHeaders = {
        'pisys_auth_token': props.pisysToken,
        'username': props.clientNmbr,
    }

    let fundName = props.fundPricePerformance.invTypeNm.includes("2_") ? props.fundPricePerformance.invTypeNm.substring(2) : props.fundPricePerformance.invTypeNm;
    const response = await fundInfoApi.getFundClassData((fundName), requestHeaders)
    return response?.data;
}


const getFundRisk = (scheme, invRskNm, t) => {
    let fundRisk = { spectrum: '', risk: '', color: '' };
    if (scheme ==='SMP') {
        switch (invRskNm) {
            case 'LOW':
                fundRisk = { spectrum: lowRiskSpectrumSmp, risk: t("feesAndCharges.riskTypeSmp.1"), color: 'fpap-smp-low' };
                break;
            case 'Low to Medium':
                fundRisk = { spectrum: lowToMediumRiskSpectrumSmp, risk: t("feesAndCharges.riskTypeSmp.2"), color: 'fpap-smp-low-to-medium' };
                break;
            case 'Medium':
                fundRisk = { spectrum: mediumRiskSpectrumSmp, risk: t("feesAndCharges.riskTypeSmp.3"), color: 'fpap-smp-medium' };
                break;
            case 'Medium to High':
                fundRisk = { spectrum: mediumToHighRiskSpectrumSmp, risk: t("feesAndCharges.riskTypeSmp.4"), color: 'fpap-smp-medium-to-high' }
                break;
            case 'HIGH':
                fundRisk = { spectrum: highRiskSpectrumSmp, risk: t("feesAndCharges.riskTypeSmp.5"), color: 'fpap-smp-high' }
                break;
            default:
                fundRisk = { spectrum: '', risk: '', color: '' };
                break;
        }
    } else {
        switch (invRskNm) {
            case 'LOW':
                fundRisk = { spectrum: lowRiskSpectrum, risk: t("feesAndCharges.riskType.1"), color: 'fpap-low' };
                break;
            case 'MODERATE':
                fundRisk = { spectrum: moderateRiskSpectrum, risk: t("feesAndCharges.riskType.2"), color: 'fpap-moderate' };
                break;
            case 'MODERATE TO HIGH':
                fundRisk = { spectrum: moderateToHighRiskSpectrum, risk: t("feesAndCharges.riskType.3"), color: 'fpap-moderate-to-high' };
                break;
            case 'HIGH':
                fundRisk = { spectrum: highRiskSpectrum, risk: t("feesAndCharges.riskType.4"), color: 'fpap-high' }
                break;
            default:
                fundRisk = { spectrum: '', risk: '', color: '' };
                break;

        }
    }
    return fundRisk;
}

export default { getFundRisk, getschemeList, getFundDetails, getSchemeNumber, getFundConfigDetails, getFundHistory, getFundFactsheetData, getFundClassData, getOldDate, getSchemeFromProductType, isDuplicateFund }
