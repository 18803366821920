import {
    IS_FIRST_TIME_LOGIN,
    IS_POLICY_TERMINDATED, RESET_LOGIN_INFO, SAVE_AS_OF_DATE, SAVE_CLIENT_NMBR, SAVE_CLIENT_NMBR_LIST, SAVE_CLIENT_SCHEME, SAVE_CLNT_RLTNSHP_NMBR, SAVE_CONTRACT_NMBR, SAVE_CUT_OFF_HRS, SAVE_FEES_CHARGE_INFO, SAVE_LAST_LOGIN_DATE, SAVE_LOGIN_ID, SAVE_ORG_CHINESE_NAMES, SAVE_PRODUCT_TYPE_NMBR, SAVE_QUICK_POLICY_CLNT_INFO, SAVE_SELECT_ACC_FIRST_LOGIN, SAVE_WEB_CYCLE_DATE
} from "../actions/ActionConstants.js";

const initialState = {
    clientNmbr: '',
    loginId: '',
    scheme: '',
    clntRltnshpNmbr: '',
    productTypeNmbr: '',
    isFirstTimeLogin: false,
    contractNmbr: '',
    isPolicyTerminated: false,
    asOfDate: "",
    lastLoginDate: "",
    quickPolicyClientInfo: {},
    cutOffHours: "",
    webCycleDate: "",
    chineseOrgNames: [],
    firstTimeLoadSelectAccount: true,
    feesChargesInfo: {}
};

export default function empLoginReducer(state = initialState, action) {
    switch (action.type) {
        case SAVE_CLIENT_NMBR:
            return { ...state, clientNmbr: action.data };
        case SAVE_CLIENT_NMBR_LIST:
            return { ...state, clientNmbrList: action.data };
        case SAVE_LOGIN_ID:
            return { ...state, loginId: action.data };
        case SAVE_CLIENT_SCHEME:
            return { ...state, scheme: action.data };
        case SAVE_CLNT_RLTNSHP_NMBR:
            return { ...state, clntRltnshpNmbr: action.data };
        case SAVE_PRODUCT_TYPE_NMBR:
            return { ...state, productTypeNmbr: action.data };
        case IS_FIRST_TIME_LOGIN:
            return { ...state, isFirstTimeLogin: action.data };
        case SAVE_CONTRACT_NMBR:
            return { ...state, contractNmbr: action.data };
        case IS_POLICY_TERMINDATED:
            return { ...state, isPolicyTerminated: action.data };
        case SAVE_AS_OF_DATE:
            return { ...state, asOfDate: action.data };
        case SAVE_LAST_LOGIN_DATE:
            return { ...state, lastLoginDate: action.data };
        case SAVE_QUICK_POLICY_CLNT_INFO:
            return { ...state, quickPolicyClientInfo: action.data };
        case SAVE_CUT_OFF_HRS:
            return { ...state, cutOffHours: action.data };
        case SAVE_WEB_CYCLE_DATE:
            return { ...state, webCycleDate: action.data };
        case SAVE_ORG_CHINESE_NAMES:
            return { ...state, chineseOrgNames: action.data };
        case SAVE_SELECT_ACC_FIRST_LOGIN:
            return { ...state, firstTimeLoadSelectAccount: action.data };
        case SAVE_FEES_CHARGE_INFO:
            return { ...state, feesChargesInfo: action.data };
        case RESET_LOGIN_INFO:
            return {
                ...state,
                clientNmbr: '',
                loginId: '',
                scheme: '',
                clntRltnshpNmbr: '',
                productTypeNmbr: '',
                isFirstTimeLogin: false,
                contractNmbr: '',
                isPolicyTerminated: false,
                asOfDate: "",
                lastLoginDate: "",
                quickPolicyClientInfo: {},
                cutOffHours: "",
                webCycleDate: "",
                chineseOrgNames: [],
                firstTimeLoadSelectAccount: true,
                feesChargesInfo: {}
            };
        default:
            return state;
    }
}