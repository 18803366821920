import React from 'react'
import '../css/PromotionalBanner.css'

const PromotionalBanner = ()=> {
    return (
        <div className='promotional-banner' data-testid="PromotionalBanner">
            
        </div>
    )
}

export default PromotionalBanner
