import moment from "moment";
import changesApi from '../API/Changes/ChangesApi';
import AccountUtils from "./AccountUtils";

const getProcessChangesResponse = (response) => {
    let arr = [];
    response.cycleActivityResponseVO.data.forEach((item) => {
        if(item?.activityTypeName?.includes("TRNSFR")) {
            let obj = {}
            obj["activityStatusname"] = item.actyStatusNm;
            obj["fundType"] = "fundSwitch.cardTitle1";
            obj["transHistoryEffectiveDate"] = moment(item.trnsHstEfctvDt).format('DD/M/YYYY');
            obj["activityInputdate"] = moment(item.actyInptDt).format('DD/M/YYYY');
            obj["refNumber"] = (item.clntRqstLogNmbr === "020")?(""):(item.clntRqstLogNmbr);
            obj["activityTablenmbr"] = item.actyTableNmbr;
            arr.push(obj);
        }
    })

    response.policyDetailResponseVO?.investmentDirectionHistories?.forEach((item) => {
        let obj = {}
        obj["activityStatusname"] = "COMPLETED";
        obj["fundType"] = "fundSwitch.cardTitle2";       
        obj["lastChangeDt"] = moment(item.lastChangeDt).format('DD/M/YYYY');
        obj["transHistoryEffectiveDate"] = moment(item.invDrctEfctvDt).format('DD/M/YYYY');
        obj["contributionType"] = item.contributionType;
        obj["refNumber"] = null;
        obj["activityInputdate"] = moment(item.lastChangeDt).format('DD/M/YYYY');
        obj["switchInData"] = item.investments;
        arr.push(obj);
    })

    return arr;
};

const getChangesListInfo = async (props,pisysToken,loginId,clientRelationshipNumber) => {

    const headers = {
        'pisys_auth_token': pisysToken,
        'username': loginId,
        'Access-Control-Allow-Origin': '*'
    }

    let promise = new Promise((resolve)=>{
        changesApi.getChangesList((clientRelationshipNumber), headers)
        .then(async(response) => {
            let decodedResponse = response.data;
            await AccountUtils.compareWebCycleDateWithExistingWebCycleDate(props,decodedResponse?.webCycleDate);
            resolve(decodedResponse);
        })
        .catch(() => {
            resolve("technical-error");
        });
    })
    return promise;
}

const getDetailsForExistingTransaction = (referenceNumber,changesListInfo) => {
    let dataArr = changesListInfo.cycleActivityResponseVO.data;
    for (let index = 0; index < dataArr.length; index++) {
        if(dataArr[index].clntRqstLogNmbr === referenceNumber){
            return dataArr[index];
        }
        
    }
}

const getDetailsForFutureTransaction = (transactionEffectiveDate,changesListInfo) => {
    let dataArr = changesListInfo.policyDetailResponseVO.investmentDirectionHistories;
    for (let index = 0; index < dataArr.length; index++) {
        if(dataArr[index].invDrctEfctvDt.slice(0,10) === transactionEffectiveDate.slice(0,10)){
            return dataArr[index];
        }
        
    }
}


export default { getProcessChangesResponse, getChangesListInfo, getDetailsForExistingTransaction, getDetailsForFutureTransaction };