import React from "react";
import PropTypes from "prop-types";
import { OverlayTrigger, Popover } from "react-bootstrap";
import ButtonTooltip from "../assets/Icon/Button_Tooltip.svg";

const OverlayTip = (props)=> {
  return (
    <OverlayTrigger
      trigger="click"
      rootClose
      placement={"top"}
      overlay={
        <Popover id="popover-positioned">
          <Popover.Content>
            {props.msg}
          </Popover.Content>
        </Popover>
      }
    >
      <span style={{marginLeft:"5px"}}>
        <img src={ButtonTooltip} className="img-info" data-testid="tips-btn" alt="info" />
      </span>
    </OverlayTrigger>
  );
}

OverlayTip.propTypes = {
  props: PropTypes.any,
  msg: PropTypes.string,
};

export default OverlayTip;
