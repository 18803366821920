import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useHistory } from 'react-router-dom';
import { saveChineseDropDownInfo, saveCountryDropDown, saveEnglishDropDownInfo, saveFilteredChineseDropDownInfo, saveFilteredEnglishDropDownInfo } from "../../actions/CommonDropDownAction";
import { saveClientDetails, saveMaxAge } from "../../actions/LandingAction";
import commonApi from "../../API/Common/commonApi";
import AddressInfo from "../../App/Landing/Personal-Info/AddressInfo/AddressInfo";
import ContactInfoComponent from "../../App/Landing/Personal-Info/ContactInfo/Contact-Info";
import EmailInfoComponent from "../../App/Landing/Personal-Info/EmailInfo/EmailInfo";
import ImportantNote from "../../App/Landing/Personal-Info/Important-Note";
import MobileInfo from "../../App/Landing/Personal-Info/Mobile-Info/Mobile-Info";
import Breadcrumbs from "../../SharedComponents/Breadcrumbs";
import Header from "../../SharedComponents/Header";
import Logger from "../../SharedComponents/Logger";
import Navigation from "../../SharedComponents/Navigation";
import { HKAIIB, PERSONAL_INFO, schemeAIIB, schemeS800 } from "../../translations/common/commonConstants";
import OrsoFatcaDeclaration from "../Account/FatcaDeclaration/ORSO/OrsoFatcaDeclaration";
import api from "./../../API/PersonalInfo/api";
import GeneralInfoComponent from "./Personal-Info/General/General-Info";

const Landing = (props) => {
  const { t, i18n } = useTranslation("common");
  const history = useHistory();
  const [activeEdit, setActiveEdit] = useState(false);
  let showAllNavigationOptions = props.haveSelectedAccount === true ? true : false;
  const [isFatcaAiibPending, setFatcaAiibPending] = useState(false)

  const [appState, setAppState] = useState({
    loading: false,
    responseData: null,
    isFatcaDclarationPending: true,
  });

  const headers = {
    'pisys_auth_token': props.pisysToken,
    'username': props.clientNmbr,
    'Accept-Language': 'en',
    'Access-Control-Allow-Origin': '*'
  }

  useEffect(() => {
    if (i18n.language === "zh") {
      props.dispatch(
        saveCountryDropDown(props.filteredChineseCountryDropDown)
      );
    } else {
      props.dispatch(
        saveCountryDropDown(props.filteredEngCountryDropDown)
      );
    }
  }, [])

  useEffect(() => {
    setAppState({ loading: true });
    let clntNmbr = props.clientNmbr;
    let policyList = "";
    let unitBalance = 0;

    if (props.filteredChineseCountryDropDown.length === 0) {
      commonApi.getCntryDropDowns(headers)
        .then((response) => {
          let decodedResponse = ((response.data));
          props.dispatch(
            saveEnglishDropDownInfo(decodedResponse)
          );
          const filteredCountryList = decodedResponse.filter((country) => country.pltcJrsdNmbr % 2 !== 0);
          props.dispatch(saveFilteredEnglishDropDownInfo(filteredCountryList));
          props.dispatch(
            saveCountryDropDown(filteredCountryList)
          );
        });
    }

    if (props.filteredEngCountryDropDown.length === 0) {
      let cnHeaders = {
        'pisys_auth_token': props.pisysToken,
        'username': props.clientNmbr,
        'Accept-Language': 'cn',
        'Access-Control-Allow-Origin': '*'
      }
      commonApi.getCntryDropDowns(cnHeaders)
        .then((response) => {
          let decodedResponse = ((response.data));
          props.dispatch(
            saveChineseDropDownInfo(decodedResponse)
          );
          props.dispatch(saveFilteredChineseDropDownInfo(decodedResponse.filter((country) => country.pltcJrsdNmbr % 2 !== 0)));
        })
    }

    policyList = props?.policyList[props.selectedPolicyIndex]?.policyAccountValueVOList || ""
    if (policyList) {
      unitBalance = policyList.reduce((total, unitCount) => total + unitCount.unitCnt, 0);
    }

    api.getClientDetails(clntNmbr, headers)
      .then((response) => {
        let decodedResponse = response.data;
        if (Object.prototype.hasOwnProperty.call(decodedResponse, 'factaUSAccount')) {
          setAppState({
            isFatcaDclarationPending: false
          });
        }
        if (decodedResponse?.factaUSAccount && decodedResponse?.factaUSAccount !== "") {
          setFatcaAiibPending(false)
        } else {
          setFatcaAiibPending(true)
        }
        props.dispatch(
          saveClientDetails(decodedResponse)
        );
        const policy = props?.policyList.find((policy) => policy?.policyVO?.contract?.contractNmbr == props.contractNmbr);
        props.dispatch(
          saveMaxAge(policy?.policyVO.maxAge)
        )
        setAppState({
          loading: false,
          responseData: decodedResponse,
        });
      })
      .catch(() => { });
    if (!appState.isFatcaDclarationPending && unitBalance > 0.001 && !props.scheme?.includes(HKAIIB) && !(props.isOrsoAccount)) {
      history.push("/fatca");
    }
  }, [setAppState]);

  const renderBreadCrumb = (t) => {
    const propArr = [
      {
        title: t("titles.dashboard"),
        active: true,
        route: '/dashboard'
      },
      {
        title: t("titles.personalinformation"),
        active: false
      }
    ]
    return <Breadcrumbs setCurrentPage={(route) => { history.push(route) }} breadCumbInfo={propArr} />
  }

  const handleActiveEdit = (status) => {
    setActiveEdit(status);
  }

  return (
    <>
      <Logger logImpression data={{ page: PERSONAL_INFO }}>
        <Header />
        {(props.scheme?.includes(HKAIIB) || props?.isOrsoAccount) && (props.selectedAccountBalance > 0.001 || !props.isPolicyTerminated) && isFatcaAiibPending && <OrsoFatcaDeclaration />}
        {showAllNavigationOptions && renderBreadCrumb(t)}
        <div className={props.scheme === schemeS800 ? "head" : ""}>
          <div className="container">
            <h1 data-testid="infoTitle" className="personal-info-title mb-0">
              {props.scheme !== schemeAIIB ? t("titles.personalinformation") : t("titles.personalInformationAiib")}
            </h1>
          </div>
        </div>
        <Navigation />
        {(appState.responseData) ? (
          <div className={props.scheme === schemeS800 ? "info-card-content" : "info-card-content-aiib"}>
            <div className="container">

              <div className="col-lg-10 col-md-12 mx-auto">
                <GeneralInfoComponent></GeneralInfoComponent>
                <AddressInfo onEdit={handleActiveEdit} activeEdit={activeEdit} data={appState.responseData}></AddressInfo>
                <EmailInfoComponent onEdit={handleActiveEdit} activeEdit={activeEdit}></EmailInfoComponent>
                <MobileInfo onEdit={handleActiveEdit} activeEdit={activeEdit}></MobileInfo>
                <ContactInfoComponent onEdit={handleActiveEdit} activeEdit={activeEdit}></ContactInfoComponent>
                <ImportantNote scheme={props.scheme}></ImportantNote>
              </div>
            </div>
          </div>
        ) : (
          <div className="d-flex justify-content-center">
            <div className="spinner-border spinner m-5" role="status">
              <span className="sr-only"></span>
            </div>
          </div>
        )}
      </Logger>
    </>
  );
};

Landing.propTypes = {
  props: PropTypes.any,
  dispatch: PropTypes.func,
  clientNmbr: PropTypes.string,
  pisysToken: PropTypes.string,
  loginId: PropTypes.string,
  isFatcaDeclPending: PropTypes.bool,
  policyList: PropTypes.array,
  selectedPolicyIndex: PropTypes.number,
  haveSelectedAccount: PropTypes.bool,
  filteredEngCountryDropDown: PropTypes.arrayOf(PropTypes.object),
  filteredChineseCountryDropDown: PropTypes.arrayOf(PropTypes.object),
  scheme: PropTypes.string,
  selectedAccountBalance: PropTypes.number,
  isPolicyTerminated: PropTypes.bool,
  selectedQuickPolicyInfo: PropTypes.object,
  isOrsoAccount: PropTypes.bool,
  contractNmbr: PropTypes.number
};
const mapStateToProps = (state) => {
  return {
    clientNmbr: state.empLogin.clientNmbr,
    pisysToken: state.token.pisysToken,
    loginId: state.empLogin.loginId,
    isFatcaDeclPending: state.twoAccounts.isFatcaDeclPending,
    policyList: state.twoAccounts.allPolicyList,
    selectedPolicyIndex: state.twoAccounts.selectedPolicyIndex,
    haveSelectedAccount: state.twoAccounts.haveSelectedAccount,
    filteredEngCountryDropDown: state.commonDropDown.filteredEngCountryDropDown,
    filteredChineseCountryDropDown: state.commonDropDown.filteredChineseCountryDropDown,
    scheme: state.empLogin.scheme,
    selectedAccountBalance: state.twoAccounts.selectedAccountBalance,
    isPolicyTerminated: state.empLogin.isPolicyTerminated,
    selectedQuickPolicyInfo: state.twoAccounts.selectedQuickPolicyInfo,
    isOrsoAccount: state.twoAccounts.isOrsoAccount,
    contractNmbr: state.empLogin.contractNmbr
  };
}
const LandingComponent = connect(mapStateToProps)(Landing);
export default LandingComponent;