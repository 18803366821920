import PropTypes from "prop-types";
import React from 'react';
import { useTranslation } from "react-i18next";
import sessionExpire from "../assets/Icon/Expired.svg";
import lock from "../assets/Icon/Locked.svg";
import errorIcon from "../assets/Icon/Locked_Error.svg";
import logout from "../assets/Icon/Logout.svg";
import successIcon from '../assets/Icon/Tick.svg';
import styles from '../css/toast.module.css';
const Toast = (props) => {
  const { t } = useTranslation("common");
  let containerCss = '';
  let logo = '';
  let message = '';
  let messageCss = '';
  let errorSuccessFlag = false;
  let header = '';
  switch (props.type) {
    case 'success':
      containerCss = styles.toastsuccesscontainer;
      messageCss = styles.toastmessage
      logo = successIcon;
      message = t('common.successToat');
      errorSuccessFlag = true;
      break;
    case 'error':
      containerCss = styles.toasterrorcontainer;
      messageCss = styles.toastmessage
      logo = errorIcon;
      message = t('common.errorToat');
      errorSuccessFlag = true;
      break;
    case 'account-locked-box':
      containerCss = styles.toasterrorcontainer;
      messageCss = styles.toastmessage
      logo = lock;
      message = t("notifications.accountLockedMessage");
      header = t("notifications.accountLockedHeader");
      break;
    case 'invalid-login-box':
      containerCss = styles.toasterrorcontainer;
      messageCss = styles.toastmessage
      logo = lock;
      header = t("notifications.invalidLoginHeader");
      message = t("notifications.invalidLoginMessage");
      break;
    case 'forgot-Pin-Error':
      containerCss = styles.toastsuccesscontainer;
      messageCss = styles.toastmessage
      logo = logout;
      message = t("forgotpin.forgotpinerror");
      errorSuccessFlag = true;
      break;
    case 'logged-out':
      containerCss = styles.toastsuccesscontainer;
      messageCss = styles.toastmessage
      logo = logout;
      message = t("notifications.successfullyLogoutHeader");
      errorSuccessFlag = true;
      break;
    case 'session-expired':
      containerCss = styles.toastsessionexpirecontainer;
      messageCss = styles.toastmessage
      logo = sessionExpire;
      header = t("notifications.sessionExpiredHeader");
      message = t("notifications.sessionExpiredMessage");
      break;
    case 'something-went-wrong':
      containerCss = styles.toasterrorcontainer;
      messageCss = styles.toastmessage
      logo = errorIcon;
      header = t("notifications.invalidLoginHeader");
      message = t("notifications.somethingWentWrong");
      break;
    case 'report-error-message':
      containerCss = styles.toasterrorcontainer;
      messageCss = styles.toastmessage
      logo = errorIcon;
      message = t("notifications.reportErrorMessage");
      break;
    case 'technical-error':
      containerCss = styles.toasterrorcontainer;
      messageCss = styles.toastmessage
      logo = errorIcon;
      header = t("notifications.technicalError");
      message = t("notifications.tryAgain");
      break;
    case 'no-valid-policy':
      containerCss = styles.toasterrorcontainer;
      messageCss = styles.toastmessage
      logo = errorIcon;
      message = t("notifications.noValidPolicy");
      break;
    case 'incorrect-old-password':
      containerCss = styles.toasterrorcontainer;
      messageCss = styles.toastmessage
      logo = errorIcon;
      message = t("notifications.incorrectOldPassword");
      break;
    case 'funds-exceeded':
      containerCss = styles.toasterrorcontainer;
      messageCss = styles.toastmessage
      logo = errorIcon;
      message = t("notifications.FundsExceededMsg");
      break;
    case 'Invalid-Channel':
      containerCss = styles.toasterrorcontainer;
      messageCss = styles.toastmessage
      logo = sessionExpire;
      message = t("notifications.InvalidChannelMsg");
      break;
    case 'otp-expired':
      containerCss = styles.toastsessionexpirecontainer;
      messageCss = styles.toastmessage
      logo = sessionExpire;
      header = t("notifications.otpExpired");
      message = t("notifications.loginAgain");
      break;
    case 'invalid-phone':
      containerCss = styles.toasterrorcontainer;
      messageCss = styles.toastmessage
      logo = errorIcon;
      header = t("notifications.invalidPhone");
      message = t("notifications.tryAgain");
      break;
    case 'no-valid-account':
      containerCss = styles.toasterrorcontainer;
      messageCss = styles.toastmessage
      logo = errorIcon;
      message = t("notifications.noValidAccount");
      break;
    case 'same-fund-switchIn-err':
      containerCss = styles.toasterrorcontainer;
      messageCss = styles.toastmessage
      logo = errorIcon;
      message = t("notifications.sameFundError");
      break;
    default:
      break;
  }

  return (
    <div id="successToast" className={containerCss}>
      <div className={styles.toastflex}>
        <div>
          <img src={logo} />
        </div>
        {errorSuccessFlag ?
          <p className={messageCss}>{message}</p>
          : <div>
            <p className={messageCss}>{header}</p>
            <p className={messageCss}>{message}</p>
          </div>
        }
      </div>
    </div>
  )
}
Toast.propTypes = {
  props: PropTypes.any,
  type: PropTypes.string,
  header: PropTypes.string,
  message: PropTypes.string,
  toastProps: PropTypes.object,
};

export default Toast