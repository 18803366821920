import { SAVE_PISYS_TOKEN, SAVE_AUTH_TOKEN } from "../actions/ActionConstants";

const initialState = {
    pisysToken: "",
    authToken: "",
}

export default function tokenReducer(state = initialState, action) {
    switch (action.type) {
        case SAVE_PISYS_TOKEN:
            return { ...state, pisysToken: action.data };
        case SAVE_AUTH_TOKEN:
            return { ...state, authToken: action.data };
        default:
            return state;
    }
}