import { BadAddressText } from "../translations/common/commonConstants";

const getOrsoOfficePh = (clientPhones) => {
    let orsoOffPh = "";
    clientPhones?.forEach(el => {
        if (el.phoneTypeNm === "ORSO OFFICE" && el.phoneTxt !== "" && el.phoneLocationNm !== BadAddressText) {
            orsoOffPh = el;
        }
    })
    return orsoOffPh;
}

const getOfficePhone = (clientPhones) => {
    let orsoOffPh = "";
    clientPhones?.forEach(el => {
        if (el.phoneTypeNm === "OFFICE" && el.phoneLocationNm !== BadAddressText) {
            orsoOffPh = el;
        }
    })
    return orsoOffPh;
}

const getMpfOfficePhone = (clientPhones) => {
    let mpfOfficePhone = "";
    clientPhones?.forEach(el => {
        if (el.phoneTypeNm === "MPF OFFICE" && el.phoneLocationNm !== BadAddressText) {
            mpfOfficePhone = el;
        }
    })
    return mpfOfficePhone;
}

export default { getOrsoOfficePh, getOfficePhone, getMpfOfficePhone }