import { React } from 'react'
import { Route, Redirect } from 'react-router-dom'
import PropTypes from "prop-types";

/**
 * Only allows navigation to a route if a condition is met.
 * Otherwise, it redirects to a different specified route.
 */
export default function ProtectedRoute({
    condition,
    redirectTo,
    children,
    ...rest
}) {
    return (<Route
        {...rest}
        render={() =>
            condition ? (
                children
            ) : (
                <Redirect
                    push
                    to={redirectTo}
                />
            )
        }
    />)
}

ProtectedRoute.propTypes = {
    condition: PropTypes.bool,
    redirectTo: PropTypes.string,
    children: PropTypes.any
}