import {
    IS_FIRST_TIME_LOGIN, IS_POLICY_TERMINDATED, RESET_LOGIN_INFO, SAVE_AS_OF_DATE, SAVE_CLIENT_NMBR, SAVE_CLIENT_NMBR_LIST, SAVE_CLIENT_SCHEME,
    SAVE_CLNT_RLTNSHP_NMBR, SAVE_CONTRACT_NMBR, SAVE_CUT_OFF_HRS,
    SAVE_FEES_CHARGE_INFO, SAVE_LAST_LOGIN_DATE, SAVE_LOGIN_ID,
    SAVE_ORG_CHINESE_NAMES, SAVE_PRODUCT_TYPE_NMBR, SAVE_QUICK_POLICY_CLNT_INFO, SAVE_SELECT_ACC_FIRST_LOGIN, SAVE_WEB_CYCLE_DATE
} from "../actions/ActionConstants.js";

export function saveClientNmbr(data) {
    return { type: SAVE_CLIENT_NMBR, data };
}
export function saveClientNmbrList(data) {
    return { type: SAVE_CLIENT_NMBR_LIST, data };
}
export function saveLoginId(data) {
    return { type: SAVE_LOGIN_ID, data };
}
export function saveSchemeType(data) {
    return { type: SAVE_CLIENT_SCHEME, data };
}
export function saveClntRltnshpNmbr(data) {
    return { type: SAVE_CLNT_RLTNSHP_NMBR, data };
}
export function saveProductTypeNmbr(data) {
    return { type: SAVE_PRODUCT_TYPE_NMBR, data };
}
export function saveFirstTimeLoginInfo(data) {
    return { type: IS_FIRST_TIME_LOGIN, data };
}
export function saveContractNmbr(data) {
    return { type: SAVE_CONTRACT_NMBR, data };
}
export function isPolicyTerminated(data) {
    return { type: IS_POLICY_TERMINDATED, data };
}
export function saveAsOfDate(data) {
    return { type: SAVE_AS_OF_DATE, data };
}
export function saveLastLoginDate(data) {
    return { type: SAVE_LAST_LOGIN_DATE, data };
}
export function saveQuicPolicyClientInfo(data) {
    return { type: SAVE_QUICK_POLICY_CLNT_INFO, data };
}
export function saveCutOffHours(data) {
    return { type: SAVE_CUT_OFF_HRS, data };
}
export function saveWebCycleDate(data) {
    return { type: SAVE_WEB_CYCLE_DATE, data };
}
export function saveOrganizationsChineseNames(data) {
    return { type: SAVE_ORG_CHINESE_NAMES, data };
}
export function saveFirstTimeSelectAccoutnLoading(data) {
    return { type: SAVE_SELECT_ACC_FIRST_LOGIN, data };
}
export function saveFeesChargeInfo(data) {
    return { type: SAVE_FEES_CHARGE_INFO, data };
}
export function resetLoginInfo() {
    return { type: RESET_LOGIN_INFO };
}