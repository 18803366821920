import React from "react";
import { useTranslation } from "react-i18next";
import "../css/LssPopUp.css"

const LssPopUp = () => {
    const { t } = useTranslation("common");

    const lssData = [
        {age: '50 or below', equityExposure: 60, bondExposure:40},
        {age: '51', equityExposure: 57, bondExposure:43},
        {age: '52', equityExposure: 55, bondExposure:45},
        {age: '53', equityExposure: 52, bondExposure:48},
        {age: '54', equityExposure: 49, bondExposure:51},
        {age: '55', equityExposure: 47, bondExposure:53},
        {age: '56', equityExposure: 44, bondExposure:56},
        {age: '57', equityExposure: 41, bondExposure:59},
        {age: '58', equityExposure: 39, bondExposure:61},
        {age: '59', equityExposure: 36, bondExposure:64},
        {age: '60', equityExposure: 33, bondExposure:67},
        {age: '61', equityExposure: 31, bondExposure:69},
        {age: '62', equityExposure: 28, bondExposure:72},
        {age: '63', equityExposure: 25, bondExposure:75},
        {age: '64', equityExposure: 23, bondExposure:77},
        {age: '65', equityExposure: 20, bondExposure:80},
    ]
    return (
        <div className="modal fade lss-modal-background" id="lssModal" tabIndex="-1" aria-labelledby="lssModalLabel" aria-hidden="true">
            <div className="modal-dialog lss-modal-dlg">
                <div className="modal-content lss-modal-content">
                    <div className="modal-body lss-modal-body">
                        <div className="lss-modal-title">Principal Lifestyle Strategy</div>
                        <div className="lss-modal-subttl">Rebalancing Glide Path</div>
                        <table className="table lss-table">
                            <thead>
                                <tr>
                                    <td>Age</td>
                                    <td>Equity Exposure</td>
                                    <td>Bond Exposure</td>
                                </tr>
                            </thead>
                            <tbody>
                                {lssData.map((data, index) => {
                                        return (
                                            <tr key={index}>
                                                <td className="font-weight-bold">{data.age}</td>
                                                <td>{data.equityExposure}%</td>
                                                <td>{data.bondExposure}%</td>
                                            </tr>
                                        )
                                    })
                                }
                                
                            </tbody>
                        </table>
                        <div className="row lss-para lss-mgt">
                            <div className="col">
                                <div className="font-weight-bold">For members age 50 or below</div>
                                <div>
                                    <ul className="lss-list">
                                        <li>
                                            Auto rebalancing will occur on the last valuation day of the member’s birth month and the last valuation date 6 months from the member’s birthday every year.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="row lss-para lss-mgb">
                            <div className="col">
                                <div className="font-weight-bold">For members age 51 and above</div>
                                <div>
                                    <ul className="lss-list">
                                        <li>
                                            Risk adjustment between Equity and Bond will occur on the last valuation day of the member’s birth month in each year
                                        </li>
                                        <li>
                                            Auto rebalancing will occur on the last valuation date 6 months from the member’s birthday every year.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="row d-flex justify-content-center lss-btn-mg">
                            <button type="button" className="col-md-6 col-12 lss-btn" data-dismiss="modal">
                                {t('gainLossPopUp.iUnderstand')}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LssPopUp;