import { saveContactInfoDetails, saveContactInfoUpdateFlag, saveContactUpdateJson, saveContactVerifyEditFlag, saveEmailInfoDetails, saveEmailInfoUpdateFlag, saveEmailUpdateJson, saveEmailUpdateOktaJson, saveEmailVerifyEditFlag, saveMobileInfoDetails, saveMobileInfoUpdateFlag, saveMobileUpdateJson, saveMobileUpdateOktaJson, saveMobileVerifyEditFlag } from "../actions/LandingAction";
import { openVerifyPage } from "../actions/VerifyAction";

const resetPropsValue = (props) => {
    props.dispatch(saveContactVerifyEditFlag(false));
    props.dispatch(saveContactInfoUpdateFlag(false));
    props.dispatch(saveContactInfoDetails({}));
    props.dispatch(saveContactUpdateJson({}));
    props.dispatch(saveMobileVerifyEditFlag(false));
    props.dispatch(saveMobileInfoUpdateFlag(false));
    props.dispatch(saveMobileInfoDetails({}));
    props.dispatch(saveMobileUpdateJson({}));
    props.dispatch(saveMobileUpdateOktaJson({}));
    if (props.verifyData != undefined) {
        const verifyData = {
            "updatedSection": "",
            "room": "",
            "block": "",
            "floor": "",
            "nameOfBuilding": "",
            "streetNumber": "",
            "nameOfStreet": "",
            "city": "",
            "postOrZip": "",
            "countryCode": "",
            "selectLangPref": "E",
            "territory": "",
            "verifyAddress": "",
            "fromVerifyFlag": false,
            "nonHKAddressLine1": "",
            "nonHKAddressLine2": "",
            "nonHKAddressLine3": "",
        }
        props.dispatch(
            openVerifyPage(verifyData),
        );
    }
    props.dispatch(saveEmailVerifyEditFlag(false));
    props.dispatch(saveEmailInfoUpdateFlag(false));
    props.dispatch(saveEmailInfoDetails({}));
    props.dispatch(saveEmailUpdateJson({}));
    props.dispatch(saveEmailUpdateOktaJson({}));
}

export default { resetPropsValue }