import moment from "moment";

const dynamicsort = (property, order) => {
  var sort_order = 1;
  if (order === "desc") {
    sort_order = -1;
  }
  return function (a, b) {
    if (a[property] < b[property]) {
      return -1 * sort_order;
    } else if (a[property] > b[property]) {
      return 1 * sort_order;
    } else {
      return 0 * sort_order;
    }
  };
};

const sortOnDate = (property, order, dateFormat) => {
  var sort_order = 1;
  if (order === "desc") {
    sort_order = -1;
  }
  return function (a, b) {
    const dateA = moment(a[property], dateFormat).toDate();
    const dateB = moment(b[property], dateFormat).toDate();
    if (dateA < dateB) {
      return -1 * sort_order;
    } else if (dateA > dateB) {
      return 1 * sort_order;
    } else {
      return 0 * sort_order;
    }
  };
};

const riskOrdering = (fundList, order) => {
  if (Array.isArray(fundList) && fundList.length > 0) {
    fundList.forEach(fund => {
      const risk = fund?.invRskNm ?? fund?.invRisk;
      switch (risk?.toUpperCase()) {
        case 'LOW':
          fund["riskOrder"] = 2;
          break;

        case 'LOW TO MODERATE':
        case 'MODERATE':
        case 'LOW TO MEDIUM':
          fund["riskOrder"] = 3;
          break;

        case 'MODERATE TO HIGH':
        case 'MEDIUM':
          fund["riskOrder"] = 4;
          break;

        case 'MEDIUM TO HIGH':
          fund["riskOrder"] = 5;
          break;

        case 'HIGH':
          fund["riskOrder"] = 6;
          break;

        case 'DIS':
        case 'STABLE':
          fund["riskOrder"] = 1;
          break;

        default:
          fund["riskOrder"] = 7;
          break;
      }
    });
    fundList.sort(dynamicsort("riskOrder", order));
  }
};

function removeExtraChrs(element) {
  let newElement;
  if ((element.investment).includes("2_")) {
    newElement = (element.investment).replace('2_', '');
  }
  if (element.investment.includes("TERM")) {
    newElement = (element.investment).replace("TERM", '');
  }
  return newElement;
}

const getNewSort = (arrayToSort, globalfundArray, sortProp) => {
  let newArr = [];
  for (let i = 0; i < arrayToSort.length; i++) {
    if (arrayToSort[i].length) {
      arrayToSort[i].forEach(element => {
        const filteredFundDetails = globalfundArray.find(fundInfoGlobalServiceObj => fundInfoGlobalServiceObj.invTypeNmbr === element.invTypeNmbr);
        let newElement = removeExtraChrs(element);
        if (newElement) {
          let arr = [];
          arr[0] = element;
          arr[1] = newElement;
          arr[2] = filteredFundDetails?.[sortProp];
          newArr.push(arr);
        } else {
          let arr = [];
          arr[0] = element;
          arr[1] = (element.investment);
          arr[2] = filteredFundDetails?.[sortProp];
          newArr.push(arr);
        }
      });
    }
  }
  newArr.sort(function (a, b) {
    return a[2]?.localeCompare(b[2]);
  });
  let finalArray = [];
  let count = 0;
  for (let i = 0; i < newArr.length; i++) {
    let subArray = [];
    for (let j = 1; j < newArr.length; j++) {
      if (newArr[i][0].invTypeNmbr === newArr[j][0].invTypeNmbr) {
        subArray.push(newArr[i][0]);
        subArray.push(newArr[j][0]);
        count++;
      } else {
        subArray.push(newArr[i][0]);
      }
    }
    i = count;
    if (subArray.length === 0) {
      finalArray.push(new Array(newArr[i][0]));
    } else {
      finalArray.push(Array.from(new Set(subArray)));
    }
  }
  return finalArray;
};

const sortArrayByAnotherArray = (sortArray, orderArray, key) => {
  sortArray?.sort(function (a, b) {
    return orderArray?.indexOf(a?.[key]) - orderArray?.indexOf(b?.[key]);
  });
};

const sortDashboardFunds = (fundList) => {
  const sortedFundList = fundList?.sort((fund1, fund2) => {
    return ((fund2?.value - fund1?.value) || (fund2?.currentValue - fund1?.currentValue) || fund1?.title?.localeCompare(fund2?.title));
  });
  return sortedFundList;
};

const sortOnDoubleDate = (property1, property2, order, dateFormat) => {
  var sortingOrder = 1;
  if (order === "desc") {
    sortingOrder = -1;
  }

  return function (date1, date2) {
    const dateAofProp1 = moment(date1[property1], dateFormat).toDate();
    const dateBofProp1 = moment(date2[property1], dateFormat).toDate();
    const dateAofProp2 = moment(date1[property2], dateFormat).toDate();
    const dateBofProp2 = moment(date2[property2], dateFormat).toDate();

    let dateA = dateAofProp2;
    if (dateAofProp1 < dateAofProp2) {
      dateA = dateAofProp1;
    }

    let dateB = dateBofProp2;
    if (dateBofProp1 < dateBofProp2) {
      dateB = dateBofProp1;
    }

    if (dateA < dateB) {
      return -1 * sortingOrder;
    } else if (dateA > dateB) {
      return 1 * sortingOrder;
    } else {
      return 0 * sortingOrder;
    }
  };
};

export default { dynamicsort, riskOrdering, sortOnDoubleDate, sortOnDate, getNewSort, sortArrayByAnotherArray, sortDashboardFunds };
