import PropTypes from 'prop-types';
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { Link } from "react-scroll";
import DownloadIcon from '../assets/Download.svg';
import '../css/FundInfoTabView.css';
import { brochureLinkOrso, brochureLinkOrsoPD_EN, brochureLinkOrsoPD_ZH, brochureLinkOrsoZH, brochureLinkSIMP_EN, brochureLinkSIMP_ZH, brochureLinkSMAP_EN, brochureLinkSMAP_ZH, HKAIIB, S800Acronym, schemeBrochureLinkConst, schemeBrochureLinkZhConst, SMP } from "../translations/common/commonConstants";

const NavigationMod = (props) => {
  const { t, i18n } = useTranslation("common");
  const selectedQuickPolicyInfo = props?.quickPolicyInfo[props.selectedPolicyIndex];
  const [isTop, setTop] = useState(true);
  const history = useHistory();
  const productTypeNmbr = selectedQuickPolicyInfo?.product?.productTypeNmbr;

  let brochureLink, brochureLinkZH;
  if (props?.isOrsoAccount) {
    if (productTypeNmbr == 125) {
      brochureLink = brochureLinkOrsoPD_EN;
      brochureLinkZH = brochureLinkOrsoPD_ZH;

    } else {
      brochureLink = brochureLinkOrso;
      brochureLinkZH = brochureLinkOrsoZH;
    }
  } else if (props.scheme == SMP) {
    if (productTypeNmbr == 129) {
      brochureLink = brochureLinkSIMP_EN;
      brochureLinkZH = brochureLinkSIMP_ZH;
    } else {
      brochureLink = brochureLinkSMAP_EN;
      brochureLinkZH = brochureLinkSMAP_ZH;
    }
  } else {
    brochureLink = schemeBrochureLinkConst;
    brochureLinkZH = schemeBrochureLinkZhConst;
  }

  useEffect(() => {
    document.addEventListener("scroll", () => {
      const top = window.scrollY < 500;
      if (top !== isTop) {
        setTop(top);
      }
    });
  });

  return (
    <>
      <div className="color-white fund-info-tab-sub-nav-bg sticky-top fund-info-tab-padding" >
        <div className="container color-white fund_info_tab_view">
          <nav className="nav sub-navigation scroll hide-scroll">
            <Link
              data-testid="tabIndex0"
              className={`nav-link ${isTop && "active"}`}
              activeClass="active"
              to="fund_info_tab_overview"
              spy={true}
              smooth={true}
              offset={-200}
              duration={500}
            >
              {t("fundInfo.overview")}
            </Link>
            <Link
              data-testid="tabIndex1"
              className={"nav-link "}
              activeClass="active"
              to="fund_info_tab_details"
              id="addressSection"
              spy={true}
              smooth={true}
              offset={-200}
              duration={500}
            >
              {t("fundDetails.title")}
            </Link>
            {!(props.cobrandGUA) &&
              <Link
                data-testid="tabIndex2"
                className={"nav-link "}
                id="emailSection"
                activeClass="active"
                to="fund_performance"
                spy={true}
                smooth={true}
                offset={-200}
                duration={500}
              >{t("fundPerformance.title")}
              </Link>
            }
            {props.scheme != SMP &&
              <Link
                data-testid="tabIndex3"
                className={"nav-link "}
                id="otherContactSection"
                activeClass="active"
                to="fund_details_returns"
                spy={true}
                smooth={true}
                offset={-200}
                duration={500}
              >
                {t("fundReturns.title")}
              </Link>
            }
            <Link
              data-testid="tabIndex4"
              className={"nav-link "}
              id="otherContactSection"
              activeClass="active"
              to="fund_details_downloads"
              spy={true}
              smooth={true}
              offset={-200}
              duration={500}
            >
              {t("downloads.title")}
            </Link>
            {((props?.scheme?.includes(S800Acronym) && !props.isOrsoAccount) || props.scheme == SMP || (props.isOrsoAccount && productTypeNmbr == 105 || productTypeNmbr == 125)) &&
              <a className="fund_info_tab_view_actions downloadLink ml-auto"
                href={i18n.language === 'en' ? brochureLink : brochureLinkZH}
                target="_blank"
                rel="noreferrer"
              >{t(props?.isOrsoAccount ? "downloads.brochureOrso" : "fundInfo.brochure")}<img className='fund_info_tab_view_icon' src={DownloadIcon}></img>
              </a>
            }
            {((props?.scheme?.includes(S800Acronym) && !props.isOrsoAccount) || (props?.isOrsoAccount) || props.scheme == SMP) &&
              <a className="fund_info_tab_view_actions downloadLink ml-auto"
                onClick={() => history.push("/fundFactSheet")}
                data-testid="fundFactSheet"
                target="_blank"
                rel="noreferrer"
              >{t("fundInfo.factsheet")}<img className='fund_info_tab_view_icon' src={DownloadIcon}></img>
              </a>
            }
            {props?.scheme?.includes(HKAIIB) &&
              <a className="fund_info_tab_view_actions downloadLink ml-auto"
                href={"https://rsc.principal.com.hk/AIIB/download/FFS_Principal_AIIB.PDF"}
                target="_blank"
                rel="noreferrer"
              >{t("fundInfo.factsheet")}<img className='fund_info_tab_view_icon' src={DownloadIcon}></img>
              </a>
            }
          </nav>
        </div>
        <hr className="landing-hr" />
      </div>
    </>
  );
};

NavigationMod.propTypes = {
  props: PropTypes.any,
  scheme: PropTypes.string,
  isOrsoAccount: PropTypes.bool,
  quickPolicyInfo: PropTypes.array,
  selectedPolicyIndex: PropTypes.number,
  cobrandGUA: PropTypes.bool
};
const mapStateToProps = (state) => {
  return {
    scheme: state.empLogin.scheme,
    isOrsoAccount: state.twoAccounts.isOrsoAccount,
    selectedPolicyIndex: state.twoAccounts.selectedPolicyIndex,
    quickPolicyInfo: state.twoAccounts.quickPolicyInfo
  };
};

export default connect(mapStateToProps)(NavigationMod);