import { instance } from '../AxiosInterceptor';

export default {
    getFundDetails: (productTypeNmbr, effectiveDate, requestHeaders) =>
        instance({
            method: 'GET',
            url: 'investments/funddetails',
            headers: requestHeaders,
            params: {
                productTypeNmbr,
                effectiveDate
            }
        }),

    getFundDetailsLatest: (productTypeNmbr, requestHeaders) =>
        instance({
            method: 'GET',
            url: 'investments/funddetails',
            headers: requestHeaders,
            params: {
                productTypeNmbr,
            }
        }),

    getFundFactSheet: (inputParams, requestHeaders) =>
        instance({
            method: 'GET',
            url: 'config/funds/fundfactsheets',
            headers: requestHeaders,
            'params': {
                'product_type_nmbr': inputParams.productTypeNmbr
            }
        }),

    getFundHistory: (params, requestHeaders) =>
        instance({
            method: 'GET',
            url: 'investments/priceshistory',
            headers: requestHeaders,
            params: {
                startDate: params.startDate,
                endDate: params.endDate,
                invTypeNmbr: params.invTypeNmbr
            }
        }),

    getFundConfigDetails: (contractNmbr, effectiveDate, requestHeaders) =>
        instance({
            method: 'GET',
            url: 'config/funds',
            headers: requestHeaders,
            params: {
                contractNmbr: contractNmbr
            }
        }),

    getFundClassData: (invTypeNm, requestHeaders) =>
        instance({
            method: 'GET',
            url: 'config/funds',
            headers: requestHeaders,
            params: {
                invTypeNm
            }
        }),

    getFundInfo: (contractNmbr, productTypeNmbr, effectiveDt, requestHeaders) =>
        instance({
            method: 'GET',
            url: 'funds/fundinfosummary',
            headers: requestHeaders,
            params: {
                contractNmbr,
                productTypeNmbr,
                effectiveDt
            }
        }),

    getLatestFundInfo: (contractNmbr, productTypeNmbr, requestHeaders) =>
        instance({
            method: 'GET',
            url: 'funds/fundinfosummary',
            headers: requestHeaders,
            params: {
                contractNmbr,
                productTypeNmbr
            }
        }),

    getFundInfoDetails: (productTypeNmbr, invTypeNm, endDate, startDate, invTypeNmbr, requestHeaders) =>
        instance({
            method: 'GET',
            url: 'funds/fundinfodetail',
            headers: requestHeaders,
            params: {
                productTypeNmbr,
                invTypeNm,
                endDate,
                startDate,
                invTypeNmbr
            }
        })
}
