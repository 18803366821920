import React, { useEffect, useState } from "react";
import { Link } from "react-scroll";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { connect } from "react-redux";

const NavigationComponent = (props) => {
  const { t } = useTranslation("common");
  const [isTop, setTop] = useState(true);

  useEffect(() => {
    document.addEventListener("scroll", () => {
      const top = window.scrollY < 100;
      if (top !== isTop) {
        setTop(top);
      }
    });
  });


  return (
    <>
      <div className={props.scheme === "S800" ? "sub-nav-bg sticky-top head" : "sub-nav-bg sticky-top"}>
        <div className="container">
          <nav className="nav sub-navigation scroll hide-scroll">
            <Link
              className={"nav-link " + (isTop ? " active" : "")}
              activeClass="active"
              to="general-section"
              spy={true}
              smooth={true}
              offset={-200}
              duration={500}
            >
              {t("titles.general")}
            </Link>
            <Link
              className="nav-link"
              activeClass="active"
              to="address-section"
              id="addressSection"
              spy={true}
              smooth={true}
              offset={-200}
              duration={500}
            >
              {t("titles.correspondenceaddress")}
            </Link>
            <Link
              className="nav-link"
              id="emailSection"
              activeClass="active"
              to="email-section"
              spy={true}
              smooth={true}
              offset={-200}
              duration={500}
            >
              {t("titles.email")}
            </Link>
            <Link
              className="nav-link"
              id="mobileSection"
              activeClass="active"
              to="mobile-section"
              spy={true}
              smooth={true}
              offset={-200}
              duration={500}
            >
              {t("titles.mobile")}
            </Link>
            <Link
              className="nav-link"
              id="otherContactSection"
              activeClass="active"
              to="contact-section"
              spy={true}
              smooth={true}
              offset={-200}
              duration={500}
            >
              {t("titles.othercontacts")}
            </Link>
          </nav>

        </div>
        <hr className="landing-hr" />
      </div>
    </>
  );
};

NavigationComponent.propTypes = {
  props: PropTypes.any,
  scheme: PropTypes.string
};

const mapStateToProps = (state) => {
  return {
    scheme: state.empLogin.scheme
  };
};
const Navigation = connect(mapStateToProps)(NavigationComponent);

export default Navigation;
