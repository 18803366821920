const contributionTrnsTypes = [
  "PAYROLL CONTRIBUTION",
  "PAYROLL CNTB",
  "AC1-FORF ALLOC CNTB",
  "SC4-NORMAL",
  "SC4-INT CREDIT",
  "SPORADIC CNTRB-CB",
  "SPORADIC CNTRB-NORMAL",
  "SPOR CNTRB-NORMAL",
  "SPORADIC CNTRB-TR RES",
  "SPORADIC CONTRIBUTION",
  "SC8-REAPPLY PAYROLL CNTB",
];

const transferInTrnsTypes = [
  "AI1-RETAIN IN SAME SCHEME",
  "AI1-TO IND AC FR SAME SCH",
  "AI2-CONVERSION",
  "PI1-EE CHOICE",
  "SC2-CONVERSION",
  "SC2-SURRENDER CHG ADJ",
  "SPORADIC CNTRB-CONVERSION",
  "SC3-TFR FRM OUTSIDE/PS",
  "SC3-SINGLE PREMIUM",
  "SPORADIC CNTRB-TRANSFER",
  "SPORADIC CNTRB-TRANSFERS",
  "SC7-TFR FRM PTC SCH",
  "SC10-EE CHOICE",
  "SPORADIC CNTRB-TR RES",
  "SC7-TO IND AC FRM PTC SCH",
];

const transferOutTrnsTypes = [
  "CD2-CONVERSION",
  "CMPLT DSTRB-CONVERSION",
  "CD3-TFR TO OUTSIDE",
  "CMPLT DSTRB-TFER OUT",
  "CMPLT DSTRB-TFR OUT",
  "AO1-RETAIN IN SAME SCHEME",
  "AO2-CONVERSION",
];

const withdrawalTrnsTypes = [
  "CD2-SURRENDER",
  "CMPLT DSTRB - DEATH",
  "CMPLT DSTRB - RETIRE",
  "CMPLT DSTRB - TERMINATION",
  "CMPLT DSTRB - TPD",
  "CMPLT DSTRB -TERMINATION",
  "CMPLT DSTRB FRM FORFEITUR",
  "CMPLT DSTRB FRM RESERVE",
  "CMPLT DSTRB-DEATH",
  "CMPLT DSTRB-RETIRE",
  "CMPLT DSTRB-TERMINATION",
  "CMPLT DSTRB-TPD",
  "CD3--MATURITY",
];

const unitCreditTrnsTypes = [
  "UNIT CREDIT",
  "SC6-GIR",
  "SPORADIC CNTRB-GIR",
  "GC1-GIR CNTB",
];

export { contributionTrnsTypes, transferInTrnsTypes, transferOutTrnsTypes, unitCreditTrnsTypes, withdrawalTrnsTypes };
