import moment from "moment";
import { DD_MM_YYYY_HH_mm_ss, asiaHongKong, enGB, PRODUCT_TYPE_NMBR_ORSO_CHUBB, PRODUCT_TYPE_NMBR_ORSO_CRC, PRODUCT_TYPE_NMBR_ORSO_HKSI, PRODUCT_TYPE_NMBR_ORSO_MAERSK, PRODUCT_TYPE_NMBR_ORSO_PTC, PRODUCT_TYPE_NMBR_ORSO_PD, PRODUCT_TYPE_NMBR_ORSO_FTLIFE } from "../translations/common/commonConstants";

const scrollToTop = () => {
    window.scrollTo(0, 0);
};

const callMedalliaUpdate = () => {
    if (window.KAMPYLE_ONSITE_SDK && typeof window.KAMPYLE_ONSITE_SDK.updatePageView === 'function')
    {
        window.KAMPYLE_ONSITE_SDK.updatePageView();
    }
};

const checkIsEffectiveforGFCOrso = () => {
    let isEffectiveTimeForOrso = false;
    // eslint-disable-next-line no-undef
    let startDate = moment(process.env.REACT_APP_GFC_EFFECTIVE_DATE_FOR_ORSO, DD_MM_YYYY_HH_mm_ss, true);
    // eslint-disable-next-line no-undef
    let endDate = moment(process.env.REACT_APP_GFC_EFFECTIVE_END_DATE_FOR_ORSO, DD_MM_YYYY_HH_mm_ss, true);

    const date = new Date();
    const currentDateString = date.toLocaleString(enGB, { timeZone: asiaHongKong })
    const currentDate = moment(currentDateString, DD_MM_YYYY_HH_mm_ss, true);
    if (currentDate.toDate() > startDate.toDate() && currentDate.toDate() < endDate.toDate()) {
        isEffectiveTimeForOrso = true;
    }
    return isEffectiveTimeForOrso;
};

const disableCurrentInvButton = (props) => {
    const productTypeNum = props.quickPolicyInfo[props.selectedPolicyIndex]?.product?.productTypeNmbr;
    const productTypeNumberOrsoArr = [PRODUCT_TYPE_NMBR_ORSO_CRC, PRODUCT_TYPE_NMBR_ORSO_MAERSK, PRODUCT_TYPE_NMBR_ORSO_CHUBB, PRODUCT_TYPE_NMBR_ORSO_PTC, PRODUCT_TYPE_NMBR_ORSO_FTLIFE, PRODUCT_TYPE_NMBR_ORSO_HKSI, PRODUCT_TYPE_NMBR_ORSO_PD]
    const isOrsoEffectiveGFCUser = productTypeNumberOrsoArr.includes(productTypeNum);

    let enableButton = true;
    let isEffectiveTime = checkIsEffectiveforGFCOrso();
    if (isEffectiveTime && isOrsoEffectiveGFCUser) {
        enableButton = false;
    }
    return enableButton;
}

export default { callMedalliaUpdate, scrollToTop, checkIsEffectiveforGFCOrso, disableCurrentInvButton };
