import {
    FUND_SWITCH_ESTIMATED_AMOUNT, FUND_SWITCH_OUT_PERCENTAGE,
    SAVE_ALL_POLICY_FUND_INFO, SAVE_CONTRIBUTION_TYPE,
    SAVE_FUNDWISE_PERCENTAGE_ALLOCATION_FUTURE,
    SAVE_FUND_INFO_DATA, SAVE_INVESTMENT_DIRECTION, SAVE_INVESTMENT_TRANSFER_DETAILS, SAVE_PENDING_FUND,
    SAVE_PENDING_FUNDS_LIST, SAVE_SELECTED_ASSET_TYPE,
    SAVE_SELECTED_FUND_FOR_SWITCH_OUT,
    SAVE_SWITCHIN_CONFIRMED_FUNDS, SAVE_SWITCH_IN_DATA,
    SAVE_BAL_CHECK_EDIT,
    SAVE_GFC_INVESTMENT_FLAG
} from "../actions/ActionConstants";

const initialState = {
    assetType: '',
    contributionType: '',
    selectedFundForSwitchOut: {},
    fundSwitchOutPercentage: '',
    fundSwitchEstimatedAmount: '',
    fundwisePercentAllocationForFuture: [],
    fundSwitchPerformanceList: [],
    switchInData: [],
    investmenttransferdetails: [],
    fundInfoData: [],
    investmentDirection: {},
    pendingFund: [],
    pendingFundsList: {},
    switchInConfirmedFunds: [],
    allPolicyFundInfo: [],
    showEditBtn: false,
    hasGfcInv: false
}

export default function fundSwitchReducer(state = initialState, action) {
    switch (action.type) {
        case SAVE_SELECTED_ASSET_TYPE:
            return { ...state, assetType: action.data }
        case SAVE_CONTRIBUTION_TYPE:
            return { ...state, contributionType: action.data };
        case SAVE_SELECTED_FUND_FOR_SWITCH_OUT:
            return { ...state, selectedFundForSwitchOut: action.data };
        case FUND_SWITCH_OUT_PERCENTAGE:
            return { ...state, fundSwitchOutPercentage: action.data };
        case FUND_SWITCH_ESTIMATED_AMOUNT:
            return { ...state, fundSwitchEstimatedAmount: action.data };
        case SAVE_FUNDWISE_PERCENTAGE_ALLOCATION_FUTURE:
            return { ...state, fundwisePercentAllocationForFuture: action.data }
        case SAVE_SWITCH_IN_DATA:
            return { ...state, switchInData: action.data }
        case SAVE_INVESTMENT_TRANSFER_DETAILS:
            return { ...state, investmenttransferdetails: action.data }
        case SAVE_FUND_INFO_DATA:
            return { ...state, fundInfoData: action.data }
        case SAVE_INVESTMENT_DIRECTION:
            return { ...state, investmentDirection: action.data }
        case SAVE_PENDING_FUND:
            return { ...state, pendingFund: action.data }
        case SAVE_PENDING_FUNDS_LIST:
            return { ...state, pendingFundsList: action.data }
        case SAVE_SWITCHIN_CONFIRMED_FUNDS:
            return { ...state, switchInConfirmedFunds: action.data }
        case SAVE_ALL_POLICY_FUND_INFO:
            return { ...state, allPolicyFundInfo: action.data }
        case SAVE_BAL_CHECK_EDIT:
            return { ...state, showEditBtn: action.data }
        case SAVE_GFC_INVESTMENT_FLAG:
            return { ...state, hasGfcInv: action.data }
        default:
            return state;
    }
}