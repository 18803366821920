import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from 'react-redux';
import { Slide, toast } from "react-toastify";
import { saveChangeFundDetails, setCurrentPageNo, setCurrentPostOnPage, setChangesPageEidtFlag } from '../../actions/ChangesAction';
import ChangesApi from "../../API/Changes/ChangesApi";
import arrorright from '../../assets/Arrow_right-2.svg';
import Toast from "../../Components/Toast";
import '../../css/Changes.css';
import { EE, HKAIIB, indAcc, MAN, processorId, SEP, SMP } from "../../translations/common/commonConstants";
import moment from "moment";
const toastOptions = {
    position: 'top-center',
    autoClose: 4000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    transition: Slide,
    rtl: false,
    closeButton: false,
}
const sendNotification = (type) => {
    toast(<Toast type={type} />, toastOptions)
}

const checkInvTypeNmbr = (switchOutList, switchOutInvTypeNmbr) => {
    let resultInvNmbr = false;
    if (Array.isArray(switchOutList) && Array.isArray(switchOutInvTypeNmbr)) {
        for (let index = 0; index < switchOutInvTypeNmbr.length; index++) {
            const invTypeNumber = switchOutInvTypeNmbr[index];
            if (switchOutList?.includes(invTypeNumber)) {
                resultInvNmbr = true;
                break;
            }
        }
    }
    return resultInvNmbr
}

const ChangeFundDetailsPagination = (props) => {
    const { t, i18n } = useTranslation("common");
    let [state, setState] = useState({
        totalPost: 0,
        paginationNumber: []
    });
    const [currentPage, setCurrentPage] = useState(props.pageNo);
    const cobrandGPA = props.scheme == SMP && props?.quickPolicyInfo[props.selectedPolicyIndex]?.orgTypeNm === indAcc;
    let [currentPosts, setCurrentPosts] = useState([]);
    const [postsPerPage] = useState(10);
    useEffect(() => {
        if (props.pageNo) {
            setCurrentPage(props.pageNo);
            setCurrentPosts(props.currentPostsOnPage);
        } else {
            setCurrentPage(0);
            setCurrentPosts(props.data.slice(0, postsPerPage));
        }
        let paginationNumber = []
        let totalPost = Math.ceil((props.data.length) / 10);
        for (let i = 0; i <= totalPost; i++) {
            paginationNumber.push(i)
        }
        state.totalPost = totalPost;
        state.paginationNumber = paginationNumber
        let paginationArray = range(0, state.totalPost - 1);
        setState({
            ...state,
            paginationNumber: paginationArray
        })
    }, [props.data]);
    const range = (start, end) => {
        return Array(end - start + 1).fill().map((_, idx) => start + idx)
    }
    const paginate = (number) => {
        if (number <= state.totalPost) {
            setCurrentPage(number);
            setCurrentPosts(props.data.slice(number * postsPerPage, number * postsPerPage + postsPerPage));
            props.dispatch(setCurrentPageNo(number));
            props.dispatch(setCurrentPostOnPage(props.data.slice(number * postsPerPage, number * postsPerPage + postsPerPage)));
        }
    };

    const onGenerateRecordsClick = (props, item) => {
        if (item.fundType === "fundSwitch.cardTitle1") {
            getCycleActivityDetails(props, item)
        } else {
            props.dispatch(setChangesPageEidtFlag(true));
            let activityDetails = {}
            let obj = {};

            item.switchInData.forEach((data) => {
                if (!obj[data.invTypeNmbr]) {
                    obj[data.invTypeNmbr] = { name: data.legalNm, allocation: data.cntrbRt * 100 }
                }
            })
            let arr = [];
            for (let key in obj) {
                arr.push(obj[key])
            }
            activityDetails.activityInputDate = item.lastChangeDt;
            activityDetails.transHistoryEffectiveDt = item.transHistoryEffectiveDate;
            activityDetails["contributionType"] = item.contributionType;
            activityDetails.statusType = item.statusType;
            activityDetails.fundType = item.fundType;
            activityDetails.switchInData = arr;
            props.dispatch(saveChangeFundDetails(activityDetails));
            props.setCurrentPage('changesDetails');
        }
    }
    const checkLssInvNmbr = (invTypeNumberList) => {
        const lssInvNmbrs = [313, 314, 315];
        let resultSwitchOut = []
        invTypeNumberList.forEach((invTypeNmbr) => {
            if (lssInvNmbrs?.includes(invTypeNmbr)) {
                resultSwitchOut.push(...lssInvNmbrs)
            } else {
                resultSwitchOut.push(invTypeNmbr)
            }

        })
        return resultSwitchOut
    }
    const getCycleActivityDetails = (props, item) => {
        const headers = {
            'pisys_auth_token': props.pisysToken,
            'username': props.clientNmbr,
            'Access-Control-Allow-Origin': '*'
        }
        let clntRltnshpNmbr = props.clntRltnshpNmbr;
        const contrubutionType = props.isAgencyPlan ? "fsContribution.type12" : "fsContribution.type6";
        ChangesApi.getCycleActivityDetails((clntRltnshpNmbr), (item?.refNumber?.substring(2)), headers)
            .then((response) => {
                let decodedResponse = response.data

                let activityDetails = {}
                activityDetails.fundType = item.fundType;
                activityDetails.statusType = item.statusType;
                activityDetails.activityTableNmbr = item.refNumber;
                activityDetails.activityInputDate = item.activityInputdate;
                activityDetails.transHistoryEffectiveDt = item.transHistoryEffectiveDate;
                activityDetails.actyNmbr = item.activityTablenmbr;
                activityDetails.transactions = []
                activityDetails.pmtMethodTypeNm = null
                activityDetails.processorId = processorId
                let manSwitchOutInvList = []
                let volSwitchOutInvList = []
                let eeSwitchOutInvList = []
                let erSwitchOutInvList = []

                let transactionDetails = decodedResponse ?? []

                let filteredGpaList = []
                let transactionDetailsGpa = []
                if (cobrandGPA && transactionDetails.length > 1) {
                    for (let i = 0; i < transactionDetails.length; i++) {
                        transactionDetails?.[i]?.existingAssetDetail.forEach((item) => {
                            if (!(filteredGpaList.some((element) => element.invTypeNmbr == item.invTypeNmbr && element.tferTypeCd == item.tferTypeCd))) {
                                filteredGpaList.push(item)
                            }
                        })
                    }
                    transactionDetailsGpa.push({
                        ...transactionDetails[0], existingAssetDetail: [...filteredGpaList]
                    })
                    transactionDetails = transactionDetailsGpa
                }

                if (Array.isArray(transactionDetails) && transactionDetails.length > 0) {
                    activityDetails.pmtMethodTypeNm = transactionDetails?.[0]?.existingAssetDetail?.[0]?.pmtMethodTypeNm
                    transactionDetails.forEach((transaction) => {
                        const transactionList = transaction?.existingAssetDetail ?? []

                        if (Array.isArray(transactionList) && transactionList.length > 0) {
                            let actvtDtl = {}
                            actvtDtl.contributionType = ''
                            actvtDtl.switchOutData = []
                            actvtDtl.switchInData = []
                            let switchOutInvTypeNmbr = []
                            if (props?.scheme?.includes(HKAIIB) || props?.isOrsoAccount || props?.scheme?.includes(SMP)) {
                                if (transactionList?.[0]?.moneyTypeNm?.includes(EE) || (props?.scheme?.includes(SMP) && transactionList?.[0]?.moneyTypeNm?.includes(SEP)) || cobrandGPA) {
                                    actvtDtl.contributionType = "fsContribution.type4"
                                } else {
                                    actvtDtl.contributionType = (props?.isOrsoAccount || props?.scheme?.includes(SMP)) ? contrubutionType : "fsContribution.type5"
                                }
                            } else {
                                if (transactionList?.[0]?.moneyTypeNm?.includes(MAN)) {
                                    actvtDtl.contributionType = "fsContribution.type1"
                                } else {
                                    actvtDtl.contributionType = "fsContribution.type2"
                                }
                            }

                            transactionList.forEach((trnsctn) => {
                                if (trnsctn.tferTypeCd === 1) {
                                    actvtDtl.switchOutData.push(trnsctn)
                                } else {
                                    actvtDtl.switchInData.push(trnsctn)
                                }
                            })
                            switchOutInvTypeNmbr = actvtDtl?.switchOutData?.map((switchOutFund) => switchOutFund?.invTypeNmbr)

                            if (props?.scheme?.includes(HKAIIB)) {
                                if (activityDetails.transactions.length === 0 || (actvtDtl.contributionType === "fsContribution.type4" && !checkInvTypeNmbr(eeSwitchOutInvList, switchOutInvTypeNmbr)) || (actvtDtl.contributionType === "fsContribution.type5" && !checkInvTypeNmbr(erSwitchOutInvList, switchOutInvTypeNmbr))) {
                                    activityDetails.transactions.push(actvtDtl)
                                    if (actvtDtl.contributionType === "fsContribution.type4") {
                                        eeSwitchOutInvList.push(...checkLssInvNmbr(switchOutInvTypeNmbr))
                                    } else {
                                        erSwitchOutInvList.push(...checkLssInvNmbr(switchOutInvTypeNmbr))
                                    }
                                }
                            } else if (props?.isOrsoAccount || props?.scheme?.includes(SMP)) {
                                if (activityDetails.transactions.length === 0 || (actvtDtl.contributionType === "fsContribution.type4" && !checkInvTypeNmbr(eeSwitchOutInvList, switchOutInvTypeNmbr)) || (actvtDtl.contributionType === contrubutionType && !checkInvTypeNmbr(erSwitchOutInvList, switchOutInvTypeNmbr))) {
                                    activityDetails.transactions.push(actvtDtl)
                                    if (actvtDtl.contributionType === "fsContribution.type4") {
                                        eeSwitchOutInvList.push(...switchOutInvTypeNmbr)
                                    } else {
                                        erSwitchOutInvList.push(...switchOutInvTypeNmbr)
                                    }

                                }
                            } else {
                                if (activityDetails.transactions.length === 0 || (actvtDtl.contributionType === "fsContribution.type1" && !checkInvTypeNmbr(manSwitchOutInvList, switchOutInvTypeNmbr)) || (actvtDtl.contributionType === "fsContribution.type2" && !checkInvTypeNmbr(volSwitchOutInvList, switchOutInvTypeNmbr))) {
                                    activityDetails.transactions.push(actvtDtl)
                                    if (actvtDtl.contributionType === "fsContribution.type1") {
                                        manSwitchOutInvList.push(...switchOutInvTypeNmbr)
                                    } else {
                                        volSwitchOutInvList.push(...switchOutInvTypeNmbr)
                                    }

                                }
                            }
                        }
                    })
                }

                props.dispatch(saveChangeFundDetails(activityDetails));
                props.dispatch(setChangesPageEidtFlag(true))
                props.setCurrentPage('changesDetails');
            })
            .catch(() => {
                sendNotification('error')
            })

    }
    return (
        <React.Fragment>
            {currentPosts.map((item, index) => {
                return (
                    <div className="row" key={'row' + index}>
                        <div className="col-12">
                            <div className="pds-card ch-cardBody ch-cursor-pointer" data-testid="changes-listitem" onClick={() => onGenerateRecordsClick(props, item)}>
                                <div className="row ">
                                    <div className="col-10 col-md-11 col-sm-11 col-xl-11 col-lg-11">
                                        <label className="lbl-ch-card-fundName">{t(item.fundType)}</label>
                                    </div>
                                    {(item.statusType != 'Completed') && <div className="col-1 text-right ch-cursor-pointer">
                                        <img src={arrorright}></img>
                                    </div>}
                                </div>
                                <div className="row">
                                    <label className={item.statusType === 'Pending' ? "col-12 lbl-ch-fund-status pending" : item.statusType === 'Cancelled' ? "col-12 lbl-ch-fund-status cancelled" :
                                        item.statusType === 'Completed' ? "col-12 lbl-ch-fund-status completed" : item.statusType === 'Failed' ? "col-12 lbl-ch-fund-status failed" :
                                            item.statusType === 'Being Processed' ? "col-12 lbl-ch-fund-status being-processed " : 'col-12 lbl-ch-fund-status'} >
                                        {item.statusType === 'Pending' ? t('changesFundDetails.pending') : item.statusType === 'Cancelled' ? t('changesFundDetails.cancelled') : item.statusType === 'Completed' ? t('changesFundDetails.completed') : item.statusType === 'Failed' ? t('changesFundDetails.failed') :
                                            item.statusType === 'Being Processed' && (t('changesFundDetails.beingProcessed'))}

                                    </label>
                                </div>

                                {(item.statusType === 'Completed') ? (<div className="row ">
                                    <div className="col-6">
                                        <label className="value-lbl-ch-card-field">{t('changesFundDetails.transactionDate')}</label>
                                    </div>
                                    <div className="col-6 text-right">
                                        <label className="value-lbl-ch-card-field">{moment(item.transHistoryEffectiveDate, "DD/MM/YYYY").format(i18n.language === 'en' ? 'D MMM YYYY' : 'YYYY年M月D日')}</label>
                                    </div>
                                </div>) : (false)
                                }

                                {((item.refNumber && item.fundType === "fundSwitch.cardTitle1") && (item.statusType != 'Completed')) ? (<div className="row ">
                                    <div className="col-6">
                                        <label className="value-lbl-ch-card-field" >{t('changesFundDetails.transactionNo')}</label>
                                    </div>
                                    <div className="col-6 text-right">
                                        <label className="value-lbl-ch-card-field">{item.refNumber}</label>
                                    </div>
                                </div>) : (false)
                                }
                                {((item.fundType === "fundSwitch.cardTitle1") && (item.statusType != 'Completed')) ? (
                                    <div className="row ">
                                        <div className="col-6">
                                            <label className="value-lbl-ch-card-field">{t('changesFundDetails.instructionDate')}</label>
                                        </div>
                                        <div className="col-6 text-right">
                                            <label className="value-lbl-ch-card-field">{moment(item.activityInputdate, "DD/M/YYYY").format(i18n.language === 'en' ? 'D MMM YYYY' : 'YYYY年M月D日')}</label>
                                        </div>
                                    </div>
                                ) : (false)
                                }
                                {((item.fundType === "fundSwitch.cardTitle1") && (item.statusType != 'Completed')) ? (<div className="row ">
                                    <div className="col-6">
                                        <label className="value-lbl-ch-card-field">{t('changesFundDetails.transactionDate')}</label>
                                    </div>
                                    <div className="col-6 text-right">
                                        <label className="value-lbl-ch-card-field">{moment(item.transHistoryEffectiveDate, "DD/MM/YYYY").format(i18n.language === 'en' ? 'D MMM YYYY' : 'YYYY年M月D日')}</label>
                                    </div>
                                </div>) : (false)
                                }
                            </div>
                        </div>
                    </div>
                );
            })}
            <div className="row">
                <div className="col-12">
                    <div className="pagination ch-pagination-padding">
                        <div data-testid="previous-btn-test" className={currentPage === 0 ? "ch-page-item ch-leftarrordiv ch-cursor-pointer" : "ch-page-item ch-rightarrordiv ch-cursor-pointer"} onClick={() => paginate(currentPage - 1 >= 0 ? currentPage - 1 : 0)}><img className="ch-leftarrow text-center ch-paginationMarginicon" src={arrorright}></img></div>
                        {state.paginationNumber.map((number) => {
                            return (
                                (currentPage === 0 && number <= currentPage + 2) ? <div key={'row' + number} className={currentPage === number ? "ch-page-item ch-paginationMargin ch-activePage" : "ch-page-item ch-paginationMargin ch-deactivePage"} onClick={() => paginate(number)}>{number + 1}</div> :
                                    (number >= currentPage - 1 && number <= currentPage + 1 && number < state.totalPost) && <div key={'row' + number} className={currentPage === number ? "ch-page-item ch-paginationMargin ch-activePage" : "ch-page-item ch-paginationMargin ch-deactivePage"} onClick={() => paginate(number)}>{number + 1}</div>
                            );
                        })}
                        {(state.totalPost - currentPage) > 3 && <div className="ch-page-item  ch-paginationMargin ch-cursor-pointer">...</div>}
                        {(state.totalPost - currentPage) > 3 && <div key={'row' + state.totalPost} className={currentPage === state.totalPost ? "ch-page-item ch-paginationMargin ch-activePage" : "ch-page-item ch-paginationMargin ch-deactivePage"} onClick={() => paginate(state.totalPost - 1)}>{state.totalPost}</div>}
                        <div data-testid="next-btn-test" className={state.totalPost - 1 === currentPage ? "ch-page-item ch-leftarrordiv ch-cursor-pointer" : "ch-page-item ch-rightarrordiv ch-cursor-pointer"} onClick={() => paginate(currentPage + 1 < state.totalPost ? currentPage + 1 : state.totalPost - 1)}><img className="text-center ch-paginationMarginicon" src={arrorright}></img></div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
ChangeFundDetailsPagination.propTypes = {
    props: PropTypes.any,
    totalPostlength: PropTypes.number,
    paginationNumber: PropTypes.arrayOf(PropTypes.number),
    data: PropTypes.arrayOf(PropTypes.object),
    setCurrentPage: PropTypes.func,
    dispatch: PropTypes.func,
    pisysToken: PropTypes.string,
    clientNmbr: PropTypes.string,
    clntRltnshpNmbr: PropTypes.number,
    pageNo: PropTypes.number,
    currentPostsOnPage: PropTypes.arrayOf(PropTypes.string),
    scheme: PropTypes.string,
    isOrsoAccount: PropTypes.bool,
    isAgencyPlan: PropTypes.bool,
    selectedPolicyIndex: PropTypes.number,
    quickPolicyInfo: PropTypes.array,
};
const mapStateToProps = (state) => {
    return {
        pisysToken: state.token.pisysToken,
        clientNmbr: state.empLogin.clientNmbr,
        changeFundDetails: state.changeFundDetails.changeFundDetails,
        clntRltnshpNmbr: state.empLogin.clntRltnshpNmbr,
        pageNo: state.changeFundDetails.pageNo,
        currentPostsOnPage: state.changeFundDetails.currentPostsOnPage,
        scheme: state.empLogin.scheme,
        isOrsoAccount: state.twoAccounts.isOrsoAccount,
        isAgencyPlan: state.dashboard.isAgencyPlan,
        selectedPolicyIndex: state.twoAccounts.selectedPolicyIndex,
        quickPolicyInfo: state.twoAccounts.quickPolicyInfo,
    }
}
export default connect(mapStateToProps)(ChangeFundDetailsPagination);
