import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Slide, toast } from 'react-toastify';
import { saveFirstTimeSelectAccoutnLoading, saveSchemeType } from "../../actions/EmployeeLoginAction";
import { saveContactInfoDetails, saveContactInfoUpdateFlag, saveContactUpdateJson, saveContactVerifyEditFlag, saveCurrentLoadedPageOnUI, saveEmailInfoDetails, saveEmailInfoUpdateFlag, saveEmailUpdateJson, saveEmailVerifyEditFlag, saveMobileInfoDetails, saveMobileInfoUpdateFlag, saveMobileUpdateJson, saveMobileVerifyEditFlag } from "../../actions/LandingAction";
import { savePisysToken } from "../../actions/TokenAction";
import { saveAuthenticationFlag } from "../../actions/TwoFactorAuthenticationAction";
import Toast from '../../Components/Toast';
import "../../css/SuperLogonLogin.css";
import LoginHeader from "../../SharedComponents/LoginHeader";
import { SuperLogonUtils } from "../../utils";
import pkceChallenge from 'pkce-challenge';
import randomstring from 'randomstring'
import { saveCodeChallenge, saveCodeVerifier, saveNonce, saveState } from "../../actions/SSOAction";


const SuperLogon = (props) => {
    const [serviceCallFlag, setServiceCallFlag] = useState(false);

    useEffect(() => {
        resetReduxToDefaults(props)
        props.dispatch(saveAuthenticationFlag(false))
        setServiceCallFlag(true);
        SuperLogonUtils.getCode().then(async (response) => {
            resetPropsValue();
            if (response.data) {

                const url = response.data;
                const pkce = pkceChallenge();
                const codeChallenge = pkce?.code_challenge ?? '';
                const codeVerifier = pkce?.code_verifier ?? '';
                const nonce = randomstring.generate({
                    length: 10,
                    charset: 'alphabetic'
                });
                const state = randomstring.generate({
                    length: 10,
                    charset: 'alphabetic'
                });

                props.dispatch(saveCodeChallenge(codeChallenge));
                props.dispatch(saveCodeVerifier(codeVerifier));
                props.dispatch(saveNonce(nonce));
                props.dispatch(saveState(state));

                const ssoLink = SuperLogonUtils.generateOAuthUrl(url, codeChallenge, nonce, state);
                window.location.href = ssoLink;
            }
        }).catch(() => {
            setServiceCallFlag(false);
            history.push("/superLogon/signIn");
            sendNotification('something-went-wrong');
        });
    }, [])

    const resetReduxToDefaults = (props) => {
        props.dispatch(saveCurrentLoadedPageOnUI(''))
        props.dispatch(saveSchemeType(""))
        props.dispatch(savePisysToken(""))
    }

    const toastOptions = {
        position: 'top-center',
        autoClose: 4000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        transition: Slide,
        rtl: false,
        closeButton: false,
    }

    const sendNotification = (type) => { toast(<Toast type={type} />, toastOptions) }

    const resetPropsValue = () => {
        props.dispatch(saveContactVerifyEditFlag(false));
        props.dispatch(saveContactInfoUpdateFlag(false));
        props.dispatch(saveContactInfoDetails({}));
        props.dispatch(saveContactUpdateJson({}));
        props.dispatch(saveEmailVerifyEditFlag(false));
        props.dispatch(saveEmailInfoUpdateFlag(false));
        props.dispatch(saveEmailInfoDetails({}));
        props.dispatch(saveEmailUpdateJson({}));
        props.dispatch(saveMobileInfoDetails({}));
        props.dispatch(saveMobileInfoUpdateFlag(false));
        props.dispatch(saveMobileUpdateJson({}));
        props.dispatch(saveMobileVerifyEditFlag(false));
        props.dispatch(saveFirstTimeSelectAccoutnLoading(false));
    };

    return (
        <React.StrictMode>
            <LoginHeader />
            <div className="main-content">
                <div className="sll-container row">
                    <label className="col-12 sll-title text-center">Super Logon Login</label>
                    <div className="col-sm-12 col-md-6 mx-auto">
                        {serviceCallFlag && <div className="d-flex justify-content-center">
                            <div className="spinner-border spinner m-5" role="status">
                                <span className="sr-only"></span>
                            </div>
                        </div>}
                    </div>
                </div>
            </div>
        </React.StrictMode>
    )
}

SuperLogon.propTypes = {
    props: PropTypes.any,
    dispatch: PropTypes.func
};
export default connect()(SuperLogon);
