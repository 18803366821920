import React from "react";
import arrow from "./../assets/breadcrumb-icon.png"
import PropTypes from "prop-types";
import '../css/BreadCrumbs.css'

const Breadcrumbs = (props) => {

    return (
      <div className="container">
      <nav className="d-none d-md-block d-lg-block d-xl-block row" aria-label="breadcrumb">
        <div className="col-12">
        <ol className={(props.currentPage === 'contributionTypes'|| props.currentPage === 'assetTypes' )?"breadcrumb mb-0 breadcrumbbackground ":"breadcrumb mb-0 "}>
            { props.breadCumbInfo.map((item,index)=>{               
              return(
                <>
                  <li onClick={()=>{props.setCurrentPage(item?.route);
                  if(props.currentPage=="contributionDetail" && item?.route=="contributionRecord"){ props.setReturnFlag("true");}}
                   } className={`breadcrumb-item`}>
                    {(item?.active)?(<span className='active hover'>{item.title}</span>):(<span>{item.title}</span>)
                    }
                  </li>
                  {
                    (props.breadCumbInfo.length === index+1 )
                    ?
                    (false)
                    :
                    (
                    <li className="breadcrumb-item ">
                      <img src={arrow} />
                    </li>
                    )
                  }

                </>
              )
            })
            }
        </ol>
        </div>
      </nav>
      </div>
    )
};

Breadcrumbs.propTypes = {
  props: PropTypes.any,
  breadCumbInfo: PropTypes.array,
  setCurrentPage:PropTypes.func,
  currentPage:PropTypes.any,
  setReturnFlag:PropTypes.func,

};

export default Breadcrumbs;
