import FormatUtils from "./FormatUtils";


const checkPercentageAccuracy = (fundList, attributeName) => {

    if (Array.isArray(fundList) && fundList.length > 0) {
        const percentReducer = (total, fund) => total + Number(fund?.[attributeName]) ?? 0;
        let totalPercent = fundList.reduce(percentReducer, 0)

        if (totalPercent != 100) {

            fundList.forEach((fund) => {
                fund[attributeName] = FormatUtils.roundNumber((Number(fund?.[attributeName]) * 100 / totalPercent), 2) || 0
            });
            totalPercent = fundList.reduce(percentReducer, 0)

            if (totalPercent != 100) {

                let fundPercents = []
                let totalPercent = 0
                fundList.forEach((fund) => {
                    let fundPercent = Number(fund?.[attributeName])
                    fundPercents.push(fundPercent)
                    totalPercent = totalPercent + fundPercent
                })

                const difPercent = totalPercent - 100

                let index = -1
                if (difPercent > 0) {
                    index = fundPercents.indexOf(Math.max(...fundPercents))
                } else {
                    index = fundPercents.indexOf(Math.min(...fundPercents))
                }

                if (index !== -1) {
                    fundList[index][attributeName] = FormatUtils.roundNumber((Number(fundList?.[index]?.[attributeName]) - difPercent), 2)
                }
            }
        }

    }
    return fundList;
}


const normalPercentageAccuracyCheck = (fundList, attributeName) => {
    if (Array.isArray(fundList) && fundList.length > 0) {
        const percentReducer = (total, fund) => total + FormatUtils.roundNumber(Number(fund?.[attributeName] ?? 0), 2);
        let totalPercent = fundList.reduce(percentReducer, 0)
        // Commented by Jadhav, Suraj -> will remove it once ticket 761 will get closed
        /* if (totalPercent != 100) {
            fundList.forEach((fund) => {
                fund[attributeName] = FormatUtils.roundNumber((Number(fund?.[attributeName]) * 100 / totalPercent), 2) || 0
            });
            totalPercent = fundList.reduce(percentReducer, 0) */
            if (totalPercent != 100) {
                let fundPercents = []
                fundList.forEach((fund) => {
                    let fundPercent = Number(fund?.[attributeName] ?? 0)
                    fundPercents.push(fundPercent)
                })
                const difPercent = totalPercent - 100

                let index = -1
                index = fundPercents.indexOf(Math.max(...fundPercents))

                if (index !== -1) {
                    fundList[index][attributeName] = FormatUtils.roundNumber((Number(fundList?.[index]?.[attributeName]) - difPercent), 2)
                }
            }
        // }

    }
    return fundList
}

export { checkPercentageAccuracy, normalPercentageAccuracyCheck }