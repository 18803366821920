import {
    IS_AGENCY_PLAN,
    SAVE_CUSTOM_FIELDS, SAVE_CUSTOM_FIELDS_ARRAY, SAVE_GAIN_LOSS_DATA, SAVE_POPUP_ALERT_STATUS, SAVE_TOTAL_ACCOUNT_BALANCE
} from './ActionConstants.js';

export function saveGainLossData(data) {
    return { type: SAVE_GAIN_LOSS_DATA, data };
}
export function saveTotalAccountBalance(data) {
    return { type: SAVE_TOTAL_ACCOUNT_BALANCE, data };
}
export function saveCustomFields(data) {
    return { type: SAVE_CUSTOM_FIELDS, data };
}
export function saveCustomFieldsArray(data) {
    return { type: SAVE_CUSTOM_FIELDS_ARRAY, data };
}
export function savePopUpAlertStatus(data) {
    return { type: SAVE_POPUP_ALERT_STATUS, data };
}
export function isAgencyPlan(data) {
    return { type: IS_AGENCY_PLAN, data };
}