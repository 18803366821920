import PropTypes from 'prop-types';
import React from "react";
import { useTranslation } from "react-i18next";
import { connect } from 'react-redux';
import ArrowRight from "../../../assets/Icon_Arrow_right.svg";
import empPlan from "../../../assets/Icon_ORSO.svg";
import "../../../css/EmpLogin.css";

const OtherORSO = () => {
    const { t } = useTranslation("common");

    const onClickLinkOpen = () => {
        document.getElementById('osroLink').click();
    }

    return (
        <React.Fragment>
            <div className="login-card orso-card text-center" onClick={onClickLinkOpen} id="orso">
                <img src={empPlan} className="empPlan" />
                <div className="row">
                    <div className="col-12">
                        <a href="https://e-orso.principal.com.hk/orso/welcome.do?methodName=welcome" className="login-type-cards-title" name="goto" data-testid="otherOrso">
                            {t("titles.orso")}
                            <img src={ArrowRight} alt="" className="gotoArrow" />
                        </a>
                        <p className="lt-title-transparent">
                            {t("titles.mpfEmployee").substring(t("titles.orso").length + 9)}
                        </p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 lt-subtext">
                        {t("orso.orsoText")}
                    </div>
                </div>
                <a id="osroLink" href="https://e-orso.principal.com.hk/orso/welcome.do?methodName=welcome"></a>
            </div>
        </React.Fragment>
    );
};

OtherORSO.propTypes = {
    props: PropTypes.any,
    dispatch: PropTypes.func
}

const OtherORSOComponent = connect()(OtherORSO);
export default OtherORSOComponent;