import { au$, aud, cn$, cny, EE, EQUITY, hk$, HKAIIB, hkd, jp$, jpy, MAN, S800Acronym, SEP, SMP, us$, usd } from '../translations/common/commonConstants';
import { FormatUtils } from '../utils';
import { normalPercentageAccuracyCheck } from './accuracyCheckutil';

const getQbDetails = (ltgfFundList, fundBalanceList) => {
    const result = {
        qualifyingPeriod: null,
        qb1: null,
        qb2: null,
        qualifyingBalance: 0,
        rqb: 0,
        currency: null,
        qb1Flag: false,
        qb2Flag: false,
        qbFlag: false
    }
    if (Array.isArray(fundBalanceList) && fundBalanceList?.length) {
        ltgfFundList.map((qb) => {
            if (qb.ltgfTypeNm && qb.ltgfTypeNm.includes('fixed') && qb.qbOutAmt != 0) {
                result['qb1'] = qb.qbOutAmt;
                result['qualifyingBalance'] += qb.qbOutAmt
                result['rqb'] += Number((qb.qbOutAmt).toFixed(2));
            }
            if (qb.ltgfTypeNm && qb.ltgfTypeNm.includes('variable') && qb.qbOutAmt != 0) {
                result['qb2'] = qb.qbOutAmt;
                result['qualifyingBalance'] += qb.qbOutAmt
                result['rqb'] += Number((qb.qbOutAmt).toFixed(2));
            }
            result['qualifyingPeriod'] = qb.qpMonth;
            result['currency'] = getTranslatedCurrency(fundBalanceList?.[0]?.invAbbreviatedNum)
        })
        result.qualifyingBalance = Number(result?.qualifyingBalance?.toFixed(2))
        result.rqb = Number(result?.rqb?.toFixed(2))

        if (result.qualifyingBalance !== result.rqb) {
            result.qb1 = result.qualifyingBalance - result.qb2
        }

        if (result['qb1'] != null && result['qb1'] != 0) {
            result['qb1Flag'] = true;
        } else {
            result['qb1Flag'] = false;
        }
        if (result['qb1Flag']) {
            if (result['qb2'] != null && result['qb2'] != 0) {
                result['qb2Flag'] = true;
                result['qbFlag'] = true;
            } else {
                result['qb2'] = 0;
                result['qb2Flag'] = true;
                result['qbFlag'] = true;
            }
        } else {
            if (result['qb2'] != null && result['qb2'] != 0) {
                result['qb2Flag'] = false;
                result['qbFlag'] = true;
            } else {
                result['qb2Flag'] = false;
                result['qbFlag'] = false;
            }
        }
    }
    return result;
}

const getAccBalBreadCrumbDetails = (t, currentPage, history) => {
    let propArr = [];
    if (currentPage === 'accountBalanceByFund') {
        propArr = [
            {
                title: t("titles.dashboard"),
                active: true,
                route: '/'
            },
            {
                title: t("feesAndCharges.account"),
                active: true,
                route: '/account'
            },
            {
                title: t("titles.accBalByFund"),
                active: false,
                route: 'accountBalanceByFund'
            }
        ]
    } else if (currentPage === '/') {
        history.push('/dashboard')
    } else if (currentPage === '/account') {
        history.push('/portfolio')
    }
    return propArr;
};

const mergeDuplicateFunds = (arr) => {
    let arrayToProcess = arr.map(a => { return { ...a } })
    let obj = {};
    arrayToProcess.forEach((item, index) => {
        if (!obj[item.invTypeNmbr]) {
            obj[item.invTypeNmbr] = [index];
        } else {
            obj[item.invTypeNmbr].push(index)
        }
    })
    let valuesObj = {}
    let indexesToRemove = [];
    let manVolAmts = {}
    for (let prop in obj) {
        valuesObj[prop] = [];
        manVolAmts[prop] = {
            manAmt: 0,
            manUnitCnt: 0,
            volAmt: 0,
            volUnitCnt: 0
        }
        let indexAtZero = obj[prop][0];
        obj[prop].forEach((item, index) => {

            if (arrayToProcess?.[item]?.["moneyTypeNm"]?.includes(MAN)) {
                manVolAmts[prop].manAmt += arrayToProcess[item]["investmentAmt"]
                manVolAmts[prop].manUnitCnt += arrayToProcess[item]["unitCnt"]
            } else {
                manVolAmts[prop].volAmt += arrayToProcess[item]["investmentAmt"]
                manVolAmts[prop].volUnitCnt += arrayToProcess[item]["unitCnt"]
            }

            if (index === 0) {
                arrayToProcess[indexAtZero]["fixed"] = true;
                valuesObj[prop].push(arrayToProcess[item]["investmentAmt"]);
                valuesObj[prop].push(arrayToProcess[item]["unitCnt"]);
            } else if (index > 0) {
                indexesToRemove.push(item)
                valuesObj[prop][0] = arrayToProcess[item]["investmentAmt"] + valuesObj[prop][0];
                valuesObj[prop][1] = arrayToProcess[item]["unitCnt"] + valuesObj[prop][1];
            }
        })
    }
    let finalarr = arrayToProcess.filter((item) => { return item.fixed });
    for (let index = 0; index < finalarr.length; index++) {
        let invTypeNumber = finalarr[index].invTypeNmbr;
        finalarr[index]["investmentAmt"] = valuesObj[invTypeNumber][0];
        finalarr[index]["unitCnt"] = valuesObj[invTypeNumber][1];
        finalarr[index]["percent"] = valuesObj[invTypeNumber][2];
        finalarr[index]["manVolAmts"] = manVolAmts[invTypeNumber]
    }
    return finalarr;
}

const addPercentAccuracy = (fundsFromPolicyDetails, fundListFromService, scheme) => {
    let obj = {}
    fundListFromService.forEach((fund) => {
        if (fund.invTypeNmbr === 310 && scheme?.includes(S800Acronym)) {
            let disAmount = 0;
            fundsFromPolicyDetails.forEach((item) => {
                if (item.invTypeNmbr === 298 || item.invTypeNmbr === 300) {
                    disAmount = disAmount + item.investmentAmt
                }
            })
            let fundAmount = disAmount;
            obj[310] = Number(FormatUtils.formatToDecimal(fundAmount).replaceAll(",", ""));
        } else if (fund.invTypeNmbr === 313 && scheme?.includes(HKAIIB)) {
            let lssAmount = 0;
            fundsFromPolicyDetails.forEach((item) => {
                if (item.invTypeNmbr === 314 || item.invTypeNmbr === 315) {
                    lssAmount = lssAmount + item.investmentAmt
                }
            })
            let fundAmount = lssAmount;
            obj[313] = Number(FormatUtils.formatToDecimal(fundAmount).replaceAll(",", ""));
        } else if ([311, 312].includes(fund.invTypeNmbr) && scheme?.includes(SMP)) {
            let disAmount = 0;
            fundsFromPolicyDetails.forEach((item) => {
                if ([302, 304, 306, 308].includes(item.invTypeNmbr)) {
                    disAmount = disAmount + item.investmentAmt
                }
            })
            let fundAmount = disAmount;
            obj[fund.invTypeNmbr] = Number(FormatUtils.formatToDecimal(fundAmount).replaceAll(",", ""));
        } else {
            let fundAmount = fundsFromPolicyDetails.filter(item => fund.invTypeNmbr === item.invTypeNmbr)[0]?.investmentAmt;
            if (fundAmount) {
                obj[fund.invTypeNmbr] = Number(FormatUtils.formatToDecimal(fundAmount).replaceAll(",", ""));
            }
        }
    })

    let total = 0;
    for (let key in obj) {
        total = total + Number(obj[key])
    }
    total = FormatUtils.roundNumber(total, 2)

    fundListFromService.forEach((fund) => {
        let fundPercent = Number(obj[fund.invTypeNmbr]) * 100 / total;
        fund['fundPercent'] = FormatUtils.roundNumber(fundPercent, 2);
    })
    fundListFromService = normalPercentageAccuracyCheck(fundListFromService, 'fundPercent')
    return fundListFromService;
}
const getAllMoneyTypeInfo = (fundsFromPolicyDetails, fundListFromService, scheme) => {
    let erObj = {}
    let eeObj = {}
    let erAmt = 0
    let eeAmt = 0
    if (Array.isArray(fundsFromPolicyDetails) && fundsFromPolicyDetails.length > 0) {
        fundListFromService.forEach((fund) => {
            if ([311, 312].includes(fund.invTypeNmbr) && scheme?.includes(SMP)) {
                let erDisAmount = 0;
                let eeDisAmount = 0;
                fundsFromPolicyDetails.forEach((item) => {
                    if ([302, 304, 306, 308].includes(item.invTypeNmbr)) {
                        if (item?.moneyTypeNm?.includes(EE) || item?.moneyTypeNm?.includes(SEP)) {
                            eeDisAmount = eeDisAmount + item.investmentAmt
                        } else {
                            erDisAmount = erDisAmount + item.investmentAmt
                        }

                    }
                })
                eeObj[fund.invTypeNmbr] = Number(FormatUtils.formatToDecimal(eeDisAmount).replaceAll(",", "")) ?? 0;
                erObj[fund.invTypeNmbr] = Number(FormatUtils.formatToDecimal(erDisAmount).replaceAll(",", "")) ?? 0;
            } else {
                let investmentList = fundsFromPolicyDetails.filter(item => item.invTypeNmbr == fund.invTypeNmbr);
                erAmt = 0;
                eeAmt = 0;
                for (let index = 0; index < investmentList.length; index++) {
                    const fund = investmentList[index];
                    if (fund?.investmentAmt) {
                        if (fund?.moneyTypeNm?.includes(EE) || fund?.moneyTypeNm?.includes(SEP)) {
                            eeAmt += Number(FormatUtils.formatToDecimal(fund.investmentAmt).replaceAll(",", "")) ?? 0;
                        } else {
                            erAmt += Number(FormatUtils.formatToDecimal(fund.investmentAmt).replaceAll(",", "")) ?? 0;
                        }
                    }
                }

                eeObj[fund.invTypeNmbr] = eeAmt
                erObj[fund.invTypeNmbr] = erAmt
            }
        })

        fundListFromService.forEach((fund) => {
            fund['eeAmount'] = eeObj[fund.invTypeNmbr] ?? 0
            fund['erAmount'] = erObj[fund.invTypeNmbr] ?? 0
        })
    } else {
        fundListFromService = []
    }
    return fundListFromService;
}

const getTranslatedCurrency = (currency) => {
    switch (currency) {
        case usd: case us$: case (100): case (102):
            return "currencies.usd";
        case hkd: case hk$: case (101):
            return "currencies.hkd";
        case aud: case au$: case (103):
            return "currencies.aud";
        case cny: case cn$: case (104):
            return "currencies.cny";
        case jpy: case jp$: case (105):
            return "currencies.jpy";
        default:
            break;
    }
}
const getFixedEquityIncome = (fundList) => {
    let fixedIncome = 0;
    let equityIncome = 0;
    let totalIncome = 0;
    let fixedPercent = 0;
    let equityPercent = 0;

    fundList?.forEach((fund) => {
        if (fund?.invTypeLegalName?.includes(EQUITY) || [474, 476].includes(Number(fund.invTypeNmbr))) {
            equityIncome += Number(fund?.investmentAmt)
        } else {
            fixedIncome += Number(fund?.investmentAmt)
        }
    })
    totalIncome = fixedIncome + equityIncome;
    if (totalIncome !== 0) {
        fixedPercent = fixedIncome * 100 / totalIncome;
        equityPercent = equityIncome * 100 / totalIncome;

        fixedPercent = Number(fixedPercent.toFixed(2))
        equityPercent = Number(equityPercent.toFixed(2))

        const totalPercentage = fixedPercent + equityPercent;
        if (totalPercentage !== 100) {
            let difference = 100 - totalPercentage;
            if (difference > 0) {
                (fixedPercent < equityPercent) ? (fixedPercent + difference) : (equityPercent + difference)
            } else {
                (fixedPercent > equityPercent) ? (fixedPercent + difference) : (equityPercent + difference)
            }
        }
    }
    return { fixedPercent, equityPercent }
}
export default { getAccBalBreadCrumbDetails, mergeDuplicateFunds, addPercentAccuracy, getTranslatedCurrency, getFixedEquityIncome, getQbDetails, getAllMoneyTypeInfo };
