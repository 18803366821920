import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import { saveFirstTimeSelectAccoutnLoading, saveSchemeType } from "../../actions/EmployeeLoginAction";
import { saveContactInfoDetails, saveContactInfoUpdateFlag, saveContactUpdateJson, saveContactVerifyEditFlag, saveCurrentLoadedPageOnUI, saveEmailInfoDetails, saveEmailInfoUpdateFlag, saveEmailUpdateJson, saveEmailVerifyEditFlag, saveMobileInfoDetails, saveMobileInfoUpdateFlag, saveMobileUpdateJson, saveMobileVerifyEditFlag } from "../../actions/LandingAction";
import { savePisysToken } from "../../actions/TokenAction";
import { saveAuthenticationFlag } from "../../actions/TwoFactorAuthenticationAction";
import "../../css/SuperLogonLogin.css";
import LoginHeader from "../../SharedComponents/LoginHeader";

const SuperLogonSignIn = (props) => {
    const { t } = useTranslation("common");
    const history = useHistory();

    useEffect(() => {
        resetReduxToDefaults(props)
        props.dispatch(saveAuthenticationFlag(false))
        resetPropsValue();
    }, [])

    const resetReduxToDefaults = (props) => {
        props.dispatch(saveCurrentLoadedPageOnUI(''))
        props.dispatch(saveSchemeType(""))
        props.dispatch(savePisysToken(""))
    }

    const resetPropsValue = () => {
        props.dispatch(saveContactVerifyEditFlag(false));
        props.dispatch(saveContactInfoUpdateFlag(false));
        props.dispatch(saveContactInfoDetails({}));
        props.dispatch(saveContactUpdateJson({}));
        props.dispatch(saveEmailVerifyEditFlag(false));
        props.dispatch(saveEmailInfoUpdateFlag(false));
        props.dispatch(saveEmailInfoDetails({}));
        props.dispatch(saveEmailUpdateJson({}));
        props.dispatch(saveMobileInfoDetails({}));
        props.dispatch(saveMobileInfoUpdateFlag(false));
        props.dispatch(saveMobileUpdateJson({}));
        props.dispatch(saveMobileVerifyEditFlag(false));
        props.dispatch(saveFirstTimeSelectAccoutnLoading(false));
    };

    return (
        <React.StrictMode>
            <LoginHeader />
            <div className="main-content">
                <div className="sll-container row">
                    <label className="col-12 sll-title text-center">Super Logon Login</label>
                    <button name="login" type="submit" className="sll-login-btn col-5 mx-auto" onClick={() => { history.push("/") }}>{t("titles.login")}</button>
                </div>
            </div>
        </React.StrictMode>
    )
}

SuperLogonSignIn.propTypes = {
    props: PropTypes.any,
    dispatch: PropTypes.func
};
export default connect()(SuperLogonSignIn);