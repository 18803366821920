import React from "react";
import PropTypes from "prop-types";
import { getColorByCode } from "../utils";

const ColorDot = (props)=> {
  if(props.colorcode && props?.height && props?.width && props.shape){
    return (
      <span
        style={{
          display: "inline-block",
          height: props.height,
          width: props.width,
          borderRadius: "2px",
          backgroundColor: getColorByCode(props.colorcode),
          marginRight:"8px"
        }}
      ></span>
    );
  }else{
    return (
      <span
        style={{
          display: "inline-block",
          height: "16px",
          width: "16px",
          borderRadius: "8px",
          backgroundColor: getColorByCode(props.colorcode),
          marginRight:"8px"
        }}
      ></span>
    );
  }
}

ColorDot.propTypes = {
  props: PropTypes.any,
  colorcode: PropTypes.number,
  height:PropTypes.string,
  width:PropTypes.string,
  shape:PropTypes.string,
  borderRadius:PropTypes.string
};

export default ColorDot;
