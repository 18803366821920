import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import { Slide, toast } from 'react-toastify';
import api from "../../../../API/PersonalInfo/api";
import Toast from "../../../../Components/Toast";
import "../../../../css/OrsoFatcaDeclaration.css";
import SpinnerButton from "../../../../SharedComponents/SpinnerButton";

const OrsoFatcaDeclaration = (props) => {
    const { t } = useTranslation("common")
    const history = useHistory();
    const [option, setOption] = useState('Not-US');
    const [show, setShow] = useState('show d-block')
    const [isDisable, setDisable] = useState(true);
    const [serviceCallFlag, setServiceCallFlag] = useState(false)
    const [state, setState] = useState({
        tin: "",
        isValidTin: false
    });

    const handleScroll = (e) => {
        const isBottom = e.target.scrollHeight - Math.ceil(e.target.scrollTop) <= e.target.clientHeight;
        if (isDisable && isBottom) {
            setDisable(false);
        }
    }

    const checkScrollBar = () => {
        let element = document.getElementById('fatca-orso')

        let res = !!element['scrollTop'];

        if (!res) {
            element['scrollTop'] = 1;
            res = !!element['scrollTop'];
            element['scrollTop'] = 0;
        }
        return res;
    }

    useEffect(() => {
        const isScrollabel = checkScrollBar()
        setDisable(isScrollabel)
    }, [])

    const onChangeOption = (event) => {
        setOption(event.target.value)
    }

    const checkTin = (event) => {
        if (event.target.value !== null && event.target.value !== "") {
            setState({
                ...state,
                tin: event.target.value,
                isValidTin: true,
            });
        } else {
            setState({
                ...state,
                tin: event.target.value,
                isValidTin: false
            });
        }
    };

    const isNotEmpty = (event) => {
        if (event.target.value !== null && event.target.value !== "") {
            setState({
                ...state,
                isValidTin: true,
            });
        } else {
            setState({
                ...state,
                isValidTin: false,
            });
        }
    };

    const toastOptions = {
        position: 'top-center',
        autoClose: 4000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        transition: Slide,
        rtl: false,
        closeButton: false,
    }

    const sendNotification = (type) => {
        toast(<Toast type={type} />, toastOptions)
    }

    const onAgree = () => {
        let updateJson = [
            {
                chgDt: props.cycleDate,
                clientNmbr: props.clientNmbr,
                cstmAttrCd: 227,
                cstmAttrNm: "FATCA - US ACCOUNT (Y/N)",
                cstmAttrTxt: option === 'US' ? "Y" : "N",
                efctvDt: props.cycleDate,
            }
        ]

        if (option === "US") {
            updateJson.push({
                chgDt: props.cycleDate,
                clientNmbr: props.clientNmbr,
                cstmAttrCd: 229,
                cstmAttrNm: "FATCA - US TIN",
                cstmAttrTxt: String(state.tin),
                efctvDt: props.cycleDate,
            })
        }

        const headers = {
            'pisys_auth_token': props.pisysToken,
            'username': props.clientNmbr,
            'Access-Control-Allow-Origin': '*'
        }

        setServiceCallFlag(true);
        api.updateCustomData(updateJson, headers)
            .then(() => {
                setServiceCallFlag(false);
                setShow('')
            })
            .catch(() => {
                sendNotification('report-error-message');
                setServiceCallFlag(false);
            });

    }

    const handleDisagree = () => {
        setShow('');
        history.push("/dashboard");
    }

    return (
        <>
            <div className={`modal fade fatca-modal-background ${show}`} id="fatcaModal" tabIndex="-1" aria-labelledby="fatcaModalLabel" aria-hidden="false">
                <div className="modal-dialog modal-dialog-scrollable fatca-modal-dlg">
                    <div className="modal-content fatca-modal-content">
                        <div className="modal-body fatca-modal-body">
                            <div className="fatca-modal-title">{`Terms & Condition`}</div>

                            <div className="pds-radio radio-option">
                                <input name="channel" type="radio" id="pdsRadioOptions1" value="Not-US" checked={option === 'Not-US'} onChange={onChangeOption} />
                                <label htmlFor="pdsRadioOptions1">
                                    <label className="fatca-option">{t('fatcaDeclaration.notUsPerson')}</label>
                                    {option === 'Not-US' && <label className="fatca-option-subttl">{t('fatcaDeclaration.consentNo')}</label>}
                                </label>
                            </div>

                            <div className="pds-radio radio-option">
                                <input name="channel" type="radio" id="pdsRadioOptions2" value="US" checked={option === 'US'} onChange={onChangeOption} />
                                <label htmlFor="pdsRadioOptions2">
                                    <label className="fatca-option">{t('fatcaDeclaration.usPerson')}</label>
                                    {option === 'US' &&
                                        <>
                                            <label className="fatca-option-subttl mb-0">{t('fatcaDeclaration.consentYes')}</label>
                                            <label className="fatca-option-subttl">{t('fatcaDeclaration.tinText')}</label>
                                            <input type="number" className="form-input form-control required fatca-tin-input col-12 col-md-7" placeholder={t("fatcaDeclaration.enterTin")} aria-label="Text input for TIN" onFocus={isNotEmpty} onChange={(e) => checkTin(e)} value={state.tin} onWheel={(e) => e.target.blur()} />
                                        </>
                                    }
                                </label>
                            </div>

                            <div className="card fatca-card">
                                <div className="card-body overflow-auto fatca-card-content" id="fatca-orso" onScroll={handleScroll}>
                                    {t('fatcaDeclaration.consentText')}
                                </div>
                            </div>

                            <div className="d-flex flex-wrap">
                                <button type="button" className="col-12 col-md-4 fatca-d-btn"
                                    onClick={() => { handleDisagree() }}
                                    // eslint-disable-next-line no-undef
                                    disabled={!(Number(process.env.REACT_APP_WRITE_ACCESS)) || serviceCallFlag}
                                >
                                    <SpinnerButton serviceCallFlag={serviceCallFlag} label={t('TandC.disagree')}></SpinnerButton>
                                </button>
                                <div className="col-1 mx-n3 d-none d-md-block d-lg-block d-xl-block"></div>
                                <button type="button"
                                    className="col-12 col-md-4 fatca-a-btn"
                                    // eslint-disable-next-line no-undef
                                    disabled={!(Number(process.env.REACT_APP_WRITE_ACCESS)) || (option === 'US' ? (isDisable || !state.isValidTin) : isDisable) || serviceCallFlag}
                                    onClick={onAgree}
                                >
                                    <SpinnerButton serviceCallFlag={serviceCallFlag} label={t('TandC.agree')}></SpinnerButton>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

OrsoFatcaDeclaration.propTypes = {
    props: PropTypes.any,
    loginId: PropTypes.string,
    pisysToken: PropTypes.string,
    clientNmbr: PropTypes.string,
    setAppState: PropTypes.func,
    dispatch: PropTypes.func,
    cycleDate: PropTypes.string
}

const mapStateToProps = (state) => {
    return {
        loginId: state.empLogin.loginId,
        pisysToken: state.token.pisysToken,
        clientNmbr: state.empLogin.clientNmbr,
        cycleDate: state.landing.cycleDate,
    }
};

export default connect(mapStateToProps)(OrsoFatcaDeclaration);