import React from "react";
import "../css/LoadingPage.css";

const LoadingPage = () => {
  return (
    <div className="main-content full-screen-dashboard">
      <span
        className="spinner-border spinner-border-dashboard"
        data-testid="loader"
        role="status"
        aria-hidden="true"
      ></span>
    </div>
  );
};

export default LoadingPage;
