import { instance } from '../AxiosInterceptor';

export default {
    getCustomFields: (productTypeNmbr, requestHeaders) =>
        instance({
            method: 'GET',
            url: '/product/customFields',
            headers: requestHeaders,
            params: {
                productTypeNmbr
            }
        })
}