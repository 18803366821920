import loadable from '@loadable/component';
import PropTypes from 'prop-types';
import React, { useEffect } from "react";
import { connect } from 'react-redux';
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import ScrollToTop from "react-router-scroll-top";
import { ToastContainer } from "react-toastify";
import Account from "./App/Account/index";
import Changes from "./App/Changes/Changes";
import Dashboard from './App/Dashboard';
import FundInfo from './App/FundInfo/FundInfo';
import LandingComponent from "./App/Landing/index";
import Login from "./App/Login/index";
import SuperLogon from './App/Login/SuperLogon';
import SuperLogonLogin from './App/Login/SuperLogonLogin';
import SuperLogonSignIn from './App/Login/SuperLogonSignIn';
import Footer from "./SharedComponents/Footer.js";
import FooterAIIB from "./SharedComponents/FooterAIIB";
import IdleTimerContainer from "./SharedComponents/IdleTimerContainer";
import LoadingPage from './SharedComponents/LoadingPage';
import { APIUtils, logoutTo } from './utils';
import ProtectedRoute from './SharedComponents/ProtectedRoute';

const fallbackPage = { fallback: <LoadingPage /> };

const FundSwitch = loadable(() => import(/*webpackPrefetch:true, webpackChunkName: "FundSwitch" */ './App/FundSwitch'), fallbackPage);
const ManagerFund = loadable(() => import(/*webpackPrefetch:true, webpackChunkName: "ManagerFund" */ './App/ManagerFund'), fallbackPage);
const Documents = loadable(() => import(/*webpackPrefetch:true, webpackChunkName: "Documents" */ './App/Downloads/Documents/Documents.js'), fallbackPage);
const ForgotPin = loadable(() => import(/*webpackPrefetch:true, webpackChunkName: "ForgotPin" */ './App/Login/ForgotPin/ForgotPin'), fallbackPage);
const Verification = loadable(() => import(/*webpackPrefetch:true, webpackChunkName: "Verification" */ './Components/Verification/Verification'), fallbackPage);
const ChangePin = loadable(() => import(/*webpackPrefetch:true, webpackChunkName: "ChangePin" */ './App/ProfileInfo/ChangePinInfo/ChangePin'), fallbackPage);
const TermsAndConditions = loadable(() => import(/*webpackPrefetch:true, webpackChunkName: "TermsAndConditions" */ './App/Login/TermsAndConditions'), fallbackPage);
const Reports = loadable(() => import(/*webpackPrefetch:true, webpackChunkName: "Reports" */ './App/Downloads/Reports/Reports'), fallbackPage);
const GenerateReports = loadable(() => import(/*webpackPrefetch:true, webpackChunkName: "GenerateReports" */ './App/Downloads/Reports/GenerateReports'), fallbackPage);
const FeesAndCharges = loadable(() => import(/*webpackPrefetch:true, webpackChunkName: "FeesAndCharges" */ './App/Account/FeesAndCharges'), fallbackPage);
const TransactionContributionHistory = loadable(() => import(/*webpackPrefetch:true, webpackChunkName: "FeesAndCharges" */ './App/Changes/TransactionContributionHistory'), fallbackPage);
const FatcaDeclaration = loadable(() => import(/*webpackPrefetch:true, webpackChunkName: "FatcaDeclaration" */ './App/Account/FatcaDeclaration/FatcaDeclaration'), fallbackPage);
const AccBalanceByFund = loadable(() => import(/*webpackPrefetch:true, webpackChunkName: "AccBalanceByFund" */ './App/Account/BalanceByFund/AccBalanceByFund'), fallbackPage);
const Contribution = loadable(() => import(/*webpackPrefetch:true, webpackChunkName: "Contribution" */ './App/Account/Contribution'), fallbackPage);
const Portfolio = loadable(() => import(/*webpackPrefetch:true, webpackChunkName: "Portfolio" */ './App/Portfolio/PortfolioComponent'), fallbackPage);
const SelectChannel = loadable(() => import(/*webpackPrefetch:true, webpackChunkName: "SelectChannel" */ './App/Login/TwoFactorAuthentication/SelectChannel'), fallbackPage);
const AuthenticateOTP = loadable(() => import(/*webpackPrefetch:true, webpackChunkName: "AuthenticateOTP" */ './App/Login/TwoFactorAuthentication/AuthenticateOTP'), fallbackPage);
const VerifyInfo = loadable(() => import(/*webpackPrefetch:true, webpackChunkName: "VerifyInfo" */ './App/Login/TwoFactorAuthentication/VerifyInfo'), fallbackPage);
const VerificationNeeded = loadable(() => import(/*webpackPrefetch:true, webpackChunkName: "VerificationNeeded" */ './App/Login/TwoFactorAuthentication/Verification-Needed'), fallbackPage);
const SetNewPin = loadable(() => import(/*webpackPrefetch:true, webpackChunkName: "SetNewPin" */ './App/ProfileInfo/ChangePinInfo/SetNewPin'), fallbackPage);
const FundFactSheet = loadable(() => import(/*webpackPrefetch:true, webpackChunkName: "GenerateReports" */ './App/FundInfo/FundFactSheet'), fallbackPage);
const SelectChannelForgotPin = loadable(() => import(/*webpackPrefetch:true, webpackChunkName: "selectChannel_forgotPin" */ './App/Login/TwoFactorAuthentication/SelectChannel_forgotPin'), fallbackPage);
const AuthenticateOTPForgotPin = loadable(() => import(/*webpackPrefetch:true, webpackChunkName: "authenticateOTP_forgotPin" */ './App/Login/TwoFactorAuthentication/AuthenticateOTP_forgotPin'), fallbackPage);

//S800 and ORSO
const EmpLoginComponent = loadable(() => import(/*webpackPrefetch:true, webpackChunkName: "EmpLoginComponent" */ './App/Login/EmployeeLogin'), fallbackPage);


//AIIB
const EmpLoginAIIBComponent = loadable(() => import(/*webpackPrefetch:true, webpackChunkName: "EmpLoginAIIBComponent" */ './App/Login/EmployeeLoginAIIB'), fallbackPage);

//Simple and Smart
const EmpLoginCobrandComponent = loadable(() => import(/*webpackPrefetch:true, webpackChunkName: "EmpLoginComponent" */ './App/Login/EmployeeLoginCobrand'), fallbackPage);

const App = (props) => {
  const location = useLocation();


  let aiibFooter = false;
  // eslint-disable-next-line no-undef
  const isSuperLogon = !(Number(process.env.REACT_APP_WRITE_ACCESS));
  const redirectPath = logoutTo(props.scheme, !isSuperLogon)
  if (!isSuperLogon) {
    if (location.pathname == "/aiib/employeelogin" || location.pathname == "/aiib/employeelogintemp" || props.scheme == "HKAIIB") {
      aiibFooter = true;
    }
  }

  useEffect(() => {
    setTimeout(() => {
      APIUtils.loadScriptsAsync("https://www.principalcdn.com/shared/tealeaf/v6/tealeaf-uic-saas-hk.js");
      APIUtils.loadScriptsAsync("https://nebula-cdn.kampyle.com/wu/343255/onsite/embed.js");
    }, 3000);
  }, []);

  return (
    <>
      <ToastContainer />
      <IdleTimerContainer />
      <ScrollToTop>
        <Switch>
          <Route exact path="/" component={isSuperLogon ? SuperLogon : Login}></Route>
          <ProtectedRoute exact path="/authenticateOTP" condition={!isSuperLogon && props.isAuthenticated} redirectTo="/">
            <AuthenticateOTP />
          </ProtectedRoute>

          <ProtectedRoute exact path="/selectChannel_forgotPin" condition={!isSuperLogon} redirectTo="/">
            <SelectChannelForgotPin />
          </ProtectedRoute>


          {/* S800 and ORSO */}
          <ProtectedRoute exact path="/employeelogin" condition={!isSuperLogon} redirectTo="/">
            <EmpLoginComponent />
          </ProtectedRoute>


          {/* AIIB */}
          <ProtectedRoute exact path="/aiib/employeelogin" condition={!isSuperLogon} redirectTo="/">
            <EmpLoginAIIBComponent />
          </ProtectedRoute>

          {/* Simple and Smart */}
          <ProtectedRoute exact path="/cobrand/employeelogin" condition={!isSuperLogon} redirectTo="/">
            <EmpLoginCobrandComponent />
          </ProtectedRoute>

          {/* Superlogon */}
          <ProtectedRoute exact path="/superLogon/login" condition={isSuperLogon} redirectTo="/">
            <SuperLogonLogin />
          </ProtectedRoute>
          <ProtectedRoute exact path="/superLogon/signIn" condition={isSuperLogon} redirectTo="/">
            <SuperLogonSignIn />
          </ProtectedRoute>

          <ProtectedRoute exact path="/account" condition={props.isAuthenticated} redirectTo={redirectPath}>
            <Account />
          </ProtectedRoute>
          <ProtectedRoute exact path="/managerFund" condition={props.isAuthenticated} redirectTo={redirectPath}>
            <ManagerFund />
          </ProtectedRoute>
          <ProtectedRoute exact path="/fundInfo" condition={props.isAuthenticated} redirectTo={redirectPath}>
            <FundInfo />
          </ProtectedRoute>
          <ProtectedRoute exact path="/documents" condition={props.isAuthenticated} redirectTo={redirectPath}>
            <Documents />
          </ProtectedRoute>
          <Route exact path="/forgotpin" component={ForgotPin}></Route>
          <ProtectedRoute exact path="/profile" condition={props.isAuthenticated} redirectTo={redirectPath}>
            <LandingComponent />
          </ProtectedRoute>
          <ProtectedRoute exact path="/verify" condition={props.isAuthenticated} redirectTo={redirectPath}>
            <Verification />
          </ProtectedRoute>
          <ProtectedRoute exact path="/changePin" condition={props.isAuthenticated} redirectTo={redirectPath}>
            <ChangePin />
          </ProtectedRoute>
          <ProtectedRoute exact path="/fundSwitch" condition={props.isAuthenticated} redirectTo={redirectPath}>
            <FundSwitch />
          </ProtectedRoute>
          <ProtectedRoute exact path="/termsandconditions" condition={props.isAuthenticated} redirectTo={redirectPath}>
            <TermsAndConditions />
          </ProtectedRoute>
          <ProtectedRoute exact path="/reports" condition={props.isAuthenticated} redirectTo={redirectPath}>
            <Reports />
          </ProtectedRoute>
          <ProtectedRoute exact path="/generatereports" condition={props.isAuthenticated} redirectTo={redirectPath}>
            <GenerateReports />
          </ProtectedRoute>
          <ProtectedRoute exact path="/changes" condition={props.isAuthenticated} redirectTo={redirectPath}>
            <Changes />
          </ProtectedRoute>
          <ProtectedRoute exact path="/dashboard" condition={props.isAuthenticated} redirectTo={redirectPath}>
            <Dashboard />
          </ProtectedRoute>
          <ProtectedRoute exact path="/feesAndCharges" condition={props.isAuthenticated} redirectTo={redirectPath}>
            <FeesAndCharges />
          </ProtectedRoute>
          <ProtectedRoute exact path="/transactionContributionHistory" condition={props.isAuthenticated} redirectTo={redirectPath}>
            <TransactionContributionHistory />
          </ProtectedRoute>
          <ProtectedRoute exact path="/fatca" condition={props.isAuthenticated} redirectTo={redirectPath}>
            <FatcaDeclaration />
          </ProtectedRoute>
          <ProtectedRoute exact path="/accountBalanceByFund" condition={props.isAuthenticated} redirectTo={redirectPath}>
            <AccBalanceByFund />
          </ProtectedRoute>
          <ProtectedRoute exact path="/contribution" condition={props.isAuthenticated} redirectTo={redirectPath}>
            <Contribution />
          </ProtectedRoute>
          <ProtectedRoute exact path="/portfolio" condition={props.isAuthenticated} redirectTo={redirectPath}>
            <Portfolio />
          </ProtectedRoute>
          <ProtectedRoute exact path="/selectChannel" condition={props.isAuthenticated} redirectTo={redirectPath}>
            <SelectChannel />
          </ProtectedRoute>
          <ProtectedRoute exact path="/verifyInfo" condition={props.isAuthenticated} redirectTo={redirectPath}>
            <VerifyInfo />
          </ProtectedRoute>
          <Route exact path="/verificationNeeded" component={VerificationNeeded}></Route>
          <Route exact path="/setnewpin" component={SetNewPin}></Route>
          <ProtectedRoute exact path="/fundFactSheet" condition={props.isAuthenticated} redirectTo={redirectPath}>
            <FundFactSheet />
          </ProtectedRoute>
          <Route exact path="/authenticateOTP_forgotPin" component={AuthenticateOTPForgotPin}></Route>

          <Redirect to="/" />
        </Switch>
      </ScrollToTop >
      {!isSuperLogon && (aiibFooter ? <FooterAIIB /> : <Footer />)}
    </>
  );
};

App.propTypes = {
  dispatch: PropTypes.func,
  scheme: PropTypes.string,
  isAuthenticated: PropTypes.bool
};

const mapStateToProps = (state) => {
  return {
    scheme: state.empLogin.scheme,
    isAuthenticated: state.isAuthenticated.isAuthenticated

  };
};

export default connect(mapStateToProps)(App);