import React from "react";
import PropTypes from "prop-types";
import "../css/Bubble.css";

const Bubble = (props)=> {
  return (
    <span
      style={{ height: props.height, width: props.width }}
      className="bubble"
    >
      {props.children}
    </span>
  );
}

Bubble.propTypes = {
  props: PropTypes.any,
  height: PropTypes.string,
  width: PropTypes.string,
  children: PropTypes.any,
};

export default Bubble;
