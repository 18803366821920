import capitalize from "capitalize";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { BrowserRouter, NavLink, useHistory, useLocation } from "react-router-dom";
import { toast } from 'react-toastify';
import { haveSelectedAccount, resetAccountInfo, saveQuickPolicyInfo } from "../actions/AccountAction";
import { resetChangesList, setChangesPageEidtFlag, setCurrentPageNo } from "../actions/ChangesAction";
import { saveCountryDropDown } from "../actions/CommonDropDownAction";
import { savePopUpAlertStatus } from "../actions/DashboardAction";
import { resetLoginInfo, saveOrganizationsChineseNames, saveSchemeType } from "../actions/EmployeeLoginAction";
import { resetPerformaceInfo, saveRedirectPricePerformancePage, setFundDetailRedirection } from "../actions/FundPerformanceAction";
import { clearSwitchInFundsPercent, resetFundSwitchData } from "../actions/FundSwitchAction";
import { resetMergerData, saveMergerAttributes, setIsMerger } from "../actions/MergerInfoAction";
import { resetportfoilioDetails } from "../actions/PortfolioAction";
import { saveAuthenticationFlag } from '../actions/TwoFactorAuthenticationAction';
import greyArrow from "../assets/Arrow-DarkGray.svg";
import arrow from "../assets/Arrow-right-blue.svg";
import lang from "../assets/Language-TC.svg";
import aiibLogo from "../assets/Logo/AIIB_full_logo.svg";
import axaLogo from "../assets/Logo/axaLogo.svg";
import principalLogo from "../assets/Logo/Principal-Logo-2.svg";
import profile from "../assets/Profile.png";
import Toast from '../Components/Toast';
import "../css/HeaderFooter.css";
import { accountTypPa, accountTypSep, accountTypSvc, accountTypTvc, HKAIIB, SMP } from "../translations/common/commonConstants";
import { AccountUtils, logoutTo, UiUtils } from "../utils";
import LandingUtils from "../utils/LandingUtils";

Header.propTypes = {
  props: PropTypes.any,
  dispatch: PropTypes.func,
  filteredEngCountryDropDown: PropTypes.arrayOf(PropTypes.object),
  filteredChineseCountryDropDown: PropTypes.arrayOf(PropTypes.object),
  scheme: PropTypes.string,
  clientNmbr: PropTypes.string,
  quickPolicyInfo: PropTypes.array,
  selectedPolicyIndex: PropTypes.number,
  currentLoadedPageonUI: PropTypes.string,
  haveSelectedAccount: PropTypes.bool,
  chineseOrgNames: PropTypes.array,
  clntRltnshpNmbr: PropTypes.string,
  selectedQuickPolicyInfo: PropTypes.object,
  policyDetailsList: PropTypes.object,
  isOrsoAccount: PropTypes.bool
};

function Header(props) {
  const history = useHistory();
  const location = useLocation();
  const { t, i18n } = useTranslation("common");
  var lang1 = i18n.language;
  const [englishFlag, setEnglishFlag] = useState(lang1 === "zh" ? true : false);
  const selectedAccountType = props?.quickPolicyInfo[props.selectedPolicyIndex]?.accountType.toUpperCase().trim();
  let clientNmbr = String(props.clientNmbr).padStart(7, "0");
  let showAllMenuOptions = props.haveSelectedAccount === true ? true : false;
  let accountTypeText;
  let capitalFlag = false;
  if (selectedAccountType === accountTypPa) {
    accountTypeText = 'selectAccount.personalAcc';
  } else if (selectedAccountType === accountTypSep) {
    accountTypeText = 'selectAccount.selfEmp';
  } else if (selectedAccountType === accountTypTvc) {
    accountTypeText = 'selectAccount.tvc';
  } else if (selectedAccountType === accountTypSvc) {
    accountTypeText = 'selectAccount.svc';
  } else {
    if (props.scheme === HKAIIB) {
      capitalFlag = true;
      accountTypeText = props?.policyDetailsList?.filter((x) => x?.policyVO?.clntRltnshpNmbr?.toString() === props?.clntRltnshpNmbr)?.[0]?.policyVO?.policyHistoryList?.[0]?.planName;
    } else {
      capitalFlag = true;
      accountTypeText = props?.chineseOrgNames?.[props.selectedPolicyIndex] ?? props?.quickPolicyInfo[props.selectedPolicyIndex]?.client?.organizationNm.toLowerCase();
    }
  }
  let aiibHeader = false;
  if (location.pathname == "/aiib/employeelogin" || location.pathname == "/aiib/employeelogintemp" || props.scheme == "HKAIIB") {
    aiibHeader = true;
  }

  const handleChangeLanguage = () => {
    setEnglishFlag(!englishFlag);
    englishFlag ? i18n.changeLanguage("en") : i18n.changeLanguage("zh");
    if (englishFlag === true) {
      props.dispatch(saveCountryDropDown(props.filteredEngCountryDropDown));
      window.GLOBAL_LANG = "en";
      UiUtils.callMedalliaUpdate();
    } else {
      props.dispatch(saveCountryDropDown(props.filteredChineseCountryDropDown));
      window.GLOBAL_LANG = "zh";
      UiUtils.callMedalliaUpdate();
    }
  };

  const onPersonalInfoClick = () => {
    history.push('/profile');
    LandingUtils.resetPropsValue(props);
  };

  const onLogOutClick = () => {
    let filteredAccounts = [];
    props.dispatch(saveAuthenticationFlag(false));
    props.dispatch(clearSwitchInFundsPercent());
    props.dispatch(setChangesPageEidtFlag(false));
    props.dispatch(setCurrentPageNo(0));
    props.dispatch(saveQuickPolicyInfo([...filteredAccounts]));
    props.dispatch(setIsMerger(false));
    props.dispatch(saveMergerAttributes([]));
    props.dispatch(resetPerformaceInfo());
    props.dispatch(resetMergerData());
    props.dispatch(saveOrganizationsChineseNames([]));
    props.dispatch(haveSelectedAccount(false));
    props.dispatch(resetportfoilioDetails());
    props.dispatch(resetAccountInfo());
    props.dispatch(resetFundSwitchData());
    props.dispatch(saveSchemeType(""));
    props.dispatch(savePopUpAlertStatus(false));
    props.dispatch(resetLoginInfo());
    sendNotification();
  };

  const onFundPriceAndPerformance = () => {
    props.dispatch(saveRedirectPricePerformancePage(true));
    props.dispatch(setFundDetailRedirection(false));
    history.push('/fundInfo');
  };

  const toastOptions = {
    position: 'top-center',
    autoClose: 4000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    rtl: false,
    closeButton: false,
  };

  const sendNotification = () => {
    toast(<Toast type='logged-out' />, toastOptions);
    // eslint-disable-next-line no-undef
    let path = logoutTo(props?.scheme, !!(Number(process.env.REACT_APP_WRITE_ACCESS)));
    history.push(path);
  };

  const hideNavBar = () => {
    document.getElementById("navbar-toggle-button").click();
  };

  const changesListLoading = () => {
    hideNavBar();
    UiUtils.scrollToTop();
    if (props.currentLoadedPageonUI !== "CHANGES_0") {
      props.dispatch(resetChangesList());
      props.dispatch(setChangesPageEidtFlag(false));
      props.dispatch(setCurrentPageNo(0));
      history.push({ pathname: '/changes', resetPage: true, });
    }
  };

  const isCurrentURL = (url) => {
    return location.pathname.toLowerCase() === url.toLowerCase();
  };

  return (
    <div className="nav_bg">
      <BrowserRouter>
        <nav className="navbar navbar-expand-lg navbar-light rsc-navbar ">
          <NavLink to="./account" onClick={() => { history.push('account'); }} data-testid="account-link-1" className="navbar-brand">
            {aiibHeader ? <img src={aiibLogo} className="aiib-logo" /> : <><img src={principalLogo} className="rsc-logo" />{props.scheme == SMP && <img src={axaLogo} className="axa-logo" />}</>}
          </NavLink>
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" id="navbar-toggle-button">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mb-2 mb-lg-0 header-nav">
              {!isCurrentURL('/account') &&
                <li className="nav-item d-xl-none d-lg-none d-md-block d-sm-block">
                  <NavLink to="./account" activeclassname="menu-active" className="nav-link border-bottom-none">
                    <div className="row" onClick={() => { history.push('/account'); }} data-testid="account-link-2">
                      <div className="col-11 ">
                        {showAllMenuOptions ? <div className="clientNmbrTxt">{AccountUtils.getContractNumber(props, props.selectedQuickPolicyInfo)}</div> : <div className="clientNmbrTxt">{clientNmbr}</div>}
                        <div className="accountTypeTxt">{t(accountTypeText)}</div>
                      </div>
                      <div className="col-1 p-0 pl-md-5">
                        <img src={arrow} alt="arrow" />
                      </div>
                    </div>
                  </NavLink>
                </li>
              }

              {!isCurrentURL('/account') && showAllMenuOptions && <>
                <li className="nav-item">
                  <a className="nav-link hk-link" href="javascript:;" id="accountDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" role="button">
                    {t("titles.account")}
                  </a>
                  <div className="dropdown-menu hk-dropdown-menu" aria-labelledby="accountDropdown">
                    <span onClick={() => { history.push('/portfolio'); }} data-testid="portfolio" className="dropdown-item dropdown-list-item">{t("titles.accDetails")}</span>
                    <span onClick={() => { history.push('/accountBalanceByFund'); }} data-testid="accountBalanceByFund" className="dropdown-item dropdown-list-item">{t("titles.accBalByFund")}</span>
                    {(!(props.isOrsoAccount)) && <span onClick={() => { history.push('/transactionContributionHistory'); }} data-testid="transactionContributionHistory" className="dropdown-item dropdown-list-item">{t("titles.transaction")}</span>}
                    <span onClick={() => { history.push({ pathname: '/contribution', resetPage: true, }); }} data-testid="contribution" className="dropdown-item dropdown-list-item">{t("titles.contribution")}</span>
                    {(props.scheme !== HKAIIB && !(props.isOrsoAccount) && props.scheme !== SMP) && <span onClick={() => { history.push('/feesAndCharges'); }} data-testid="feesAndCharges" className="dropdown-item dropdown-list-item">{t("titles.feesnCharges")}</span>}
                  </div>
                </li>
                <li className="nav-item dropdown">
                  <a className="nav-link hk-link" href="javascript:;" id="manageFundDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" role="button">
                    {t("titles.manageFund")}
                  </a>
                  <div className="dropdown-menu hk-dropdown-menu" aria-labelledby="manageFundDropdown">
                    <span onClick={() => { hideNavBar(); history.push({ pathname: '/fundSwitch', resetPage: true, }); }} data-testid="fundSwitch" className="dropdown-item dropdown-list-item">{t("titles.fundSwitch")}</span>
                    <span onClick={() => { changesListLoading(); }} className="dropdown-item dropdown-list-item" data-testid="changes">{t("titles.switchingFundsInstructions")}</span>
                  </div>
                </li>
                <li className="nav-item">
                  <a className="nav-link hk-link" href="javascript:;" id="fundInfoDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" role="button">
                    {t("titles.fundInfo")}
                  </a>
                  <div className="dropdown-menu hk-dropdown-menu" aria-labelledby="fundInfoDropdown">
                    <a target='_blank' rel="noreferrer" href="https://www.principal.com.hk/investblog" className="dropdown-item dropdown-list-item">{t("titles.qurtEcoOutlook")}</a>
                    <span onClick={onFundPriceAndPerformance} data-testid="fundInfo" className="dropdown-item dropdown-list-item">{t("titles.fundPricesAndPerformance")}</span>
                  </div>
                </li>
                <li className="nav-item dropdown">
                  <a className="nav-link hk-link" href="javascript:;" id="downloadDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" role="button">
                    {t("titles.downloads")}
                  </a>
                  <div className="dropdown-menu hk-dropdown-menu" aria-labelledby="downloadDropdown">
                    <span onClick={() => { history.push('/documents'); }} data-testid="documents" className="dropdown-item dropdown-list-item">{t("titles.documents")}</span>
                    <span onClick={() => { history.push('/reports'); }} data-testid="reports" className="dropdown-item dropdown-list-item">{t("titles.reports")}</span>
                  </div>
                </li>
              </>}

              <li className="nav-item dropdown">
                <span className="nav-link hk-link hk-profile-link hk-link-inactive profile-link" id="userDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" role="button">
                  <img src={profile} alt="" className="d-inline-block nav-profile" />
                  <div className="d-none d-sm-block d-md-block d-lg-none inline-display"> {t("titles.profile")}</div>
                </span>
                <div className="dropdown-menu hk-dropdown-menu dropdown-menu-right" aria-labelledby="userDropdown">
                  {!isCurrentURL('/account') &&
                    <span className="dropdown-item dropdown-list-item d-none d-lg-block d-xl-block border-bottom" onClick={() => { history.push('/account'); }}>
                      <div className="row">
                        {showAllMenuOptions ? <div className="col-11 clientNmbrTxt">{AccountUtils.getContractNumber(props, props.selectedQuickPolicyInfo)}</div> : <div className="col-11 clientNmbrTxt">{clientNmbr}</div>}
                        <div className="col-11 my-auto accountTypeTxt">{capitalFlag ? capitalize.words(t(accountTypeText)) : t(accountTypeText)}</div>
                        <div className="col-1 p-0"><img src={arrow} alt="arrow" /></div>
                      </div>
                    </span>
                  }
                  {props.scheme === HKAIIB && showAllMenuOptions ?
                    <span onClick={onPersonalInfoClick} data-testid="profile" className="dropdown-item dropdown-list-item" href="/profile">{t("titles.personalInfo")}</span>
                    :
                    <span onClick={onPersonalInfoClick} data-testid="profile" className="dropdown-item dropdown-list-item" href="/profile">{t("titles.personalInfo")}</span>
                  }
                  <span onClick={() => { history.push('/changePin'); }} data-testid="changePin" className="dropdown-item dropdown-list-item" href="#/changePin">{t("titles.changePin")}</span>
                  <span className="dropdown-item dropdown-list-item d-none d-lg-block d-xl-block" onClick={onLogOutClick}>
                    <div className="row">
                      <div className="col-11 logout-text">
                        {t("titles.logout")}
                      </div>
                      <div className="col-1 p-0"><img src={greyArrow} alt="arrow" /></div>
                    </div>
                  </span>
                </div>
              </li>

              <li className="nav-item d-xl-none d-lg-none d-md-block d-sm-block">
                <NavLink
                  // eslint-disable-next-line no-undef
                  to={logoutTo(props?.scheme, !!(Number(process.env.REACT_APP_WRITE_ACCESS)))}
                  activeclassname="menu-active"
                  className="nav-link"
                >
                  <div className="row">
                    <div className="col-11 logout-text" data-testid="logOutLink" onClick={onLogOutClick}>{t("titles.logout")}</div>
                    <div className="col-1 p-0 pl-md-5"><img src={greyArrow} alt="arrow" /></div>
                  </div>
                </NavLink>
              </li>

              {props.scheme !== HKAIIB ?
                englishFlag ? (
                  <li className="nav-item translation">
                    <div className="nav-link" data-testid="EnglishLangLink" onClick={() => handleChangeLanguage()}>EN</div>
                  </li>
                ) : (
                  <li className="nav-item translation">
                    <div className="nav-link" data-testid="ChineseLangLink" onClick={() => handleChangeLanguage()}>
                      <img src={lang} alt="" className="d-inline-block" />
                    </div>
                  </li>
                ) : ""
              }
            </ul>
          </div>
        </nav>
      </BrowserRouter>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    countryDropDown: state.commonDropDown.countryDropDown,
    filteredEngCountryDropDown: state.commonDropDown.filteredEngCountryDropDown,
    filteredChineseCountryDropDown: state.commonDropDown.filteredChineseCountryDropDown,
    scheme: state.empLogin.scheme,
    clientNmbr: state.empLogin.clientNmbr,
    policyDetailsList: state.twoAccounts.allPolicyList,
    quickPolicyInfo: state.twoAccounts.quickPolicyInfo,
    selectedPolicyIndex: state.twoAccounts.selectedPolicyIndex,
    currentLoadedPageonUI: state.genInfo.currentLoadedPageOnUI,
    haveSelectedAccount: state.twoAccounts.haveSelectedAccount,
    chineseOrgNames: state.empLogin.chineseOrgNames,
    selectedQuickPolicyInfo: state.twoAccounts.selectedQuickPolicyInfo,
    clntRltnshpNmbr: state.empLogin.clntRltnshpNmbr,
    isOrsoAccount: state.twoAccounts.isOrsoAccount
  };
};

export default connect(mapStateToProps)(Header);