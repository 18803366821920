import {
    CHANGES_LIST_LOADING, CHANGE_FUND_DETAILS, CHANGE_STATUS_TYPE, RESET_CHANGES_LIST, SAVE_CHANGES_LIST,
    SAVE_CHANGES_PAGE_EDIT_FLAG, SAVE_CHANGES_PAGINATION, SAVE_CURRENT_PAGE_NO, SAVE_CURRENT_POSTS_ON_PAGE,
    SAVE_FILTERED_CHANGES_LIST, SAVE_FUND_FROM_DATE, SAVE_FUND_STATUS, SAVE_FUND_TO_DATE
} from "../actions/ActionConstants";

export function saveChangeFundDetails(data) {
    return { type: CHANGE_FUND_DETAILS, data }
}
export function saveChangesList(data) {
    return { type: SAVE_CHANGES_LIST, data }
}
export function changeStatusType(status) {
    return { type: CHANGE_STATUS_TYPE, status }
}
export function changePagination(totalActivities, paginationNo) {
    return {
        type: SAVE_CHANGES_PAGINATION,
        payload: { totalActivities, paginationNo }
    }
}
export function saveFilteredChangesList(data) {
    return { type: SAVE_FILTERED_CHANGES_LIST, data }
}
export function resetChangesList() {
    return { type: RESET_CHANGES_LIST }
}
export function isChangesListLoading() {
    return { type: CHANGES_LIST_LOADING }
}
export function setCurrentPageNo(data) {
    return { type: SAVE_CURRENT_PAGE_NO, data }
}
export function setCurrentPostOnPage(data) {
    return { type: SAVE_CURRENT_POSTS_ON_PAGE, data }
}
export function setFundStatus(data) {
    return { type: SAVE_FUND_STATUS, data }
}
export function setFundFromDate(data) {
    return { type: SAVE_FUND_FROM_DATE, data }
}
export function setFundToDate(data) {
    return { type: SAVE_FUND_TO_DATE, data }
}
export function setChangesPageEidtFlag(data) {
    return { type: SAVE_CHANGES_PAGE_EDIT_FLAG, data }
}
