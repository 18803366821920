import { HAVE_SELECTED_ACCOUNT, RESET_ACCOUNT_INFO, SAVE_ACCOUNT_INFO, SAVE_ALL_POLICY_DETAILS, SAVE_CORE_ORSO_ACCOUNT, SAVE_ENROLLMENT_DAY_COUNT, SAVE_LOGIN_SCHEME_PRODUCT, SAVE_MERGED_CONTRACT_CUSTOM_ATTRIBUTES, SAVE_ORSO_ACC_FLAG, SAVE_POLICY_DETAILS, SAVE_POLICY_ENROLLMENT_PERIOD_LIST, SAVE_PWC_CHECK, SAVE_QUICK_POLICY_INFO, SAVE_SELECTED_ACCOUNT_BALANCE, SAVE_SELECTED_POLICY_INDEX, SAVE_SELECTED_QUICK_POLICY_INFO, SET_PWC_SERVICE_FLAG } from "../actions/ActionConstants";

const initialState = {
    twoAccounts: true,
    isFatcaDeclPending: false,
    policyList: {},
    allPolicyList: [],
    quickPolicyInfo: [],
    loginSchemeProduct: '',
    selectedQuickPolicyInfo: {},
    selectedPolicyIndex: 0,
    pwcClientCheck: {},
    selectedAccountBalance: 0,
    haveSelectedAccount: false,
    pwcSvcFlag: false,
    isOrsoAccount: false,
    policyEnrollmentPeriodList: [],
    enrollmentDayCount: 0,
    mergedContractCustomAttributes: []
};

export default function accountReducer(state = initialState, action) {
    switch (action.type) {
        case SAVE_ACCOUNT_INFO:
            return { ...state, twoAccounts: action.data };
        case SAVE_CORE_ORSO_ACCOUNT:
            return { ...state, isFatcaDeclPending: action.data };
        case SAVE_POLICY_DETAILS:
            return { ...state, policyList: action.data };
        case SAVE_ALL_POLICY_DETAILS:
            return { ...state, allPolicyList: action.data };
        case SAVE_LOGIN_SCHEME_PRODUCT:
            return { ...state, loginSchemeProduct: action.data };
        case SAVE_QUICK_POLICY_INFO:
            return { ...state, quickPolicyInfo: action.data };
        case SAVE_SELECTED_QUICK_POLICY_INFO:
            return { ...state, selectedQuickPolicyInfo: action.data };
        case SAVE_SELECTED_POLICY_INDEX:
            return { ...state, selectedPolicyIndex: action.data };
        case SAVE_PWC_CHECK:
            return { ...state, pwcClientCheck: action.data };
        case SET_PWC_SERVICE_FLAG:
            return { ...state, pwcSvcFlag: action.data };
        case SAVE_SELECTED_ACCOUNT_BALANCE:
            return { ...state, selectedAccountBalance: action.data };
        case HAVE_SELECTED_ACCOUNT:
            return { ...state, haveSelectedAccount: action.data };
        case SAVE_ORSO_ACC_FLAG:
            return { ...state, isOrsoAccount: action.data };
        case SAVE_POLICY_ENROLLMENT_PERIOD_LIST:
            return { ...state, policyEnrollmentPeriodList: action.data };
        case SAVE_ENROLLMENT_DAY_COUNT:
            return { ...state, enrollmentDayCount: action.data };
        case SAVE_MERGED_CONTRACT_CUSTOM_ATTRIBUTES:
            return { ...state, mergedContractCustomAttributes: action.data };
        case RESET_ACCOUNT_INFO:
            return {
                ...state,
                twoAccounts: true,
                isFatcaDeclPending: false,
                policyList: {},
                allPolicyList: [],
                quickPolicyInfo: [],
                loginSchemeProduct: '',
                selectedQuickPolicyInfo: {},
                selectedPolicyIndex: 0,
                pwcClientCheck: {},
                selectedAccountBalance: 0,
                haveSelectedAccount: false,
                pwcSvcFlag: false,
                isOrsoAccount: false,
                policyEnrollmentPeriodList: [],
                enrollmentDayCount: 0,
                mergedContractCustomAttributes: []
            };
        default:
            return state;
    }
}