import { SAVE_TRANSACTION_DETAILS, SAVE_TRANSACTION_HISTORY } from '../actions/ActionConstants';

const initialState = {
    transactionDetails: {},
    transactionHistory: []
}

export default function TransactionReducer(state = initialState, action) {
    switch (action.type) {
        case SAVE_TRANSACTION_DETAILS:
            return { ...state, transactionDetails: action.data };
        case SAVE_TRANSACTION_HISTORY:
            return { ...state, transactionHistory: action.data };
        default:
            return state;
    }
}