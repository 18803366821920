import Dashboard from './Dashboard'
import Header from "../../SharedComponents/Header";

import React from 'react'

const index = () => {
    return (
        <>
           <Header/>
           <Dashboard/> 
        </>
    )
}

export default index
