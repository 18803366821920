import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import Arrowright from '../../assets/Arrow_right-2.svg';
import ArrowUp from '../../assets/Icon_Arrow_up.svg';
import '../../css/fund.css';
import { CORE_ORSO_ACCOUNT_S800, HKAIIB, notApplicable, SMP } from "../../translations/common/commonConstants";
import { FormatUtils, FundPerformanceUtil, getFundClass } from '../../utils';

const FundDetails = (props) => {
    const history = useHistory();
    let mgmtFees = props?.feesCharges?.feeChargesSmry?.filter((data) => (data.invTypeNmbr === props.fund.invTypeNmbr));
    const selectedFundInfoData = props.fundInfoData.find(fundInfoGlobalServiceObj => fundInfoGlobalServiceObj.invTypeNmbr === props.fund.invTypeNmbr);
    const { t, i18n } = useTranslation("common");
    const selectedQuickPolicyInfo = props?.quickPolicyInfo[props.selectedPolicyIndex];
    const productAcronym = selectedQuickPolicyInfo?.product?.productAcronym;
    const fundRisk = FundPerformanceUtil.getFundRisk(props?.scheme, props?.fund?.invRskNm, t);
    const getFundName = (i18n, filteredFundDetails) => {
        let fundName = '';
        if (props?.scheme?.includes(HKAIIB) || (CORE_ORSO_ACCOUNT_S800.includes(selectedQuickPolicyInfo?.product?.productTypeNmbr)) || props?.scheme?.includes(SMP)) {
            if (i18n.language === "en") {
                fundName = filteredFundDetails?.legalNm ? FormatUtils.formatFundName(filteredFundDetails.legalNm) : '';
            } else {
                fundName = filteredFundDetails?.othLegalNm ? filteredFundDetails.othLegalNm : '';
            }
        } else {
            const fundNamePostFix = getFundClass(props?.fundClassData, props?.fund?.invTypeNmbr, props?.fund?.invTypeNm);
            if (i18n.language === "en") {
                fundName = filteredFundDetails?.legalNm ? FormatUtils.formatFundName(filteredFundDetails.legalNm) + "-" + fundNamePostFix : '';
            } else {
                fundName = filteredFundDetails?.othLegalNm ? filteredFundDetails.othLegalNm + "-" + fundNamePostFix : '';
            }
        }
        return fundName;
    };

    return (
        <>
            <div id="fund_info_tab_details" className="row" data-testid="fund_details">
                <div className="col-12">
                    <div className="row" id={"transaction-fundDetails"}>
                        <label className="col-10 col-md-11 col-sm-11 col-xl-11 col-lg-11 fd-title">{t('fundDetails.title')}</label>
                        <a href="#" data-toggle="collapse" id={"transaction-fundDetails"} data-target={"#transactionPannel-fundDetails"} aria-expanded="true" aria-controls={"transactionPannel-fundDetails"} className="col-1">
                            <img src={ArrowUp} data-testid="hide_arrow"></img>
                        </a>
                    </div>
                    <div className="collapse-show collapse show row" data-parent={"#transaction-fundDetails"} id={"transactionPannel-fundDetails"} data-testid="fund_details_content">
                        <div className="col-12 col-md-6">
                            <label className="col-12 fd-field-lbl">{t('fundDetails.fundName')}</label>
                            <label className="col-12 fd-field-val">{getFundName(i18n, selectedFundInfoData)}</label>
                            <hr className='field-seprator-hr' />
                        </div>
                        <div className="col-12 col-md-6">
                            <label className="col-12 fd-field-lbl">{t('fundInfo.riskReturn')}</label>
                            <label className="col-12 fd-field-val">{fundRisk.risk}<img className="fd-risk" src={fundRisk.spectrum}></img></label>
                            <hr className='field-seprator-hr' />
                        </div>
                        {props.scheme != SMP &&
                            <>
                                <div className="col-12 col-md-6">
                                    <label className="col-12 fd-field-lbl">{t('fundDetails.riskClass')}</label>
                                    <label className="col-12 fd-field-val">{props?.fund?.performance?.riskClassTxt ? props.fund.performance.riskClassTxt === notApplicable ? t("common.responseNA") : props.fund.performance.riskClassTxt : t("common.responseNA")}</label>
                                    <hr className='field-seprator-hr' />
                                </div>
                            </>
                        }
                        <div className="col-12 col-md-6">
                            <label className="col-12 fd-field-lbl">{t('fundDetails.investmentManager')}</label>
                            <label className="col-12 fd-field-val">{productAcronym ? t('fundDetails.investmentManagerMPF') : t("common.responseNA")}</label>
                            {props.scheme != SMP && <hr className='field-seprator-hr' />}
                        </div>
                        {props.scheme != SMP &&
                            <>
                                <div className="col-12 col-md-6">
                                    <label className="col-12 fd-field-lbl">{t('fundDetails.launchDate')}</label>
                                    <label className="col-12 fd-field-val">{props?.fund?.performance?.inceptionDate ? moment(props.fund.performance.inceptionDate, "YYYY-MM-DD").format(i18n.language === 'en' ? 'D MMM YYYY' : 'YYYY年M月D日') : t("common.responseNA")}</label>
                                </div>
                            </>
                        }
                        {!props?.scheme?.includes(HKAIIB) || (CORE_ORSO_ACCOUNT_S800.includes(selectedQuickPolicyInfo?.product?.productTypeNmbr)) &&
                            <>
                                <div className="col-12 col-md-6">
                                    <label className="col-12 fd-field-lbl">{t('fundDetails.managementFees')}</label>
                                    <label className="col-10 col-md-11 col-sm-11 col-xl-11 col-lg-11 fd-field-val">{mgmtFees?.[0]?.stndInvAstRt ? mgmtFees[0].stndInvAstRt + '%' : ''}</label>
                                    {mgmtFees?.[0]?.noOfTier ? mgmtFees?.[0]?.noOfTier > 0 ? <img src={Arrowright} onClick={() => { history.push('/feesAndCharges'); }}></img> : '' : ''}
                                </div>
                            </>
                        }
                        {props?.scheme?.includes(HKAIIB) || (CORE_ORSO_ACCOUNT_S800.includes(selectedQuickPolicyInfo?.product?.productTypeNmbr)) && <hr className='field-seprator-hr d-none d-md-block' />}
                    </div>
                    <hr className="tab-seprator"></hr>
                </div>
            </div>
        </>
    );
};

FundDetails.propTypes = {
    props: PropTypes.any,
    fund: PropTypes.object,
    feesCharges: PropTypes.object,
    fundInfoData: PropTypes.arrayOf(PropTypes.object),
    fundClassData: PropTypes.array,
    quickPolicyInfo: PropTypes.array,
    selectedPolicyIndex: PropTypes.number,
    scheme: PropTypes.string
};

const mapStateToProps = (state) => {
    return {
        feesCharges: state.fundPerformance.feesCharges,
        fundInfoData: state.fundSwitch.fundInfoData,
        fundClassData: state.fundPerformance.fundClassData,
        quickPolicyInfo: state.twoAccounts.quickPolicyInfo,
        selectedPolicyIndex: state.twoAccounts.selectedPolicyIndex,
        scheme: state.empLogin.scheme
    };
};

export default connect(mapStateToProps)(FundDetails);