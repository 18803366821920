import { SAVE_CODE_CHALLENGE, SAVE_CODE_VERIFIER, SAVE_NONCE, SAVE_STATE } from "./ActionConstants";



export function saveCodeChallenge(data) {
    return { type: SAVE_CODE_CHALLENGE, data }
}

export function saveCodeVerifier(data) {
    return { type: SAVE_CODE_VERIFIER, data }
}

export function saveNonce(data) {
    return { type: SAVE_NONCE, data }
}
export function saveState(data) {
    return { type: SAVE_STATE, data }
}