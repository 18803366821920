import moment from "moment";
import { saveFundInfoData, saveSwitchInData } from "../actions/FundSwitchAction";
import switchInApi from "../API/Fund-Switch-Existing/switch-In-api";
import fundInfoApi from "../API/FundInfo/fundInfoApi";
import { DIS800, DIS_SIMP, DIS_SMAP, LSSPOSTFIX } from "../translations/common/commonConstants";
import AccountUtils from "./AccountUtils";
import FormatUtils from "./FormatUtils";

const addFundDetailsToArray = async (props,investmentTypeList, productTypeNmbr, cycleDate, headers) => {
  let invList = [...investmentTypeList]

  let fundDetailsResp = await fundInfoApi.getFundDetails((String(productTypeNmbr)), (cycleDate), headers)
  let decodedFundDetailsResp = fundDetailsResp.data;
  await AccountUtils.compareWebCycleDateWithExistingWebCycleDate(props,decodedFundDetailsResp?.webCycleDate);
  if (decodedFundDetailsResp.fundPriceDetails && decodedFundDetailsResp.fundPriceDetails.length > 0) {
    let fundDetails = decodedFundDetailsResp.fundPriceDetails
    invList.forEach(inv => {
      for (let index = 0; index < fundDetails.length; index++) {
        const fund = fundDetails[index];
        if (inv.invTypeNmbr === fund.invTypeNmbr) {
          inv['details'] = { ...fund };
          break;
        }
      }
    });
  }

  return invList;
}


const addFundPerformanceToArray = async (props,fundDetails, productTypeNmbr, headers, lastChangeDt) => {
  let fundInfo = []
  var emptyPerf =
  {
    "asOfDate": "N/A",
    "productTypeNmbr": productTypeNmbr,
    "rcrdTypeNmbr": "N/A",
    "acronymNm": "N/A",
    "cfInvTypeNmbr": 0,
    "productTypeNm": "N/A",
    "invTypeNm": "N/A",
    "inceptionDate": "N/A",
    "navPerUnit": "N/A",
    "totalOtstdUnit": "N/A",
    "totalNavAmt": "N/A",
    "inceptionYr": "N/A",
    "cr1mTxt": "N/A",
    "cr3mTxt": "N/A",
    "cr6mTxt": "N/A",
    "cr1yTxt": "N/A",
    "cr3yTxt": "N/A",
    "cr5yTxt": "N/A",
    "cr10yTxt": "N/A",
    "crYtdTxt": "N/A",
    "crSiTxt": "N/A",
    "crUdfPrdTxt": "N/A",
    "ar1mTxt": "N/A",
    "ar3mTxt": "N/A",
    "ar6mTxt": "N/A",
    "arPrevCalYrTxt": "N/A",
    "ar1yTxt": "N/A",
    "ar3yTxt": "N/A",
    "ar5yTxt": "N/A",
    "ar10yTxt": "N/A",
    "arYtdTxt": "N/A",
    "arSiTxt": "N/A",
    "arUdfPrdTxt": "N/A",
    "iy1Txt": "N/A",
    "iy2Txt": "N/A",
    "iy3Txt": "N/A",
    "iy4Txt": "N/A",
    "iy5Txt": "N/A",
    "iy6Txt": "N/A",
    "iy7Txt": "N/A",
    "iy8Txt": "N/A",
    "iy9Txt": "N/A",
    "iy10Txt": "N/A",
    "iyNTxt": "N/A",
    "stdDeviationTxt": "N/A",
    "cy1Txt": "N/A",
    "cy2Txt": "N/A",
    "cy3Txt": "N/A",
    "cy4Txt": "N/A",
    "cy5Txt": "N/A",
    "riskClassTxt": "N/A",
    "systemSeqNmbr": "N/A",
    "processorId": "N/A",
    "lastChangeDt": "N/A"
  };
  let fundPerformanceResp = await switchInApi.getFundPerformanceDetails((String(productTypeNmbr)), headers)
  let decodedFundPerformanceResp = fundPerformanceResp.data
  await AccountUtils.compareWebCycleDateWithExistingWebCycleDate(props,decodedFundPerformanceResp?.webCycleDate);
  let isPresent = [];
  let fundPerformanceList = []
  if (Array.isArray(decodedFundPerformanceResp.fundPerformanceVoList) && decodedFundPerformanceResp.fundPerformanceVoList.length > 0) {
    fundPerformanceList = decodedFundPerformanceResp.fundPerformanceVoList
    fundDetails.forEach(fund => {
      for (let index = 0; index < fundPerformanceList.length; index++) {
        const fundPerformance = fundPerformanceList[index];
        if (fund.invTypeNmbr === fundPerformance.invTypeNmbr) {
          isPresent.push(fund.invTypeNmbr);
          fundInfo.push(
            {
              ...fund,
              asOfDate: lastChangeDt,
              performance: { ...fundPerformance },
              percent: 0
            }
          );
          break;
        }
      }
    });
    let toAppend = 0;
    fundDetails.forEach(fund => {
      for (let index = 0; index < fundPerformanceList.length; index++) {
        for (let i = 0; i < isPresent.length; i++) {
          if (isPresent[i] == fund.invTypeNmbr) {
            toAppend = 0;
            break;
          }
          else toAppend = 1;
        }
        if (toAppend == 1) {
          fundInfo.push(
            {
              ...fund,
              asOfDate: lastChangeDt,
              performance: { ...emptyPerf, invTypeNmbr: fund.invTypeNmbr },
              percent: 0
            }
          );
          break;
        }
      }

    });
  }
  else {
    fundDetails.forEach(fund => {
      fundInfo.push(
        {
          ...fund,
          asOfDate: lastChangeDt,
          performance: { ...emptyPerf, invTypeNmbr: fund.invTypeNmbr },
          percent: 0
        }
      );

    });
  }
  return fundInfo;
}

const saveGlobalFundInfo = (props, contractNmbr, productTypeNmbr, webCycleDate, headers) => {
  switchInApi.getFundInfo((String(contractNmbr)), headers)
    .then((response) => {
      let decodedResponse =  response?.data;
      const fundInfoDetails = decodedResponse;
      props.dispatch(saveFundInfoData(fundInfoDetails));
      const webCycleDt = moment(webCycleDate, "YYYY-MM-DD HH:mm:ss A").format("YYYY-MM-DD")

      addFundDetailsToArray(props,fundInfoDetails, productTypeNmbr, webCycleDt, headers)
        .then((fundDetails) => {
          let fundData = fundDetails.filter((fund) => fund.isActiveFund === true)
          props.dispatch(saveSwitchInData(fundData))
        })
        .catch(() => {
          props.dispatch(saveSwitchInData([]))
        })
    })
    .catch(() => {
      props.dispatch(saveFundInfoData([]));
    })
}

const addGlobalFundInfoWithDetails = async (props, fundInfoDetails, productTypeNmbr, webCycleDt, headers) => {
  addFundDetailsToArray(props,fundInfoDetails, productTypeNmbr, webCycleDt, headers)
    .then((fundDetails) => {
      let fundData = fundDetails.filter((fund) => fund.isActiveFund === true)
      props.dispatch(saveSwitchInData(fundData))
    })
    .catch(() => {
      props.dispatch(saveSwitchInData([]))
    })
}

const getFundInfo = async (props,contractNmbr, productTypeNmbr, effectiveDt, reqHeaders, selectedScheme) => {
  let fundInfo = []
  let response = {}

  if(selectedScheme === 0) {
    response = await fundInfoApi.getLatestFundInfo((String(contractNmbr)), (String(productTypeNmbr)), reqHeaders)
  } else {
    response = await fundInfoApi.getFundInfo((String(contractNmbr)), (String(productTypeNmbr)), (String(effectiveDt)), reqHeaders)
  }
    
  let decodedResponse = response.data;
  await AccountUtils.compareWebCycleDateWithExistingWebCycleDate(props,decodedResponse?.webCycleDate);
  const fundInfoRiskCurrency = decodedResponse?.fundInfoRiskCurrencyVOs ?? []
  const fundPerformanceDetails = decodedResponse?.fundPerformanceDetails?.fundPerformanceVoList ?? []
  const fundPriceDetails = decodedResponse?.fundPriceDetails?.fundPriceDetails ?? []

  const fundInfoData = fundInfoRiskCurrency?.filter((fund) => fund?.productTypeNmbr === productTypeNmbr)
  
  fundInfoData.forEach(fundData => {
    for (let index = 0; index < fundPriceDetails.length; index++) {
      const fundPriceData = fundPriceDetails[index];
      if (fundData?.invTypeNmbr === fundPriceData?.invTypeNmbr && ![DIS800,DIS_SIMP,DIS_SMAP].includes(fundData?.invTypeNm) && !fundData?.invTypeNm?.includes(LSSPOSTFIX)) {
        fundInfo.push(
          {
            ...fundData,
            unitPriceAmt: fundPriceData?.unitPriceAmt ? FormatUtils.formatToDecimalFix(fundPriceData.unitPriceAmt, 4) : 0,
            asOfDate: fundPriceData?.asOfDate
          }
        );
        break;
      }
    }
  });
  
  if (fundInfo.length > 0) {
    fundInfo.forEach(fund => {
      for (let index = 0; index < fundPerformanceDetails.length; index++) {
        const fundPerformanceData = fundPerformanceDetails[index];
        if (fund?.invTypeNmbr === fundPerformanceData?.invTypeNmbr) {
          fund['performance'] = { ...fundPerformanceData }
          break;
        }
      }
    });
  }
  return fundInfo;
}

export { addFundDetailsToArray, addFundPerformanceToArray, saveGlobalFundInfo, addGlobalFundInfoWithDetails, getFundInfo };
