import {
    HAVE_SELECTED_ACCOUNT, RESET_ACCOUNT_INFO, SAVE_ACCOUNT_INFO, SAVE_ALL_POLICY_DETAILS, SAVE_CORE_ORSO_ACCOUNT,
    SAVE_ENROLLMENT_DAY_COUNT,
    SAVE_LOGIN_SCHEME_PRODUCT, SAVE_MERGED_CONTRACT_CUSTOM_ATTRIBUTES, SAVE_ORSO_ACC_FLAG, SAVE_POLICY_DETAILS, SAVE_POLICY_ENROLLMENT_PERIOD_LIST, SAVE_PWC_CHECK, SAVE_QUICK_POLICY_INFO,
    SAVE_SELECTED_ACCOUNT_BALANCE, SAVE_SELECTED_POLICY_INDEX, SAVE_SELECTED_QUICK_POLICY_INFO, SET_PWC_SERVICE_FLAG
} from "../actions/ActionConstants";

export function saveAccountInfoFlag(data) {
    return { type: SAVE_ACCOUNT_INFO, data }
}
export function saveCoreOrsoAccount(data) {
    return { type: SAVE_CORE_ORSO_ACCOUNT, data }
}
export function savePolicyDetails(data) {
    return { type: SAVE_POLICY_DETAILS, data }
}
export function saveAllPolicyDetails(data) {
    return { type: SAVE_ALL_POLICY_DETAILS, data }
}
export function saveSelectedPolicyIndex(data) {
    return { type: SAVE_SELECTED_POLICY_INDEX, data }
}
export function saveQuickPolicyInfo(data) {
    return { type: SAVE_QUICK_POLICY_INFO, data }
}
export function saveLoginSchemeProduct(data) {
    return { type: SAVE_LOGIN_SCHEME_PRODUCT, data }
}
export function saveSelectedQuickPolicyInfo(data) {
    return { type: SAVE_SELECTED_QUICK_POLICY_INFO, data }
}
export function savePwcCheck(data) {
    return { type: SAVE_PWC_CHECK, data }
}
export function setPwcSvcFlag(data) {
    return { type: SET_PWC_SERVICE_FLAG, data }
}
export function saveSelectedAccountBalance(data) {
    return { type: SAVE_SELECTED_ACCOUNT_BALANCE, data }
}
export function haveSelectedAccount(data) {
    return { type: HAVE_SELECTED_ACCOUNT, data }
}
export function savePolicyEnrollmentPeriodList(data) {
    return { type: SAVE_POLICY_ENROLLMENT_PERIOD_LIST, data }
}
export function saveEnrollmentDayCount(data) {
    return { type: SAVE_ENROLLMENT_DAY_COUNT, data }
}
export function resetAccountInfo() {
    return { type: RESET_ACCOUNT_INFO }
}
export function saveOrsoAccFlag(data) {
    return { type: SAVE_ORSO_ACC_FLAG, data }
}
export function saveMergedContractCustomAttributes(data) {
    return { type: SAVE_MERGED_CONTRACT_CUSTOM_ATTRIBUTES, data }
}