import { SAVE_CURRENT_LOADED_PAGE_ON_UI } from "../actions/ActionConstants";

const initialState = {
    currentLoadedPageOnUI: ''
}

export default function genInfoReducer(state = initialState, action) {
    switch (action.type) {
        case SAVE_CURRENT_LOADED_PAGE_ON_UI:
            return { ...state, currentLoadedPageOnUI: action.data };
        default:
            return state;
    }
}