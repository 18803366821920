import { instance } from '../AxiosInterceptor';

export default {
    getInvestmentTransferDetails: (inputParams, requestHeaders) =>
        instance({
            'method': 'GET',
            'url': '/transactions/investmenttransferdetails',
            headers: requestHeaders,
            params: {
                clntRltnShpNmbr: inputParams.clntRltnShpNmbr,
                efctvDate: inputParams.efctvDate,
                moneyTypeName: inputParams.moneyTypeName
            }
        }),
    getChangesList: (clntRltnshpNmbr, requestHeaders) =>
        instance({
            'method': 'GET',
            'url': '/cycleactivities/history?clntRltnshpNmbr=' + clntRltnshpNmbr,
            headers: requestHeaders,
        }),
    getCycleActivityDetails: (clntRltnshpNmbr, referenceNmbr, requestHeaders) =>
        instance({
            method: 'GET',
            url: 'cycleactivities/cycleActivityDetails',
            headers: requestHeaders,
            params: {
                clntRltnshpNmbr,
                referenceNmbr
            }
        }),
    cancelFundTransaction: (inputParams, requestHeaders) =>
        instance({
            'method': 'DELETE',
            'url': '/cycleactivities/cancelTransaction',
            headers: requestHeaders,
            'params': {
                'clntRltnShpNmbr': inputParams.clntRltnShpNmbr,
                'contractNmbr': inputParams.contractNmbr,
                'processorID': inputParams.processorID,
                'activityIds': inputParams.activityIds
            },
        })
}
