import { instance } from '../AxiosInterceptor';

export default {
    getClientDetails: (clientNmbr, requestHeaders) =>
        instance({
            'method': 'GET',
            'url': '/client',
            headers: requestHeaders,
            'params': {
                'clientNmbr': clientNmbr,
            },
        }),

    updateClientDetails: (updateData, requestHeaders) =>
        instance({
            'method': 'PUT',
            'url': '/client',
            headers: requestHeaders,
            data: updateData
        }),

    changePin: (changePinJson, requestHeaders) =>
        instance({
            'method': 'POST',
            'url': '/auth/changepin',
            headers: requestHeaders,
            data: changePinJson
        }),

    //forgotPin: (forgotPinJson, requestHeaders) =>
    forgotPin: (forgotPinJson) => /*Michael Lam 20210823*/
        instance({
            //'method': 'PUT',
            'method': 'POST', /*Michael Lam 20210823*/
            //'url': '/auth/resetpin',
            'url': '/auth/forgotPin', /*Michael Lam 20210823*/
            //headers: requestHeaders, /*Michael Lam 20210823*/
            data: forgotPinJson
        }),

    // checkPin: (checkPinJson, requestHeaders) =>
    //     instance({
    //         'method': 'POST',
    //         'url': 'auth/authenticate',
    //         headers: requestHeaders,
    //         data: checkPinJson
    //     }),
    checkPin: (checkPinJson, requestHeaders) => //Michael Lam 20210831
        instance({
            'method': 'POST',
            'url': 'auth/authenticateMFA',
            headers: requestHeaders,
            data: checkPinJson
        }),

    updateClientAddressDetails: (updateData, requestHeaders) =>
        instance({
            'method': 'POST',
            'url': 'client/address',
            headers: requestHeaders,
            data: updateData
        }),
    updateClientPhoneDetails: (updateData, requestHeaders) =>
        instance({
            'method': 'POST',
            'url': 'client/phone',
            headers: requestHeaders,
            data: updateData
        }),

    updateClientDetailsOKTA: (updateData, requestHeaders) => //Michael Lam 20210831
        instance({
            'method': 'POST',
            'url': 'auth/updateUserInfo',
            headers: requestHeaders,
            data: updateData
        }),

    updateCustomData: (updateData, requestHeaders) =>
        instance({
            'method': 'PUT',
            'url': '/client/customdata',
            headers: requestHeaders,
            data: updateData
        }),
}
