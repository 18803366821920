import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import IdleTimer from 'react-idle-timer';
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { Slide, toast } from 'react-toastify';
import { resetAccountInfo, saveQuickPolicyInfo } from '../actions/AccountAction';
import { setChangesPageEidtFlag, setCurrentPageNo } from '../actions/ChangesAction';
import { savePopUpAlertStatus } from '../actions/DashboardAction';
import { resetLoginInfo, saveOrganizationsChineseNames } from '../actions/EmployeeLoginAction';
import { resetPerformaceInfo } from '../actions/FundPerformanceAction';
import { clearSwitchInFundsPercent, resetFundSwitchData } from '../actions/FundSwitchAction';
import { resetMergerData, saveMergerAttributes, setIsMerger } from '../actions/MergerInfoAction';
import { resetportfoilioDetails } from '../actions/PortfolioAction';
import { saveAuthenticationFlag } from '../actions/TwoFactorAuthenticationAction';
import Toast from '../Components/Toast';
import { logoutTo } from '../utils';

const IdleTimerContainer = (props) => {
    const idleTimerRef = useRef(null);
    const sessionTimeoutRef = useRef(null);
    const history = useHistory();

    const toastOptions = {
        position: 'top-center',
        autoClose: 4000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        transition: Slide,
        rtl: false,
        closeButton: false,
    };

    const onIdle = () => {
        toast.dismiss();
        sessionTimeoutRef.current = setTimeout(logout, 5000);
    };

    const sendNotification = (type) => {
        toast(<Toast type={type} />, toastOptions);
    };

    const logout = () => {
        toast.dismiss();
        clearTimeout(sessionTimeoutRef.current);
        props.saveAuthenticationFlag(false);
        // eslint-disable-next-line no-undef
        let path = logoutTo(props?.scheme, !!(Number(process.env.REACT_APP_WRITE_ACCESS)));
        history.push(path);
        onLogOutClick();
        sendNotification('session-expired');
    };

    const onLogOutClick = () => {
        let filteredAccounts = [];
        props.saveAuthenticationFlag(false);
        props.clearSwitchInFundsPercent();
        props.setChangesPageEidtFlag(false);
        props.setCurrentPageNo(0);
        props.saveQuickPolicyInfo([...filteredAccounts]);
        props.setIsMerger(false);
        props.saveMergerAttributes([]);
        props.resetPerformaceInfo();
        props.resetMergerData();
        props.saveOrganizationsChineseNames([]);
        props.resetportfoilioDetails();
        props.resetAccountInfo();
        props.resetFundSwitchData();
        props.savePopUpAlertStatus(false);
        props.resetLoginInfo();
    };

    if (props.isAuthenticated) {
        setTimeout(function () {
            logout();
        }, 3540000);
        return (
            <div>
                <IdleTimer ref={idleTimerRef} timeout={1000 * 60 * 20} onIdle={onIdle}></IdleTimer>
            </div>
        );
    } else {
        return (<div></div>);
    }
};

const mapDispatchToProps = () => {
    return {
        saveAuthenticationFlag,
        resetPerformaceInfo,
        saveMergerAttributes,
        setIsMerger,
        saveQuickPolicyInfo,
        setCurrentPageNo,
        setChangesPageEidtFlag,
        clearSwitchInFundsPercent,
        resetMergerData,
        saveOrganizationsChineseNames,
        resetportfoilioDetails,
        resetAccountInfo,
        resetFundSwitchData,
        savePopUpAlertStatus,
        resetLoginInfo
    };
};

const mapStateToProps = (state) => {
    return {
        isAuthenticated: state.isAuthenticated.isAuthenticated,
        scheme: state.empLogin.scheme
    };
};

IdleTimerContainer.propTypes = {
    props: PropTypes.any,
    saveAuthenticationFlag: PropTypes.func,
    dispatch: PropTypes.func,
    resetPerformaceInfo: PropTypes.func,
    saveMergerAttributes: PropTypes.func,
    setIsMerger: PropTypes.func,
    saveQuickPolicyInfo: PropTypes.func,
    setCurrentPageNo: PropTypes.func,
    setChangesPageEidtFlag: PropTypes.func,
    clearSwitchInFundsPercent: PropTypes.func,
    resetMergerData: PropTypes.func,
    saveOrganizationsChineseNames: PropTypes.func,
    resetportfoilioDetails: PropTypes.func,
    resetAccountInfo: PropTypes.func,
    resetFundSwitchData: PropTypes.func,
    isAuthenticated: PropTypes.bool,
    scheme: PropTypes.string,
    savePopUpAlertStatus: PropTypes.func,
    resetLoginInfo: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps())(IdleTimerContainer);