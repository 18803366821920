import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router";
import PrincipalLogo from "../assets/Logo/Principal-Logo-2.svg";
import "../css/HeaderFooter.css";
import { copyright, footerText, HKAIIB } from "../translations/common/commonConstants";

const FooterAIIB = (props) => {
  const location = useLocation();
  let footerAbsolute = false;

  if (props.scheme == HKAIIB) {
    if (location.pathname == "/selectChannel" || location.pathname == "/selectChanneltemp" || location.pathname == "/authenticateOTP" || location.pathname == "/authenticateOTPtemp") {
      footerAbsolute = true;
    } else {
      footerAbsolute = false;
    }
  }

  return (
    <div className={footerAbsolute ? "footer-aiib-absolute" : "footer-aiib"}>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <img src={PrincipalLogo} className="principal-logo-aiib" />
            <label className="footer-aiib-text">{copyright}{new Date().getFullYear()}{footerText}</label>
          </div>
        </div>
      </div>
    </div>
  );
};

FooterAIIB.propTypes = {
  props: PropTypes.any,
  scheme: PropTypes.string
};

const mapStateToProps = (state) => {
  return {
    scheme: state.empLogin.scheme
  };
};

export default connect(mapStateToProps)(FooterAIIB);