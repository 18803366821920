import { ADD_FUND_SWITCH_IN_PERCENT, CLEAR_SWITCH_IN_FUNDS_PERCENT, SAVE_SELECTED_FUNDS_FOR_SWITCH_IN } from '../actions/ActionConstants.js';

const initialState = {
    fundList: [],
    selectedSwitchInFunds: {}
}

export default function fundSwitchInReducer(state = initialState, action) {
    switch (action.type) {
        case ADD_FUND_SWITCH_IN_PERCENT:
            return { ...state, fundList: action.data }
        case SAVE_SELECTED_FUNDS_FOR_SWITCH_IN:
            return { ...state, selectedSwitchInFunds: action.data }
        case CLEAR_SWITCH_IN_FUNDS_PERCENT:
            return {
                ...state,
                selectedSwitchInFunds: {}
            };
        default:
            return state;
    }
}