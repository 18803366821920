import PropTypes from 'prop-types';
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect } from 'react-redux';
import { saveSchemeType } from '../../actions/EmployeeLoginAction';
import { saveAuthenticationFlag } from '../../actions/TwoFactorAuthenticationAction';
import "../../css/EmpLogin.css";
import LoginHeader from "../../SharedComponents/LoginHeader";
import ErAgentsOtherInd from "./LoginTypes/ErAgentsOtherInd";
import OtherORSO from "./LoginTypes/OtherORSO";
import S800andORSO from "./LoginTypes/S800andORSO";
import SmartSimple from "./LoginTypes/SmartSimple";

const LoginComponent = (props) => {
  const { t } = useTranslation("common");

  useEffect(() => {
    props.dispatch(saveSchemeType(""));
    props.dispatch(saveAuthenticationFlag(false));
  }, []);

  return (
    <div className="main-content mb-3">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h1 className="login-type-title">{t("titles.loginType")}</h1>
            <div className="row">
              <div className="col-sm-12 col-md-6 col-lg-3 d-flex align-items-stretch">
                <S800andORSO />
              </div>
              <div className="col-sm-12 col-md-6 col-lg-3 d-flex align-items-stretch">
                <SmartSimple />
              </div>
              <div className="col-sm-12 col-md-6 col-lg-3 d-flex align-items-stretch">
                <ErAgentsOtherInd />
              </div>
              <div className="col-sm-12 col-md-6 col-lg-3 d-flex align-items-stretch">
                <OtherORSO />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

LoginComponent.propTypes = {
  props: PropTypes.any,
  dispatch: PropTypes.func
}

const LoginComponentExport = connect()(LoginComponent);
export default class Login extends React.Component {
  constructor() {
    super();
  }
  render() {
    return (
      <div id="wrapper">
        <LoginHeader></LoginHeader>
        <LoginComponentExport></LoginComponentExport>
      </div>
    );
  }
}