import { instance } from '../AxiosInterceptor';

export default {
    getCntryDropDowns: (requestHeaders) =>
        instance({
            'method': 'GET',
            'url': '/config/countrylist',
            headers: requestHeaders
        }),
    getCycleDate: (requestHeaders) =>
        instance({
            'method': 'GET',
            'url': '/config/cycledate',
            headers: requestHeaders
        }),
    getInvestmentContributionValidations: (clientNumber, requestHeaders) =>
        instance({
            'method': 'GET',
            'url': '/investments/contributionvalidations?clntRltnShpNmbr=' + (clientNumber),
            headers: requestHeaders,
        }),
    getInvestmentContributionPendingStatus: (clntRltnShpNmbr, productTypeNmbr, moneyType, requestHeaders) =>
        instance({
            'method': 'GET',
            'url': '/investments/pendingContributions',
            headers: requestHeaders,
            params: {
                clntRltnShpNmbr,
                productTypeNmbr,
                moneyType
            }
        }),
}
