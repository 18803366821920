import moment from "moment";
import { SortUtils } from ".";
import { saveGainLossData } from '../actions/DashboardAction';
import { saveContributionDetails, setUpdateDone } from "../actions/PortfolioAction";
import accountApi from "../API/Account/accountApi";
import DashboardApi from "../API/Dashboard/DashboardApi";
import { removeLegalMergeTransfer, removeTransaction } from "../App/Changes/TransactionType";
import { accountTypeEe, chngDate, cnNonCorpERName, DDMMYYYY, descendingOrder, effectiveDate, EN, pollingAgrmntName, schemeNameEn, schemeNameZh, ZH } from "../translations/common/commonConstants";
import AccountUtils from "./AccountUtils";
import FormatUtils from "./FormatUtils";

const getGainLossData = (props, setServiceCallFlag, calDate) => {
    let toDate = moment(calDate).format("DD/MM/YYYY");
    const headers = {
        'pisys_auth_token': props.pisysToken
    };
    const inputJson = {
        clientNmbr: (props.clientNmbr),
        clntRltnshpNmbr: (props && props.clntRltnshpNmbr ? props.clntRltnshpNmbr : ""),
        toDate: (toDate)
    };

    let decodedResponse;
    DashboardApi.getGainAndLossDetails(inputJson, headers).then((response) => {
        if (response?.status === 200) {
            decodedResponse = response?.data;
            setServiceCallFlag(false);
            if (decodedResponse) {
                props.dispatch(saveGainLossData(decodedResponse[0]));
            }
        }
    }).catch(() => {

    });
    return decodedResponse;
};

const getUnvestedAmount = (filteredPortfolioDetails) => {
    let totalAccountBalance = 0, vestedAmount = 0, unvestedAmount = 0;
    filteredPortfolioDetails.map((amount) => totalAccountBalance = totalAccountBalance + amount.investmentAmt);
    filteredPortfolioDetails.map((amount) => vestedAmount = vestedAmount + amount.vestedAmt);
    unvestedAmount = totalAccountBalance - vestedAmount;
    return unvestedAmount;
};

const getIntraGrpTranferData = async (props, calDate) => {
    let month = new Date(calDate).getMonth() + 1;
    if (month < 10) { month = '0' + month; }
    const year = new Date(calDate).getFullYear();
    const headers = {
        'username': props.clientNmbr,
        'pisys_auth_token': props.pisysToken
    };
    const inputJson = {
        clntRltnshpNmbr: (props && props.clntRltnshpNmbr ? props.clntRltnshpNmbr : ""),
        year: (year ? year : ""),
        month: (month ? month : ""),
        isLatest: true
    };
    const response = await DashboardApi.getIntraGrpDetails(inputJson, headers);
    const decodedResponse = response?.data?.intraGroupTransferResponseVOs;
    await AccountUtils.compareWebCycleDateWithExistingWebCycleDate(props, response?.data?.webCycleDate);
    return decodedResponse;
};

const getIntraGrpTranferDetailsWithContributions = async (props, calDate) => {
    let month = moment(calDate, 'YYYY/MM/DD').format('MM');
    const year = moment(calDate, 'YYYY/MM/DD').format('YYYY');
    const headers = {
        'username': props.clientNmbr,
        'pisys_auth_token': props.pisysToken
    };
    const inputJson = {
        clntRltnshpNmbr: (props && props.clntRltnshpNmbr ? props.clntRltnshpNmbr : ""),
        year: (year ? year : ""),
        month: (month ? month : ""),
        contributionFlg: (1)
    };
    const response = await DashboardApi.getIntraGrpDetails(inputJson, headers);
    const decodedResponse = response?.data?.intraGroupTransferResponseVOs;
    await AccountUtils.compareWebCycleDateWithExistingWebCycleDate(props, response?.data?.webCycleDate);
    return decodedResponse;
};

const getContributionGainAndLoss = (accountBalance, totalAmountOfNetContribution) => {
    let mandatoryEmployerGLAmt = 0, mandatoryMemberGLAmt = 0, voluntaryEmployerGLAmt = 0, voluntaryMemberGLAmt = 0, mandatoryGLAmtFormer = 0, voluntaryGLAmtFormer = 0;
    if (accountBalance && totalAmountOfNetContribution) {
        mandatoryEmployerGLAmt = accountBalance.erManAccBal - totalAmountOfNetContribution.erManTotalNetCntrb;
        mandatoryMemberGLAmt = accountBalance.eeManAccBal - totalAmountOfNetContribution.eeManTotalNetCntrb;
        voluntaryEmployerGLAmt = accountBalance.erVolAccBal - totalAmountOfNetContribution.erVolTotalNetCntrb;
        voluntaryMemberGLAmt = accountBalance.eeVolAccBal - totalAmountOfNetContribution.eeVolTotalNetCntrb;
        mandatoryGLAmtFormer = accountBalance.manAccBalFormerEmp - totalAmountOfNetContribution.erManFormerEmpNetCntrb;
        voluntaryGLAmtFormer = accountBalance.volAccBalFormerEmp - totalAmountOfNetContribution.erVolFormerEmpNetCntrb;
    }
    return {
        mandatoryEmployerGLAmt: mandatoryEmployerGLAmt,
        mandatoryMemberGLAmt: mandatoryMemberGLAmt,
        voluntaryEmployerGLAmt: voluntaryEmployerGLAmt,
        voluntaryMemberGLAmt: voluntaryMemberGLAmt,
        mandatoryGLAmtFormer: mandatoryGLAmtFormer,
        voluntaryGLAmtFormer: voluntaryGLAmtFormer
    };
};

const getDistributedTotals = (transactions, totalReducer, accountType) => {

    let manTransactions = [];
    let volTransactions = [];
    let formerManTransactions = [];
    let formerVolTransactions = [];

    if (accountType?.includes(accountTypeEe)) {
        const tfrgManContributions = transactions?.filter((transaction) => transaction?.moneyTypeNm?.includes('MAN') && transaction?.moneyTypeNm?.includes('TFR-G'));
        const tfrgVolContributions = transactions?.filter((transaction) => !transaction?.moneyTypeNm?.includes('MAN') && transaction?.moneyTypeNm?.includes('TFR-G'));

        formerManTransactions = transactions?.filter((transaction) => transaction?.moneyTypeNm?.includes('MAN') && transaction?.moneyTypeNm?.includes('TFR') && !transaction?.moneyTypeNm?.includes('TFR-G'));
        formerVolTransactions = transactions?.filter((transaction) => !transaction?.moneyTypeNm?.includes('MAN') && transaction?.moneyTypeNm?.includes('TFR') && !transaction?.moneyTypeNm?.includes('TFR-G'));

        manTransactions = transactions?.filter((transaction) => transaction?.moneyTypeNm?.includes('MAN') && !transaction?.moneyTypeNm?.includes('TFR'));
        volTransactions = transactions?.filter((transaction) => !transaction?.moneyTypeNm?.includes('MAN') && !transaction?.moneyTypeNm?.includes('TFR'));

        manTransactions = manTransactions.concat(tfrgManContributions);
        volTransactions = volTransactions.concat(tfrgVolContributions);
    } else {
        manTransactions = transactions?.filter((transaction) => transaction?.moneyTypeNm?.includes('MAN'));
        volTransactions = transactions?.filter((transaction) => !transaction?.moneyTypeNm?.includes('MAN'));
    }

    const erManTransactions = manTransactions?.filter((transaction) => !transaction?.moneyTypeNm?.includes('EE') && !transaction?.moneyTypeNm?.includes('SEP'));
    const eeManTransactions = manTransactions?.filter((transaction) => transaction?.moneyTypeNm?.includes('EE') || transaction?.moneyTypeNm?.includes('SEP'));

    const eeManTotal = FormatUtils.roundNumber(eeManTransactions?.reduce(totalReducer, 0) ?? 0, 2);
    const erManTotal = FormatUtils.roundNumber(erManTransactions?.reduce(totalReducer, 0) ?? 0, 2);

    const erVolTransactions = volTransactions?.filter((transaction) => !transaction?.moneyTypeNm?.includes('EE') && !transaction?.moneyTypeNm?.includes('SEP'));
    const eeVolTransactions = volTransactions?.filter((transaction) => transaction?.moneyTypeNm?.includes('EE') || transaction?.moneyTypeNm?.includes('SEP'));
    const eeVolTotal = FormatUtils.roundNumber(eeVolTransactions?.reduce(totalReducer, 0) ?? 0, 2);
    const erVolTotal = FormatUtils.roundNumber(erVolTransactions?.reduce(totalReducer, 0) ?? 0, 2);

    const manFormerEmp = FormatUtils.roundNumber(formerManTransactions?.reduce(totalReducer, 0) ?? 0, 2);
    const volFormerEmp = FormatUtils.roundNumber(formerVolTransactions?.reduce(totalReducer, 0) ?? 0, 2);

    return { eeManTotal, erManTotal, eeVolTotal, erVolTotal, manFormerEmp, volFormerEmp };
};

const getInvestmentDirectionDetails = (props, selectedQuickPolicyInfo, policyAccountValueList, webCycleDate) => {
    let contributionDetails = {
        totalAmountOfContributionVO: {
            erManTotal: null,
            erVolTotal: null,
            eeManTotal: null,
            eeVolTotal: null,
            manFormerEmp: null,
            volFormerEmp: null,
            totalContribution: null
        },
        totalAmountOfNetContributionVO: {
            erManTotalNetCntrb: null,
            erVolTotalNetCntrb: null,
            eeManTotalNetCntrb: null,
            eeVolTotalNetCntrb: null,
            erManFormerEmpNetCntrb: null,
            erVolFormerEmpNetCntrb: null,
            totalNetCntrb: null
        },
        accountBalanceVO: {
            erManAccBal: null,
            erVolAccBal: null,
            eeManAccBal: null,
            eeVolAccBal: null,
            manAccBalFormerEmp: null,
            volAccBalFormerEmp: null,
            totalAccBal: null
        },
        rateOfReturnVO: {
            erManRoR: null,
            erVolRoR: null,
            eeManRoR: null,
            eeVolRoR: null,
            manRoRFormerEmp: null,
            volRoRFormerEmp: null,
            totalRoR: null
        }
    };
    const accountType = selectedQuickPolicyInfo?.accountType ?? '';
    let headers = {
        'pisys_auth_token': props?.pisysToken + "." + (props?.clientNmbr),
        'username': props?.clientNmbr,
        'Accept-Language': 'en',
        'Access-Control-Allow-Origin': '*'
    };

    const encodedToDt = (moment(webCycleDate).format("DD/MM/YYYY"));
    const encodedFromDt = (selectedQuickPolicyInfo?.effectiveDate);
    const clntRltnshpNmbr = (selectedQuickPolicyInfo?.clntRltnshpNmbr);
    const mergerAttributes = selectedQuickPolicyInfo?.contract?.cstmAttrTxt;
    props.dispatch(setUpdateDone(false));
    accountApi.getContributionRecords(encodedFromDt, encodedToDt, clntRltnshpNmbr, headers)
        .then((response) => {
            let decodedResponse = response.data;

            const contributionSeqNmbrs = [5, 75, 291, 259];
            const switchInSeqNmbrs = [148, 147, 292];
            const distributionSeqNmbrs = [92, 98, 149, 290, 258, 146, 293];
            const totalReducer = (total, trns) => total + Number(trns?.grossAmt ?? 0);
            let transactions = decodedResponse?.transactionHistoryResponseVos;

            transactions = removeTransaction(transactions, mergerAttributes?.[0]?.efctvDt, selectedQuickPolicyInfo?.product?.productTypeNmbr);
            transactions = removeLegalMergeTransfer(transactions);
            transactions = [...new Map(transactions.map(item => [item['trnsHstSeqNmbr'], item])).values()];

            const scContributions = transactions?.filter((trns) => (25 === trns?.activityTypeNmbr) && (trns?.subTrnsNm?.includes('SC2') || trns?.subTrnsNm?.includes('SC3') || trns?.subTrnsNm?.includes('SC4') || trns?.subTrnsNm?.includes('SC7') || trns?.subTrnsNm?.includes('SC8') || trns?.subTrnsNm?.includes('SC10')));
            let contributions = transactions?.filter((trns) => contributionSeqNmbrs.includes(trns?.activityTypeNmbr));
            contributions = contributions.concat(scContributions);
            const totalContribution = FormatUtils.roundNumber(contributions?.reduce(totalReducer, 0) ?? 0, 2);

            const switchIn = transactions?.filter((trns) => switchInSeqNmbrs.includes(trns?.activityTypeNmbr));
            const totalSwitchIn = FormatUtils.roundNumber(switchIn?.reduce(totalReducer, 0) ?? 0, 2);

            const pdDistributions = transactions?.filter((trns) => (94 === trns?.activityTypeNmbr) && !trns?.subTrnsNm?.includes('PD1') && !trns?.subTrnsNm?.includes('PD5'));
            const paDistributions = transactions?.filter((trns) => (95 === trns?.activityTypeNmbr) && trns?.subTrnsNm?.includes('PA2-OVERPAY'));
            let distributions = transactions?.filter((trns) => distributionSeqNmbrs.includes(trns?.activityTypeNmbr));
            distributions = distributions?.concat(pdDistributions);
            distributions = distributions?.concat(paDistributions);
            const totalDistributions = FormatUtils.roundNumber(distributions?.reduce(totalReducer, 0) ?? 0, 2);

            const totalNetCntrb = FormatUtils.roundNumber(totalContribution + totalSwitchIn - totalDistributions, 2);

            const distributedContributions = getDistributedTotals(contributions, totalReducer, accountType);

            const distributedSwitchIns = getDistributedTotals(switchIn, totalReducer, accountType);

            const distDistributions = getDistributedTotals(distributions, totalReducer, accountType);

            //Net Contribution Calculations
            const erManTotalNetCntrb = FormatUtils.roundNumber((distributedContributions?.erManTotal + distributedSwitchIns?.erManTotal - distDistributions?.erManTotal) ?? 0, 2);
            const eeManTotalNetCntrb = FormatUtils.roundNumber((distributedContributions?.eeManTotal + distributedSwitchIns?.eeManTotal - distDistributions?.eeManTotal) ?? 0, 2);
            const erVolTotalNetCntrb = FormatUtils.roundNumber((distributedContributions?.erVolTotal + distributedSwitchIns?.erVolTotal - distDistributions?.erVolTotal) ?? 0, 2);
            const eeVolTotalNetCntrb = FormatUtils.roundNumber((distributedContributions?.eeVolTotal + distributedSwitchIns?.eeVolTotal - distDistributions?.eeVolTotal) ?? 0, 2);
            const erManFormerEmpNetCntrb = FormatUtils.roundNumber((distributedContributions?.manFormerEmp + distributedSwitchIns?.manFormerEmp - distDistributions?.manFormerEmp) ?? 0, 2);
            const erVolFormerEmpNetCntrb = FormatUtils.roundNumber((distributedContributions?.volFormerEmp + distributedSwitchIns?.volFormerEmp - distDistributions?.volFormerEmp) ?? 0, 2);

            const totalAmtReducer = (total, fund) => total + Number(fund?.investmentAmt ?? 0);
            const totalAccBal = FormatUtils.roundNumber(policyAccountValueList?.reduce(totalAmtReducer, 0) ?? 0, 2);

            const distributedAccBal = getDistributedTotals(policyAccountValueList, totalAmtReducer, accountType);

            //ROR calculations
            const erManRoR = distributedContributions?.erManTotal !== 0 ? FormatUtils.roundNumber(((distributedAccBal?.erManTotal - erManTotalNetCntrb) * 100 / distributedContributions?.erManTotal), 2) : 0;
            const eeManRoR = distributedContributions?.eeManTotal !== 0 ? FormatUtils.roundNumber(((distributedAccBal?.eeManTotal - eeManTotalNetCntrb) * 100 / distributedContributions?.eeManTotal), 2) : 0;
            const erVolRoR = distributedContributions?.erVolTotal !== 0 ? FormatUtils.roundNumber(((distributedAccBal?.erVolTotal - erVolTotalNetCntrb) * 100 / distributedContributions?.erVolTotal), 2) : 0;
            const eeVolRoR = distributedContributions?.eeVolTotal !== 0 ? FormatUtils.roundNumber(((distributedAccBal?.eeVolTotal - eeVolTotalNetCntrb) * 100 / distributedContributions?.eeVolTotal), 2) : 0;
            const totalRoR = totalContribution !== 0 ? FormatUtils.roundNumber(((totalAccBal - totalNetCntrb) * 100 / totalContribution), 2) : 0;
            const manRoRFormerEmp = distributedContributions?.manFormerEmp !== 0 ? FormatUtils.roundNumber(((distributedAccBal?.manFormerEmp - erManFormerEmpNetCntrb) * 100 / distributedContributions?.manFormerEmp), 2) : 0;
            const volRoRFormerEmp = distributedContributions?.volFormerEmp !== 0 ? FormatUtils.roundNumber(((distributedAccBal?.volFormerEmp - erVolFormerEmpNetCntrb) * 100 / distributedContributions?.volFormerEmp), 2) : 0;

            ({ ...contributionDetails.totalAmountOfContributionVO } = { ...distributedContributions, totalContribution: totalContribution });
            ({ ...contributionDetails.totalAmountOfNetContributionVO } = {
                erManTotalNetCntrb: erManTotalNetCntrb,
                erVolTotalNetCntrb: erVolTotalNetCntrb,
                eeManTotalNetCntrb: eeManTotalNetCntrb,
                eeVolTotalNetCntrb: eeVolTotalNetCntrb,
                erManFormerEmpNetCntrb: erManFormerEmpNetCntrb,
                erVolFormerEmpNetCntrb: erVolFormerEmpNetCntrb,
                totalNetCntrb: totalNetCntrb
            });
            ({ ...contributionDetails.accountBalanceVO } = {
                erManAccBal: distributedAccBal?.erManTotal,
                erVolAccBal: distributedAccBal?.erVolTotal,
                eeManAccBal: distributedAccBal?.eeManTotal,
                eeVolAccBal: distributedAccBal?.eeVolTotal,
                manAccBalFormerEmp: distributedAccBal?.manFormerEmp,
                volAccBalFormerEmp: distributedAccBal?.volFormerEmp,
                totalAccBal: totalAccBal
            });
            ({ ...contributionDetails.rateOfReturnVO } = {
                erManRoR: erManRoR,
                erVolRoR: erVolRoR,
                eeManRoR: eeManRoR,
                eeVolRoR: eeVolRoR,
                manRoRFormerEmp: manRoRFormerEmp,
                volRoRFormerEmp: volRoRFormerEmp,
                totalRoR: totalRoR
            });

            props.dispatch(saveContributionDetails(contributionDetails));
            props.dispatch(setUpdateDone(true));
        })
        .catch(() => {
            props.dispatch(saveContributionDetails(contributionDetails));
            props.dispatch(setUpdateDone(true));
        });
};

const getScheme = (t, i18n, cardTitle, isORSO, productCustomFields, contractCustomField, ccfSchemeText) => {
    var schemeName = "";
    if (isORSO) {
        let poolingAgrmtList = productCustomFields?.filter((customeFieldsArray) => ((customeFieldsArray?.customDataDetailsList && customeFieldsArray?.cstmAttrNm) && ([pollingAgrmntName].includes(customeFieldsArray?.cstmAttrNm))));
        if (0 < poolingAgrmtList?.length) {
            if (poolingAgrmtList?.[0]?.customDataDetailsList?.[0]?.cstmAttrTxt) {
                if (i18n.language == EN) {
                    let nonCorpERList = contractCustomField?.filter((customeFieldsArray) => ((customeFieldsArray?.customDataDetailsList && customeFieldsArray?.cstmAttrNm) && ([cnNonCorpERName].includes(customeFieldsArray?.cstmAttrNm))));
                    if (0 < nonCorpERList?.length) {
                        if (0 < nonCorpERList?.[0]?.customDataDetailsList?.length) {
                            let sortedNonCorpERList = nonCorpERList?.[0]?.customDataDetailsList.sort(SortUtils.sortOnDate(chngDate, descendingOrder, DDMMYYYY));
                            sortedNonCorpERList = nonCorpERList?.[0]?.customDataDetailsList.sort(SortUtils.sortOnDate(effectiveDate, descendingOrder, DDMMYYYY));
                            schemeName = sortedNonCorpERList?.[0]?.cstmAttrTxt;
                        }
                    } else {
                        schemeName = cardTitle + t("portfolio.retirementScheme");
                    }
                } else {
                    schemeName = cardTitle + t("portfolio.retirementScheme");
                }
            }
        } else {
            let schemeNameEnList = productCustomFields?.filter((customeFieldsArray) => ((customeFieldsArray?.customDataDetailsList && customeFieldsArray?.cstmAttrNm) && ([schemeNameEn].includes(customeFieldsArray?.cstmAttrNm))));
            let schemeNameZhList = productCustomFields?.filter((customeFieldsArray) => ((customeFieldsArray?.customDataDetailsList && customeFieldsArray?.cstmAttrNm) && ([schemeNameZh].includes(customeFieldsArray?.cstmAttrNm))));
            if (i18n.language == ZH && 0 < schemeNameZhList?.length && schemeNameZhList?.[0]?.customDataDetailsList?.[0]?.cstmAttrTxt) {
                if (0 < schemeNameZhList?.length) {
                    if (schemeNameZhList?.[0]?.customDataDetailsList?.[0]?.cstmAttrTxt) {
                        schemeName = schemeNameZhList?.[0]?.customDataDetailsList?.[0]?.cstmAttrTxt;
                    }
                }
            } else {
                if (0 < schemeNameEnList?.length) {
                    if (schemeNameEnList?.[0]?.customDataDetailsList?.[0]?.cstmAttrTxt) {
                        schemeName = schemeNameEnList?.[0]?.customDataDetailsList?.[0]?.cstmAttrTxt;
                    }
                }
            }
        }
    } else {
        schemeName = (i18n.language == ZH && ccfSchemeText?.chineseSchemeName) ? ccfSchemeText?.chineseSchemeName : ccfSchemeText?.englishSchemeName;
    }
    return schemeName.toUpperCase();
};

export default { getGainLossData, getUnvestedAmount, getIntraGrpTranferData, getIntraGrpTranferDetailsWithContributions, getContributionGainAndLoss, getDistributedTotals, getInvestmentDirectionDetails, getScheme };