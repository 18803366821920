import { HKAIIB, S800Acronym } from "../translations/common/commonConstants";

const colorcodes = {
    1: "#0091DA",
    2: "#00C4D9",
    3: "#FF9231",
    4: "#FFC82E",
    5: "#68478D",
    6: "#333333",
    7: "#464E7E",
    8: "#00A29B",
    9: "#E74E1C",
    10: "#B30000",
    11: "#94D8FA",
    12: "#A3ABD8",
    13: "#A5EEF6",
    14: "#ADEEDD",
    15: "#FFEFAA",
    16: "#FFD9B8",
    17: "#FFC5B2",
    18: "#EEB0B0",
    19: "#DBD1FF"
};

const getColorByCode = (code) => {
    return colorcodes[code] ? colorcodes[code] : "black";
};

const getRiskIndicator = (risk, scheme) => {
    risk = risk ?? ''
    let riskIndicator = '';
    switch (risk.toUpperCase()) {
        case 'LOW':
            riskIndicator = ([S800Acronym, HKAIIB]?.includes(scheme)) ? 'low-risk' : 'ss-low-risk'
            break;

        case 'LOW TO MODERATE':
        case 'MODERATE':
        case 'MEDIUM':
            riskIndicator = 'moderate-risk'
            break;

        case 'LOW TO MEDIUM':
            riskIndicator = 'low-risk'
            break;

        case 'MODERATE TO HIGH':
        case 'MEDIUM TO HIGH':
            riskIndicator = 'moderate-to-high-risk'
            break;

        case 'HIGH':
            riskIndicator = 'high-risk'
            break;

        case 'DIS':
        case 'STABLE':
            riskIndicator = 'dis-risk'
            break;

        default:
            break;
    }

    return riskIndicator;
}

export { getColorByCode, getRiskIndicator };