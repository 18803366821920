import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import Breadcrumbs from '../../SharedComponents/Breadcrumbs';
import Header from "../../SharedComponents/Header";
import ChangesDetails from './ChangesDetails';
import ChangesFundDetailsComponent from './ChangesFundDetails';


const renderBreadCrumb = (t, currentPage, setcurrentPage) => {
  const history = useHistory();
  let propArr = [];
  if (currentPage === 'changes') {
    propArr = [
      {
        title: t("titles.dashboard"),
        active: true,
        route: '/'
      },
      {
        title: t("titles.switchingFundsInstructions"),
        active: false,
        route: 'changes'
      }
    ]
  } else if (currentPage === 'changesDetails') {
    propArr = [
      {
        title: t("titles.dashboard"),
        active: true,
        route: '/'
      },
      {
        title: t("titles.switchingFundsInstructions"),
        active: true,
        route: 'changes'
      },
      {
        title: t("changesDetails.pageTitle"),
        active: false,
        route: 'changesDetails'
      }
    ]
  } else if (currentPage === '/') {
    history.push('/dashboard')
  }


  return (<Breadcrumbs setCurrentPage={(route) => { setcurrentPage(route) }} currentPage={currentPage} breadCumbInfo={propArr}></Breadcrumbs>)
}

const renderPageContent = (currentPage, setcurrentPage) => {
  window.scrollTo(0, 0)
  const history = useHistory();
  const location = useLocation();

  const skipToPage = location.skipToPage;
  if (skipToPage) {
    return <ChangesDetails setCurrentPage={setcurrentPage} />;
  }
  else if (currentPage !== 'changes' && location.resetPage) {
    history.push({ resetPage: false })
    setcurrentPage('changes')
  } else if (location.resetPage) {
    history.push({ resetPage: false })
  }

  if (currentPage === 'changes') {
    return (<ChangesFundDetailsComponent setCurrentPage={setcurrentPage} />)
  } else if (currentPage === 'changesDetails') {
    return (<ChangesDetails setCurrentPage={setcurrentPage} />)
  }
}
const Changes = () => {
  const { t } = useTranslation("common");
  const location = useLocation();
  const [currentPage, setcurrentPage] = useState(location.skipToPage || 'changes');
  return (
    <>
      <Header />
      {renderBreadCrumb(t, currentPage, setcurrentPage)}
      {renderPageContent(currentPage, setcurrentPage)}
    </>
  );
}

export default Changes;