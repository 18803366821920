import React from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import '../../css/DBShortcut.css'

function DBShortcut(props) {
  const history = useHistory();
  return (
    <div data-testid="db-shortcut" onClick={()=>{(props?.goToPage)?(history.push(props.goToPage)):(window.open(props.goToLink))}} className="db-shortcut-d-center db-shortcut-card-contents pointer-cursor">
      <div><img className={(props?.goToPage)?('db-shortcut-icon'):('db-shortcut-icon-link')} src={props.icon}></img></div>
      <div><span className='db-shortcut-title'>{props.title}</span></div>
    </div>
  );
}

DBShortcut.propTypes = {
  props: PropTypes.any,
  icon: PropTypes.any,
  title: PropTypes.string,
  goToPage: PropTypes.string,
  goToLink:PropTypes.string
};

export default DBShortcut;
