import api from "../API/Common/commonApi";
import accountApi from "../API/Account/accountApi";
import postscribe from 'postscribe';
import moment from 'moment';
import { savePwcCheck, setPwcSvcFlag } from "../actions/AccountAction";
import AccountUtils from "./AccountUtils";

const getCycleDate = (headers) => {
  let promise = new Promise((resolve, reject) => {
    api
      .getCycleDate(headers)
      .then((response) => {
        let decodedResponse = response.data
        for (let index = 0; index < decodedResponse.length; index++) {
          if (decodedResponse[index].dateTypeCd == 1) {
            resolve(decodedResponse[index].cycleDt);
            break;
          }
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
  return promise;
};

const getLatestPriceDate = (productTypeNmbr, headers) => {
  let promise = new Promise((resolve) => {
    accountApi.getAsOfDate(productTypeNmbr, headers).then((asofDtResponse) => {
      let decodedResponse = (asofDtResponse.data)
      resolve(decodedResponse);
    })
  })
  return promise;
}


const pwcClientCheck = async (clientNumber, headers, props) => {
  let resp = {
    hsitfProhibit: false,
    changeAllowed: false,
  }
  props.dispatch(setPwcSvcFlag(true))
  await api.getInvestmentContributionValidations(clientNumber, headers)
    .then(async (response) => {
      let decodedResponse = (response.data);
      resp.hsitfProhibit = decodedResponse.hsitfProhibit;
      resp.changeAllowed = decodedResponse.changeAllowed;
      props.dispatch(savePwcCheck(resp))
      props.dispatch(setPwcSvcFlag(false))
      await AccountUtils.compareWebCycleDateWithExistingWebCycleDate(props, decodedResponse?.webCycleDate);
    })
    .catch(() => {
      props.dispatch(savePwcCheck(resp))
      props.dispatch(setPwcSvcFlag(false))
    });
};

const filterListFromTo = (type, response) => {
  let arr = [];
  let checkFlag = (type === "FROM") ? (1) : (2)
  response.pendingVoList.forEach((item) => {
    if (item.tferTypeCd === checkFlag) {
      arr.push(item)
    }
  })
  return arr;
}


const clubDuplicates = (objArray, type) => {
  let tempObj = {};
  objArray.forEach((obj) => {
    if (type === "SI") {
      if (!(obj.invTypeNmbr in tempObj)) {
        // tempObj[obj.invTypeNmbr] = Number(obj.requestedRate);
        tempObj[Number(obj.invTypeNmbr)] = { requestedRate: Number(obj.requestedRate), effectiveDate: obj.actyCycleDate, transactionEffectiveDate: obj.transactionEffectiveDate };
      } else {
        //tempObj[obj.invTypeNmbr] += Number(obj.requestedRate);
      }
    } else if (type === "SO") {
      if (!(obj.invTypeNmbr in tempObj)) {
        tempObj[Number(obj.invTypeNmbr)] = { requestedRate: obj.requestedRate, effectiveDate: obj.actyCycleDate, transactionEffectiveDate: obj.transactionEffectiveDate };
      } else {
        // tempObj[obj.invTypeNmbr] = {requestedRate: tempObj[obj.invTypeNmbr].requestedRate + obj.requestedRate,effectiveDate:obj.actyCycleDate};
      }
    }
  });
  let finalArr = [];
  for (const key in tempObj) {
    if (type === "SI") {
      finalArr.push({ invTypeNmbr: key, requestedRate: tempObj[key].requestedRate, effectiveDate: tempObj[key].effectiveDate, transactionEffectiveDate: tempObj[key].transactionEffectiveDate });
    } else if (type === "SO") {
      finalArr.push({ invTypeNmbr: key, requestedRate: tempObj[key].requestedRate, effectiveDate: tempObj[key].effectiveDate, transactionEffectiveDate: tempObj[key].transactionEffectiveDate });
    }
  }
  return finalArr;
}

const getPendingFunds = (clubArr) => {
  let obj = {}
  clubArr.forEach((item) => {
    if (!(item.invTypeNmbr in obj)) {
      obj[item.invTypeNmbr] = true
    }
  })
  return obj;
}


const getPendingFundsforSO = (clubFilteredFromArr, clubFilteredToArr, cycleDate) => {

  let obj = {};
  let switchCycleDate = moment(cycleDate, "DD/MM/YYYY");
  clubFilteredFromArr.forEach((item) => {
    let transEffectiveDate = moment(item.transactionEffectiveDate, "DD/MM/YYYY");
    if (!(item.invTypeNmbr in obj)) {
      if (switchCycleDate.toDate() > transEffectiveDate.toDate()) {
        if (item.requestedRate == 1) {
          obj[item.invTypeNmbr] = true;
        }
      } else {
        obj[item.invTypeNmbr] = true;
      }
    }
  })

  clubFilteredToArr.forEach((item) => {
    if (!(item.invTypeNmbr in obj)) {
      obj[item.invTypeNmbr] = true;
    }
  })


  if (obj[298] || obj[310] || obj[300]) {
    obj[298] = true;
    obj[310] = true;
    obj[300] = true;
  }

  if (obj[313] || obj[314] || obj[315]) {
    obj[313] = true;
    obj[314] = true;
    obj[315] = true;
  }

  if (obj[311] || obj[302] || obj[304]) {
    obj[311] = true;
    obj[302] = true;
    obj[304] = true;
  }

  if (obj[312] || obj[306] || obj[308]) {
    obj[312] = true;
    obj[306] = true;
    obj[308] = true;
  }


  return obj;
}

const getPendingFundsforSMP = (clubFilteredFromArr, cycleDate) => {

  let obj = {};
  let switchCycleDate = moment(cycleDate, "DD/MM/YYYY");
  clubFilteredFromArr.forEach((item) => {
    let transEffectiveDate = moment(item.transactionEffectiveDate, "DD/MM/YYYY");
    if (!(item.invTypeNmbr in obj)) {
      if (switchCycleDate.toDate() > transEffectiveDate.toDate()) {
        if (item.requestedRate == 1) {
          obj[item.invTypeNmbr] = true;
        }
      } else {
        obj[item.invTypeNmbr] = true;
      }
    }
  })


  if (obj[298] || obj[310] || obj[300]) {
    obj[298] = true;
    obj[310] = true;
    obj[300] = true;
  }

  if (obj[313] || obj[314] || obj[315]) {
    obj[313] = true;
    obj[314] = true;
    obj[315] = true;
  }

  if (obj[311] || obj[302] || obj[304]) {
    obj[311] = true;
    obj[302] = true;
    obj[304] = true;
  }

  if (obj[312] || obj[306] || obj[308]) {
    obj[312] = true;
    obj[306] = true;
    obj[308] = true;
  }

  return obj;
}

const getOnlyPendingFunds = (clubFilteredFromArr, cycleDate) => {

  let obj = {};
  let switchCycleDate = moment(cycleDate, "DD/MM/YYYY");
  clubFilteredFromArr.forEach((item) => {
    let transEffectiveDate = moment(item.transactionEffectiveDate, "DD/MM/YYYY");
    if (!(item.invTypeNmbr in obj)) {
      if (switchCycleDate.toDate() <= transEffectiveDate.toDate()) {
        obj[item.invTypeNmbr] = true;
      }
    }
  })

  if (obj[298] || obj[310] || obj[300]) {
    obj[298] = true;
    obj[310] = true;
    obj[300] = true;
  }

  if (obj[313] || obj[314] || obj[315]) {
    obj[313] = true;
    obj[314] = true;
    obj[315] = true;
  }

  if (obj[311] || obj[302] || obj[304]) {
    obj[311] = true;
    obj[302] = true;
    obj[304] = true;
  }

  if (obj[312] || obj[306] || obj[308]) {
    obj[312] = true;
    obj[306] = true;
    obj[308] = true;
  }

  return obj;
}


const getListofPendingFunds = (props, clientRelationShipNumber, productTypeNumber, moneyType, headers) => {
  let resp = {
    pendingFundsObj: {},
    pendingFundsObjSO: {},
    processingFundsObj: {},
    pendingFundsObjSMP: {},
  }

  let promise = new Promise((resolve, reject) => {
    api
      .getInvestmentContributionPendingStatus(clientRelationShipNumber, productTypeNumber, moneyType, headers)
      .then(async (response) => {
        let decodedResponse = response.data
        await AccountUtils.compareWebCycleDateWithExistingWebCycleDate(props, decodedResponse?.webCycleDate);
        let cycleDate = (decodedResponse.effectiveDate) ? (decodedResponse.effectiveDate) : (decodedResponse.switchCycleDate);
        let filteredArr = filterListFromTo("FROM", decodedResponse);
        let pendingFlagObj = getPendingFunds(filteredArr)
        resp.pendingFundsObj = pendingFlagObj;

        let filteredFromArr = filterListFromTo("FROM", decodedResponse);
        let filteredToArr = filterListFromTo("TO", decodedResponse);

        resp.pendingFundsObjSO = getPendingFundsforSO(filteredFromArr, filteredToArr, cycleDate)
        resp.processingFundsObj = getOnlyPendingFunds(filteredFromArr, cycleDate)
        resp.pendingFundsObjSMP = getPendingFundsforSMP(filteredFromArr, cycleDate)
        resolve(resp)
      })
      .catch(() => {
        reject();
      });
  });
  return promise;
};

const gtmInitialize = function (w, d, s, l, i) { w[l] = w[l] || []; w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' }); var f = d.getElementsByTagName(s)[0], j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f); }

const loadScriptsAsync = (url) => {
  postscribe('#root', `<script language="javascript" src = ${url} ></script>`)
}

export default { getCycleDate, pwcClientCheck, getListofPendingFunds, getLatestPriceDate, gtmInitialize, loadScriptsAsync, clubDuplicates, getPendingFundsforSO, getOnlyPendingFunds, getPendingFundsforSMP };
